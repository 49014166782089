/* 309 롯데슈퍼(~12/18) */
const superData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
    ·전단적용기간 : 2024. 12. 12(목) ~ 2024. 12. 18(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이

    2024 연말 결산
    SUPER 페스타!

    L.POINT 최대 40%
    호주산 청정와규 전품목 (각 100g/냉장/호주산 소고기) 

    L.POINT 30% -> 롯데/신한/삼성카드 5% 추가할인
    국내산 돼지고기 삼겹살/목심 (각 100g/냉장/국내산 돼지고기) 각2,594원
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 35%
    홈파티 갈비찜 (100g/냉장/국내산 돼지고기) 1,391원
    ※조기 품절될 수 있습니다

    L.POINT 회원 3,000원 할인
    딸기 전품목(상품별 상이/국산)
    ※점별 운영상품 상이 ※조기 품절될 수 있습니다

    MISSION 미션! 물가를 잡아라
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다

    L.POINT 1,000원 -> 롯데/신한/삼성카드 1,000원 추가할인
    비파괴 당도선별 샤인머스캣 (1.5kg/박스/국산) 12,990원
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 2,000원
    한봉지 가득 활전복 (10마리/봉/냉장/국산) 8,990원

    L.POINT 50%
    자숙 닭가슴살 4종 모음전 (상품별 용량 상이/원산지 별도표기) 각1,390원
    ※점포별 한정수량 입고(품절주의)

    왕새우 튀김 (8입/15입, 팩, 원산지 별도표기) 5,490/9,990원

    해물양장피 (팩/원산지 별도표기) 10,900원

    CJ 비비고 통새우만두(200g) 2개 구매시 10,000원
    ※1개 구매시 5,990원

    인기 스낵 4종(상품별 용량 상이) 각990원
    ※크라운 콘칩 외 3종

    롯데카드/삼성카드 800원 할인
    다우니 섬유유연제 3종 (각 1L, 블루/퍼플/핑크) 각4,100원
    ※페이지 하단 카드할인 세부내용 참고

    2024 롯데마트와 롯데슈퍼가 매주 여러분의 경제적 소비생활을 지원합니다.
    이번주 핫프라이스 HOT
    신선한 겨울 제철 시금치 할인! 영양만점 시금치로 추위 극복!
    남해 시금치 (250g/봉/국산) 2,290원
    L.POINT 300원 -> 롯데/신한/삼성카드 900원 추가할인
    ※기간 : 12/12(목)~12/15(일), 4일간 ※일부 점포 '실속 겨울 시금치'로 운영
    ※페이지 하단 카드할인 세부내용 참고

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    ※점별 취급상품 상이
    제주GAP감귤(3kg/박스/국산) 11,990원

    스위트마운틴바나나 (송이/필리핀산) 2,990원
    
    대봉시(4입/팩/국산) 4,990원

    브로콜리(1입/봉) 2개 구매시 3,980원
    ※1개 구매시 2,990원

    롯데카드/삼성카드 1,000원 할인
    무항생제 판계란(30입/대란/국산) 5,990원
    ※기간 : 12/13(금) ~ 12/15(일)
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 50%
    산더미 대파 소불고기(800g/냉장/원산지 별도표기) 13,900원
    ※12/12(목)~12/15(일), 4일간
    ※기간 외 12/16(월)~12/18(수) 27,800원 16,680원 L.POINT 40%
    
    롯데/신한/삼성카드 30%
    한우 국거리/불고기(1등급)(각 100g/냉장/국내산 한우고기)
    ※페이지 하단 카드할인 세부내용 참고

    2봉 이상 각500원 할인
    청양고추/오이맛고추/꽈리고추 (각 봉/국산) 2봉 이상 구매시 1봉당 각1,490원
    ※교차구매 가능
    ※1봉 구매시 각1,990원

    L.POINT 10,000원
    파타고니아 생연어 필렛 (500g/냉장/칠레산) 19,900원

    하나사면 하나 더 1+1
    2개 이상 구매시 50%
    1+1
    빙그레 요플레 5종(각 85g×4입) 각3,780원
    ※클래식/플레인/샤인머스캣/제주감귤/라이트바나나
    ※교차구매 가능

    1+1
    CJ 비비고 슈크림붕어빵 (300g) 9,990원ㄴ

    1+1
    삼진 오동통 모듬어묵 (700g) 9,990원

    1+1
    오뚜기 용기죽 4종(상품별 상이) 각4,490원

    2개 이상 50%
    자임 스테비아 고흥 유자차/제주 레몬차(각 1kg) 2개 이상 구매시 1개당 각5,450원
    ※1개 구매시 각10,900원

    1+1
    CJ 백설 고소한 맛 진한 참기름(320ml) 10,900원

    1+1
    포스트 고소한아몬드 후레이크(620g) 10,900원

    1+1
    코카콜라 스프라이트/제로 (각 1.5L) ※교차구매 가능 각3,040원

    1+1
    풀무원 새콤달콤 유부초밥 (330g) 5,690원
    ※기간 : 12/12(목)~12/15(일)

    1+1
    풀무원 가케우동 2인 (407.6g) 6,990원
    ※기간 : 12/12(목)~12/15(일)
    
    1+1
    닥터오트커 까사디마마 포미트/불고기 피자(357g/345g) 각9,990원
    ※교차구매 가능

    2개 이상 50%
    크리넥스 울트라클린 시그니처(30m×30롤) 2개 이상 구매시 1개당 각14,450원
    ※1개 구매시 각28,900원

    서울우유 (2.3L) 6,990원

    2개 이상 50%
    롯데슈퍼&롯데마트 단독!
    풀무원 점보 밀떡볶이(383.5g) 2개 이상 구매시 1개당 각2,995원
    ※풀무원 점보 돈까스와 교차구매 가능
    ※1개 구매시 5,990원

    2개 이상 50%
    롯데슈퍼&롯데마트 단독!
    풀무원 점보 돈까스(300g) 2개 이상 구매시 1개당 각5,295원
    ※풀무원 밀떡볶이와 교차구매 가능
    ※1개 구매시 10,590원

    2개 이상 50%
    명장불난로 핫팩 4종 2개 이상 구매시 1개당 각1,790~3,290원
    ※1개 구매시 각3,590~6,590원

    엘라스틴 카멜리아 블랙 샴푸/트리트먼트(1,000ml) 각8,950원

    L.POINT 30%
    동원 훈제연어 (180g/냉동/원산지 별도표기) 7,630원

    2개 이상 40%
    궁 쇠고기육포 오리지널/골든올리브(각 100g) 2개 이상 구매시 1개당 각8,940원
    ※교차구매 가능
    ※1개 구매시 각14,900원

    맥심 모카믹스 (12g×200입) 29,980원

    롯데/신한/삼성카드 1,800원 할인
    하이뮨 프로틴밸런스(190ml×16입) 19,800원
    ※페이지 하단 카드할인 세부내용 참고

    휴럼 배도라지 스틱(10g×28포) 7,000원 할인 11,900원 

    세계맥주 특가
    4캔 구매시
    길디필스너, 길디오리지널(각 568ml/캔)/기린당류제로, 아사히맥주(각 500ml/캔) 9,400원
    ※1캔 구매시 각 3,000원

    4캔 구매시
    제주위트에일/타이거레몬/ 북극곰의 눈물(잔기획)(720ml) 코젤다크(각 500ml/캔) 8,400원
    ※1캔 구매시 각 3,000원

    북극곰의 눈물(잔기획)(720ml) 24,900원
    북극곰의 눈물 전용 사케잔 2PCS 증정! 롯데슈퍼&롯데마트 단독기획!

    8,000원 할인
    19크라이스 쉬라즈/까베르네소비뇽(각 750ml) 각19,900원

    최대8,000원 할인
    업타운 마가리타/업타운 캐리비안 펀치(각 750ml) 각9,900원

    ※식품구매시 ‘유통기한’을 꼭 확인하시기 바랍니다 ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율,가공지역, 품질보증기간 및 취급시 주의사항은 제품 및 라벨등에 표시되어있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다

  </div>
  `,
];

/* 209 210 롯데슈퍼(~12/11) */
const superNewData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
    ·전단적용기간 : 2024. 12. 5(목) ~ 2024. 12. 11(수)
    ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이

    롯데슈퍼
    윈터딸기클럽 오픈!
    딸기에 진심인 당신을 위한 최고 품질&최다 품종

    2024 롯데마트와 롯데슈퍼가 매주 여러분의 경제적 소비생활을 지원합니다
    이번주 핫프라이스 HOT
    윈터딸기클럽 런칭 기념 '달콤한 특가'
    딸기 전품목 (상품별 상이/국산)
    L.POINT회원 롯데/신한/삼성카드 결제시 각3,000원 할인
    ※L.POINT 회원 각 2천원 할인 해당카드 결제시 각 1천원 추가할인 ※교차구매 가능
    ※점별 운영상품 상이 ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    1+1 
    떠먹는요구르트 17종(상품별 용량 상이) 3,780~6,090원
    ※빙그레(딸기라이트/라이트슈퍼베리/플레인화이트) 外
    ※브랜드별 동일 중량 교차구매 가능

    롯데 몽쉘 딸기 외 3종(각 408g) 각4,980원

    롯데카드/삼성카드 2,000원 할인
    스트로베리 에디션 무형광 3겹 화장지 (28m×30롤) 9,900원
    ※페이지 하단 카드할인 세부내용 참고

    스트로베리 에디션 물티슈(100매)/미용티슈(180매×3입) 1,000/3,300원

    MISSION 미션! 물가를 잡아라
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.

    스윗볼 스테비아 대추방울토마토 (500g/팩/국산) 6,990원
    ※기간 : 12/5(목)~12/8(일)

    2봉 이상 각500원 
    청양고추(봉/국산) 2봉 이상 구매시 1봉당 각1,490원
    ※1봉 구매시 각 1,990원

    1+1
    제주우유(각 750ml) 각4,990원
    ※무항생제/A2유기농/저지방
    ※교차구매 가능

    햇 절임차 2kg 가성비 기획(2kg) 각1,000원 할인 각9,900원

    2024 연말결산 SUPER 페스타!
    L.POINT 35% 롯데카드/삼성카드 15% 추가할인
    한우 국거리/불고기(1등급)(각 100g/냉장/국내산 한우고기)
    ※기간 : 12/5(목)~12/8(일)
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 40%
    훈제오리 슬라이스 (540g/냉장/원산지별도표기) 13,140원

    AI선별 제주 올레길 감귤 (2.5kg/박스/국산) 13,990원

    롯데단독 롯데슈퍼 인기 대란템!
    12/7(토) 2차 판매 시작!
    롯데 빼빼로 PLAVE 기획 (크런키 140g/아몬드 132g) 각4,780원
    해당상품 2개 구매시 플레이브 포토카드 1세트(2매) 증정! 
    ※점별 입고 수량 상이

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div> 
  `,
  `
  <div>
    상생 사과(4~7입/봉/국산) 10,990원

    밤고구마/감자 (각 1.5kg/박스/국산) 6,990/5,990원

    2팩 이상 각500원 할인
    서산 달래/태안 냉이 (80g/100g, 팩, 국산) 2팩 이상 구매시 1팩당 각2,490원
    ※교차구매 가능
    ※1팩 구매시 각2,990원

    국산 흰다리새우 (100g/냉장/국산) 2,590원

    1+1
    동원 양반 들기름/참기름/참숯구이/곱창돌김 (상품별 상이/원산지 별도표기) 각12,980원
    ※교차구매 가능

    L.POINT 25% + 롯데/삼성카드 5%
    한우 등심(1등급) (100g/냉장/국내산 한우고기)
    ※기간 : 12/5(목)~12/8(일), 4일간
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 3,000원 할인
    미국산 소고기 구이류 스킨팩 (300~400g/냉장/미국산 소고기)
    ※척아이롤/부채살/토시살/립아이캡

    L.POINT 25% 
    국내산 무항생제 돼지고기 삼겹살/앞다리(각 100g/냉장/국내산 한우고기) 2,993/1,995원
    ※조기 품절될 수 있습니다

    L.POINT 50% 
    산더미 콩나물 제육불고기 (700g/냉장/원산지별도표기) 9,900원

    2팩 이상 각2,000원 할인
    수제 모둠 소시지 2팩 이상 구매시 1팩당 각5,900원
    (500g/냉장/원산지 별도표기)
    ※1팩 구매시 7,900원

    크라운 버터와플(316g) 3,780원
    
    1+1
    동원 인기 파우치죽 6종 (각 420g) 각5,550~6,530원
    ※동일금액상품 교차구매 가능
    ※양반쇠고기죽/양반단호박죽/양반밤단팥죽/양반버섯야채죽/양반전복죽/수라고려인삼전복삼계죽

    L.POINT 4,000원
    후라이드 오징어튀김 오리지널(130g/원산지 별도표기) 8,900원

    2개 이상 50%
    코주부 징기스칸 육포 (각 130g, 순한맛/매운맛) 2개 이상 구매시 1개당 각7,450원
    ※1개 구매시 각14,900원ㄴ
    ※교차구매 가능

    2개 이상 50%
    리큐 액체세제 리필(각 2.6L, 일반/드럼) 2개 이상 구매시 1개당 각5,450원
    ※1개 구매시 각 10,900원

    하나사면 하나 더 1+1
    1+1
    사세 치킨가라아게 (500g) 10,990원

    2개 이상 50%
    삼진 전통모듬어묵/생생꼬불어묵꼬치(802g/416g) 2개 이상 구매시 1개당 각6,495/4,995원
    ※1개 구매시 각12,990/9,990원

    1+1
    오뚜기 가쓰오 우동(466g) 6,990원

    1+1
    마늘치킨 훈제 슬라이스 (400g/냉장/원산지 별도표기) 10,900원

    1+1
    사이다/펩시(각 1.25L) 각3,580원
    ※교차구매 가능
    ※ 사이다/사이다제로/펩시/펩시제로라임/펩시제로블랙
    
    1+1
    매일 뼈로가는 칼슘치즈 (180g) 7,190원

    서울우유 후레쉬 밀크 2입 기획(900ml×2) 4,990원

    오리온 초코파이 하우스 초코/딸기(각 408g) 각4,480원

    동원 인포켓치즈 (각 20g×10, 플레인/라이트) 각7,990원

    닥터리브 애사비 클렌즈 (15g×10개입) 3,000원 할인 8,900원

    벤앤제리스 3종(각 473ml) 각7,990원
    ※뉴욕수퍼퍼지청크/하프베이크드/초콜릿칩쿠키도우

    동서 미떼 오리지날&마일드 2+1기획(900g) 11,900원

    나무야 키친타월 (150매×4입) 3,500원

    롯데카드/삼성카드 1,000원 할인
    유한락스 세정제 2종(상품별 용량 상이) 5,900원
    ※페이지 하단 카드할인 세부내용 참고

    아임비타 멀티비타민 이뮨 플러스 (23.5g×7병) 4,000원 할인 12,900원

    미리 만나는 크리스마스
    북극곰의 눈물(잔기획)(720ml) 24,900원
    롯데슈퍼&롯데마트 단독!

    ※1캔당 1,850원
    테라 크리스마스 에디션(453ml×8입) 13,900원

    맛있는 간편식
    ※운영 점포에 한함
    끝장초밥 (원산지 별도표기) 7,990원

    경양식함박세트 (원산지 별도표기) 7,990원

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
 
  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getSuperData = () => {
  const dataArray = [];
  superData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getSuperNewData = () => {
  const dataArray = [];
  superNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L309: {
    title: "스마트전단지-슈퍼309",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202412_3/02",
    contents: getSuperData(),
  },
  L209: {
    title: "스마트전단지-슈퍼209",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202412_2/02",
    contents: getSuperNewData(),
  },
  L210: {
    title: "스마트전단지-슈퍼210",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202412_2/02",
    contents: getSuperNewData(),
  },
};

export default data;
