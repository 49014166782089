import styled from "styled-components";
import { IMAGE_PATH } from "./constants";
import { isMcouponApp } from "../../utils/utility";
import { isMobile } from "react-device-detect";

/**
 * @name 종이전단 화면 컴포넌트 240711
 */
export const StyledLeafletPaper = styled.div`
  padding-top: ${!isMcouponApp && isMobile ? "0.5rem" : "3.1rem"};
  position: relative;
  button {
    background-color: transparent;
  }

  .pc_subhome {
    position: relative;
    button {
      position: absolute;
      top: -2.5rem;
      left: 1rem;
      width: 2rem;
      height: 2rem;
    }
  }

  .paper-btns {
    position: relative;
    width: 90%;
    height: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
    background: #e60012;
    border-radius: 1.5rem;
    margin-top: 1rem;

    .paper-paging {
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      strong {
        font-family: "TheJamsil-4Medium";
        font-size: 22px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 0;
      width: 3rem;
      height: 100%;
      margin-top: 0;
      background-repeat: no-repeat;
      background-size: 2.2rem 2.2rem;
    }

    .swiper-button-next {
      right: -0.2rem;
      background-image: url("${IMAGE_PATH}/common/btn_arrow_right.png");
      background-position: calc(100% - 10px) 50%;
    }

    .swiper-button-prev {
      left: 0.3rem;
      background-image: url("${IMAGE_PATH}/common/btn_arrow_right.png");
      background-position: 10px 50%;
      transform: rotate(180deg);
    }
  }

  .paper-share {
    margin-top: 1rem;
    height: 5rem;
    position: relative;
    button {
      padding-bottom: 1rem;
      position: absolute;
      right: 1.3rem;
      width: 3rem;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
