import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 12. 12(목) ~ 2024. 12. 25(수) < 신선식품 적용기간 : 2024. 12. 12(목) ~ 12. 18(수) >

    롯데마트 매장에서 오직 롯데마트GO 회원에게만! 스노우 특가 상품을 찾아보세요!

    SNOWPLAN 페스타 HELLO Winter 
    스노우특가란?
    롯데마트 Go앱 회원 단독 특가로, 결제시 롯데마트 GO App의 바코드를 꼭 스캔해주세요
    ※핸드폰번호로 적립시, 스노우특가 적용 불가
    ※스노우특가 상품은 롯데마트GO앱 스캔 필수
    ※매장에 더 많은 스노우특가 상품이 준비되어 있습니다

    스노우특가 2,000원 할인
    비파괴 당도선별 샤인머스캣(1.5kg/박스/국산) 12,990원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 1+1 
    6개입 박스 컵라면 5종 (삼양 불닭볶음면 소컵 6개입 外 4종) 각5,200~6,980원
    ※기간 : 12/12(목) ~ 12/18(수) ※1인 1일 4개 구매한정
    ※동일 상품에 한함 ※페이지 하단 카드할인 세부내용 참고
    
    L.POINT 40% -> 롯데/신한/NH농협/삼성카드 결제 스노우특가 30% 추가할인
    치악산 한우 등심/국거리/불고기(각 1+/1++등급) (각 100g/냉장/국내산 한우고기)
    ※기간 : 12/14(토) ~ 12/15(일)
    ※점별 운영등급 상이 ※양지 국거리 제외
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    스노우특가 40%
    ALL NEW 새우 초밥(20입) (팩/원산지 별도표기) 11,994원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 50%
    킹크랩(100g/냉장/러시아산) 5,995원
    ※입점점에 한함 ※제주점 제외
    ※페이지 하단 카드할인 세부내용 참고

    스노우특가 20%
    완도 활전복(중/특)(마리/국산) 1,920/3,280원

    스노우특가 3,000원 할인
    제주 GAP 감귤(3kg/박스/국산) 11,990원

    스노우특가 1,500원 할인
    CJ 비비고 통새우만두(200g) 5,480원

    스노우특가 7,500원 할인
    씨제이 스팸 클래식(340g×3) 13,980원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 1,000원 할인
    아미카 엘도라다 포테이토칩 (400g/통) 8,980원
    ※점별 운영물량 상이
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 20% 스노우특가 25%
    제주 삼다수 그린(2L×6입/500ml×20입) 각3,888/5,760원
    ※교차구매 가능 ※제주점 행사 상이

    롯데/신한/NH농협/삼성카드 결제 스노우특가 10%
    정관장 활기력 샷 2종(20ml×8병, 아미노/에너지) 각18,720원
    ※페이지 하단 카드할인 세부내용 참고

    스노우특가 50%
    글라스락 핸디퓨어 3종(1.8L/2.7L/3.7L) 12,450~15,950원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 1+1
    스너글 전품목(각 470ml) 각8,500원
    ※교차구매 가능 ※페이지 하단 카드할인 세부내용 참고

    스노우특가 30%
    듀플렉스 무드등 가습기 27,930원

    스노우특가 30%
    오늘좋은 동물/프렌치 쿠션/방석 (상품별 상이) 11,130~17,430원
    ※입점점에 한함 ※해당상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    스노우특가 20%
    순면 긴팔 파자마 19,120원
    ※해당상품에 한함 ※점별 입고상품 상이

    스노우특가 30%
    반려동물 식품
    잇츄/츄잇 12종 (상품별 상이) 4,165~6,860원
    ※입점점에 한함

    2024 롯데마트가 여러분의 경제적 소비생활을 매주 지원합니다
    이번주 핫 프라이스 HOT
    롯데마트 연중 최저가 도전! 
    L.POINT 30% -> 롯데/신한/NH농협/삼성카드 10% 추가할인
    거꾸로 거슬러 오른 '역행 특가' 내가 만드는 연어 횟감용/구이용(각 100g/냉장/노르웨이산) 3,591/3,339원
    ※연중기간 : '23년 11월 ~ '24년 12월
    ※기간 : 12/12(목) ~ 12/18(수) ※페이지 하단 카드할인 세부내용 참고

    윈터딸기클럽 스탬프 이벤트
    ▶기간 : '24/12/5(목) ~ '25/1/22(수)
    혜택 1 스탬프미션 참여하기 누르면 2만원 딸기할인 쿠폰팩 증정! 
    혜택 2 선착순 1천명 BAGGU(바쿠) 딸기 장바구니 증정!

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    온가족 함께 즐기는 연말 홈파티 먹거리 추천!
    3개 이상 70%
    연중 최저가 도전! 닭가슴살 천원! ※연중 기간 : 24년 1월~24년 11월
    동물복지 수비드 닭가슴살 5종 모음전 (상품별 규격 상이/냉장/원산지 별도표기) 3개 이상 구매시 1개당 각1,000원
    ※점포별 한정수량 입고
    ※1개 구매시 각 3,330원

    L.POINT 최대 50%
    와규 윗등심, 국거리, 불고기/호주산 갈비살 (각 100g/냉장/호주산) 
    ※조기품절 될 수 있습니다
    ※기간 : 12/12(목)~12/15(일)

    국내산 돼지고기 삼겹살/목심 각2,490원
    (100g/냉장/국내산 돼지고기) ※제주점 제외

    2개 이상 50%
    산더미 대파 소불고기 (800g/원산지 별도표기) 2개 이상 구매시 1개당 각13,900원
    ※조기 품절될 수 있습니다
    ※1개 구매시 40% 할인 16,680원

    국내산 돼지갈비/등갈비 (각 100g/냉장/국내산 돼지고기) 1,190/1,990원
    ※기간 : 12/12(목)~12/15(일) ※제주점 제외 ※조기 품절될 수 있습니다

    L.POINT 25%
    New 한통 가아아득 치킨(1팩/국내산 계육) 9,743원
    ※제주점 제외

    L.POINT 25%
    마늘 치킨구이(1팩/원산지 별도표기) 8,993원

    족발 패밀리세트(1팩/원산지 별도표기) 19,800원
    ※연말 홈파티 안주류 3종(불껍데기/순살족/불족)
    ※족발 입점점에 한함

    샌드위치 4종(각 1팩/원산지 별도표기) 각4,990원
    ※듬뿍햄치즈/듬뿍에그햄/키토에그/크랜베리 치킨

    10개 구매시
    해금 골드키위(개/국산) 9,900원
    ※조기품절될 수 있습니다
    ※1개 구매시 1,290원

    필리핀산 스위트마운틴 바나나 (1.2kg 내외/송이/필리핀산) 2,990원

    옥광밤(600g/팩/국산) 9,990원
    ※조기 품절될 수 있습니다

    CJ 육공육 스모크 소시지(260g×2)/목우촌 주부9단 프랑크소시지(500g)/사조대림 잠룽 부어스트(705g) 각5,980~7,380원

    2개 이상 50%
    동원 바른어묵 전골용(350g)/ 삼진어묵 오동통 모듬어묵(700g) 2개 이상 구매시 1개당 각2,240/4,990원
    ※동일상품에 한함
    ※1개 구매시 각4,480/9,980원

    2개 이상 50% 롯데단독
    풀무원 점보 떡볶이/돈까스 (상품별 상이) 2개 이상 구매시 1개당 각2,990/4,990원
    ※교차구매 가능
    ※1개 구매시 각5,980/9,980원

    롯데/신한/NH농협/삼성카드 1+1 롯데단독
    동원 양반 김말이(406g) 8,980원
    ※페이지 하단 카드할인 세부내용 참고

    필라델피아 치즈케익/오레오 치즈케익(각 794g) 22,380/22,480원

    2개 이상 20%
    폰타나 파스타소스 전품목 13종 (상품별 규격 상이) 2개 이상 구매시 1개당 각5,184~7,184원
    ※교차구매 가능
    ※1개 구매시 각6,480~8,980원

    2개 이상 20%
    오뚜기 소스 11종(상품별 규격 상이) 2개 이상 구매시 1개당 각2,464~4,384원
    ※오뚜기 돈까스 소스 外 ※교차구매 가능
    ※1개 구매시 각3,080~5,480원

    빙그레 요플레 기획 (85g×10입) 5,980원

    1+1
    돈 시몬 4종(각 1L) 6,980~8,980원
    ※오렌지, 홍자몽/만다린, 토마토
    ※동일 가격간 교차구매 가능

    L.POINT 500원 
    삼립 바닐라빈 슈크림 호빵 (90g×4입) 5,480원

    50%
    임페리얼 데니쉬쿠키(500g) 5,990원

    1+1
    요거트향 퍼프 패스트리(176g)/마요라 커피조이(351g) 5,980/7,980원
    ※동일상품에 한함

    프리미엄 참다랑어 모둠회 (300g 내외/냉장/참다랑어 : 원산지 별도표기) 29,800원
    ※입점점에 한함 ※연출된 컷입니다

    L.POINT 2,000원
    반각 석화(700g/냉장/원산지 별도표기) 14,900원

    L.POINT 3,000원
    서울 트리플 슈레드 치즈 (1kg) 14,980원

    주류 믹서 음료 15종 ※상품별 행사 상이 각740~4,190원
    (진로 토닉워터 300ml 外 14종)

    2개 이상 50%
    궁 쇠고기 육포 오리지널/골든올리브 2개 이상 구매시 1개당 각7,450원
    (각 100g/원산지 별도표기) ※교차구매 가능
    ※1개 구매시 각14,900원

    롯데/신한/NH농협/삼성카드 10,000원 할인
    파이퍼 하이직/모엣 샹동 브뤼 (각 750ml) 59,900원
    ※1인 2병 구매 한정
    ※페이지 하단 카드할인 세부내용 참고

    북극곰의 눈물(도자기잔 증정) (720ml) 24,900원
    북극곰의 눈물 전용 사케잔 2PCS 증정!

    5캔 구매시
    세계맥주 32종 골라담기 (상품별 상이) 11,000원
    ※1캔 구매시 각 2,280~3,300원

    스텔라 아르투아 홀리데이 기획팩(500ml×6) 17,900원

    풀무원 다논 요구르트 전품목 1만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉭ "를 확인하세요
    ※결제시 계산대에서 수령
    ※점별 운영상품 상이 ※교차구매 가능
    1+1
    풀무원 다논 하루요거트 3종/그릭 시그니처 2종 외 (상품별 상이) 각3,780~6,480원
    ※동일 가격 교차구매 가능 

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    온가족 함께 즐기는 해피 스트로베리 크리스마스!

    딸기 전품목(상품별 상이/국산) 롯데/신한/NH농협/삼성카드로 2개 이상 구매시 각4,000원 할인
    ※ 2개 이상 구매시 각 2천원 할인 해당카드 결제시 각 2천원 추가할인
    ※1개 구매시 카드할인 2천원만 적용
    ※교차구매 가능 ※점별 운영상품 상이
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    최대2,100원 할인
    멜론 크림 카스텔라(2입)/크리스마스 카스텔라 (각 1팩/원산지 별도표기) 각4,900/9,900원
    ※벌꿀/녹차
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    해태 버터링딥 레드벨벳/티라미수(각 155g) 각3,980원
    
    롯데칠성음료 
    롯데칠성음료 1만 5천원 이상 구매시 롯데 말랑카우 딸기우유(79g)증정
    ※입점점에 한함 ※점별 수량 한정
    ※증정품 소진시 행사 자동 종료
    ※구분자 " ㉣ "을 확인하세요
    ※당일 영수증에 한함
    ※비연속식 증정(1일 1인 1개)
    ※도와드립니다 센터에서 증정품 수령

    1+1
    칠성사이다/칠성사이다제로/펩시콜라/펩시콜라제로 각3,280원
    (각 1.25L) ※교차구매 가능

    크라운 포차코/헬로키티 과자 선물세트(170g/193g) 각4,980/6,980원

    2개 이상 20%
    크리스마스 식기/머그류(상품별 상이) 2개 이상 구매시 1개당 각2,320~7,920원
    ※1개 구매시 각 2,900~9,900원

    최대 30%
    포인세티아/미니트리 외 크리스마스 용품(상품별 상이) 1,520~39,200원
    ※기간 : 12/12(목)~12/18(수)

    2,000원 할인
    코코도르 크리스마스 디퓨저(200ml)/윈터랜드 1+1디퓨저(500ml×2) 7,900~17,900원

    2개 이상 50%
    오늘좋은 부드러운 양면담요(각 100×140cm) 2개 이상 구매시 1개당 각9,950원
    ※교차구매 가능 ※크리스마스/스트라이프/에스닉코지 ※입점점에 한함
    ※해당상품에 한함 ※한정수량으로 조기 품절될 수 있습니다
    ※1개 구매시 각 19,900원

    크리스마스 양말/패션악세서리(상품별 상이) 2,900~8,900원
    ※입점점에 한함 ※점별 입점상품 상이

    2개 이상 30%
    파밍트리 아동 크리스마스 상하세트 (상품별 상이) 2개 이상 구매시 1개당 각9,030원
    ※교차구매 가능
    ※1개 구매시 각 12,900원

    20%
    산리오 외 캐릭터 종이컵(상품별 상이) 1,910~2,390원
    ※입점점에 한함 ※점별 재고 상이    

    요리하다 온가족 함께 즐기는 연말 먹거리 추천!
    1+1
    요리하다 포르치니&트러플 라비올리/치즈&페퍼 라비올리(각 250g) 각7,990원
    ※교차구매 가능

    L.POINT 1,000원
    요리하다 후라이드 바사삭 치킨(400g) 4,990원

    L.POINT 1,000원
    요리하다 정통 가쓰오우동(484g) 3,990원

    요리하다×롯데월드 로티 미니치즈까스(350g)/미니고로케(400g) 4,990/5,990원

    오늘 가장 좋은 선택 오늘좋은 BEST MD'S PICK
    오늘좋은 코인 초콜릿 크리스마스(78g) 2,990원

    오늘좋은 프레첼 스낵믹스 2종(각 65g) 각1,000원
    ※멕시칸타코맛/체다치즈맛

    L.POINT 1,000원
    오늘좋은 고래사 종합 모둠어묵탕(700g) 6,990원

    최대600원 할인
    오늘좋은 스파게티(500g/1kg) 1,690/2,990원

    ToysRus
    L.POINT회원 롯데/신한/NH농협/삼성카드
    전점 8만장 한정
    완구 6만원 이상 구매시 10,000원 롯데상품권 증정
    ▶기 간 : 12/12(목) ~ 12/25(수)
    ※전자게임/유아용품/문구/서적 등 일부품목 제외
    ※상품권 소진 시 행사가 조기종료될 수 있습니다
    ※L.POINT회원 해당카드 결제시에 한함
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1회)
    ※페이지 하단 카드할인 세부내용 참고

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    금주의 쇼핑추천 아이템!
    L.POINT 3,000원
    과메기 야채모둠 (200g/냉장/원산지 별도표기) 16,900원

    L.POINT 30%
    직소싱 껍질벗긴 새우 (750g/냉동/원산지 별도표기) 16,730원
 
    CJ 1등급 깨끗한 계란/풀무원 1+등급 신선란 (각 대란/25구/국산) 각7,990원
    ※상품별 조기 품절될 수 있습니다

    2개 이상 각 1,000원 할인
    브로콜리(개/국산) 2개 이상 구매시 1개당 각1,990원
    ※1개 구매시 2,990원
    
    2개 이상 각1,980원 할인
    오뚜기 우동/칼제비 5종(각 2인) 2개 이상 구매시 1개당 각5,000원
    ※교차구매 가능
    
    1+1
    진주햄 천하장사 2종/CJ 맥스봉 2종(상품별 상이) 7,980/8,480원
    ※기간 : 12/12(목)~12/15(일)
    ※동일 브랜드간 교차구매 가능

    1+1
    롯데 식사이론 만두 3종 6,980~9,980원
    (상품별 상이) ※대파고기/메밀부추/납작만두
    ※동일 가격 간 교차구매 가능

    1+1
    사세 치킨가라아게(500g)/버팔로윙 마일드 허니맛(310g) 9,900/9,980원
    ※동일 상품에 한함

    2개 이상 각2,500원 할인
    오뚜기 크러스트 피자 3종/붕어빵 2종 (상품별 상이) 2개 이상 구매시 1개당 각6,480~6,980원
    ※동일 가격 간 교차구매 가능
    ※콘, 미트콤보, 페퍼로니/팥, 슈크림
    ※1개 구매시 각8,980~9,480원

    1+1
    차오차이 즉석 짜장/마파두부 7종(각 180g) 각3,980원
    ※130직화 간짜장 外 6종 ※교차구매 가능

    2,500원 할인
    슈퍼너츠 피넛버터 스무스/크런치(각 460g) 11,900원

    2개 이상 10%
    코카콜라 6입 캔 번들 7종 2개 이상 구매시 1개당 각3,087~3,753원
    (215ml×6캔/ 210ml×6캔) ※교차구매 가능
    ※1개 구매시 각3,430~4,170원

    동서 맥심 기획 모카골드 33,000원
    (12g×60포×4개) ※점포별 재고 상이
    ※한정수량으로 조기 품절될 수 있습니다

    1+1
    동서 포스트 콘푸라이트/켈로그 첵스초코(600g/570g) 7,380/8,880원
    ※동일 상품에 한함

    서울 흰우유(2.3L) 6,980원

    1+1
    베지밀 검은콩 고칼슘 두유(파우치)/삼육 검은콩과 호두 아몬드(190ml×15입 / 190ml×16입) 18,750/17,400원
    ※동일 상품에 한함

    1+1
    내추럴박스/다정헌 레몬즙 2종(상품별 상이) 각9,990/15,900원
    ※동일 상품에 한함

    1+1
    상쾌환 숙취해소제 6종 (상품별 상이) 각7,480~9,980원
    ※상쾌환/스틱/부스터
    ※동일 가격간 교차구매 가능

    2개 이상 50%
    인기 클렌징 대전(상품별 상이)
    ※ 뉴트로지나, 식물나라, 해피바스 등 11개 브랜드
    ※재고소진 품목 제외 ※점별 운영 상품 상이
    ※동일 브랜드에 한하여 교차구매 가능    

    2개 이상 50%
    히말라야/맨소래담/소프트립스 립케어(상품별 상이)
    ※점별 운영 상품 상이 ※일부 재고소진 품목 제외
    ※브랜드별 교차 구매 가능

    최대3,600원 할인
    니베아 바디로션 1+1기획 14,240~16,200원
    (각 400ml×2, 인텐시브/SOS)

    2개 이상 50%
    크리넥스/좋은느낌/화이트 등 최대 37종(상품별 상이) 2개 이상 구매시 1개당 각1,300~8,950원
    ※교차구매 가능 ※점별 운영 상품 상이
    ※1개 구매시 각2,600~17,900원

    2개 이상 50%
    크리넥스 울트라클린 시그니처(30m×30롤) 2개 이상 구매시 1개당 각14,450원
    ※1개 구매시 28,900원

    2개 이상 50%
    퍼실/퍼울 액체세제 전상품(상품별 상이) 2개 이상 구매시 1개당 각8,750~14,950원
    ※교차구매 가능 ※점별 취급상품 상이
    ※퍼실 디스크 캡슐세제 제외
    ※1개 구매시 각17,500~29,900원

    2개 이상 50%
    다우니 섬유유연제 8종(용기 각 2L/리필 각 2.6L) 2개 이상 구매시 1개당 각9,800~11,650원
    ※교차구매 가능
    ※1개 구매시 각19,600~23,300원

    2개 이상 55%
    엘라스틴 카멜리아 블랙 샴푸/트리트먼트(각 1,000ml) 2개 이상 구매시 1개당 각8,055원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각17,900원

    롯데/신한/NH농협/삼성카드 2개 이상 50%
    프로쉬 전품목(상품별 상이) 해당카드로 2개 이상 구매시 1개당 각3,450~7,750원
    ※교차구매 가능 
    ※기간 : 12/12(목)~12/15(일) ※해당 상품에 한함
    ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 각6,900~15,500원

    L.POINT 50%
    해피콜 루시드 프라이팬 17,450~26,950원
    
    L.POINT 50%
    글라스락 인기 밀폐용기 3종 (모듈러/퓨어오븐/스마트) 2,450~8,450원

    코렐 인기패턴 2종 (누보스케치/보라꽃) 30% 6,230~12,530원

    L.POINT 30%
    크린랩 레이스고무장갑/크린장갑(50매×2입) 각2,163~3,766원

    2개 이상 40%
    좋은부탄 캠핑에디션(4입) 2개 이상 구매시 1개당 각4,074원
    ※1개 구매시 6,790원

    오늘좋은 뱀부&코튼 세면타월(각 4P) 30% 각13,930원
    ※카키/민트 ※입점점에 한함 ※해당 상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    오늘좋은 개별 스위치 멀티탭 (상품별 상이) 20% 7,920~21,520원

    에너자이저 맥스 구매시 장바구니 증정!
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    에너자이저 맥스 18입(AA/AAA) 35% 각11,900원 

    2개 이상 30%
    방한 샌들/슬리퍼 2개 이상 구매시 1개당 각11,060~16,660원
    ※점별 입점 상품 상이 ※행사상품에 한함
    ※1개 구매시 각15,800~23,800원

    오뚜기 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉧"를 확인하세요
    2개 이상 각590원 할인
    오뚜기 진라면 순한맛/매운맛 (각 5개입) 2개 이상 구매시 1개당 각2,990원
    ※교차구매 가능
    ※1개 구매시 각3,580원

    애경 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "●"를 확인하세요
    2080 시그니처 토탈블루 치약 (130g×5입) 6,900원

    1+1
    케라시스 케라틴본드 샴푸/트리트먼트 (각 750ml, 딥리페어/볼륨) 각12,900원
    ※ 교차구매 가능 ※ 점별 운영 상품 상이

    디펜드 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 " D "를 확인하세요
    디펜드 언더웨어 10종(상품별 상이) 각9,500~16,500원

    3M 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 " M "를 확인하세요
    3M 올터치 막대걸레 (표준형/대형) 각26,290/29,590원
    ※입점점에 한함
    ※해당 상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    위스카스/쉬바/템테이션 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉠"를 확인하세요
    위스카스/쉬바/템테이션 브랜드(상품별 상이) 30% 840~18,130원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2024. 12. 12(목) ~ 2024. 12. 25(수) < 신선식품 적용기간 : 2024. 12. 12(목) ~ 12. 18(수) > 
    ·제타플렉스 잠실점

    제타플렉스 매장에서 오직 롯데마트GO회원에게만! 스노우 특가 상품을 찾아보세요!
    SNOWPLAN 페스타 Hello Winter 
    스노우특가란?
    롯데마트 Go앱 회원 단독 특가로, 결제시 롯데마트 GO App의 바코드를 꼭 스캔해주세요
    ※핸드폰번호로 적립시, 스노우특가 적용 불가
    ※스노우특가 상품은 롯데마트GO앱 스캔 필수
    ※매장에 더 많은 스노우특가 상품이 준비되어 있습니다

    스노우특가 2,000원 할인
    비파괴 당도선별 샤인머스캣(1.5kg/박스/국산) 12,990원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 1+1 
    6개입 박스 컵라면 5종 (삼양 불닭볶음면 소컵 6개입 外 4종) 각5,200~6,980원
    ※기간 : 12/12(목) ~ 12/18(수) ※1인 1일 4개 구매한정
    ※동일 상품에 한함 ※페이지 하단 카드할인 세부내용 참고

    L.POINT 40% -> 롯데/신한/NH농협/삼성카드 결제 스노우특가 30% 추가할인
    치악산 한우 등심/국거리/불고기(각 1+/1++등급) (각 100g/냉장/국내산 한우고기)
    ※기간 : 12/14(토) ~ 12/15(일)
    ※점별 운영등급 상이 ※양지 국거리 제외
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    스노우특가 40%
    ALL NEW 새우 초밥(20입) (팩/원산지 별도표기) 11,994원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 50%
    킹크랩(100g/냉장/러시아산) 5,995원
    ※입점점에 한함 ※제주점 제외
    ※페이지 하단 카드할인 세부내용 참고

    스노우특가 20%
    완도 활전복(중/특)(마리/국산) 1,920/3,280원

    스노우특가 3,000원 할인
    제주 GAP 감귤(3kg/박스/국산) 11,990원

    스노우특가 1,500원 할인
    CJ 비비고 통새우만두(200g) 5,480원

    스노우특가 7,500원 할인
    씨제이 스팸 클래식(340g×3) 13,980원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 1,000원 할인
    아미카 엘도라다 포테이토칩 (400g/통) 8,980원
    ※점별 운영물량 상이
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 20% 스노우특가 25%
    제주 삼다수 그린(2L×6입/500ml×20입) 각3,888/5,760원
    ※교차구매 가능 ※제주점 행사 상이

    롯데/신한/NH농협/삼성카드 결제 스노우특가 10%
    정관장 활기력 샷 2종(20ml×8병, 아미노/에너지) 각18,720원
    ※페이지 하단 카드할인 세부내용 참고

    스노우특가 50%
    글라스락 핸디퓨어 3종(1.8L/2.7L/3.7L) 12,450~15,950원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 1+1
    스너글 전품목(각 470ml) 각8,500원
    ※교차구매 가능 ※페이지 하단 카드할인 세부내용 참고

    스노우특가 30%
    듀플렉스 무드등 가습기 27,930원

    스노우특가 30%
    오늘좋은 동물/프렌치 쿠션/방석 (상품별 상이) 11,130~17,430원
    ※입점점에 한함 ※해당상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    스노우특가 20%
    순면 긴팔 파자마 19,120원
    ※해당상품에 한함 ※점별 입고상품 상이

    스노우특가 30%
    반려동물 식품
    잇츄/츄잇 12종 (상품별 상이) 4,165~6,860원
    ※입점점에 한함

    2024 롯데마트가 여러분의 경제적 소비생활을 매주 지원합니다
    이번주 핫 프라이스 HOT
    롯데마트 연중 최저가 도전! 
    L.POINT 30% -> 롯데/신한/NH농협/삼성카드 10% 추가할인
    거꾸로 거슬러 오른 '역행 특가' 내가 만드는 연어 횟감용/구이용(각 100g/냉장/노르웨이산) 3,591/3,339원
    ※연중기간 : '23년 11월 ~ '24년 12월
    ※기간 : 12/12(목) ~ 12/18(수) ※페이지 하단 카드할인 세부내용 참고

    윈터딸기클럽 스탬프 이벤트
    ▶기간 : '24/12/5(목) ~ '25/1/22(수)
    혜택 1 스탬프미션 참여하기 누르면 2만원 딸기할인 쿠폰팩 증정! 
    혜택 2 선착순 1천명 BAGGU(바쿠) 딸기 장바구니 증정!

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    온가족 함께 즐기는 연말 홈파티 먹거리 추천!
    3개 이상 70%
    연중 최저가 도전! 닭가슴살 천원! ※연중 기간 : 24년 1월~24년 11월
    동물복지 수비드 닭가슴살 5종 모음전 (상품별 규격 상이/냉장/원산지 별도표기) 3개 이상 구매시 1개당 각1,000원
    ※점포별 한정수량 입고
    ※1개 구매시 각 3,330원

    L.POINT 최대 50%
    와규 윗등심, 국거리, 불고기/호주산 갈비살 (각 100g/냉장/호주산) 
    ※조기품절 될 수 있습니다
    ※기간 : 12/12(목)~12/15(일)

    국내산 돼지고기 삼겹살/목심 각2,490원
    (100g/냉장/국내산 돼지고기) ※제주점 제외

    2개 이상 50%
    산더미 대파 소불고기 (800g/원산지 별도표기) 2개 이상 구매시 1개당 각13,900원
    ※조기 품절될 수 있습니다
    ※1개 구매시 40% 할인 16,680원

    국내산 돼지갈비/등갈비 (각 100g/냉장/국내산 돼지고기) 1,190/1,990원
    ※기간 : 12/12(목)~12/15(일) ※제주점 제외 ※조기 품절될 수 있습니다

    L.POINT 25%
    New 한통 가아아득 치킨(1팩/국내산 계육) 9,743원
    ※제주점 제외

    L.POINT 25%
    마늘 치킨구이(1팩/원산지 별도표기) 8,993원

    족발 패밀리세트(1팩/원산지 별도표기) 19,800원
    ※연말 홈파티 안주류 3종(불껍데기/순살족/불족)
    ※족발 입점점에 한함

    샌드위치 4종(각 1팩/원산지 별도표기) 각4,990원
    ※듬뿍햄치즈/듬뿍에그햄/키토에그/크랜베리 치킨

    10개 구매시
    해금 골드키위(개/국산) 9,900원
    ※조기품절될 수 있습니다
    ※1개 구매시 1,290원

    필리핀산 스위트마운틴 바나나 (1.2kg 내외/송이/필리핀산) 2,990원

    옥광밤(600g/팩/국산) 9,990원
    ※조기 품절될 수 있습니다

    CJ 육공육 스모크 소시지(260g×2)/목우촌 주부9단 프랑크소시지(500g)/사조대림 잠룽 부어스트(705g) 각5,980~7,380원

    2개 이상 50%
    동원 바른어묵 전골용(350g)/ 삼진어묵 오동통 모듬어묵(700g) 2개 이상 구매시 1개당 각2,240/4,990원
    ※동일상품에 한함
    ※1개 구매시 각4,480/9,980원

    2개 이상 50% 롯데단독
    풀무원 점보 떡볶이/돈까스 (상품별 상이) 2개 이상 구매시 1개당 각2,990/4,990원
    ※교차구매 가능
    ※1개 구매시 각5,980/9,980원

    롯데/신한/NH농협/삼성카드 1+1 롯데단독
    동원 양반 김말이(406g) 8,980원
    ※페이지 하단 카드할인 세부내용 참고

    필라델피아 치즈케익/오레오 치즈케익(각 794g) 22,380/22,480원

    2개 이상 20%
    폰타나 파스타소스 전품목 13종 (상품별 규격 상이) 2개 이상 구매시 1개당 각5,184~7,184원
    ※교차구매 가능
    ※1개 구매시 각6,480~8,980원

    2개 이상 20%
    오뚜기 소스 11종(상품별 규격 상이) 2개 이상 구매시 1개당 각2,464~4,384원
    ※오뚜기 돈까스 소스 外 ※교차구매 가능
    ※1개 구매시 각3,080~5,480원

    빙그레 요플레 기획 (85g×10입) 5,980원

    1+1
    돈 시몬 4종(각 1L) 6,980~8,980원
    ※오렌지, 홍자몽/만다린, 토마토
    ※동일 가격간 교차구매 가능

    L.POINT 500원 
    삼립 바닐라빈 슈크림 호빵 (90g×4입) 5,480원

    50%
    임페리얼 데니쉬쿠키(500g) 5,990원

    1+1
    요거트향 퍼프 패스트리(176g)/마요라 커피조이(351g) 5,980/7,980원
    ※동일상품에 한함

    프리미엄 참다랑어 모둠회 (300g 내외/냉장/참다랑어 : 원산지 별도표기) 29,800원
    ※입점점에 한함 ※연출된 컷입니다

    L.POINT 2,000원
    반각 석화(700g/냉장/원산지 별도표기) 14,900원

    L.POINT 3,000원
    서울 트리플 슈레드 치즈 (1kg) 14,980원

    주류 믹서 음료 15종 ※상품별 행사 상이 각740~4,190원
    (진로 토닉워터 300ml 外 14종)

    2개 이상 50%
    궁 쇠고기 육포 오리지널/골든올리브 2개 이상 구매시 1개당 각7,450원
    (각 100g/원산지 별도표기) ※교차구매 가능
    ※1개 구매시 각14,900원

    5캔 구매시
    세계맥주 32종 골라담기 (상품별 상이) 11,000원
    ※1캔 구매시 각 2,280~3,300원

    스텔라 아르투아 홀리데이 기획팩 (500ml×6) 17,900원

    풀무원 다논 요구르트 전품목 1만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉭ "를 확인하세요
    ※결제시 계산대에서 수령
    ※점별 운영상품 상이 ※교차구매 가능
    1+1
    풀무원 다논 하루요거트 3종/그릭 시그니처 2종 외 (상품별 상이) 각3,780~6,480원
    ※동일 가격 교차구매 가능

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    온가족 함께 즐기는 해피 스트로베리 크리스마스!

    딸기 전품목(상품별 상이/국산) 롯데/신한/NH농협/삼성카드로 2개 이상 구매시 각4,000원 할인
    ※ 2개 이상 구매시 각 2천원 할인 해당카드 결제시 각 2천원 추가할인
    ※1개 구매시 카드할인 2천원만 적용
    ※교차구매 가능 ※점별 운영상품 상이
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    최대2,100원 할인
    멜론 크림 카스텔라(2입)/크리스마스 카스텔라 (각 1팩/원산지 별도표기) 각4,900/9,900원
    ※벌꿀/녹차
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    해태 버터링딥 레드벨벳/티라미수(각 155g) 각3,980원
    
    롯데칠성음료 
    롯데칠성음료 1만 5천원 이상 구매시 롯데 말랑카우 딸기우유(79g)증정
    ※입점점에 한함 ※점별 수량 한정
    ※증정품 소진시 행사 자동 종료
    ※구분자 " ㉣ "을 확인하세요
    ※당일 영수증에 한함
    ※비연속식 증정(1일 1인 1개)
    ※도와드립니다 센터에서 증정품 수령

    1+1
    칠성사이다/칠성사이다제로/펩시콜라/펩시콜라제로 각3,280원
    (각 1.25L) ※교차구매 가능

    크라운 포차코/헬로키티 과자 선물세트(170g/193g) 각4,980/6,980원

    2개 이상 20%
    크리스마스 식기/머그류(상품별 상이) 2개 이상 구매시 1개당 각2,320~7,920원
    ※1개 구매시 각 2,900~9,900원

    최대 30%
    포인세티아/미니트리 외 크리스마스 용품(상품별 상이) 1,520~39,200원
    ※기간 : 12/12(목)~12/18(수)

    2,000원 할인
    코코도르 크리스마스 디퓨저(200ml)/윈터랜드 1+1디퓨저(500ml×2) 7,900~17,900원

    2개 이상 50%
    오늘좋은 부드러운 양면담요(각 100×140cm) 2개 이상 구매시 1개당 각9,950원
    ※교차구매 가능 ※크리스마스/스트라이프/에스닉코지 ※입점점에 한함
    ※해당상품에 한함 ※한정수량으로 조기 품절될 수 있습니다
    ※1개 구매시 각 19,900원

    크리스마스 양말/패션악세서리(상품별 상이) 2,900~8,900원
    ※입점점에 한함 ※점별 입점상품 상이

    2개 이상 30%
    파밍트리 아동 크리스마스 상하세트 (상품별 상이) 2개 이상 구매시 1개당 각9,030원
    ※교차구매 가능
    ※1개 구매시 각 12,900원

    20%
    산리오 외 캐릭터 종이컵(상품별 상이) 1,910~2,390원
    ※입점점에 한함 ※점별 재고 상이    

    요리하다 온가족 함께 즐기는 연말 먹거리 추천!
    1+1
    요리하다 포르치니&트러플 라비올리/치즈&페퍼 라비올리(각 250g) 각7,990원
    ※교차구매 가능

    L.POINT 1,000원
    요리하다 후라이드 바사삭 치킨(400g) 4,990원

    L.POINT 1,000원
    요리하다 정통 가쓰오우동(484g) 3,990원

    요리하다×롯데월드 로티 미니치즈까스(350g)/미니고로케(400g) 4,990/5,990원

    오늘좋은오늘 가장 좋은 선택
    BEST MD'S PICK
    오늘좋은 코인 초콜릿 크리스마스(78g) 2,990원

    오늘좋은 프레첼 스낵믹스 2종(각 65g) 각1,000원
    ※멕시칸타코맛/체다치즈맛

    L.POINT 1,000원
    오늘좋은 고래사 종합 모둠어묵탕(700g) 6,990원

    최대600원 할인
    오늘좋은 스파게티(500g/1kg) 1,690/2,990원

    ToysRus
    L.POINT 회원 롯데/신한/NH농협/삼성카드
    완구 6만원 이상 구매시 10,000원 롯데상품권 증정
    ▶기 간 : 12/12(목) ~ 12/25(수)
    전점 8만장 한정
    ※전자게임/유아용품/문구/서적 등 일부품목 제외
    ※상품권 소진 시 행사가 조기종료될 수 있습니다
    ※L.POINT회원 해당카드 결제시에 한함
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1회)
    ※페이지 하단 카드할인 세부내용 참고

    글로벌 1위 프랑스 멀티 스포츠용품 스토어
    세상의 모든 스포츠, 데카트론!
    "Move People Through the wonders of sport"
    스포츠의 경이로움으로 사람들을 움직인다
    롤온 마사지기 1,000원 할인 7,900원

    물병 250ml 소프트 SA2020(블루) 18,000원

    100 남성용 스키 베이스 레이어 하의 4,000원 할인 9,900원

    남성 러닝 레깅스 런 웜 100 24,900원

    100 아동용 스키 방한 방수 장갑 2,000원 할인 18,900원

    남성 러닝 바람막이 자켓 2,100원 할인 25,900원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    금주의 쇼핑추천 아이템!
    L.POINT 3,000원
    과메기 야채모둠 (200g/냉장/원산지 별도표기) 16,900원

    L.POINT 30%
    직소싱 껍질벗긴 새우 (750g/냉동/원산지 별도표기) 16,730원
 
    CJ 1등급 깨끗한 계란/풀무원 1+등급 신선란 (각 대란/25구/국산) 각7,990원
    ※상품별 조기 품절될 수 있습니다

    2개 이상 각 1,000원 할인
    브로콜리(개/국산) 2개 이상 구매시 1개당 각1,990원
    ※1개 구매시 2,990원
    
    2개 이상 각1,980원 할인
    오뚜기 우동/칼제비 5종(각 2인) 2개 이상 구매시 1개당 각5,000원
    ※교차구매 가능
    
    1+1
    진주햄 천하장사 2종/CJ 맥스봉 2종(상품별 상이) 7,980/8,480원
    ※기간 : 12/12(목)~12/15(일)
    ※동일 브랜드간 교차구매 가능

    1+1
    롯데 식사이론 만두 3종 6,980~9,980원
    (상품별 상이) ※대파고기/메밀부추/납작만두
    ※동일 가격 간 교차구매 가능

    1+1
    사세 치킨가라아게(500g)/버팔로윙 마일드 허니맛(310g) 9,900/9,980원
    ※동일 상품에 한함

    2개 이상 각2,500원 할인
    오뚜기 크러스트 피자 3종/붕어빵 2종 (상품별 상이) 2개 이상 구매시 1개당 각6,480~6,980원
    ※동일 가격 간 교차구매 가능
    ※콘, 미트콤보, 페퍼로니/팥, 슈크림
    ※1개 구매시 각8,980~9,480원

    1+1
    차오차이 즉석 짜장/마파두부 7종(각 180g) 각3,980원
    ※130직화 간짜장 外 6종 ※교차구매 가능

    2,500원 할인
    슈퍼너츠 피넛버터 스무스/크런치(각 460g) 11,900원

    2개 이상 10%
    코카콜라 6입 캔 번들 7종 2개 이상 구매시 1개당 각3,087~3,753원
    (215ml×6캔/ 210ml×6캔) ※교차구매 가능
    ※1개 구매시 각3,430~4,170원

    동서 맥심 기획 모카골드 33,000원
    (12g×60포×4개) ※점포별 재고 상이
    ※한정수량으로 조기 품절될 수 있습니다

    1+1
    동서 포스트 콘푸라이트/켈로그 첵스초코(600g/570g) 7,380/8,880원
    ※동일 상품에 한함

    서울 흰우유(2.3L) 6,980원

    1+1
    베지밀 검은콩 고칼슘 두유(파우치)/삼육 검은콩과 호두 아몬드(190ml×15입 / 190ml×16입) 18,750/17,400원
    ※동일 상품에 한함

    1+1
    내추럴박스/다정헌 레몬즙 2종(상품별 상이) 각9,990/15,900원
    ※동일 상품에 한함

    1+1
    상쾌환 숙취해소제 6종 (상품별 상이) 각7,480~9,980원
    ※상쾌환/스틱/부스터
    ※동일 가격간 교차구매 가능

    2개 이상 50%
    인기 클렌징 대전(상품별 상이)
    ※ 뉴트로지나, 식물나라, 해피바스 등 11개 브랜드
    ※재고소진 품목 제외 ※점별 운영 상품 상이
    ※동일 브랜드에 한하여 교차구매 가능    

    2개 이상 50%
    히말라야/맨소래담/소프트립스 립케어(상품별 상이)
    ※점별 운영 상품 상이 ※일부 재고소진 품목 제외
    ※브랜드별 교차 구매 가능

    최대3,600원 할인
    니베아 바디로션 1+1기획 14,240~16,200원
    (각 400ml×2, 인텐시브/SOS)

    2개 이상 50%
    크리넥스/좋은느낌/화이트 등 최대 37종(상품별 상이) 2개 이상 구매시 1개당 각1,300~8,950원
    ※교차구매 가능 ※점별 운영 상품 상이
    ※1개 구매시 각2,600~17,900원

    2개 이상 50%
    크리넥스 울트라클린 시그니처(30m×30롤) 2개 이상 구매시 1개당 각14,450원
    ※1개 구매시 28,900원

    2개 이상 50%
    퍼실/퍼울 액체세제 전상품(상품별 상이) 2개 이상 구매시 1개당 각8,750~14,950원
    ※교차구매 가능 ※점별 취급상품 상이
    ※퍼실 디스크 캡슐세제 제외
    ※1개 구매시 각17,500~29,900원

    2개 이상 50%
    다우니 섬유유연제 8종(용기 각 2L/리필 각 2.6L) 2개 이상 구매시 1개당 각9,800~11,650원
    ※교차구매 가능
    ※1개 구매시 각19,600~23,300원

    2개 이상 55%
    엘라스틴 카멜리아 블랙 샴푸/트리트먼트(각 1,000ml) 2개 이상 구매시 1개당 각8,055원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각17,900원

    롯데/신한/NH농협/삼성카드 2개 이상 50%
    프로쉬 전품목(상품별 상이) 해당카드로 2개 이상 구매시 1개당 각3,450~7,750원
    ※교차구매 가능 
    ※기간 : 12/12(목)~12/15(일) ※해당 상품에 한함
    ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 각6,900~15,500원

    L.POINT 50%
    해피콜 루시드 프라이팬 17,450~26,950원
    
    L.POINT 50%
    글라스락 인기 밀폐용기 3종 (모듈러/퓨어오븐/스마트) 2,450~8,450원

    코렐 인기패턴 2종 (누보스케치/보라꽃) 30% 6,230~12,530원

    L.POINT 30%
    크린랩 레이스고무장갑/크린장갑(50매×2입) 각2,163~3,766원

    2개 이상 40%
    좋은부탄 캠핑에디션(4입) 2개 이상 구매시 1개당 각4,074원
    ※1개 구매시 6,790원

    오늘좋은 뱀부&코튼 세면타월(각 4P) 30% 각13,930원
    ※카키/민트 ※입점점에 한함 ※해당 상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    오늘좋은 개별 스위치 멀티탭 (상품별 상이) 20% 7,920~21,520원

    에너자이저 맥스 구매시 장바구니 증정!
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    에너자이저 맥스 18입(AA/AAA) 35% 각11,900원 

    2개 이상 30%
    방한 샌들/슬리퍼 2개 이상 구매시 1개당 각11,060~16,660원
    ※점별 입점 상품 상이 ※행사상품에 한함
    ※1개 구매시 각15,800~23,800원

    오뚜기 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉧"를 확인하세요
    2개 이상 각590원 할인
    오뚜기 진라면 순한맛/매운맛 (각 5개입) 2개 이상 구매시 1개당 각2,990원
    ※교차구매 가능
    ※1개 구매시 각3,580원

    애경 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "●"를 확인하세요
    2080 시그니처 토탈블루 치약 (130g×5입) 6,900원

    1+1
    케라시스 케라틴본드 샴푸/트리트먼트 (각 750ml, 딥리페어/볼륨) 각12,900원
    ※ 교차구매 가능 ※ 점별 운영 상품 상이

    디펜드 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 " D "를 확인하세요
    디펜드 언더웨어 10종(상품별 상이) 각9,500~16,500원

    3M 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 " M "를 확인하세요
    3M 올터치 막대걸레 (표준형/대형) 각26,290/29,590원
    ※입점점에 한함
    ※해당 상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    위스카스/쉬바/템테이션 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉠"를 확인하세요
    위스카스/쉬바/템테이션 브랜드(상품별 상이) 30% 840~18,130원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaSeoulData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2024. 12. 12(목) ~ 2024. 12. 25(수) < 신선식품 적용기간 : 2024. 12. 12(목) ~ 12. 18(수) >
    ·제타플렉스 서울역점

    제타플렉스 서울역점 매장에서 오직 롯데마트GO회원에게만! 스노우 특가 상품을 찾아보세요!
    SNOWPLAN 페스타 Hello Winter 
    스노우특가란?
    롯데마트 Go앱 회원 단독 특가로, 결제시 롯데마트 GO App의 바코드를 꼭 스캔해주세요
    ※핸드폰번호로 적립시, 스노우특가 적용 불가
    ※스노우특가 상품은 롯데마트GO앱 스캔 필수
    ※매장에 더 많은 스노우특가 상품이 준비되어 있습니다

    스노우특가 2,000원 할인
    비파괴 당도선별 샤인머스캣(1.5kg/박스/국산) 12,990원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 1+1 
    6개입 박스 컵라면 5종 (삼양 불닭볶음면 소컵 6개입 外 4종) 각5,200~6,980원
    ※기간 : 12/12(목) ~ 12/18(수) ※1인 1일 4개 구매한정
    ※동일 상품에 한함 ※페이지 하단 카드할인 세부내용 참고

    L.POINT 40% -> 롯데/신한/NH농협/삼성카드 결제 스노우특가 30% 추가할인
    치악산 한우 등심/국거리/불고기(각 1+/1++등급) (각 100g/냉장/국내산 한우고기)
    ※기간 : 12/14(토) ~ 12/15(일)
    ※점별 운영등급 상이 ※양지 국거리 제외
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    스노우특가 40%
    ALL NEW 새우 초밥(20입) (팩/원산지 별도표기) 11,994원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 50%
    킹크랩(100g/냉장/러시아산) 5,995원
    ※입점점에 한함 ※제주점 제외
    ※페이지 하단 카드할인 세부내용 참고

    스노우특가 20%
    완도 활전복(중/특)(마리/국산) 1,920/3,280원

    스노우특가 3,000원 할인
    제주 GAP 감귤(3kg/박스/국산) 11,990원

    스노우특가 1,500원 할인
    CJ 비비고 통새우만두(200g) 5,480원

    스노우특가 7,500원 할인
    씨제이 스팸 클래식(340g×3) 13,980원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 1,000원 할인
    아미카 엘도라다 포테이토칩 (400g/통) 8,980원
    ※점별 운영물량 상이
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 20% 스노우특가 25%
    제주 삼다수 그린(2L×6입/500ml×20입) 각3,888/5,760원
    ※교차구매 가능 ※제주점 행사 상이

    롯데/신한/NH농협/삼성카드 결제 스노우특가 10%
    정관장 활기력 샷 2종(20ml×8병, 아미노/에너지) 각18,720원
    ※페이지 하단 카드할인 세부내용 참고

    스노우특가 50%
    글라스락 핸디퓨어 3종(1.8L/2.7L/3.7L) 12,450~15,950원

    롯데/신한/NH농협/삼성카드 결제 스노우특가 1+1
    스너글 전품목(각 470ml) 각8,500원
    ※교차구매 가능 ※페이지 하단 카드할인 세부내용 참고

    스노우특가 30%
    듀플렉스 무드등 가습기 27,930원

    스노우특가 30%
    오늘좋은 동물/프렌치 쿠션/방석 (상품별 상이) 11,130~17,430원
    ※입점점에 한함 ※해당상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    스노우특가 20%
    순면 긴팔 파자마 19,120원
    ※해당상품에 한함 ※점별 입고상품 상이

    스노우특가 30%
    반려동물 식품
    잇츄/츄잇 12종 (상품별 상이) 4,165~6,860원
    ※입점점에 한함

    2024 롯데마트가 여러분의 경제적 소비생활을 매주 지원합니다
    이번주 핫 프라이스 HOT
    롯데마트 연중 최저가 도전! 
    L.POINT 30% -> 롯데/신한/NH농협/삼성카드 10% 추가할인
    거꾸로 거슬러 오른 '역행 특가' 내가 만드는 연어 횟감용/구이용(각 100g/냉장/노르웨이산) 3,591/3,339원
    ※연중기간 : '23년 11월 ~ '24년 12월
    ※기간 : 12/12(목) ~ 12/18(수) ※페이지 하단 카드할인 세부내용 참고

    윈터딸기클럽 스탬프 이벤트
    ▶기간 : '24/12/5(목) ~ '25/1/22(수)
    혜택 1 스탬프미션 참여하기 누르면 2만원 딸기할인 쿠폰팩 증정! 
    혜택 2 선착순 1천명 BAGGU(바쿠) 딸기 장바구니 증정!

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    온가족 함께 즐기는 연말 홈파티 먹거리 추천!
    3개 이상 70%
    연중 최저가 도전! 닭가슴살 천원! ※연중 기간 : 24년 1월~24년 11월
    동물복지 수비드 닭가슴살 5종 모음전 (상품별 규격 상이/냉장/원산지 별도표기) 3개 이상 구매시 1개당 각1,000원
    ※점포별 한정수량 입고
    ※1개 구매시 각 3,330원

    L.POINT 최대 50%
    와규 윗등심, 국거리, 불고기/호주산 갈비살 (각 100g/냉장/호주산) 
    ※조기품절 될 수 있습니다
    ※기간 : 12/12(목)~12/15(일)

    국내산 돼지고기 삼겹살/목심 각2,490원
    (100g/냉장/국내산 돼지고기) ※제주점 제외

    2개 이상 50%
    산더미 대파 소불고기 (800g/원산지 별도표기) 2개 이상 구매시 1개당 각13,900원
    ※조기 품절될 수 있습니다
    ※1개 구매시 40% 할인 16,680원

    국내산 돼지갈비/등갈비 (각 100g/냉장/국내산 돼지고기) 1,190/1,990원
    ※기간 : 12/12(목)~12/15(일) ※제주점 제외 ※조기 품절될 수 있습니다

    L.POINT 25%
    New 한통 가아아득 치킨(1팩/국내산 계육) 9,743원
    ※제주점 제외

    L.POINT 25%
    마늘 치킨구이(1팩/원산지 별도표기) 8,993원

    족발 패밀리세트(1팩/원산지 별도표기) 19,800원
    ※연말 홈파티 안주류 3종(불껍데기/순살족/불족)
    ※족발 입점점에 한함

    샌드위치 4종(각 1팩/원산지 별도표기) 각4,990원
    ※듬뿍햄치즈/듬뿍에그햄/키토에그/크랜베리 치킨

    10개 구매시
    해금 골드키위(개/국산) 9,900원
    ※조기품절될 수 있습니다
    ※1개 구매시 1,290원

    필리핀산 스위트마운틴 바나나 (1.2kg 내외/송이/필리핀산) 2,990원

    옥광밤(600g/팩/국산) 9,990원
    ※조기 품절될 수 있습니다

    CJ 육공육 스모크 소시지(260g×2)/목우촌 주부9단 프랑크소시지(500g)/사조대림 잠룽 부어스트(705g) 각5,980~7,380원

    2개 이상 50%
    동원 바른어묵 전골용(350g)/ 삼진어묵 오동통 모듬어묵(700g) 2개 이상 구매시 1개당 각2,240/4,990원
    ※동일상품에 한함
    ※1개 구매시 각4,480/9,980원

    2개 이상 50% 롯데단독
    풀무원 점보 떡볶이/돈까스 (상품별 상이) 2개 이상 구매시 1개당 각2,990/4,990원
    ※교차구매 가능
    ※1개 구매시 각5,980/9,980원

    롯데/신한/NH농협/삼성카드 1+1 롯데단독
    동원 양반 김말이(406g) 8,980원
    ※페이지 하단 카드할인 세부내용 참고

    필라델피아 치즈케익/오레오 치즈케익(각 794g) 22,380/22,480원

    2개 이상 20%
    폰타나 파스타소스 전품목 13종 (상품별 규격 상이) 2개 이상 구매시 1개당 각5,184~7,184원
    ※교차구매 가능
    ※1개 구매시 각6,480~8,980원

    2개 이상 20%
    오뚜기 소스 11종(상품별 규격 상이) 2개 이상 구매시 1개당 각2,464~4,384원
    ※오뚜기 돈까스 소스 外 ※교차구매 가능
    ※1개 구매시 각3,080~5,480원

    빙그레 요플레 기획 (85g×10입) 5,980원

    1+1
    돈 시몬 4종(각 1L) 6,980~8,980원
    ※오렌지, 홍자몽/만다린, 토마토
    ※동일 가격간 교차구매 가능

    L.POINT 500원 
    삼립 바닐라빈 슈크림 호빵 (90g×4입) 5,480원

    50%
    임페리얼 데니쉬쿠키(500g) 5,990원

    1+1
    요거트향 퍼프 패스트리(176g)/마요라 커피조이(351g) 5,980/7,980원
    ※동일상품에 한함

    프리미엄 참다랑어 모둠회 (300g 내외/냉장/참다랑어 : 원산지 별도표기) 29,800원
    ※입점점에 한함 ※연출된 컷입니다

    L.POINT 2,000원
    반각 석화(700g/냉장/원산지 별도표기) 14,900원

    L.POINT 3,000원
    서울 트리플 슈레드 치즈 (1kg) 14,980원

    주류 믹서 음료 15종 ※상품별 행사 상이 각740~4,190원
    (진로 토닉워터 300ml 外 14종)

    2개 이상 50%
    궁 쇠고기 육포 오리지널/골든올리브 2개 이상 구매시 1개당 각7,450원
    (각 100g/원산지 별도표기) ※교차구매 가능
    ※1개 구매시 각14,900원

    5캔 구매시
    세계맥주 32종 골라담기 (상품별 상이) 11,000원
    ※1캔 구매시 각 2,280~3,300원

    스텔라 아르투아 홀리데이 기획팩 (500ml×6) 17,900원

    풀무원 다논 요구르트 전품목 1만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉭ "를 확인하세요
    ※결제시 계산대에서 수령
    ※점별 운영상품 상이 ※교차구매 가능
    1+1
    풀무원 다논 하루요거트 3종/그릭 시그니처 2종 외 (상품별 상이) 각3,780~6,480원
    ※동일 가격 교차구매 가능

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    온가족 함께 즐기는 해피 스트로베리 크리스마스!

    딸기 전품목(상품별 상이/국산) 롯데/신한/NH농협/삼성카드로 2개 이상 구매시 각4,000원 할인
    ※ 2개 이상 구매시 각 2천원 할인 해당카드 결제시 각 2천원 추가할인
    ※1개 구매시 카드할인 2천원만 적용
    ※교차구매 가능 ※점별 운영상품 상이
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    최대2,100원 할인
    멜론 크림 카스텔라(2입)/크리스마스 카스텔라 (각 1팩/원산지 별도표기) 각4,900/9,900원
    ※벌꿀/녹차
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    해태 버터링딥 레드벨벳/티라미수(각 155g) 각3,980원
    
    롯데칠성음료 
    롯데칠성음료 1만 5천원 이상 구매시 롯데 말랑카우 딸기우유(79g)증정
    ※입점점에 한함 ※점별 수량 한정
    ※증정품 소진시 행사 자동 종료
    ※구분자 " ㉣ "을 확인하세요
    ※당일 영수증에 한함
    ※비연속식 증정(1일 1인 1개)
    ※도와드립니다 센터에서 증정품 수령

    1+1
    칠성사이다/칠성사이다제로/펩시콜라/펩시콜라제로 각3,280원
    (각 1.25L) ※교차구매 가능

    크라운 포차코/헬로키티 과자 선물세트(170g/193g) 각4,980/6,980원

    2개 이상 20%
    크리스마스 식기/머그류(상품별 상이) 2개 이상 구매시 1개당 각2,320~7,920원
    ※1개 구매시 각 2,900~9,900원

    최대 30%
    포인세티아/미니트리 외 크리스마스 용품(상품별 상이) 1,520~39,200원
    ※기간 : 12/12(목)~12/18(수)

    2,000원 할인
    코코도르 크리스마스 디퓨저(200ml)/윈터랜드 1+1디퓨저(500ml×2) 7,900~17,900원

    2개 이상 50%
    오늘좋은 부드러운 양면담요(각 100×140cm) 2개 이상 구매시 1개당 각9,950원
    ※교차구매 가능 ※크리스마스/스트라이프/에스닉코지 ※입점점에 한함
    ※해당상품에 한함 ※한정수량으로 조기 품절될 수 있습니다
    ※1개 구매시 각 19,900원

    크리스마스 양말/패션악세서리(상품별 상이) 2,900~8,900원
    ※입점점에 한함 ※점별 입점상품 상이

    2개 이상 30%
    파밍트리 아동 크리스마스 상하세트 (상품별 상이) 2개 이상 구매시 1개당 각9,030원
    ※교차구매 가능
    ※1개 구매시 각 12,900원

    20%
    산리오 외 캐릭터 종이컵(상품별 상이) 1,910~2,390원
    ※입점점에 한함 ※점별 재고 상이    

    요리하다 온가족 함께 즐기는 연말 먹거리 추천!
    1+1
    요리하다 포르치니&트러플 라비올리/치즈&페퍼 라비올리(각 250g) 각7,990원
    ※교차구매 가능

    L.POINT 1,000원
    요리하다 후라이드 바사삭 치킨(400g) 4,990원

    L.POINT 1,000원
    요리하다 정통 가쓰오우동(484g) 3,990원

    요리하다×롯데월드 로티 미니치즈까스(350g)/미니고로케(400g) 4,990/5,990원

    오늘 가장 좋은 선택 오늘좋은 BEST MD'S PICK
    오늘좋은 코인 초콜릿 크리스마스(78g) 2,990원

    오늘좋은 프레첼 스낵믹스 2종(각 65g) 각1,000원
    ※멕시칸타코맛/체다치즈맛

    L.POINT 1,000원
    오늘좋은 고래사 종합 모둠어묵탕(700g) 6,990원

    최대600원 할인
    오늘좋은 스파게티(500g/1kg) 1,690/2,990원

    ToysRus
    L.POINT회원 롯데/신한/NH농협/삼성카드
    전점 8만장 한정
    완구 6만원 이상 구매시 10,000원 롯데상품권 증정
    ▶기 간 : 12/12(목) ~ 12/25(수)
    ※전자게임/유아용품/문구/서적 등 일부품목 제외
    ※상품권 소진 시 행사가 조기종료될 수 있습니다
    ※L.POINT회원 해당카드 결제시에 한함
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1회)
    ※페이지 하단 카드할인 세부내용 참고

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    금주의 쇼핑추천 아이템!
    L.POINT 3,000원
    과메기 야채모둠 (200g/냉장/원산지 별도표기) 16,900원

    L.POINT 30%
    직소싱 껍질벗긴 새우 (750g/냉동/원산지 별도표기) 16,730원
 
    CJ 1등급 깨끗한 계란/풀무원 1+등급 신선란 (각 대란/25구/국산) 각7,990원
    ※상품별 조기 품절될 수 있습니다

    2개 이상 각 1,000원 할인
    브로콜리(개/국산) 2개 이상 구매시 1개당 각1,990원
    ※1개 구매시 2,990원
    
    2개 이상 각1,980원 할인
    오뚜기 우동/칼제비 5종(각 2인) 2개 이상 구매시 1개당 각5,000원
    ※교차구매 가능
    
    1+1
    진주햄 천하장사 2종/CJ 맥스봉 2종(상품별 상이) 7,980/8,480원
    ※기간 : 12/12(목)~12/15(일)
    ※동일 브랜드간 교차구매 가능

    1+1
    롯데 식사이론 만두 3종 6,980~9,980원
    (상품별 상이) ※대파고기/메밀부추/납작만두
    ※동일 가격 간 교차구매 가능

    1+1
    사세 치킨가라아게(500g)/버팔로윙 마일드 허니맛(310g) 9,900/9,980원
    ※동일 상품에 한함

    2개 이상 각2,500원 할인
    오뚜기 크러스트 피자 3종/붕어빵 2종 (상품별 상이) 2개 이상 구매시 1개당 각6,480~6,980원
    ※동일 가격 간 교차구매 가능
    ※콘, 미트콤보, 페퍼로니/팥, 슈크림
    ※1개 구매시 각8,980~9,480원

    1+1
    차오차이 즉석 짜장/마파두부 7종(각 180g) 각3,980원
    ※130직화 간짜장 外 6종 ※교차구매 가능

    2,500원 할인
    슈퍼너츠 피넛버터 스무스/크런치(각 460g) 11,900원

    2개 이상 10%
    코카콜라 6입 캔 번들 7종 2개 이상 구매시 1개당 각3,087~3,753원
    (215ml×6캔/ 210ml×6캔) ※교차구매 가능
    ※1개 구매시 각3,430~4,170원

    동서 맥심 기획 모카골드 33,000원
    (12g×60포×4개) ※점포별 재고 상이
    ※한정수량으로 조기 품절될 수 있습니다

    1+1
    동서 포스트 콘푸라이트/켈로그 첵스초코(600g/570g) 7,380/8,880원
    ※동일 상품에 한함

    서울 흰우유(2.3L) 6,980원

    1+1
    베지밀 검은콩 고칼슘 두유(파우치)/삼육 검은콩과 호두 아몬드(190ml×15입 / 190ml×16입) 18,750/17,400원
    ※동일 상품에 한함

    1+1
    내추럴박스/다정헌 레몬즙 2종(상품별 상이) 각9,990/15,900원
    ※동일 상품에 한함

    1+1
    상쾌환 숙취해소제 6종 (상품별 상이) 각7,480~9,980원
    ※상쾌환/스틱/부스터
    ※동일 가격간 교차구매 가능

    2개 이상 50%
    인기 클렌징 대전(상품별 상이)
    ※ 뉴트로지나, 식물나라, 해피바스 등 11개 브랜드
    ※재고소진 품목 제외 ※점별 운영 상품 상이
    ※동일 브랜드에 한하여 교차구매 가능    

    2개 이상 50%
    히말라야/맨소래담/소프트립스 립케어(상품별 상이)
    ※점별 운영 상품 상이 ※일부 재고소진 품목 제외
    ※브랜드별 교차 구매 가능

    최대3,600원 할인
    니베아 바디로션 1+1기획 14,240~16,200원
    (각 400ml×2, 인텐시브/SOS)

    2개 이상 50%
    크리넥스/좋은느낌/화이트 등 최대 37종(상품별 상이) 2개 이상 구매시 1개당 각1,300~8,950원
    ※교차구매 가능 ※점별 운영 상품 상이
    ※1개 구매시 각2,600~17,900원

    2개 이상 50%
    크리넥스 울트라클린 시그니처(30m×30롤) 2개 이상 구매시 1개당 각14,450원
    ※1개 구매시 28,900원

    2개 이상 50%
    퍼실/퍼울 액체세제 전상품(상품별 상이) 2개 이상 구매시 1개당 각8,750~14,950원
    ※교차구매 가능 ※점별 취급상품 상이
    ※퍼실 디스크 캡슐세제 제외
    ※1개 구매시 각17,500~29,900원

    2개 이상 50%
    다우니 섬유유연제 8종(용기 각 2L/리필 각 2.6L) 2개 이상 구매시 1개당 각9,800~11,650원
    ※교차구매 가능
    ※1개 구매시 각19,600~23,300원

    2개 이상 55%
    엘라스틴 카멜리아 블랙 샴푸/트리트먼트(각 1,000ml) 2개 이상 구매시 1개당 각8,055원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각17,900원

    롯데/신한/NH농협/삼성카드 2개 이상 50%
    프로쉬 전품목(상품별 상이) 해당카드로 2개 이상 구매시 1개당 각3,450~7,750원
    ※교차구매 가능 
    ※기간 : 12/12(목)~12/15(일) ※해당 상품에 한함
    ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 각6,900~15,500원

    L.POINT 50%
    해피콜 루시드 프라이팬 17,450~26,950원
    
    L.POINT 50%
    글라스락 인기 밀폐용기 3종 (모듈러/퓨어오븐/스마트) 2,450~8,450원

    코렐 인기패턴 2종 (누보스케치/보라꽃) 30% 6,230~12,530원

    L.POINT 30%
    크린랩 레이스고무장갑/크린장갑(50매×2입) 각2,163~3,766원

    2개 이상 40%
    좋은부탄 캠핑에디션(4입) 2개 이상 구매시 1개당 각4,074원
    ※1개 구매시 6,790원

    오늘좋은 뱀부&코튼 세면타월(각 4P) 30% 각13,930원
    ※카키/민트 ※입점점에 한함 ※해당 상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    오늘좋은 개별 스위치 멀티탭 (상품별 상이) 20% 7,920~21,520원

    에너자이저 맥스 구매시 장바구니 증정!
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    에너자이저 맥스 18입(AA/AAA) 35% 각11,900원 

    2개 이상 30%
    방한 샌들/슬리퍼 2개 이상 구매시 1개당 각11,060~16,660원
    ※점별 입점 상품 상이 ※행사상품에 한함
    ※1개 구매시 각15,800~23,800원

    오뚜기 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉧"를 확인하세요
    2개 이상 각590원 할인
    오뚜기 진라면 순한맛/매운맛 (각 5개입) 2개 이상 구매시 1개당 각2,990원
    ※교차구매 가능
    ※1개 구매시 각3,580원

    애경 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "●"를 확인하세요
    2080 시그니처 토탈블루 치약 (130g×5입) 6,900원

    1+1
    케라시스 케라틴본드 샴푸/트리트먼트 (각 750ml, 딥리페어/볼륨) 각12,900원
    ※ 교차구매 가능 ※ 점별 운영 상품 상이

    디펜드 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 " D "를 확인하세요
    디펜드 언더웨어 10종(상품별 상이) 각9,500~16,500원

    3M 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 " M "를 확인하세요
    3M 올터치 막대걸레 (표준형/대형) 각26,290/29,590원
    ※입점점에 한함
    ※해당 상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    위스카스/쉬바/템테이션 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉠"를 확인하세요
    위스카스/쉬바/템테이션 브랜드(상품별 상이) 30% 840~18,130원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202412_3";
const category = [];

const data = {
  L301: {
    title: "스마트전단지-전점301",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L303: {
    title: "스마트전단지-제타플렉스 잠실점303",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L305: {
    title: "스마트전단지-제타플렉스 서울역점305",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
