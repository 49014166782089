import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isIOS, isAndroid, isMobile } from "react-device-detect";
import CopyToClipboard from "react-copy-to-clipboard";
import axios from "axios";
import { IMAGE_PATH } from "../constants";
import { gaEvent } from "../../../utils/ga";
import { isMcouponApp } from "../../../utils/utility";
import { StyledLeafletPaper } from "../styles";

function LeafletItemPaper(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const { imageInfoList, leafletInfo } = props;
  const [slideIdx, setSlideIdx] = useState();
  const [searchParams] = useSearchParams();
  const tabIndex = searchParams.get("tabIndex");
  const pageName = `전단상세(${leafletInfo.title})`;

  //공유 링크 : https://www.mlotte.net/201?type=SUPER&tabIndex=1
  const _search = location.search
    ? `${location.search}&tabIndex=${slideIdx}`
    : `?tabIndex=${slideIdx}`;
  const shareUrl = `${process.env.REACT_APP_LEAFLET_URL}${location.pathname}${_search}`;

  // 이전/다음 버튼 클릭시 슬라이드 세팅
  const changeSlide = (type) => {
    if (type === "next") {
      const nextIdx = slideIdx + 1; //다음 슬라이드 번호

      if (nextIdx < imageInfoList.length) {
        gaEvent(pageName, `다음페이지(${nextIdx + 1})`); //다음 페이지 번호(화면표시용 index+1)
        setSlideIdx(nextIdx);
      }
    } else if (type === "prev") {
      const prevIdx = slideIdx - 1; //이전 슬라이드 번호
      if (prevIdx > -1) {
        gaEvent(pageName, `이전페이지(${slideIdx})`); //이전 페이지 번호(prevIdx+1 === slideIdx)
        setSlideIdx(slideIdx - 1);
      }
    }
    window.scrollTo(0, 0);
  };

  const gaShareEvent = () => {
    gaEvent(pageName, `전단 공유하기`);
  };

  //공유하기 클릭
  const handleClickShare = () => {
    gaShareEvent();

    //브릿지로 copy
    try {
      if (isIOS) {
        window.webkit.messageHandlers.setClipBoard.postMessage({
          text: shareUrl,
          message: "클립보드에 복사되었습니다.",
        });
      } else if (isAndroid) {
        window.binder?.setClipBoard(shareUrl);
      }
    } catch (error) {
      console.log("setClipBoard Bridge Error");
    }
  };

  const handleClickMain = () => {
    gaEvent(pageName, `메인으로가기`);
    navigate("/");
  };

  //공유 진입 시 특정 슬라이드 진입 체크
  useEffect(() => {
    if (tabIndex) {
      let _tabIndex = tabIndex;
      if (_tabIndex < 0) _tabIndex = 0;
      if (_tabIndex >= imageInfoList.length)
        _tabIndex = imageInfoList.length - 1;

      setSlideIdx(Number(_tabIndex));
    } else {
      setSlideIdx(Number(0));
    }
  }, [imageInfoList.length, tabIndex]);

  // 슬라이드 별 로그수집
  useEffect(() => {
    if (!leafletInfo || slideIdx === undefined) return;

    const params = {
      screen_id: 7,
      action_id: 15,
      action_detail: leafletInfo.type,
      seq: slideIdx + 1,
    };

    axios.post(`${process.env.REACT_APP_LOG_API}/fo/logging`, params, {
      headers: {
        "x-ssp-channel": "1",
        "Content-Type": "application/json; charset=utf-8",
        "x-ssp-gateway-service-id": "LOG",
      },
    });
  }, [leafletInfo, slideIdx]);

  return (
    <>
      {/* 전단 화면 */}
      {imageInfoList.length > 0 && (
        <StyledLeafletPaper>
          {(!isMobile || isMcouponApp) && (
            // PC/APP 상단 홈버튼 별도 추가 (헤더X)
            <div className="pc_subhome">
              <button onClick={handleClickMain}>
                <img
                  src={`${process.env.REACT_APP_BASE_S3_IMAGE}/icons/icon_home.png`}
                  alt="메인으로가기"
                />
              </button>
            </div>
          )}
          <div>
            {imageInfoList.map((data, i) => {
              // 이미지 로딩시간 단축. 전체 불러오고 숨김 처리

              return (
                <div key={i}>
                  <img
                    src={`${leafletInfo.imgPath}/${data.imgs}`}
                    alt=""
                    style={{ display: i === slideIdx ? "block" : "none" }}
                  />
                  {/* 장애인차별금지법 웹표준 퍼블리싱 figcaption */}
                  <div
                    className="alternate"
                    dangerouslySetInnerHTML={{
                      __html: `${data.title} ${data.texts}`,
                    }}
                  />
                </div>
              );
            })}
          </div>

          {/* 페이징 버튼 */}
          {slideIdx > -1 && (
            <div className="paper-btns">
              <div className="paper-paging">
                <p>
                  <strong>{slideIdx + 1}</strong> / {imageInfoList.length}
                </p>
              </div>
              <button
                title="다음 페이지로"
                className={
                  "swiper-button-next" +
                  (slideIdx === imageInfoList.length - 1 ? " disabled" : "")
                }
                onClick={() => changeSlide("next")}
              ></button>
              <button
                title="이전 페이지로"
                className={
                  "swiper-button-prev" + (slideIdx === 0 ? " disabled" : "")
                }
                onClick={() => changeSlide("prev")}
              ></button>
            </div>
          )}

          {/* 공유 버튼 */}
          <div className="paper-share">
            {isMcouponApp ? (
              <button title="공유하기" onClick={handleClickShare}>
                <img src={`${IMAGE_PATH}/common/btn_share_gray.png`} alt="" />
              </button>
            ) : (
              <CopyToClipboard
                text={shareUrl}
                onCopy={() => {
                  gaShareEvent();
                  if (!isAndroid) alert("클립보드에 복사되었습니다.");
                }}
              >
                <button title="공유하기">
                  <img src={`${IMAGE_PATH}/common/btn_share_gray.png`} alt="" />
                </button>
              </CopyToClipboard>
            )}
          </div>
        </StyledLeafletPaper>
      )}
    </>
  );
}

export default LeafletItemPaper;
