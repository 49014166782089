import React, { useEffect, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";

import { gaEvent, gaPageView } from "../../utils/ga";
import {
  StyledMain,
  StyledMainEventBanner,
  StyledMainFixedBanner,
} from "./styles";
import FixedImage from "../../components/FixedImage";
import { appStoreMove, isMcouponApp } from "../../utils/utility";
import { isAndroid, isIOS } from "react-device-detect";

function Main() {
  const location = useLocation();
  const navigate = useNavigate();
  const pageName = "전단허브";

  const [eventBannerList, setEventBannerList] = useState([]);

  // 전단 이벤트성 배너 조회
  const getCrntTimeCheck = useCallback(() => {
    axios
      .post(
        `${process.env.REACT_APP_API}/fo/assistant/leaflet/web/list`,
        { custom_type: "8" },
        {
          headers: {
            "x-ssp-channel": "1",
            "Content-Type": "application/json; charset=utf-8",
            "x-ssp-gateway-service-id": "ASSISTANT",
          },
        }
      )
      .then((res) => {
        const data = res.data.data;
        // event_seq 기준으로 정렬
        if (data.leaflet_list && data.leaflet_list.length > 0) {
          const sortleafletList = data.leaflet_list.sort(
            (a, b) => a.event_seq - b.event_seq
          );
          setEventBannerList(sortleafletList);
        }
      });
  }, []);

  // 고정전단 배너 클릭 (HYPER, ZETTA, MAXX, SUPER)
  const handleClickFixedLeaflet = useCallback(
    (storeTpye) => {
      gaEvent(pageName, `전단허브배너 고정(${storeTpye})`);
      navigate(`/leaflet?type=${storeTpye}`);
    },
    [navigate]
  );

  // 이벤트성 배너 클릭
  const handleClickEventLeaflet = (Ebanner) => {
    gaEvent(pageName, `전단허브배너 테마(${Ebanner.event_title})`);

    // 전단 host 체크
    const linkSplit = Ebanner.link1.split(process.env.REACT_APP_LEAFLET_URL);

    if (linkSplit.length > 1) {
      // 전단 내부페이지 이동
      navigate(linkSplit[1]);
    } else if (Ebanner.link1.indexOf(process.env.REACT_APP_GO_URL) > -1) {
      // 롯데마트GO 앱 내부페이지 이동
      if (isMcouponApp) {
        // 앱에서 클릭 시 - 전단웹뷰 닫고 앱 페이지로 이동
        try {
          if (isIOS) {
            window.webkit.messageHandlers.closeMoveMainUrl.postMessage({
              url: Ebanner.link1,
            });
          } else if (isAndroid) {
            window.binder?.closeMoveMainUrl(Ebanner.link1);
          }
        } catch (error) {
          console.log("openBrowser Bridge Error");
        }
      } else {
        // 웹에서 클릭 시 - 스토어 연동
        appStoreMove(Ebanner.link1);
      }
    } else {
      // 외부페이지 이동
      if (isMcouponApp) {
        // APP 브릿지 - 브라우저 열기
        try {
          if (isIOS) {
            window.webkit.messageHandlers.openBrowser.postMessage({
              url: Ebanner.link1,
            });
          } else if (isAndroid) {
            window.binder?.openBrowser(Ebanner.link1);
          }
        } catch (error) {
          console.log("openBrowser Bridge Error");
        }
      } else {
        // WEB - 새 탭으로 열기
        window.open(Ebanner.link1, "_blank");
      }
    }
  };

  useEffect(() => {
    getCrntTimeCheck();
  }, [getCrntTimeCheck]);

  useEffect(() => {
    gaPageView(pageName, location.pathname);
  }, [location.pathname]);

  return (
    <>
      {/* 전단 메인 */}
      <Helmet>
        <title>{`롯데마트 롯데슈퍼 전단`}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      <FixedImage />
      <StyledMain>
        <StyledMainFixedBanner>
          {FIXED_BANNER.map((Fbanner, i) => {
            return (
              <li key={i} onClick={() => handleClickFixedLeaflet(Fbanner.type)}>
                <img
                  src={`${process.env.REACT_APP_BASE_S3_IMAGE}/leaflet/common/leaflet_main_banner_${Fbanner.type}.png`}
                  alt={Fbanner.title}
                />
              </li>
            );
          })}
        </StyledMainFixedBanner>
        <StyledMainEventBanner>
          {eventBannerList.map((Ebanner, i) => {
            return (
              <li key={i} onClick={() => handleClickEventLeaflet(Ebanner)}>
                <img src={Ebanner.img_url1} alt={Ebanner.event_desc} />
              </li>
            );
          })}
        </StyledMainEventBanner>
      </StyledMain>
    </>
  );
}

export default Main;

const FIXED_BANNER = [
  {
    title: "롯데마트 전단 대상점포:롯데마트 오프라인 매장",
    type: "HYPER",
  },
  {
    title:
      "롯데마트 제타플렉스 전단 대상점포:롯데마트 제타플렉스 잠실, 서울역점",
    type: "ZETTA",
  },
  {
    title:
      "롯데마트 맥스 전단 대상점포:롯데마트맥스 금천, 목포, 상무, 영등포, 창원중앙점",
    type: "MAXX",
  },
  {
    title: "롯데슈퍼 전단 대상점포: 롯데슈퍼 오프라인 매장",
    type: "SUPER",
  },
];
