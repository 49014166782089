import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 12. 5(목) ~ 2024. 12. 11(수) < 비신선식품 적용기간 : 2024. 11. 28(목) ~ 12. 11(수) >

    롯데마트 윈터딸기클럽 오픈!
    딸기에 진심인 당신을 위한 최고 품질&최다 품종

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다

    이번주 핫프라이스 HOT
    윈터딸기클럽 런칭 기념 '달콤한 특가'
    딸기 전품목 (상품별 상이/국산)
    롯데/신한/NH농협/삼성카드로 2개 이상 구매시 각4,000원 할인
    ※2개 이상 구매시 각 2천원 할인 해당카드 결제시 각 2천원 추가할인
    ※1개 구매시 카드할인 2천원만 적용 ※교차구매 가능
    ※점별 운영상품 상이 ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    롯데마트 GO앱 윈터딸기클럽 런칭 이벤트
    ▶기간 : '24/12/5(목) ~ '25/1/22(수)
    ※이벤트 관련 세부내용은 롯데마트GO앱에서 확인하실 수 있습니다

    혜택 1 스탬프미션 참여하기 누르면 2만원 딸기할인 쿠폰팩 증정!
    ※해당 쿠폰 오프라인 매장에서만 사용 가능

    혜택 2 BAGGU(바쿠) 딸기 장바구니와 딸기 뷔페 식사권 증정!
    선착순 1천명 BAGGU(바쿠) 딸기 장바구니 + 스탬프 미션 가장 빨리 달성한 10명에게는 애슐리 딸기뷔페 2인 상품권 지급

    이벤트 참여 방법
    STEP 1 행사 기간 내 롯데마트 매장에서 딸기 품종 4가지 구매 
    ※설향 제외 ※품종 중복 불가

    STEP 2 딸기 구매 시, GO APP 바코드 스캔하고 스탬프 적립

    STEP 3 스탬프 미션 완료 후 선물받기

    고객 여러분의 밥상물가를 지킬 10대 아이템
    MISSION 미션! 물가를 잡아라
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.
    ※기간 : 12/5(목)~12/11(수) (비신선식품 적용은 11/28(목)~12/11(수))

    L.POINT 40%
    데친문어 (100g/냉장/세네갈산) 2,394원

    2팩 이상 각1,000원 할인
    대추방울 스윗마토/스윗볼 스테비아 대추방울토마토 (각 500g/팩/국산) 2팩 이상 구매시 1팩당 각6,990원
    ※1팩 구매시 각 7,990원

    상주일품미(10kg/국산) 25,900원

    밤고구마(1.5kg/박스/국산) 5,990원

    L.POINT 25%
    옛날 두마리 치킨 (1팩/국내산 게육) 14,993원

    1+1
    하림 더미식 유니자장면(2개입) 7,980원

    1+1
    우동/수제비/칼국수 5종 (각 2인) 6,980~8,480원
    ※동일 브랜드 교차구매 가능

    서울우유 후레쉬밀크 기획 (900ml×2) 1,000원 할인 4,480원

    L.POINT 1,000원
    자임 햇 꿀 유자차/햇 꿀 한라봉차(각 2kg) 각9,900원

    자연퐁 대용량 용기 2종(각 3.1kg, 솔잎/오렌지) 1,000원 할인 각7,950원

    초특가 4일장
    ※기간 : 12/5(목) ~ 12/8(일)

    국산 흰다리새우 (100g/냉장/국산) 2,590원

    L.POINT 30%
    훈제오리 슬라이스 (500g/냉장/원산지 별도표기) 10,490원

    20개 1만원!
    수제 모둠 소시지 20개입 (1kg/원산지 별도표기) 10,000원

    L.POINT 1,000원
    하루한알 갈아먹는 토마토 (1kg/팩/국산) 9,990원

    2개 이상 60%
    홈스타 3x 폼스프레이 2종 2개 이상 구매시 1개당 각3,560원
    (각 900ml, 후로랄/후레쉬) ※교차구매 가능
    ※1개 구매시 각 8,900원

    롯데 단독 플 레이브 빼빼로 기획! 12/7 토 2차 판매 시작!
    롯데 빼빼로 PL AVE 기획 (크런키 140g/아몬드 132g) 각 4,780원
    해당상품 2개 구매시 플레이브 포 토카드 1세트(2매) 증정!
    ※점별 입고 수량 상이

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    L.POINT 20%
    봉지굴 (150g/260g, 냉장, 국산) 4,880/7,920원

    L.POINT 25%
    완도 활 전복 (중/냉장/국산) 1,875원

    L.POINT 40%
    양념 황태구이 고추장/간장맛 (각 120g/냉장/원산지 별도표기) 5,940원

    L.POINT 5,000원
    항공직송 동원 생연어 (320g/냉장/노르웨이산) 16,900원

    GAP 친환경 표고버섯/느타리버섯(300g/500g, 팩, 국산) 4,990/1,990원

    2팩 이상 각500원 할인
    달래/냉이(80g/100g, 팩, 국산) 2팩 이상 구매시 1팩당 각2,490원
    ※조기품절될 수 있습니다 ※교차구매 가능
    ※1팩 구매시 각2,990원

    상생사과(4~7입/봉/국산) 9,990원

    2판 이상 각1,000원 할인
    행복생생란(대란/30입/국산) 2판 이상 구매시 1판당 각5,990원
    ※1판 구매시 6,990원

    크리스마스 케이크 사전예약!
    ※사전예약 기간 : 12/5(목)~12/18(수)
    ※상품수령 기간 : 12/21(토)~12/25(수)
    20% 롯데/신한/삼성/NH농협카드 최대3,000원 할인
    크렘드마롱 화이트케익/초코케익 (1호/2호, 원산지 별도표기) 17,200/25,000원
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    L.POINT 최대 40%
    1등급 한우 전품목 (각 100g/냉장/국내산 한우고기)
    ※부위별 조기 품절될 수 있습니다

    L.POINT 40%
    미국산 초이스 부채살 (100g/냉장/미국산) 2,280원
    ※기간 : 12/5(목)~12/8(일), 4일간
    ※12/9(월)~12/11(수) L.POINT 30%

    L.POINT 20%
    1등급 브랜드 돼지고기 앞다리/갈비 (각 100g/냉장/국내산 돼지고기) 1,992원
    ※입점점에 한함

    L.POINT 50%
    산더미 콩나물 제육 불고기 (700g/원산지 별도표기) 9,900원
    ※조기 품절될 수 있습니다

    AI선별 제주 올레길 감귤 (2.5kg/박스/국산) 13,990원

    L.POINT 20%
    광어연어도미 모둠회 (250g 내외/냉장/원산지 별도표기) 23,920원
    ※입점점에 한함

    L.POINT 30%
    큰 초밥 (20입/팩/원산지 별도표기) 13,993원

    L.POINT 30%
    볼케이노 봉구이 (1팩/원산지 별도표기) 6,993원

    더 커진 깐쇼새우/크리스피 허니 쉬림프 각6,990원
    (팩/원산지 별도표기)

    김밥&유부&롤 한판세트 (1팩/원산지 별도표기) 10,990원

    깍둑 순살 불족발 (팩/원산지 별도표기) 15,900원
    ※족발 입점점에 한함

    2개 이상 50%
    코주부 징기스칸 육포 (각 130g, 순한맛/매운맛) 2개 이상 구매시 1개당 각7,450원
    ※1개 구매시 각14,900원
    ※교차구매 가능

    함께 즐기는 홈파티 추천 먹거리
    하나사면 하나 더 1+1 
    2개 이상 구매시 더 싸게

    2개 이상 50%
    목우촌 주부9단 육즙팡팡 프랑크(350g) 2개 이상 구매시 1개당 각4,490원
    ※1개 구매시 8,980원

    2개 이상 50%
    삼진어묵 생생꼬불 어묵꼬치/전통모듬 어묵탕(416g/802g) 2개 이상 구매시 1개당 각4,990/6,490원
    ※1개 구매시 각9,980/12,980원
    ※교차구매 가능

    2개 이상 40%
    애슐리 볶음밥 4종(각 4인) 2개 이상 구매시 1개당 각7,788원
    ※통새우/스크램블&게살/캐나다랍스터/깍두기베이컨
    ※교차구매 가능
    ※1개 구매시 각12,980원

    1+1
    마니커 바사삭 치킨 (500g) 10,980원

    1+1
    동원 리얼 크랩스/랍스터 (각 216g) 각5,980원
    ※교차구매 가능

    2개 이상 50%
    풀무원 노엣지피자 3종(상품별 상이) 2개 이상 구매시 1개당 각4,990원
    ※코리안BBQ/포테이토&콘/토마토&미트콤보
    ※교차구매 가능
    ※1개 구매시 각9,980원

    2개 이상 10%
    대상 호밍스 냉동 국물요리 5종 (각 700g) 2개 이상 구매시 1개당 각8,982원
    ※나주곰탕/얼큰소고기장터국 外
    ※교차구매 가능
    ※1개 구매시 각9,980원

    양반 죽/비빔드밥/국물요리 전품목 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

    1+1
    동원 양반죽 16종(상품별 규격 상이) 각3,980~5,980원
    ※동일 가격 교차구매 가능

    1+1
    동원 양반 상온 국물요리 14종 (차돌육개장 460g 外 13종/상품별 규격 상이) 각2,980~6,980원 
    ※동일 가격 교차구매 가능

    1+1
    청정원 맛선생 국물내기 한알 4종
    (상품별 규격 상이) ※교차구매 가능
    ※멸치디포리/야채/사골/황태

    1+1
    아즈테카 밀또띠아 2종 (6인치/8인치) 각3,490/3,990원
    ※동일 상품에 한함

    1+1 
    서울 까요까요/큐빅 치즈 각4,580~5,380원
    (상품별 규격 상이) ※동일 가격간 교차구매 가능

    1+1
    풀무원다논 더블액션/하루요거트 6종(각 80g×4입) 각3,780원
    ※풀무원다논 하루요거트 생크림 外 5종
    ※교차구매 가능

    1+1
    베지밀 검은콩과 검은참깨(190ml×24입)/삼육 검은콩과 칼슘파우치(190ml×20입) 30,000/19,900원
    ※동일 상품에 한함

    1+1
    델몬트 오렌지/포도/망고 (각 1.8L) 각5,480원
    ※교차구매 가능

    2개 이상 각2,480원 할인
    카프리썬 오렌지 外 5종 (각 200ml×10입) 2개 이상 구매시 1개당 각4,500원
    ※1개 구매시 각6,980원
    ※교차구매 가능
    ※오렌지망고/사파리/사과/정글/펀알람

    1+1
    켈로그 든든한 브랜 그래놀라(450g)/그래놀라 넛츠&씨드(500g) 9,580/9,980원
    ※동일 상품에 한함

    2개 이상 30%
    프링글스 오리지날/사워크림어니언(각 142g) 2개 이상 구매시 1개당 각2,576원
    ※교차구매 가능
    ※1개 구매시 각3,680원
    
    L.POINT 1+1
    삼립 미니 꿀호떡(각 192g) 각3,480원
    ※오리지널/옥수수 ※교차구매 가능

    L.POINT 1+1
    삼립 누네띠네 오리지널(60g) 2,980원

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    함께 즐기는 홈파티 추천 먹거리 최대 50%할인
    동원 스페셜비엔나 기획 (300g×2) 80g 증량 UP 6,480원

    최대2,430원 할인
    풀무원 밀누들/밀국물 떡볶이 (각 423.5g) 각3,280원

    3kg
    종가 아삭하고 개운한 포기김치(3kg) 2,100원 할인 26,900원

    2+1
    아지노모도 날개달린 교자/교자만두/가라아게(상품별 규격 상이) 5,980~9,980원
    ※동일가격 교차구매 가능

    살코기 마일드 참치 기획(85g×8입) 5,000원 할인 10,980원

    L.POINT 각 1,000원
    오뚜기 찹쌀호떡믹스/미니도나스믹스(상품별 규격 상이) 각2,980원

    세계맥주 32종 골라담기 (상품별 상이) 5캔 구매시 11,000원
    ※1캔 구매시 각 2,280~3,300원

    최대 50% 1+1
    코카콜라 1.8L 外 탄산음료 대형 PET 14종(상품별 상이) 각1,190~2,680원
    ※동일시리즈간 교차구매 가능

    L.POINT 10,000원
    무알콜 르 쁘띠 에뚜알 오가닉 샤르도네(750ml) 14,900원

    L.POINT 2,000원
    서울 체다 슬라이스 치즈 50매 특별기획(18g×50매) 10,980원

    동원 드링킹 요구르트 2종 (각 750ml) 800원 할인 각3,980원
    ※플레인/딸기    

    서울우유 더 진한 그릭요거트 2종 (각 100g) 1,200원 할인 각2,780원
    ※오리지널/소프트

    캐치 티니핑 딸기 外 어린이 음료 19종(상품별 상이) 각1,000원

    오뚜기 행사상품 2개 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉧ "를 확인하세요
    ※결제시 계산대에서 수령
    오뚜기 인기 냉동식품 9종 (상품별 규격 상이) 8,980~9,980원
    ※XO 딤섬 3종, 카레치킨, 치킨너겟, 붕어빵 2종, 브리또 2종

    담터 전상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " # "를 확인하세요
    ※결제시 계산대에서 수령
    담터 호두 아몬드 율무차(18g×50입)/호두 아몬드 율무차 라이트(18g×40입) 각12,800원

    2+1
    스타벅스 다크/미디엄 로스트 (각 1.1g×30입) 각11,900원
    ※교차구매 가능 ※점별 입고상품 상이

    오리온 포카칩 오리지널/어니언 지퍼백 2종(각 266g) 20% 4,312원

    오레오 화이트크림 기획(450g) 850원 할인 4,780원

    롯데 칙촉 오리지널 2번들(336g) 1,010원 할인 4,980원

    애경 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉵ "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    리큐 프레쉬 리필 (각 2.6L, 일반/드럼) 2개 이상 구매시 1개당 각5,450원
    ※교차구매 가능
    ※1개 구매시 각10,900원

    2개 이상 65%
    닥터 루티어 효모 샴푸(각 800ml) 2개 이상 구매시 1개당 각6,930원
    ※1개 구매시 각19,800원
    ※머스크/쟈스민/석류향
    ※교차구매 가능 ※점별 운영상품 상이

    1+1
    샤워메이트 산양유 바디워시 (각 800ml) 9,980원
    ※오리지널/마누카허니
    ※교차구매 가능 ※점별 운영상품 상이

    1+1
    2080 어드밴스드 치약(각 420g) 각8,900원
    ※블루/그린 ※교차구매 가능
    ※점별 입고상품 상이

    2+1
    종근당건강 락토핏 6종 (상품별 상이) 각15,900~29,900원
    ※교차구매 가능
    ※골드/코어/당케어/슬림/키즈/뷰티
    ※3개 구매시 34% 할인
    ※3의 배수로 적용

    유한킴벌리 해당상품 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉪ "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    크리넥스/좋은느낌/화이트 등 최대 39종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,300~10,350원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각2,600~20,700원

    롯데/신한/NH농협/삼성카드 2개 이상 50%
    크리넥스 수프림소프트 3겹 화장지(27m×30롤) 해당카드 2개 이상 구매시 각19,700원
    ※유한킴벌리 상품권 증정 행사 제외 ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 39,400원

    2개 이상 20%
    오늘좋은 건강식품 전품목 (상품별 상이) 2개 이상 구매시 1개당 각9,520~71,920원
    ※교차구매 가능
    ※1개 구매시 각11,900~89,900원

    L.POINT 각 2,000원
    뉴케어 클래식 3종(각 200ml×16입) 27,900~30,900원
    ※구수한맛/검은깨맛/인절미맛

    헨켈 감사절 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉬ "를 확인하세요
    ※결제시 계산대에서 수령
    2개 이상 50%
    프릴&브레프 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,250~4,750원
    ※교차구매 가능 ※해당상품에 한함
    ※1개 구매시 각4,500~9,500원

    센텔리안 24 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령
    센텔리안24 파워 부스팅 마데카크림(50ml+30ml) 17,520원
    ※점별 재고수량 상이

    각2,000원 할인 -> 롯데/신한/NH농협/삼성카드 각1,000원 추가할인
    유한락스 주거세정제 번들 3종 (상품별 상이) 각5,900원
    ※곰팡이/욕실/주방
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 50%
    아우라 퍼퓸캡슐 6종 (용기 2L/리필 2.3L) 2개 이상 구매시 1개당 각10,900원
    ※교차구매 가능
    ※미스틱문라이즈/스윗만다린/양재동꽃시장
    ※1개 구매시 각21,800원

    롯데/신한/NH농협/삼성카드 2,000원 할인
    스트로베리 에디션 무형광 3겹 화장지(28m×30롤) 9,900원
    ※페이지 하단 카드할인 세부내용 참고

    스트로베리 에디션 물티슈/미용티슈(100매/180매×3입) 1,000/3,300원

    2개 이상 55%
    엘라스틴 아미노프로틴 샴푸/컨디셔너(각 855ml) 2개 이상 구매시 1개당 각6,705원
    ※손상/볼륨 ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각14,900원

    50% -> 롯데/신한/NH농협/삼성카드 20% 추가할인
    바세린 바디로션 4종(각 400ml) 각5,960원
    ※드라이스킨/어드벤스드/핸드&네일/알로에수드
    ※교차구매 가능 ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    센카 퍼펙트휩 콜라겐/올클리어 더블워시(각 120g) 7,550/7,250원

    질레트 프로글라이드 파워 기획(기+날 5입) 38,900원
    ※점별 운영상품 상이

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    미리 준비하는 연말 크리스마스
    크리스마스 행사상품 롯데/신한/NH농협/삼성카드 5만원 이상 결제시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ♣ "를 확인하세요 ※결제시 계산대에서 수령
    ※상품권 소진시 행사가 조기 종료될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고
    전나무 세트(60) 外 (상품별 규격 상이) 49,800~115,000원
    ※점별 입점상품 상이

    2개 이상 최대 20%
    크리스마스 화초/장식용품(상품별 상이) 2개 이상 구매시 1개당 각1,520~39,200원
    ※입점점에 한함 ※해당상품에 한함 ※점별 입점상품 상이
    ※동일상품군에 한해 교차구매 가능
    ※1개 구매시 각 1,900~49,000원

    크리스마스 머그/플레이트(상품별 상이) 20% 2,320~6,320원

    2개 이상 20%
    타탄/포레스트 일회용 식기/종이컵 (상품별 상이) 2개 이상 구매시 1개당 각1,592원
    ※교차구매 가능
    ※1개 구매시 각 1,990원

    겨울 보온 용품 최대 30%
    2개 이상 30%
    BYC 내의세트 外 브랜드 동내의(1입) 2개 이상 구매시 1개당 각9,730~27,930원
    ※1개 구매시 각 13,900~39,900원 ※교차구매 가능

    2개 이상 30%
    브랜드 방한 양말/타이즈 (상품별 상이) 2개 이상 구매시 1개당 각2,730~10,430원
    ※입점점에 한함
    ※네파/휠라/레노마/컬럼비아/비비안/피에르가르뎅
    ※1개 구매시 각 3,900~14,900원 ※교차구매 가능


    겨울 패딩 자켓 (웰론 경량패딩자켓/포켓 중량자켓) 10,000원 할인 29,900/39,900원
    ※입점점에 한함

    푸마 하이브리드 아노락 10,000원 할인 각35,900원
    ※입점점에 한함 

    2개 이상 30%
    오늘좋은 겨울 파자마 2개 이상 구매시 1개당 각20,930원
    ※소프트기모/피치스킨 파자마세트
    ※1개 구매시 각 29,900원 ※교차구매 가능

    방한 운동화/슬립온/부츠(상품별 상이) 20% 12,640~43,040원
    ※점별 입점상품 상이 ※행사상품에 한함

    오늘좋은 발열침구/패브릭 소품(상품별 상이) 30% 6,930~59,430원

    20%
    오늘좋은 양털 거실화 外(상품별 상이) 6,320~10,320원
    ※입점점에 한함 ※해당상품에 한함

    20%
    가습기/히터/보온시트(상품별 상이) 1,590~55,920원

    해피콜 코어센서 프라이팬(상품별 상이) 50% 23,950~32,450원

    2개 이상 50%
    네오플램 퓨어락(상품별 상이) 2개 이상 구매시 1개당 각2,200~6,200원
    ※1개 구매시 각 4,400~12,400원 ※교차구매 가능

    오늘좋은 고중량 세면타월(각 6P) 30% 각17,430원
    ※그레이/라이트브라운/라이트블루
    ※입점점에 한함 ※해당상품에 한함

    30%
    오늘좋은 데일리 쿠션 부직포 청소대(대형)/극세사 청소대(대형)/집게 청소대 11,130/11,830/13,230원
    ※입점점에 한함 ※해당상품에 한함

    최대 30%
    폭설 대비 제설 용품(스프레이체인, 제설기 外) 2,000~66,320원
    ※상품별 행사내용 상이 ※점별 재고 상이

    20%
    3M 리싸이클 정전기 청소포(각 60매) 6,940/7,920원
    ※표준형/대형 ※입점점에 한함 ※해당상품에 한함

    40%
    콜리올리 말이껌 10종 (상품별 상이) 1,434~5,394원

    20%
    콜리올리 두부모래 2종 (각 3kg, 오리지널/녹차) 각6,320원

    인기 와인&칵테일 2종 L.POINT 최대 7,600원 할인!

    L.POINT 7,000원
    1865 카베르네소비뇽 (청뱀띠 에디션) 29,800원

    L.POINT 7,600원
    업타운 마가리타(750ml) 9,900원

    L.POINT 10,990원
    컨디션 스틱 3종(각 18g×10입) 각16,000원
    ※컨디션/그린애플/자두

    3캔 구매시
    하이볼 골라담기(상품별 용량 상이) 9,990원
    ※생레몬/라임/청귤 하이볼, 퐁당 하이볼 外 5종
    ※1캔 구매시 각 4,000원

    요리하다 겨울맞이 신상품 4종!
    요리하다 쫄깃한 소곱창구이(220g) 9,990원

    요리하다 방과후 소떡소떡(550g) 4,990원

    L.POINT 1,000원
    요리하다 우리쌀 국물떡볶이(345g) 2,990원

    요리하다 얼큰한 김치우동 2인(432g) 3,990원

    오늘 가장 좋은 선택 BEST MD'S PICK
    오늘좋은 프레첼 스낵믹스 2종(각 65g) 각1,000원
    ※멕시칸타코맛/체다치즈맛

    최대1,500원 할인
    오늘좋은 런천미트/데일리팜 2종(각 340g) 3,490/4,490원

    오늘좋은 아몬드호두 율무차 (18g×50입) 2,000원 할인 7,990원

    오늘좋은 캡슐커피 싱글 오리진 콜롬비아/롱고/디카페인 (각 5.2g×10입) 각2,000원

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2024. 12. 5(목) ~ 2024. 12. 11(수) < 비신선식품 적용기간 : 2024. 11. 28(목) ~ 12. 11(수) >
    ·제타플렉스 잠실점

    윈터딸기클럽 오픈! 
    딸기에 진심인 당신을 위한 최고 품질&최다 품종

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다
    이번주 핫프라이스 HOT
    윈터딸기클럽 런칭 기념 '달콤한 특가'
    딸기 전품목 (상품별 상이/국산)
    롯데/신한/NH농협/삼성카드로 2개 이상 구매시 각4,000원 할인
    ※2개 이상 구매시 각 2천원 할인 해당카드 결제시 각 2천원 추가할인
    ※1개 구매시 카드할인 2천원만 적용 ※교차구매 가능
    ※점별 운영상품 상이 ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    롯데마트GO앱 윈터딸기클럽 런칭 이벤트
    ▶기간 : '24/12/5(목) ~ '25/1/22(수)
    ※이벤트 관련 세부내용은 롯데마트GO앱에서 확인하실 수 있습니다

    혜택 1 스탬프미션 참여하기 누르면 2만원 딸기할인 쿠폰팩 증정!
    ※해당 쿠폰 오프라인 매장에서만 사용 가능

    혜택 2 BAGGU(바쿠) 딸기 장바구니와 딸기 뷔페 식사권 증정!
    선착순 1천명 BAGGU(바쿠) 딸기 장바구니 + 스탬프 미션 가장 빨리 달성한 10명에게는 애슐리 딸기뷔페 2인 상품권 지급

    이벤트 참여 방법
    STEP 1 행사 기간 내 롯데마트 매장에서 딸기 품종 4가지 구매 
    ※설향 제외 ※품종 중복 불가

    STEP 2 딸기 구매 시, GO APP 바코드 스캔하고 스탬프 적립

    STEP 3 스탬프 미션 완료 후 선물받기

    고객 여러분의 밥상물가를 지킬 10대 아이템
    MISSION 미션! 물가를 잡아라
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.
    ※기간 : 12/5(목)~12/11(수) (비신선식품 적용은 11/28(목)~12/11(수))

    L.POINT 40%
    데친문어 (100g/냉장/세네갈산) 2,394원

    2팩 이상 각1,000원 할인
    대추방울 스윗마토/스윗볼 스테비아 대추방울토마토 (각 500g/팩/국산) 2팩 이상 구매시 1팩당 각6,990원
    ※1팩 구매시 각 7,990원

    상주일품미(10kg/국산) 25,900원

    밤고구마(1.5kg/박스/국산) 5,990원

    L.POINT 25%
    옛날 두마리 치킨 (1팩/국내산 게육) 14,993원

    1+1
    하림 더미식 유니자장면(2개입) 7,980원

    1+1
    우동/수제비/칼국수 5종 (각 2인) 6,980~8,480원
    ※동일 브랜드 교차구매 가능

    서울우유 후레쉬밀크 기획 (900ml×2) 1,000원 할인 4,480원

    L.POINT 1,000원
    자임 햇 꿀 유자차/햇 꿀 한라봉차(각 2kg) 각9,900원

    자연퐁 대용량 용기 2종(각 3.1kg, 솔잎/오렌지) 1,000원 할인 각7,950원

    초특가 4일장
    ※기간 : 12/5(목) ~ 12/8(일)

    국산 흰다리새우 (100g/냉장/국산) 2,590원

    L.POINT 30%
    훈제오리 슬라이스 (500g/냉장/원산지 별도표기) 10,490원

    20개 1만원!
    수제 모둠 소시지 20개입 (1kg/원산지 별도표기) 10,000원

    L.POINT 1,000원
    하루한알 갈아먹는 토마토 (1kg/팩/국산) 9,990원

    2개 이상 60%
    홈스타 3x 폼스프레이 2종 2개 이상 구매시 1개당 각3,560원
    (각 900ml, 후로랄/후레쉬) ※교차구매 가능
    ※1개 구매시 각 8,900원

    롯데 단독 플 레이브 빼빼로 기획! 12/7 토 2차 판매 시작!
    롯데 빼빼로 PL AVE 기획 (크런키 140g/아몬드 132g) 각 4,780원
    해당상품 2개 구매시 플레이브 포 토카드 1세트(2매) 증정!
    ※점별 입고 수량 상이

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    L.POINT 20%
    봉지굴 (150g/260g, 냉장, 국산) 4,880/7,920원

    L.POINT 25%
    완도 활 전복 (중/냉장/국산) 1,875원

    L.POINT 40%
    양념 황태구이 고추장/간장맛 (각 120g/냉장/원산지 별도표기) 5,940원

    L.POINT 5,000원
    항공직송 동원 생연어 (320g/냉장/노르웨이산) 16,900원

    GAP 친환경 표고버섯/느타리버섯(300g/500g, 팩, 국산) 4,990/1,990원

    2팩 이상 각500원 할인
    달래/냉이(80g/100g, 팩, 국산) 2팩 이상 구매시 1팩당 각2,490원
    ※조기품절될 수 있습니다 ※교차구매 가능
    ※1팩 구매시 각2,990원

    상생사과(4~7입/봉/국산) 9,990원

    2판 이상 각1,000원 할인
    행복생생란(대란/30입/국산) 2판 이상 구매시 1판당 각5,990원
    ※1판 구매시 6,990원

    크리스마스 케이크 사전예약!
    ※사전예약 기간 : 12/5(목)~12/18(수)
    ※상품수령 기간 : 12/21(토)~12/25(수)
    20% 롯데/신한/삼성/NH농협카드 최대3,000원 할인
    크렘드마롱 화이트케익/초코케익 (1호/2호, 원산지 별도표기) 17,200/25,000원
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    L.POINT 최대 40%
    1등급 한우 전품목 (각 100g/냉장/국내산 한우고기)
    ※부위별 조기 품절될 수 있습니다

    L.POINT 40%
    미국산 초이스 부채살 (100g/냉장/미국산) 2,280원
    ※기간 : 12/5(목)~12/8(일), 4일간
    ※12/9(월)~12/11(수) L.POINT 30%

    L.POINT 20%
    1등급 브랜드 돼지고기 앞다리/갈비 (각 100g/냉장/국내산 돼지고기) 1,992원
    ※입점점에 한함

    L.POINT 50%
    산더미 콩나물 제육 불고기 (700g/원산지 별도표기) 9,900원
    ※조기 품절될 수 있습니다

    AI선별 제주 올레길 감귤 (2.5kg/박스/국산) 13,990원

    L.POINT 20%
    광어연어도미 모둠회 (250g 내외/냉장/원산지 별도표기) 23,920원
    ※입점점에 한함

    L.POINT 30%
    큰 초밥 (20입/팩/원산지 별도표기) 13,993원

    L.POINT 30%
    볼케이노 봉구이 (1팩/원산지 별도표기) 6,993원

    더 커진 깐쇼새우/크리스피 허니 쉬림프 각6,990원
    (팩/원산지 별도표기)

    김밥&유부&롤 한판세트 (1팩/원산지 별도표기) 10,990원

    깍둑 순살 불족발 (팩/원산지 별도표기) 15,900원
    ※족발 입점점에 한함

    2개 이상 50%
    코주부 징기스칸 육포 (각 130g, 순한맛/매운맛) 2개 이상 구매시 1개당 각7,450원
    ※1개 구매시 각14,900원
    ※교차구매 가능

    함께 즐기는 홈파티 추천 먹거리
    하나사면 하나 더 1+1 
    2개 이상 구매시 더 싸게

    2개 이상 50%
    목우촌 주부9단 육즙팡팡 프랑크(350g) 2개 이상 구매시 1개당 각4,490원
    ※1개 구매시 8,980원

    2개 이상 50%
    삼진어묵 생생꼬불 어묵꼬치/전통모듬 어묵탕(416g/802g) 2개 이상 구매시 1개당 각4,990/6,490원
    ※1개 구매시 각9,980/12,980원
    ※교차구매 가능

    2개 이상 40%
    애슐리 볶음밥 4종(각 4인) 2개 이상 구매시 1개당 각7,788원
    ※통새우/스크램블&게살/캐나다랍스터/깍두기베이컨
    ※교차구매 가능
    ※1개 구매시 각12,980원

    1+1
    마니커 바사삭 치킨 (500g) 10,980원

    1+1
    동원 리얼 크랩스/랍스터 (각 216g) 각5,980원
    ※교차구매 가능

    2개 이상 50%
    풀무원 노엣지피자 3종(상품별 상이) 2개 이상 구매시 1개당 각4,990원
    ※코리안BBQ/포테이토&콘/토마토&미트콤보
    ※교차구매 가능
    ※1개 구매시 각9,980원

    2개 이상 10%
    대상 호밍스 냉동 국물요리 5종 (각 700g) 2개 이상 구매시 1개당 각8,982원
    ※나주곰탕/얼큰소고기장터국 外
    ※교차구매 가능
    ※1개 구매시 각9,980원

    양반 죽/비빔드밥/국물요리 전품목 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

    1+1
    동원 양반죽 16종(상품별 규격 상이) 각3,980~5,980원
    ※동일 가격 교차구매 가능

    1+1
    동원 양반 상온 국물요리 14종 (차돌육개장 460g 外 13종/상품별 규격 상이) 각2,980~6,980원 
    ※동일 가격 교차구매 가능

    1+1
    청정원 맛선생 국물내기 한알 4종
    (상품별 규격 상이) ※교차구매 가능
    ※멸치디포리/야채/사골/황태

    1+1
    아즈테카 밀또띠아 2종 (6인치/8인치) 각3,490/3,990원
    ※동일 상품에 한함

    1+1 
    서울 까요까요/큐빅 치즈 각4,580~5,380원
    (상품별 규격 상이) ※동일 가격간 교차구매 가능

    1+1
    풀무원다논 더블액션/하루요거트 6종(각 80g×4입) 각3,780원
    ※풀무원다논 하루요거트 생크림 外 5종
    ※교차구매 가능

    1+1
    베지밀 검은콩과 검은참깨(190ml×24입)/삼육 검은콩과 칼슘파우치(190ml×20입) 30,000/19,900원
    ※동일 상품에 한함

    1+1
    델몬트 오렌지/포도/망고 (각 1.8L) 각5,480원
    ※교차구매 가능

    2개 이상 각2,480원 할인
    카프리썬 오렌지 外 5종 (각 200ml×10입) 2개 이상 구매시 1개당 각4,500원
    ※1개 구매시 각6,980원
    ※교차구매 가능
    ※오렌지망고/사파리/사과/정글/펀알람

    1+1
    켈로그 든든한 브랜 그래놀라(450g)/그래놀라 넛츠&씨드(500g) 9,580/9,980원
    ※동일 상품에 한함

    2개 이상 30%
    프링글스 오리지날/사워크림어니언(각 142g) 2개 이상 구매시 1개당 각2,576원
    ※교차구매 가능
    ※1개 구매시 각3,680원
    
    L.POINT 1+1
    삼립 미니 꿀호떡(각 192g) 각3,480원
    ※오리지널/옥수수 ※교차구매 가능

    L.POINT 1+1
    삼립 누네띠네 오리지널(60g) 2,980원

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    함께 즐기는 홈파티 추천 먹거리 최대 50%할인
    동원 스페셜비엔나 기획 (300g×2) 80g 증량 UP 6,480원

    최대2,430원 할인
    풀무원 밀누들/밀국물 떡볶이 (각 423.5g) 각3,280원

    3kg
    종가 아삭하고 개운한 포기김치(3kg) 2,100원 할인 26,900원

    2+1
    아지노모도 날개달린 교자/교자만두/가라아게(상품별 규격 상이) 5,980~9,980원
    ※동일가격 교차구매 가능

    살코기 마일드 참치 기획(85g×8입) 5,000원 할인 10,980원

    L.POINT 각 1,000원
    오뚜기 찹쌀호떡믹스/미니도나스믹스(상품별 규격 상이) 각2,980원

    세계맥주 15종 골라담기 (상품별 상이) 5캔 구매시 11,000원
    ※1캔 구매시 각 2,280~3,300원

    최대 50% 1+1
    코카콜라 1.8L 外 탄산음료 대형 PET 14종(상품별 상이) 각1,190~2,680원
    ※동일시리즈간 교차구매 가능

    L.POINT 2,000원
    서울 체다 슬라이스 치즈 50매 특별기획(18g×50매) 10,980원

    동원 드링킹 요구르트 2종 (각 750ml) 800원 할인 각3,980원
    ※플레인/딸기    

    서울우유 더 진한 그릭요거트 2종 (각 100g) 1,200원 할인 각2,780원
    ※오리지널/소프트

    캐치 티니핑 딸기 外 어린이 음료 19종(상품별 상이) 각1,000원

    오뚜기 행사상품 2개 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉧ "를 확인하세요
    ※결제시 계산대에서 수령
    오뚜기 인기 냉동식품 9종 (상품별 규격 상이) 8,980~9,980원
    ※XO 딤섬 3종, 카레치킨, 치킨너겟, 붕어빵 2종, 브리또 2종

    담터 전상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " # "를 확인하세요
    ※결제시 계산대에서 수령
    담터 호두 아몬드 율무차(18g×50입)/호두 아몬드 율무차 라이트(18g×40입) 각12,800원

    2+1
    스타벅스 다크/미디엄 로스트 (각 1.1g×30입) 각11,900원
    ※교차구매 가능 ※점별 입고상품 상이

    오리온 포카칩 오리지널/어니언 지퍼백 2종(각 266g) 20% 4,312원

    오레오 화이트크림 기획(450g) 850원 할인 4,780원

    롯데 칙촉 오리지널 2번들(336g) 1,010원 할인 4,980원

    애경 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉵ "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    리큐 프레쉬 리필 (각 2.6L, 일반/드럼) 2개 이상 구매시 1개당 각5,450원
    ※교차구매 가능
    ※1개 구매시 각10,900원

    2개 이상 65%
    닥터 루티어 효모 샴푸(각 800ml) 2개 이상 구매시 1개당 각6,930원
    ※1개 구매시 각19,800원
    ※머스크/쟈스민/석류향
    ※교차구매 가능 ※점별 운영상품 상이

    1+1
    샤워메이트 산양유 바디워시 (각 800ml) 9,980원
    ※오리지널/마누카허니
    ※교차구매 가능 ※점별 운영상품 상이

    1+1
    2080 어드밴스드 치약(각 420g) 각8,900원
    ※블루/그린 ※교차구매 가능
    ※점별 입고상품 상이

    2+1
    종근당건강 락토핏 6종 (상품별 상이) 각15,900~29,900원
    ※교차구매 가능
    ※골드/코어/당케어/슬림/키즈/뷰티
    ※3개 구매시 34% 할인
    ※3의 배수로 적용

    유한킴벌리 해당상품 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉪ "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    크리넥스/좋은느낌/화이트 등 최대 39종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,300~10,350원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각2,600~20,700원

    롯데/신한/NH농협/삼성카드 2개 이상 50%
    크리넥스 수프림소프트 3겹 화장지(27m×30롤) 해당카드 2개 이상 구매시 각19,700원
    ※유한킴벌리 상품권 증정 행사 제외 ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 39,400원

    2개 이상 20%
    오늘좋은 건강식품 전품목 (상품별 상이) 2개 이상 구매시 1개당 각9,520~71,920원
    ※교차구매 가능
    ※1개 구매시 각11,900~89,900원

    L.POINT 각 2,000원
    뉴케어 클래식 3종(각 200ml×16입) 27,900~30,900원
    ※구수한맛/검은깨맛/인절미맛

    헨켈 감사절 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉬ "를 확인하세요
    ※결제시 계산대에서 수령
    2개 이상 50%
    프릴&브레프 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,250~4,750원
    ※교차구매 가능 ※해당상품에 한함
    ※1개 구매시 각4,500~9,500원

    센텔리안 24 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령
    센텔리안24 파워 부스팅 마데카크림(50ml+30ml) 17,520원
    ※점별 재고수량 상이

    각2,000원 할인 -> 롯데/신한/NH농협/삼성카드 각1,000원 추가할인
    유한락스 주거세정제 번들 3종 (상품별 상이) 각5,900원
    ※곰팡이/욕실/주방
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 50%
    아우라 퍼퓸캡슐 6종 (용기 2L/리필 2.3L) 2개 이상 구매시 1개당 각10,900원
    ※교차구매 가능
    ※미스틱문라이즈/스윗만다린/양재동꽃시장
    ※1개 구매시 각21,800원

    롯데/신한/NH농협/삼성카드 2,000원 할인
    스트로베리 에디션 무형광 3겹 화장지(28m×30롤) 9,900원
    ※페이지 하단 카드할인 세부내용 참고

    스트로베리 에디션 물티슈/미용티슈(100매/180매×3입) 1,000/3,300원

    2개 이상 55%
    엘라스틴 아미노프로틴 샴푸/컨디셔너(각 855ml) 2개 이상 구매시 1개당 각6,705원
    ※손상/볼륨 ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각14,900원

    50% -> 롯데/신한/NH농협/삼성카드 20% 추가할인
    바세린 바디로션 4종(각 400ml) 각5,960원
    ※드라이스킨/어드벤스드/핸드&네일/알로에수드
    ※교차구매 가능 ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    센카 퍼펙트휩 콜라겐/올클리어 더블워시(각 120g) 7,550/7,250원

    질레트 프로글라이드 파워 기획(기+날 5입) 38,900원
    ※점별 운영상품 상이

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    미리 준비하는 연말 크리스마스
    크리스마스 행사상품 롯데/신한/NH농협/삼성카드 5만원 이상 결제시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ♣ "를 확인하세요 ※결제시 계산대에서 수령
    ※상품권 소진시 행사가 조기 종료될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고
    전나무 세트(60) 外 (상품별 규격 상이) 49,800~115,000원
    ※점별 입점상품 상이

    2개 이상 최대 20%
    크리스마스 화초/장식용품(상품별 상이) 2개 이상 구매시 1개당 각1,520~39,200원
    ※입점점에 한함 ※해당상품에 한함 ※점별 입점상품 상이
    ※동일상품군에 한해 교차구매 가능
    ※1개 구매시 각 1,900~49,000원

    크리스마스 머그/플레이트(상품별 상이) 20% 2,320~6,320원

    2개 이상 20%
    타탄/포레스트 일회용 식기/종이컵 (상품별 상이) 2개 이상 구매시 1개당 각1,592원
    ※교차구매 가능
    ※1개 구매시 각 1,990원

    겨울 보온 용품 최대 30%
    2개 이상 30%
    BYC 내의세트 外 브랜드 동내의(1입) 2개 이상 구매시 1개당 각9,730~27,930원
    ※1개 구매시 각 13,900~39,900원 ※교차구매 가능

    2개 이상 30%
    브랜드 방한 양말/타이즈 (상품별 상이) 2개 이상 구매시 1개당 각2,730~10,430원
    ※입점점에 한함
    ※네파/휠라/레노마/컬럼비아/비비안/피에르가르뎅
    ※1개 구매시 각 3,900~14,900원 ※교차구매 가능


    겨울 패딩 자켓 (웰론 경량패딩자켓/포켓 중량자켓) 10,000원 할인 29,900/39,900원
    ※입점점에 한함

    푸마 하이브리드 아노락 10,000원 할인 각35,900원
    ※입점점에 한함 

    2개 이상 30%
    오늘좋은 겨울 파자마 2개 이상 구매시 1개당 각20,930원
    ※소프트기모/피치스킨 파자마세트
    ※1개 구매시 각 29,900원 ※교차구매 가능

    방한 운동화/슬립온/부츠(상품별 상이) 20% 12,640~43,040원
    ※점별 입점상품 상이 ※행사상품에 한함

    오늘좋은 발열침구/패브릭 소품(상품별 상이) 30% 6,930~59,430원

    20%
    오늘좋은 양털 거실화 外(상품별 상이) 6,320~10,320원
    ※입점점에 한함 ※해당상품에 한함

    20%
    가습기/히터/보온시트(상품별 상이) 1,590~55,920원

    해피콜 코어센서 프라이팬(상품별 상이) 50% 23,950~32,450원

    2개 이상 50%
    네오플램 퓨어락(상품별 상이) 2개 이상 구매시 1개당 각2,200~6,200원
    ※1개 구매시 각 4,400~12,400원 ※교차구매 가능

    오늘좋은 고중량 세면타월(각 6P) 30% 각17,430원
    ※그레이/라이트브라운/라이트블루
    ※입점점에 한함 ※해당상품에 한함

    30%
    오늘좋은 데일리 쿠션 부직포 청소대(대형)/극세사 청소대(대형)/집게 청소대 11,130/11,830/13,230원
    ※입점점에 한함 ※해당상품에 한함

    최대 30%
    폭설 대비 제설 용품(스프레이체인, 제설기 外) 2,000~66,320원
    ※상품별 행사내용 상이 ※점별 재고 상이

    20%
    3M 리싸이클 정전기 청소포(각 60매) 6,940/7,920원
    ※표준형/대형 ※입점점에 한함 ※해당상품에 한함

    40%
    콜리올리 말이껌 10종 (상품별 상이) 1,434~5,394원

    20%
    콜리올리 두부모래 2종 (각 3kg, 오리지널/녹차) 각6,320원

    "Move People Through the wonders of sport"
    스포츠의 경이로움으로 사람들을 움직인다
    세상의 모든 스포츠, 데카트론!
    성인 스키 넥워머 퍼스트 히트 4,900원

    백패킹 캡 모자 트래블 100 900원 할인 5,900원

    킵드라이 500 성인용 모자 2,100원 할인 7,900원

    R500 무릎 스트랩 2,100원 할인 10,900원

    아동 스키 보온 긴팔티 BL500 3,000원 할인 11,900원

    피트니스 폼 줄넘기 JR500 2,200원 할인 5,900원

    요리하다 겨울맞이 신상품 4종!
    요리하다 쫄깃한 소곱창구이(220g) 9,990원

    요리하다 방과후 소떡소떡(550g) 4,990원

    L.POINT 1,000원
    요리하다 우리쌀 국물떡볶이(345g) 2,990원

    요리하다 얼큰한 김치우동 2인(432g) 3,990원

    
    오늘 가장 좋은 선택 BEST MD'S PICK
    오늘좋은 프레첼 스낵믹스 2종(각 65g) 각1,000원
    ※멕시칸타코맛/체다치즈맛

    최대1,500원 할인
    오늘좋은 런천미트/데일리팜 2종(각 340g) 3,490/4,490원

    오늘좋은 아몬드호두 율무차 (18g×50입) 2,000원 할인 7,990원

    오늘좋은 캡슐커피 싱글 오리진 콜롬비아/롱고/디카페인 (각 5.2g×10입) 각2,000원

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaSeoulData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2024. 12. 5(목) ~ 2024. 12. 11(수) < 비신선식품 적용기간 : 2024. 11. 28(목) ~ 12. 11(수) >
    ·제타플렉스 서울역점

    ZETTAPLEX SEOUL STATION

    윈터딸기클럽 오픈! 
    딸기에 진심인 당신을 위한 최고 품질&최다 품종

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다
    이번주 핫프라이스 HOT
    윈터딸기클럽 런칭 기념 '달콤한 특가'
    딸기 전품목 (상품별 상이/국산)
    롯데/신한/NH농협/삼성카드로 2개 이상 구매시 각4,000원 할인
    ※2개 이상 구매시 각 2천원 할인 해당카드 결제시 각 2천원 추가할인
    ※1개 구매시 카드할인 2천원만 적용 ※교차구매 가능
    ※점별 운영상품 상이 ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    롯데마트GO앱 윈터딸기클럽 런칭 이벤트
    ▶기간 : '24/12/5(목) ~ '25/1/22(수)
    ※이벤트 관련 세부내용은 롯데마트GO앱에서 확인하실 수 있습니다

    혜택 1 스탬프미션 참여하기 누르면 2만원 딸기할인 쿠폰팩 증정!
    ※해당 쿠폰 오프라인 매장에서만 사용 가능

    혜택 2 BAGGU(바쿠) 딸기 장바구니와 딸기 뷔페 식사권 증정!
    선착순 1천명 BAGGU(바쿠) 딸기 장바구니 + 스탬프 미션 가장 빨리 달성한 10명에게는 애슐리 딸기뷔페 2인 상품권 지급

    이벤트 참여 방법
    STEP 1 행사 기간 내 롯데마트 매장에서 딸기 품종 4가지 구매 
    ※설향 제외 ※품종 중복 불가

    STEP 2 딸기 구매 시, GO APP 바코드 스캔하고 스탬프 적립

    STEP 3 스탬프 미션 완료 후 선물받기

    고객 여러분의 밥상물가를 지킬 10대 아이템
    MISSION 미션! 물가를 잡아라
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.
    ※기간 : 12/5(목)~12/11(수) (비신선식품 적용은 11/28(목)~12/11(수))

    L.POINT 40%
    데친문어 (100g/냉장/세네갈산) 2,394원

    2팩 이상 각1,000원 할인
    대추방울 스윗마토/스윗볼 스테비아 대추방울토마토 (각 500g/팩/국산) 2팩 이상 구매시 1팩당 각6,990원
    ※1팩 구매시 각 7,990원

    상주일품미(10kg/국산) 25,900원

    밤고구마(1.5kg/박스/국산) 5,990원

    L.POINT 25%
    옛날 두마리 치킨 (1팩/국내산 게육) 14,993원

    1+1
    하림 더미식 유니자장면(2개입) 7,980원

    1+1
    우동/수제비/칼국수 5종 (각 2인) 6,980~8,480원
    ※동일 브랜드 교차구매 가능

    서울우유 후레쉬밀크 기획 (900ml×2) 1,000원 할인 4,480원

    L.POINT 1,000원
    자임 햇 꿀 유자차/햇 꿀 한라봉차(각 2kg) 각9,900원

    자연퐁 대용량 용기 2종(각 3.1kg, 솔잎/오렌지) 1,000원 할인 각7,950원

    초특가 4일장
    ※기간 : 12/5(목) ~ 12/8(일)

    국산 흰다리새우 (100g/냉장/국산) 2,590원

    L.POINT 30%
    훈제오리 슬라이스 (500g/냉장/원산지 별도표기) 10,490원

    20개 1만원!
    수제 모둠 소시지 20개입 (1kg/원산지 별도표기) 10,000원

    L.POINT 1,000원
    하루한알 갈아먹는 토마토 (1kg/팩/국산) 9,990원

    2개 이상 60%
    홈스타 3x 폼스프레이 2종 2개 이상 구매시 1개당 각3,560원
    (각 900ml, 후로랄/후레쉬) ※교차구매 가능
    ※1개 구매시 각 8,900원

    롯데 단독 플 레이브 빼빼로 기획! 12/7 토 2차 판매 시작!
    롯데 빼빼로 PL AVE 기획 (크런키 140g/아몬드 132g) 각 4,780원
    해당상품 2개 구매시 플레이브 포 토카드 1세트(2매) 증정!
    ※점별 입고 수량 상이

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    L.POINT 20%
    봉지굴 (150g/260g, 냉장, 국산) 4,880/7,920원

    L.POINT 25%
    완도 활 전복 (중/냉장/국산) 1,875원

    L.POINT 40%
    양념 황태구이 고추장/간장맛 (각 120g/냉장/원산지 별도표기) 5,940원

    L.POINT 5,000원
    항공직송 동원 생연어 (320g/냉장/노르웨이산) 16,900원

    GAP 친환경 표고버섯/느타리버섯(300g/500g, 팩, 국산) 4,990/1,990원

    2팩 이상 각500원 할인
    달래/냉이(80g/100g, 팩, 국산) 2팩 이상 구매시 1팩당 각2,490원
    ※조기품절될 수 있습니다 ※교차구매 가능
    ※1팩 구매시 각2,990원

    상생사과(4~7입/봉/국산) 9,990원

    2판 이상 각1,000원 할인
    행복생생란(대란/30입/국산) 2판 이상 구매시 1판당 각5,990원
    ※1판 구매시 6,990원

    크리스마스 케이크 사전예약!
    ※사전예약 기간 : 12/5(목)~12/18(수)
    ※상품수령 기간 : 12/21(토)~12/25(수)
    20% 롯데/신한/삼성/NH농협카드 최대3,000원 할인
    크렘드마롱 화이트케익/초코케익 (1호/2호, 원산지 별도표기) 17,200/25,000원
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    L.POINT 최대 40%
    1등급 한우 전품목 (각 100g/냉장/국내산 한우고기)
    ※부위별 조기 품절될 수 있습니다

    L.POINT 40%
    미국산 초이스 부채살 (100g/냉장/미국산) 2,280원
    ※기간 : 12/5(목)~12/8(일), 4일간
    ※12/9(월)~12/11(수) L.POINT 30%

    L.POINT 20%
    1등급 브랜드 돼지고기 앞다리/갈비 (각 100g/냉장/국내산 돼지고기) 1,992원
    ※입점점에 한함

    L.POINT 50%
    산더미 콩나물 제육 불고기 (700g/원산지 별도표기) 9,900원
    ※조기 품절될 수 있습니다

    AI선별 제주 올레길 감귤 (2.5kg/박스/국산) 13,990원

    L.POINT 20%
    광어연어도미 모둠회 (250g 내외/냉장/원산지 별도표기) 23,920원
    ※입점점에 한함

    L.POINT 30%
    큰 초밥 (20입/팩/원산지 별도표기) 13,993원

    L.POINT 30%
    볼케이노 봉구이 (1팩/원산지 별도표기) 6,993원

    더 커진 깐쇼새우/크리스피 허니 쉬림프 각6,990원
    (팩/원산지 별도표기)

    김밥&유부&롤 한판세트 (1팩/원산지 별도표기) 10,990원

    깍둑 순살 불족발 (팩/원산지 별도표기) 15,900원
    ※족발 입점점에 한함

    2개 이상 50%
    코주부 징기스칸 육포 (각 130g, 순한맛/매운맛) 2개 이상 구매시 1개당 각7,450원
    ※1개 구매시 각14,900원
    ※교차구매 가능

    함께 즐기는 홈파티 추천 먹거리
    하나사면 하나 더 1+1 
    2개 이상 구매시 더 싸게

    2개 이상 50%
    목우촌 주부9단 육즙팡팡 프랑크(350g) 2개 이상 구매시 1개당 각4,490원
    ※1개 구매시 8,980원

    2개 이상 50%
    삼진어묵 생생꼬불 어묵꼬치/전통모듬 어묵탕(416g/802g) 2개 이상 구매시 1개당 각4,990/6,490원
    ※1개 구매시 각9,980/12,980원
    ※교차구매 가능

    2개 이상 40%
    애슐리 볶음밥 4종(각 4인) 2개 이상 구매시 1개당 각7,788원
    ※통새우/스크램블&게살/캐나다랍스터/깍두기베이컨
    ※교차구매 가능
    ※1개 구매시 각12,980원

    1+1
    마니커 바사삭 치킨 (500g) 10,980원

    1+1
    동원 리얼 크랩스/랍스터 (각 216g) 각5,980원
    ※교차구매 가능

    2개 이상 50%
    풀무원 노엣지피자 3종(상품별 상이) 2개 이상 구매시 1개당 각4,990원
    ※코리안BBQ/포테이토&콘/토마토&미트콤보
    ※교차구매 가능
    ※1개 구매시 각9,980원

    2개 이상 10%
    대상 호밍스 냉동 국물요리 5종 (각 700g) 2개 이상 구매시 1개당 각8,982원
    ※나주곰탕/얼큰소고기장터국 外
    ※교차구매 가능
    ※1개 구매시 각9,980원

    양반 죽/비빔드밥/국물요리 전품목 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

    1+1
    동원 양반죽 16종(상품별 규격 상이) 각3,980~5,980원
    ※동일 가격 교차구매 가능

    1+1
    동원 양반 상온 국물요리 14종 (차돌육개장 460g 外 13종/상품별 규격 상이) 각2,980~6,980원 
    ※동일 가격 교차구매 가능

    1+1
    청정원 맛선생 국물내기 한알 4종
    (상품별 규격 상이) ※교차구매 가능
    ※멸치디포리/야채/사골/황태

    1+1
    아즈테카 밀또띠아 2종 (6인치/8인치) 각3,490/3,990원
    ※동일 상품에 한함

    1+1 
    서울 까요까요/큐빅 치즈 각4,580~5,380원
    (상품별 규격 상이) ※동일 가격간 교차구매 가능

    1+1
    풀무원다논 더블액션/하루요거트 6종(각 80g×4입) 각3,780원
    ※풀무원다논 하루요거트 생크림 外 5종
    ※교차구매 가능

    1+1
    베지밀 검은콩과 검은참깨(190ml×24입)/삼육 검은콩과 칼슘파우치(190ml×20입) 30,000/19,900원
    ※동일 상품에 한함

    1+1
    델몬트 오렌지/포도/망고 (각 1.8L) 각5,480원
    ※교차구매 가능

    2개 이상 각2,480원 할인
    카프리썬 오렌지 外 5종 (각 200ml×10입) 2개 이상 구매시 1개당 각4,500원
    ※1개 구매시 각6,980원
    ※교차구매 가능
    ※오렌지망고/사파리/사과/정글/펀알람

    1+1
    켈로그 든든한 브랜 그래놀라(450g)/그래놀라 넛츠&씨드(500g) 9,580/9,980원
    ※동일 상품에 한함

    2개 이상 30%
    프링글스 오리지날/사워크림어니언(각 142g) 2개 이상 구매시 1개당 각2,576원
    ※교차구매 가능
    ※1개 구매시 각3,680원
    
    L.POINT 1+1
    삼립 미니 꿀호떡(각 192g) 각3,480원
    ※오리지널/옥수수 ※교차구매 가능

    L.POINT 1+1
    삼립 누네띠네 오리지널(60g) 2,980원

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    함께 즐기는 홈파티 추천 먹거리 최대 50%할인
    동원 스페셜비엔나 기획 (300g×2) 80g 증량 UP 6,480원

    최대2,430원 할인
    풀무원 밀누들/밀국물 떡볶이 (각 423.5g) 각3,280원

    3kg
    종가 아삭하고 개운한 포기김치(3kg) 2,100원 할인 26,900원

    2+1
    아지노모도 날개달린 교자/교자만두/가라아게(상품별 규격 상이) 5,980~9,980원
    ※동일가격 교차구매 가능

    살코기 마일드 참치 기획(85g×8입) 5,000원 할인 10,980원

    L.POINT 각 1,000원
    오뚜기 찹쌀호떡믹스/미니도나스믹스(상품별 규격 상이) 각2,980원

    세계맥주 15종 골라담기 (상품별 상이) 5캔 구매시 11,000원
    ※1캔 구매시 각 2,280~3,300원

    최대 50% 1+1
    코카콜라 1.8L 外 탄산음료 대형 PET 14종(상품별 상이) 각1,190~2,680원
    ※동일시리즈간 교차구매 가능

    L.POINT 2,000원
    서울 체다 슬라이스 치즈 50매 특별기획(18g×50매) 10,980원

    동원 드링킹 요구르트 2종 (각 750ml) 800원 할인 각3,980원
    ※플레인/딸기    

    서울우유 더 진한 그릭요거트 2종 (각 100g) 1,200원 할인 각2,780원
    ※오리지널/소프트

    캐치 티니핑 딸기 外 어린이 음료 19종(상품별 상이) 각1,000원

    오뚜기 행사상품 2개 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉧ "를 확인하세요
    ※결제시 계산대에서 수령
    오뚜기 인기 냉동식품 9종 (상품별 규격 상이) 8,980~9,980원
    ※XO 딤섬 3종, 카레치킨, 치킨너겟, 붕어빵 2종, 브리또 2종

    담터 전상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※구분자 " # "를 확인하세요
    ※결제시 계산대에서 수령
    담터 호두 아몬드 율무차(18g×50입)/호두 아몬드 율무차 라이트(18g×40입) 각12,800원

    2+1
    스타벅스 다크/미디엄 로스트 (각 1.1g×30입) 각11,900원
    ※교차구매 가능 ※점별 입고상품 상이

    오리온 포카칩 오리지널/어니언 지퍼백 2종(각 266g) 20% 4,312원

    오레오 화이트크림 기획(450g) 850원 할인 4,780원

    롯데 칙촉 오리지널 2번들(336g) 1,010원 할인 4,980원

    애경 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉵ "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    리큐 프레쉬 리필 (각 2.6L, 일반/드럼) 2개 이상 구매시 1개당 각5,450원
    ※교차구매 가능
    ※1개 구매시 각10,900원

    2개 이상 65%
    닥터 루티어 효모 샴푸(각 800ml) 2개 이상 구매시 1개당 각6,930원
    ※1개 구매시 각19,800원
    ※머스크/쟈스민/석류향
    ※교차구매 가능 ※점별 운영상품 상이

    1+1
    샤워메이트 산양유 바디워시 (각 800ml) 9,980원
    ※오리지널/마누카허니
    ※교차구매 가능 ※점별 운영상품 상이

    1+1
    2080 어드밴스드 치약(각 420g) 각8,900원
    ※블루/그린 ※교차구매 가능
    ※점별 입고상품 상이

    2+1
    종근당건강 락토핏 6종 (상품별 상이) 각15,900~29,900원
    ※교차구매 가능
    ※골드/코어/당케어/슬림/키즈/뷰티
    ※3개 구매시 34% 할인
    ※3의 배수로 적용

    유한킴벌리 해당상품 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉪ "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    크리넥스/좋은느낌/화이트 등 최대 39종(상품별 규격 상이) 2개 이상 구매시 1개당 각1,300~10,350원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각2,600~20,700원

    롯데/신한/NH농협/삼성카드 2개 이상 50%
    크리넥스 수프림소프트 3겹 화장지(27m×30롤) 해당카드 2개 이상 구매시 각19,700원
    ※유한킴벌리 상품권 증정 행사 제외 ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 39,400원

    2개 이상 20%
    오늘좋은 건강식품 전품목 (상품별 상이) 2개 이상 구매시 1개당 각9,520~71,920원
    ※교차구매 가능
    ※1개 구매시 각11,900~89,900원

    L.POINT 각 2,000원
    뉴케어 클래식 3종(각 200ml×16입) 27,900~30,900원
    ※구수한맛/검은깨맛/인절미맛

    헨켈 감사절 해당상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉬ "를 확인하세요
    ※결제시 계산대에서 수령
    2개 이상 50%
    프릴&브레프 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,250~4,750원
    ※교차구매 가능 ※해당상품에 한함
    ※1개 구매시 각4,500~9,500원

    센텔리안 24 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉦ "를 확인하세요
    ※결제시 계산대에서 수령
    센텔리안24 파워 부스팅 마데카크림(50ml+30ml) 17,520원
    ※점별 재고수량 상이

    각2,000원 할인 -> 롯데/신한/NH농협/삼성카드 각1,000원 추가할인
    유한락스 주거세정제 번들 3종 (상품별 상이) 각5,900원
    ※곰팡이/욕실/주방
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 50%
    아우라 퍼퓸캡슐 6종 (용기 2L/리필 2.3L) 2개 이상 구매시 1개당 각10,900원
    ※교차구매 가능
    ※미스틱문라이즈/스윗만다린/양재동꽃시장
    ※1개 구매시 각21,800원

    롯데/신한/NH농협/삼성카드 2,000원 할인
    스트로베리 에디션 무형광 3겹 화장지(28m×30롤) 9,900원
    ※페이지 하단 카드할인 세부내용 참고

    스트로베리 에디션 물티슈/미용티슈(100매/180매×3입) 1,000/3,300원

    2개 이상 55%
    엘라스틴 아미노프로틴 샴푸/컨디셔너(각 855ml) 2개 이상 구매시 1개당 각6,705원
    ※손상/볼륨 ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각14,900원

    50% -> 롯데/신한/NH농협/삼성카드 20% 추가할인
    바세린 바디로션 4종(각 400ml) 각5,960원
    ※드라이스킨/어드벤스드/핸드&네일/알로에수드
    ※교차구매 가능 ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    센카 퍼펙트휩 콜라겐/올클리어 더블워시(각 120g) 7,550/7,250원

    질레트 프로글라이드 파워 기획(기+날 5입) 38,900원
    ※점별 운영상품 상이

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    미리 준비하는 연말 크리스마스
    크리스마스 행사상품 롯데/신한/NH농협/삼성카드 5만원 이상 결제시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ♣ "를 확인하세요 ※결제시 계산대에서 수령
    ※상품권 소진시 행사가 조기 종료될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고
    전나무 세트(60) 外 (상품별 규격 상이) 49,800~115,000원
    ※점별 입점상품 상이

    2개 이상 최대 20%
    크리스마스 화초/장식용품(상품별 상이) 2개 이상 구매시 1개당 각1,520~39,200원
    ※입점점에 한함 ※해당상품에 한함 ※점별 입점상품 상이
    ※동일상품군에 한해 교차구매 가능
    ※1개 구매시 각 1,900~49,000원

    크리스마스 머그/플레이트(상품별 상이) 20% 2,320~6,320원

    2개 이상 20%
    타탄/포레스트 일회용 식기/종이컵 (상품별 상이) 2개 이상 구매시 1개당 각1,592원
    ※교차구매 가능
    ※1개 구매시 각 1,990원

    겨울 보온 용품 최대 30%
    2개 이상 30%
    BYC 내의세트 外 브랜드 동내의(1입) 2개 이상 구매시 1개당 각9,730~27,930원
    ※1개 구매시 각 13,900~39,900원 ※교차구매 가능

    2개 이상 30%
    브랜드 방한 양말/타이즈 (상품별 상이) 2개 이상 구매시 1개당 각2,730~10,430원
    ※입점점에 한함
    ※네파/휠라/레노마/컬럼비아/비비안/피에르가르뎅
    ※1개 구매시 각 3,900~14,900원 ※교차구매 가능


    겨울 패딩 자켓 (웰론 경량패딩자켓/포켓 중량자켓) 10,000원 할인 29,900/39,900원
    ※입점점에 한함

    푸마 하이브리드 아노락 10,000원 할인 각35,900원
    ※입점점에 한함 

    2개 이상 30%
    오늘좋은 겨울 파자마 2개 이상 구매시 1개당 각20,930원
    ※소프트기모/피치스킨 파자마세트
    ※1개 구매시 각 29,900원 ※교차구매 가능

    방한 운동화/슬립온/부츠(상품별 상이) 20% 12,640~43,040원
    ※점별 입점상품 상이 ※행사상품에 한함

    오늘좋은 발열침구/패브릭 소품(상품별 상이) 30% 6,930~59,430원

    20%
    오늘좋은 양털 거실화 外(상품별 상이) 6,320~10,320원
    ※입점점에 한함 ※해당상품에 한함

    20%
    가습기/히터/보온시트(상품별 상이) 1,590~55,920원

    해피콜 코어센서 프라이팬(상품별 상이) 50% 23,950~32,450원

    2개 이상 50%
    네오플램 퓨어락(상품별 상이) 2개 이상 구매시 1개당 각2,200~6,200원
    ※1개 구매시 각 4,400~12,400원 ※교차구매 가능

    오늘좋은 고중량 세면타월(각 6P) 30% 각17,430원
    ※그레이/라이트브라운/라이트블루
    ※입점점에 한함 ※해당상품에 한함

    30%
    오늘좋은 데일리 쿠션 부직포 청소대(대형)/극세사 청소대(대형)/집게 청소대 11,130/11,830/13,230원
    ※입점점에 한함 ※해당상품에 한함

    최대 30%
    폭설 대비 제설 용품(스프레이체인, 제설기 外) 2,000~66,320원
    ※상품별 행사내용 상이 ※점별 재고 상이

    20%
    3M 리싸이클 정전기 청소포(각 60매) 6,940/7,920원
    ※표준형/대형 ※입점점에 한함 ※해당상품에 한함

    40%
    콜리올리 말이껌 10종 (상품별 상이) 1,434~5,394원

    20%
    콜리올리 두부모래 2종 (각 3kg, 오리지널/녹차) 각6,320원

    인기 와인&칵테일 2종 L.POINT 최대 7,600원 할인!

    L.POINT 7,000원
    1865 카베르네소비뇽 (청뱀띠 에디션) 29,800원

    L.POINT 7,600원
    업타운 마가리타(750ml) 9,900원

    L.POINT 10,990원
    컨디션 스틱 3종(각 18g×10입) 각16,000원
    ※컨디션/그린애플/자두

    3캔 구매시
    하이볼 골라담기(상품별 용량 상이) 9,990원
    ※생레몬/라임/청귤 하이볼, 퐁당 하이볼 外 5종
    ※1캔 구매시 각 4,000원

    요리하다 겨울맞이 신상품 4종!
    요리하다 쫄깃한 소곱창구이(220g) 9,990원

    요리하다 방과후 소떡소떡(550g) 4,990원

    L.POINT 1,000원
    요리하다 우리쌀 국물떡볶이(345g) 2,990원

    요리하다 얼큰한 김치우동 2인(432g) 3,990원

    오늘 가장 좋은 선택 BEST MD'S PICK
    오늘좋은 프레첼 스낵믹스 2종(각 65g) 각1,000원
    ※멕시칸타코맛/체다치즈맛

    최대1,500원 할인
    오늘좋은 런천미트/데일리팜 2종(각 340g) 3,490/4,490원

    오늘좋은 아몬드호두 율무차 (18g×50입) 2,000원 할인 7,990원

    오늘좋은 캡슐커피 싱글 오리진 콜롬비아/롱고/디카페인 (각 5.2g×10입) 각2,000원

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202412_2";
const category = [];

const data = {
  L201: {
    title: "스마트전단지-전점201",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L202: {
    title: "스마트전단지-전점202",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L203: {
    title: "스마트전단지-제타플렉스 잠실점203",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L204: {
    title: "스마트전단지-제타플렉스 잠실점204",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L205: {
    title: "스마트전단지-제타플렉스 서울역점205",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
  L206: {
    title: "스마트전단지-제타플렉스 서울역점206",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
