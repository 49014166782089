/* 701, 751 빅마켓(~6/12) */
const vicData = [
  `
  <h1>LOTTE VIC Market</h1>
  <div>
    롯데/신한카드 롯데마트&MAXX

    수박/탄산음료/폴로,챔피언 패션잡화브랜드 20%
    추 가 할 인
    
    전용카드
    회원만!
    ▶ 기간 : 5/30(수) ~ 6/12(수)
    ▶ 해당카드 :
    롯데마트&MAXX카드,
    LOCA for 롯데마트카드/롯데마트맥스 신한카드
    ※최종가/엘포인트 할인가에서 추가 할인 적용
    ※MAXX/VIC 매장 한정, 롯데마트몰/SCAN&GO 제외
    ※로카페이, LPAY 및 해당 실물카드 결제 시 적용
    (삼성페이 등 간편결제는 미적용)
    ※할인스티커 부착 상품과 중복적용 불가 ※재고소진시 행사종료
    ※특약(입점)행사/선물세트 제외 ※자세한 사항은 가까운 직원에게 문의

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가

    수박 전품목 20% 추가 할인 12,784~16,784원
    당도선별 수박(6~10kg 미만/통/국산)
    ※점별 운영 중량 상이
    ※ 해당카드 미적용시
    17,980~22,980원
    ※ 롯데/신한카드 결제시
    15,980~20,980원

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가
    20% 추가 할인
    탄산음료 전품목
    (콜라/사이다/혼합탄산음료)
    ※트레비 토닉워터 얼그레이 제외

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가
    20% 추가 할인
    폴로/챔피언 브랜드 의류(성인/아동)
    ※해당카드 미적용시 9,980~79,800원

    61월 한달 내내 이 가격 ※기간 : 6/1(토) ~ 6/30(일)

    3,500원 할인 캐나다산 카무트(2kg/캐나다산) 18,980원
    2,000원 할인  롤링롤링 오징어(150g/원산지 별도표기) 7,980원
  </div>
  `,
  `
  <div>
    3,000원 할인 어포튀각 9,980원
    (400g/원산지 별도표기)

    하림 더 건강한 두마리 치킨 (팩 : 두마리/닭고기 : 국내산) 15,980원

    2,000원 할인 하림 용가리 치킨 9,980원
    (1.2kg)
    
    1,000원 할인 큐원 올리고당 7,380원
    (1.2kg×2입)
    
    1+1 자임 곤약젤리 사과/
    패션후르츠(150g×10입) 각16,980원
    
    1,500원 할인 삼립 뉴 젤리뽀 7,480원
    (2.4kg)
    
    2,500원 할인 유키앤러브 파인애플
    케이크(25g×20입) 13,480원

    롯데카드/신한카드 1,000원 할인 상쾌환(3g×12입) 14,980원
    
    10,000원 할인 블루밍가든 누들볼 29,800원
    (4P)
    
    롯데카드/신한카드 3,000원 할인 싸이닉 엔조이 선에센스 9,980원
    (50ml×2)

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    시원한 할인가로
    슬기로운 여름 준비!

    롯데카드/신한카드 2,000원 할인 미국산 체리 12,980원
    (900g/팩)
    
    제스프리 한판 골드키위 14,980원
    (12입/박스/뉴질랜드산)

    단 8일간, 핫프라이스!
    롯데카드/신한카드 50% 할인  한우 등심(100g/냉장/국내산) 3,990원
    ※기간 : 5/30(목)~6/6(목) ※2등급

    L.POINT 25& 할인 1등급 한우 국거리/불고기 2,835원
    (각 100g/냉장/국내산)

    L.POINT 25& 할인 호주산 곡물비육 부채살 1,935원
    (100g/냉장/호주산)
    
    L.POINT 25& 할인 캐나다산 돼지 삼겹살/목심 각1,260원
    (각 100g/냉장/캐나다산)
  </div>
  `,
  `
  <div>
    1,000원 할인 청수 물냉면 5,280원
    (720g)
    
    3,000원 할인 풀무원 함흥비빔냉면 3,980원
    2인(460g)

    롯데카드/신한카드 2,000원 할인 티오 아이스티 복숭아맛 17,980원
    (13g×150입)
    
    스페쇼우 젤리스트로우
    플러스(1,254g) 10,980원

    4,020원 할인 트레비 토닉워터
    얼그레이 3,960원

    2,000원 할인 티젠 콤부차 매실 10,980원
    (5g×30입)

    롯데카드/신한카드 35% 할인 캐나다 활 랍스터 12,935원
    (마리/냉장)

    L.POINT 5,000원 할인 미국산 냉동 차돌박이 39,800원
    (1.2kg/냉동/미국산)

    10,000원 할인 젠텍 14인치 터보펜 에어 서큘레이터/
    젠텍 20인치 공업용 선풍기 각79,800원

    순면 누빔 편백메밀베개
    (블루/차콜) 각9,980원
     
    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    해양수산부와 함께하는 수산대전20%

    수산대전20% 프리미엄 광어회 29,984원
    (500g 내외/팩/광어 : 국산)

    수산대전20% ASC인증 완도 활전복 19,840원
    (대/14미/10마리)

    수산대전20% 원양산 오징어 2,384원
    (마리/해동)
 
    수산대전20% 영광 법성포 굴비 27,984원
    (1.4kg/팩/냉동/국산)

    수산대전20% 국산 고등어필렛 8,784원
    (1kg/팩/냉동)

    수산대전20% 황태채(400g/팩/러시아산)/
    볶음용, 조림용멸치 각9,984원
    (각 500g/팩/멸치:국산)

    완숙 토마토 10,980원
    (2.4kg/팩/국산)

    1,000원 할인 옛날방앗간 미숫가루 8,980원
    (1.2kg/원산지 별도표기) 

    1,500원 할인 냉장 다진마늘 9,480원
    (800g/국산)

    GAP 파프리카 980원
    (1개/국산) 

    다다기오이 4,480원
    (10입/봉/국산)

    
    </div>
  `,
  `
  <div>
    롯데카드/신한카드 1,800원 할인 신선한 계란
    (특/30입/1.8kg/국산) 5,480원

    3,500원 할인 귀한 쌀 26,480원
    (10kg/국산)

    각 2,000원 할인 구운아몬드/구운 점보캐슈넛 11,980/6,980원
    (1kg/400g, 원산지 별도표기)

    물가안정 갈치/참조기 각3,600/1,400원
    (각 32미/180미/마리, 해동, 국산)
 
    L.POINT 5,000원 할인 생새우살 31-40 16,980원
    (908g/팩/냉동/베트남산)

    L.POINT 20% 할인 호주산 곡물비육 살치살 3,664원
    (100g/냉장/호주산)
    
    L.POINT 25% 할인 호주산 찜갈비 1,860원
    (100g/냉장/호주산)

    L.POINT 3,000원 할인 정다운 허브 훈제오리
    (600g/냉장/원산지 별도표기) 11,980원
    
    L.POINT 1,500원 할인 하림 동물복지 닭볶음탕 8,480원
    (1.3kg/냉장/국내산)

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)

    L.POINT 4,000원 할인 양념 목심 칼집구이 23,980원
    (1.7kg/원산지 별도표기) 

    L.POINT 5,000원 할인 수원식 양념돼지 왕갈비 구이 24,980원
    (1.7kg/원산지 별도표기)

    L.POINT 50% 할인 풀무원 국산콩 와사비 나또 5,700원
    (44.5g×8입/원산지 별도표기)

    패밀리팩 초밥 24,980원
    (40P/팩/원산지 별도표기)

    씨푸드 쉐이크 우동 3,980원
    (팩/원산지 별도표기)

    핫 스파이시 윙봉 13,980원
    (팩/원산지 별도표기)

    날치알 새꼬막 비빔밥 8,980원
    (팩/원산지 별도표기)

    2,000원 할인 피크닉 마들렌 10,900원
    (20입/1팩/원산지 별도표기)

    각 1,000원 할인 RTC 피자 2종/파스타 1종 11,900~4,900원
    (듬뿍불고기/핫 페퍼로니)
    ※원산지 별도표기 ※빅마켓 영등포점에 한함
  </div>
  `,
  `
  <div>
    3,000/1,000원 할인 하림 닭가슴살 블랙페퍼(510g)/
    사조대림 로얄크랩(100g×2입) 9,480/4,480원

    1,000원 할인 백종원의 홍콩반점 해물육교자 6,980원
    (350g×2입)

    6,000원 할인 하림 치킨쌀너겟 9,980원
    (1.2 kg)

    각 2,000원 할인 쉐푸드 등심통돈까스(600g)/
    롯데 라퀴진 모짜렐라 치즈스틱(800g) 12,980/11,980원

    2,000원 할인 남가네 설악 추어탕 14,980원
    (450g×3입)

    각 2,000원 할인 문옥례 명인 전통 고추장/된장 15,580/11,980원
    (각 900g)

    각 2,000/1,000원 할인 움트리 생와사비(120g×2입)/
    육류n생와사비랑(120g×2입)/
    양념 초고추장(1kg×2입) 7,480/8,380/5,980원

    1,000원 할인 고기엔 참소스
    (800g×2입) 8,480원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  `,
  `
 <div>
    ▶ 기 간 : 5/30(목)~6/12(수)

    테이스티 말보로
    소비뇽 블랑(750ml) 19,800원

    커티삭 오리지널/프로히비션(각 700ml) 24,800/42,800원

    1,000원 할인 풀무원 다논 그릭 플레인(90g×10입) 4,680원

    롯데카드/신한카드 1,500원 할인 대파&베이컨 크림치즈(50g×4입) 5,480원

    롯데카드/신한카드 5,000원 할인 덴마크 산양 프로틴 고단백 14,480원
    슬라이스 치즈(220g×4입) 

    3,500원 할인 밀카 초코무 비스킷(40g×24입) 11,980원

    1,000원 할인 해태 홈런볼 초코(46g×9입) 7,980원
 
    2,500원 할인 액트투 전자렌지용 팝콘 버터맛 14,480원
    (1,404g)
  </div> 
  `,
  `
  <div>
    2,000원 할인 켈로그 고소한 현미 그래놀라 11,680원
    (500g×2입)

    롯데카드/신한카드 5,000원 할인 베지밀 17곡 한끼두유 11,980원
    (190ml×24입)

    롯데카드/신한카드 1,500원 할인 MAXX아메리카노 리얼블랙 1,980원

    2,000/1,000원 할인 사과당 애플밀크티/애플크러쉬 4,980/3,980원
    (각 18g×20입)

    종근당건강 전품목 3만원 이상
    5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령

    L.POINT 5,000원 할인 종근당건강 생유산균 락토핏 코어
    더블세트(60포×2입) 27,800원

    롯데카드/신한카드 1,000원 할인 독일 비타하임 발포 멀티비타민
    (4,500mg×80정) 8,980원

    4,000원 할인 뉴트리원 장용성 초임계 알티지
    오메가3 트리플(1,200mg×60캡슐) 21,980원

    5,000원 할인 내추럴박스 산양유프로틴
    (15g×42포) 29,800원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    
    롯데카드/신한카드 2,000원 할인 MAXX 프리미엄 화장지
    (40m×30롤) 17,980원

    L.POINT 2.500원 할인 아우라 피오니 블러썸 16,480원
    (5.5L)

    L.POINT 4,000원 할인 테크 베이킹+구연산 액체세제 11,980원
    (4.8L)

    2,000원 할인 히말리야 핑크솔트 아이스 15,980원
    (160g×5)

    2,000원 할인 미쟝센 퍼펙트
    오리지널 세럼 8,980원
    샴푸(900ml)

    2,000원 할인 밀크바오밥
    세라 바디워시
    베이비 파우더 
    (1,200ml) 11,980원
    
    L.POINT 최대 4,000원 할인 INTEX 플라밍고 라이드온/
    미니프레임풀(블루) 17,980/32,800원

    2,000원 할인 애견 쿨아이스 방석(스카이블루) 10,980원
  </div>
  `,
  `
  <div>
    락앤락 저장용품
    ※세부 품목은 매장에서 확인하세요
    전품목 할인!
    7,000원 할인 락앤락 클래식 밀폐용기 12P 14,980원
    
    3,000원 할인 니트릴 장갑 100매 7,980원
    (소/중/대)
    2,000원 할인 냉감 일체형 베게 10,980원
    1,000원 할인 마작 대방석(내추럴) 7,980원
    9,820원 할인 블랙야크 워크웨어
    반소매 카라티셔츠 29,980원
    5,000원 할인 SOUP 슬리브리스 포인트 원피스 34,800원
    5,000원 할인 비비안 숨쉬는 브라
    (2매입, M/L/XL) 29,800원
    5,000원 할인 나이키 코르테즈 파우치(블랙) 39,800원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
];

/* 738 maxx(~12/18) */
const maxxData = [
  `
  <h1>LOTTE Mart maxx</h1>
  <div>
    온가족 함께 즐기는 연말 홈파티 준비!

    롯데카드 신한카드 25%할인 1등급 한우등심 (100g/냉장/국내산 한우) 6,960원

    L.POINT 20%할인 1등급 한우 국거리,불고기 (각 100g/냉장/국내산 한우) 각2,944원

    2개이상 50% 그릭데이 그릭요거트 시그니처(800g) ※재고소진시 행사 종료 2개 이상 구매시 1개당 각9,990원 ※1개 구매시 19,980원

    1,000원 할인 롯데카드 신한카드 3,000원 추가할인 크리스마스 케이크 2종(원산지 별도표기) 최대할인가 26,000원
    ※기간 : 12/19(목) ~ 12/25(수)
    ※롯데마트맥스 영등포, 금천, 송천, 창원중앙점에 한함

    롯데카드 신한카드 50%할인 활 킹크랩 (100g/냉장/러시아산) 5,990원
    ※이미지 컷입니다
    ※산지 시황에 따라 조기 품절될 수 있습니다

    2개이상 50% 마니커 리얼치킨 통살치킨너겟(1kg) 2개 이상 구매시 1개당 각6,490원 ※1개 구매시 12,980원

    L.POINT 10%할잉 롯데카드 신한카드 10% 추가할인 Very Berry Festival(원산지 별도표기) 최대할인가 2,422~26,722원
    ※롯데마트맥스 상무, 목포점에 한함

    롯데카드 신한카드  3,000원할인 서귀포 감귤(3.5kg/박스/국산) 14,980원

    자숙 랍스터 (마리/냉동/캐나다산) 11,980원 ※이미지 컷입니다

    L.POINT 20%할인 패밀리팩 초밥(40P/팩) 22,784원 ※원산지 별도표기

    신상품 폭립(100g/원산지 별도표기) 1,980원

    L.POINT 20%할인 1등급 이상 돼지 삼겹살/목심(각 100g/냉장/국내산) 각1,984원

    L.POINT 20%할인 미국산 초이스 부채살/초이스 꽃갈비살(각 100g/냉장/미국산) 2,224/7,184원

    흑마늘 오리구이(팩(한마리)/오리고기 : 국내산) 18,980원

    2개이상 50% 프레지덩 과일/크림치즈 3종(각 125g×4입)  2개 이상 구매시 1개당 각7,990원 ※1개 구매시 각 15,980원
    ※교차구매 가능
    ※갈릭차이브/멜론망고/프룻너츠
    ※재고소진시 행사 종료

    롯데카드 신한카드 2개이상 50% 청정원 파스타소스 5종(각 600g/350g) 2개 이상 구매시 1개당 각2,990/3,490원 ※1개 구매시 각 5,980/6,980원
    ※ 로제, 아라비아따, 토마토/머쉬룸 투움바, 까르보나라

    디벨라 파스타면 5종(상품별 규격상이) ※스파게티니/스파게티/링귀네/푸실리/펜네 최대 40% 할인

    롯데카드 신한카드 4,000원할인 트리코 고프레 프렌치 쿠키 크리스마스 기획팩(450g) 10,980원
    ※시즌한정물량으로 점별 재고수량 상이

    롯데카드 신한카드 2,800원할인 남양 프렌치카페 믹스(10.9g×300입) 26,980원

    롯데카드 신한카드 2,000원할인 상쾌환 12입(3g×12입) 13,980원

    창고형 매장도 놀란 가격! 맥스 시그니처 페스타 롯데마트GO 앱 구매시 L.POINT 10배적립
    스위트마운틴 바나나 (1kg 내외/원산지 별도표기) 2,780원
    구운 아몬드(1kg/봉/미국산) 11,980원
    호두 (1kg/봉/미국산) 8,980원
    요리하다 양념소불고기(2kg/냉장/원산지 별도표기) 29,600원
    캐나다산 돼지 삼겹살/목심(각100g/냉장/국내산) 각1,480원
    미국산 초이스 살치살/본갈비살 리테일팩(각 100g/냉장/미국산) 4,280/4,180원
    L.POINT 25%할인 항공직송 노르웨이 생연어(100g/냉장/노르웨이산) 2,985원
    하림 더 건강한 두마리 옛날치킨(팩(두마리)/닭고기 : 국내산) 17,980원
    MAXX 1A 신선한 우유(2.3L) 4,680원
    1,000원할인 MAXX 국내산 쌀누룽지(2kg) 22,980원
    200원할인 MAXX 미네랄워터 ECO(500ml×20입) 2,980원
    MAXX 미네랄워터 ECO(2L×6입) 1,780원
    MAXX 떡갈비 (1,200g) 12,980원
    2,000원할인 맥스 초극세모 칫솔(20개) 9,980원
    탑스텝 3in1 캡슐세제(100개입) 19,980원
    MAXX 프리미엄 화장지(40m×30롤) 19,980원
    
    단, 4일간 이 가격 (기간:12월 12일 목요일부터 12월 15일 일요일까지)
    L.POINT 7,000원할인 생칵테일새우살 21-25(908g/팩/냉동/베트남산) 16,980원 ※이미지 컷입니다
    L.POINT 20%할인 호주산 안심 스테이크용(100g/냉장/호주산) 3,984원
    2개이상 50% 벨큐브 플레인(125g) 2개 이상 구매시 1개당 각4,990원 ※1개 구매시 9,980원
    2,500원할인 롯데 몽쉘 오리지널(816g) 6,980원
    4,000원할인 테크 베이킹+구연산 액체세제(4.8L) 11,980원

    롯데/신한 제휴카드 결제 고객 대상 최대 50% 놀라운 혜택!
    ▶ 해당카드 : 롯데마트&MAXX카드, LOCA for 롯데마트카드, 롯데마트맥스 신한카드
    딸기 전품목20%
    냉동 대패 삼겹살 전품목 30%
    냉동 피자 전품목 2개 이상 구매시 50% ※기간 : 12/19(목)~12/23(월) ※교차구매 가능
    봉지라면 전품목 30% ※기간 : 12/13(금)~12/15(일) ※1인 1일 1회 총 10개 구매 가능

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    실속있는 엔비사과(2kg/봉/국산) 10,980원
    동백향(1kg/팩/국산) 10,980원
    감숙왕 파인애플 (2kg 내외/통/필리핀산) 4,980원
    감자/꿀고구마(각 1.5kg/봉/국산) 3,980/5,980원
    브로콜리(2입/봉/국산)/베이비브로콜리(200g/봉/국산) 3,980/2,680원
    팽이버섯(720g/봉/국산)/깐마늘(500g/봉/국산) 2,180/4,380원
    친환경 콩나물(500g/봉/국산)/어깨동무 알찬콩두부(320g×2/원산지 별도표기) 각1,480원
    시즌컬러 채소모둠(500g/팩/국산) 4,680원
    L.POINT 30%할인 풀무원 드레싱 5종(각 495~550g/원산지 별도표기) 각4,536~4,746원
    1,000원할인 깐 메추리알(1kg/팩/국산) 6,280원
    불릴필요없는 칼집현미/올곧은 경기미(4.5kg/10kg, 국산) 11,480/29,980원
    L.POINT 최대 5,000원할인 황태채(400g/원산지 별도표기)/볶음용 멸치(500g/멸치 : 국산) 11,980/9,980원
    L.POINT 2,000원할인 껍질째 구운캐슈넛(400g/원산지 별도표기) 6,980원
    2개이상 20% HBAF 시즈닝아몬드 전품목(허니버터 아몬드 120g 外) 2개 이상 구매시 1개당 각2,384원 ※1개 구매시 각2,980원
    롯데카드 신한카드 20% 남해안 봉지굴(350g/봉/냉장/국산) 9,984원
    남해안 생굴(100g/냉장/국산) 2,680원
    L.POINT 각 2,000원할인 성형가리비살/자숙바지락살(각 800g/냉동/팩/중국산) 14,980/7,980원
    L.POINT 각 2,000원할인 대천곱창돌김(5g×30봉/원산지 별도표기) 9,980원
    L.POINT 각 2,000원할인 BBQ오징어/쥐치포(300g/400g, 원산지 별도표기) 각9,980원
    L.POINT 15%할인 호주산 곡물비육 살치살(100g/냉장/호주산) 4,233원
    2개이상 6,000원할인 미국산 냉장 우삼겹(1kg/냉장/미국산) 2개 이상 구매시 1개당 각21,980원 ※1개 구매시 27,980원
    L.POINT 40%할인 산더미 파채불고기(1kg/냉장/원산지 별도표기) 14,880원
    L.POINT 3,000원할인 정다운 허브훈제오리(600g/냉장/원산지 별도표기) 11,980원
    제주 흑돼지로 만든 편육(팩/돼지고기 : 국내산) 13,980원
    1,000원할인 멜론 크림 카스텔라(4입/원산지 별도표기) 6,900원 ※ 롯데마트맥스 금천점, 영등포점, 송천점, 창원중앙점에 한함
    4,000원할인 트리플치즈 18인치 피자+파스타(원산지 별도표기) 18,800원 ※ 롯데마트맥스 목포점 제외 ※기간 : 24/12/12(목)~25/1/1(수)
    2개이상 50% 티포 어쏘티드 미니 롤케이크(20g×30입) 2개 이상 구매시 1개당 각4,990원 ※1개 구매시 9,980원
    4,000원할인 목우촌 주부9단 프랑크소시지(1,000g) 8,980원
    3,000원할인 고래사 전통꼬치어묵(840g) 8,980원
    3,000원할인 해태 고향만두(1,350g) 8,980원
    최대 4,000원할인 씨제이 스팸 닭가슴살(200g×6입)/동원 리챔(340g×6입) 19,980/19,780원
    롯데카드 신한카드 50%할인 나뚜루 파인트 멀티(474ml×3) 해당카드 2개 이상 구매시 1개당 각13,490원 ※1개 구매시 26,980원
    2,000원할인 링티제로 레몬라임맛 (1L×6입) 8,980원 
    6,600원할인 청정원 씨간장숙성 양조간장(840ml×3입) 9,980원
    1,500원할인 오리온 감자패밀리 16팩(480g) 9,480원
    롯데카드 신한카드 2,000원할인 매일유업 피크닉 사과(200ml×24입) 6,780원
    L.POINT 2,000원할인 해태 홈런볼 초코(46g×9입) 7,980원
    1,200원할인 맥심 TOP 마스터라떼 기획(300ml×6입) 6,480원
    2,600원할인 매일 페레로 로쉐 T30(375g) 14,980원
    1+1 다정헌 유기농 레몬즙(20G×14포)/레몬생강즙(15G×14포) 각15,900원 ※교차구매가능
    1+1 더바이오마 폴리코사놀 22(500mg×60정) 47,000원
    L.POINT 5,900원할인 19크라임스 쉬라즈/까베르네소비뇽(각 750ml) 각19,900원
    북극곰의 눈물 준마이(도자기잔세트) 24,900원

    뷰티플렉스 롯데/신한카드 생필품 전품목 3만원이상 구매시 10%할인
    최대 150,000원할인 WMF 인스피레이션 4종세트/휘슬러비세오 5종세트 99,800/218,000원 ※조기품절될 수 있습니다
    크리스탈렉스 쯔비벨 와인잔 세트(각 4P, 안젤라/보르도) 각17,260원
    1,000원할인 글라스멀티쿠커(대) 6,980원
    각 20,000원할인 엘리제 밍크 극세사이불 S/Q 29,800/39,800원
    2개이상 50%할인 크리넥스 수앤수 코튼클린 물티슈(72매×6입) 2개 이상 구매시 1개당 각6,390원 ※1개 구매시 12,780원
    최대 5,000원할인 에너자이저 알카라인 건전지(30입)/에너자이저 맥스 건전지 기획(28입) 13,040/14,480원
    각 4,000원할인 액츠 고농축 퍼펙트(4L)/고농축 피죤 옐로우미모사(4.05L) 8,980/9,980원
    2,000원할인 아이보리 비누(113g×10입) 9,980원
    각 5,000원할인 기모 트레이닝/베이직 이지/방풍 기모 팬츠 각14,980원
    반려동물 식품 신상품 츄잇 한우껌 멀티팩(50g×5입, 산양유 3개/플레인 2개) 16,980원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
];

/* 703,753 빅마켓(~6/26) */
const vicNewData = [
  `
  <h1>LOTTE VIC Market</h1>
  <div>
    롯데/신한카드 롯데마트&MAXX 전용카드 회원만!
    미국산 초이스 소고기/즉석밥 20% 추가할인

    ▶ 기간 : 6/13(목) ~ 6/26(수)
    ▶ 해당카드 : 롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드
    ※최종가/엘포인트 할인가에서 추가 할인 적용
    ※MAXX/VIC 매장 한정, 롯데마트몰/SCAN&GO 제외                                      
    ※로카페이, LPAY 및 해당 실물카드 결제 시 적용
    (삼성페이 등 간편결제는 미적용)
    ※할인스티커 부착 상품과 중복적용 불가 ※재고소진시 행사종료
    ※특약(입점)행사/선물세트 제외 ※자세한 사항은 가까운 직원에게 문의

    미국산 초이스 소고기 20% 추가 할인

    롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드 적용가
    미국산 초이스 부채살/살치살 (각 100g/냉장/미국산) 1,584/3,584원
    ※해당카드 미적용시 1,980/4,480원

    롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드 적용가
    즉석밥 전품목(상품별 상이) 20% 추가 할인

    딱!! 100일간 이 가격 ※기간 : 6/10(월)~9/17(화)

    동물복지 백색 유정란 (대 30입/1.56kg/국내산) 1,500원 할인 7,980원
    건망고 (400g/베트남산) 2,000원 할인 7,980원

    L.POINT 2,000원 할인
    롱스틱 직화육포 (90g×2입/원산지 별도표기) 6,980원

  </div>
  `,
  `
  <div>
    스위트 마운틴 바나나 (1.2kg 내외/봉/원산지 별도표기) 2,780원

    1등급 국내산 돼지 앞다리(100g/냉장/국내산) 980원

    왕새우튀김 (팩/원산지 별도표기) 8,980원

    MAXX 1A 저지방우유(2.3L) 400원 할인 4,980원

    롯데 설레임 밀크쉐이크 (160ml×12입) 3,000원 할인 8,980원

    뮤엘올리바 카놀라유(2L) 1,000원 할인 8,980원

    크라운 초코하임(142g×5입) 2,000원 할인 7,480원

    토레타/파워에이드 마운틴 블라스트 (각 900ml×12입) 3,500원 할인 각11,480원

    L.POINT 4,000원 할인
    비비랩 가르시니아 플러스 (1,000mg×112정) 15,980원

    에고라운드 두피쿨링 민트 샴푸(1,000ml) 2,000원 할인 11,980원

    MAXX 테이크아웃 아이스컵 80P 세트 2,000원 할인 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    슬기로운 여름생활!

    롯데카드/신한카드 2,000원 할인
    AI선별 수박(6~10kg 미만/통/국산) 14,980~19,980원

    천도 복숭아(2kg/팩/국산) 12,980원

    롯데카드/신한카드 25% 할인
    1등급 국내산 돼지 삼겹살/목심 (각 100g/냉장/국내산) 각1,860원

    L.POINT 7,000원 할인 
    1등급 한우 차돌박이/갈비살 (700g/600g, 냉장, 국내산) 47,800/72,800원

    롯데카드/신한카드 50% 할인
    러시아 활 킹크랩 (100g/냉장/러시아산) 5,990원

    롯데카드/신한카드 2,000원 할인
    파스퇴르 쾌변 요구르트 (150ml×8입) 8,780원

  </div>
  `,
  `
  <div>
    1,000원 할인
    스페쇼우 젤리스트로우 플러스 (1,254g) 9,980원

    2팩 이상 20% 할인
    제주 삼다수 그린 (2L×6입/500ml×20입) 2팩 이상 구매시 1팩당 5,184/7,680원
    ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    풀무원 평양 물냉면(2,538g)/칠갑 얼음찬 메밀소바(804g) 2,000원 할인 8,480/5,480원

    롯데 스낵 버라이어티팩(894g) 2,000원 할인 11,980원

    기능성 반팔티셔츠/7부팬츠, 5부팬츠 3,000원 할인 12,980/14,980원

    MAXX 스탠다드 단목 양말 (6족, 남성/여성) 1,000원 할인 각7,980원
    ※일부 색상 조기 소진 가능
    ※기간 : 6/10(월)~9/17(화)

    주방 필수템!
    키친유 스텐 밀폐용기 2P(2.0L/3.6L) 최대 3,000원 할인 17,980/24,980원

    에찌모토 식과도 가위세트(3P) 2,000원 할인 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)
    6월 여름맞이 특별전 해양수산부와 함께하는 수산대전20%

    ※기간 : 6/13(목) ~ 6/19(수) ※해당상품에 한함
    ※산지시황에 따라 조기품절될 수 있습니다 ※이미지 연출컷입니다
    ※L.POINT 회원 기간 중 1인 최대 1만원 할인 ※기간 內 주차별 1인/1만원 한도

    수산대전20%
    프리미엄 광어회 (500g 내외/팩/광어 : 국산) 29,984원

    수산대전20%
    ASC인증 완도 활전복 (대/14미/10마리) 19,840원

    수산대전20%
    원양산 오징어 (마리/해동) 2,384원

    수산대전20%
    영광 법성포 굴비 (1.4kg/팩/냉동/국산) 27,984원

    수산대전20%
    국산 고등어필렛 (1kg/팩/냉동) 8,784원

    수산대전20%
    황태채(400g/팩/러시아산)/볶음용, 조림용멸치(각 500g/팩/멸치:국산) 각9,984원

    제스프리 점보 골드키위(8~12입/팩/뉴질랜드산) 14,980원

    스윗탱고 토마토 (1.5kg/국산) 10,480원

    시즌컬러 채소모둠 (500g/팩/국내산) 4,680원

    양파(3kg/국내산) 5,380원
    ※기간 : 6/13(목)~6/19(수)

    새송이 버섯 (600g/팩/국내산) 2,780원

    양상추 (1봉/국내산) 1,980원

  </div>
  `,
  `
  <div>
    가바현미 7곡 (4.2kg/국내산) 2,000원 할인 14,980원

    귀한쌀(20kg/국내산) 7,000원 할인 49,800원

    하루한줌 고메넛츠 (30g×25봉/원산지 별도표기) 2,000원 할인 15,980원

    호두(1kg/미국산) 1,500원 할인 9,480원

    손질민물장어 (500g/팩/냉장/국내산) 34,980원

    데친 문어 (100g/냉장/베네수엘라산) 2,480원

    L.POINT 5,000원 할인
    생칵테일새우살 51-70 (908g/팩/냉동/베트남산) 14,980원

    L.POINT 3,000원 할인
    한판 모둠 소시지 (1.1kg/냉장/원산지 별도표기) 8,980원

    L.POINT 2,000원 할인
    요리하다 다리살로만 훈제오리(450g/냉장/원산지 별도표기) 8,980원

    L.POINT 각2,000원 할인
    하림 IFF 6개 품목 ※냉동/국내산 (상품별 상이/품목 2kg) 

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    L.POINT 15% 할인
    참한 새우초밥 (24P/팩/냉장/원산지 별도표기) 19,533원

    오징어볼/쉬림프칩 (240g/160g/원산지 별도표기) 2,000원 할인 각7,980원

    L.POINT 6,000원 할인
    100g당 1,290원
    양념 소불고기 (2kg/팩/냉장/원산지 별도표기) 25,800원

    서리태 콩국물/국산콩 콩국물(각 1L/국내산) 5,880/4,980원

    경양식 함박스테이크(9입/팩/원산지 별도표기) 13,980원

    일품 월남쌈 세트 (팩/원산지 별도표기) 13,980원

    가성비 식사/사이드 세트, 감탄박스 (1팩/원산지 별도표기) 8,900원

    오리지널 핫도그 (5입/1팩/원산지 별도표기) 2,000원 할인 8,900원

  </div>
  `,
  `
  <div>
    최대3,000원 할인 
    동원 고추참치(100g×10입)/동원 리챔 더블라이트(300g×6입) 15,480/21,980원
    
    2,740/4,000원 할인
    티아시아 골든 버터 난(200g)/쉐푸드 생크림버터 치킨카레,토마토 비프카레(각 170g×4입) 2,740/5,980원

    최대 40% 할인
    디벨라 파스타면 5종(상품별 규격 상이) 
    ※ 스파게티니, 스파게티, 링귀네, 푸실리, 펜네

    롯데카드/신한카드 4,000원 할인
    매일 상하 체다 슬라이스 치즈 (1.36kg/80매) 13,480원

    빙그레 딸기맛 우유 (240ml×8입) 1,000원 할인 7,480원

    5개 구매시 5,980원
    삼립 인기빵 10종(상품별 규격 상이) 5개 이상 구매시 1개당 각1,196원 
    ※주종발효 단팥크림빵, 주종발효 카스타드 소보루 外
    ※1개 구매시 각 1,480원

    트리코 고프레 프렌치 쿠키(450g) 14,980원

    트윅스 미니스 (1.02kg) 5,000원 할인 16,480원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)
    1+1 & 50% 할인

    1+1
    CJ 삼호 오뎅한그릇(360g)/구이한판 스모크 그릴(60g×4) 3,680/8,480원

    1+1
    사세 쏘스치킨 윙봉/순살 단짠갈릭맛(300g/375g) 각8,900원

    50%
    사조대림 국물떡볶이 (373g) 2,340원

    1+1
    풀무원 납작지짐만두(700g) 8,980원

    1+1
    동원 양반 수라 왕갈비탕/도가니설렁탕/보양추어탕/통다리삼계탕(각 460g) 각8,980원

    1+1
    담터 콤부차 샤인머스캣 포켓몬 기획(5g×30입) 15,980원

    1+1
    립톤 아이스티 피치(14g×120입) 37,980원
    ※ 점별 재고 한정

    1+1
    락티브 어린이 면역엔 멀티비타민 젤리(4g×60EA) 24,980원

  </div>
  `,
  `
  <div>
    크러시 쿨러백 (355ml×18입) 26,800원

    볼쓰 멜론×파쏘아 하이볼 패키지 (700ml×2ea+리유저블컵 3ea) 39,800원

    트레비 탄산수 3종 (각 300ml×20입) 각7,980원
    ※ 레몬/라임/플레인

    남양 프렌치카페 믹스 (10.9g×300입) 2,000원 할인 27,780원

    롯데카드/신한카드 5,000원 할인
    베지밀 검은콩과 검은참깨 두유 (190ml×24입) 11,980원

    멘토스 뉴레인보우 츄잉캔디 (미니/1kg) 1,800원 할인 9,480원

    뉴트리원 프리미엄 프리바이오틱스(5g×50포) 4,000원 할인 12,980원

    내추럴박스 카무트 브랜드 밀효소 (3g×15포×2입) L.POINT 2,000원 할인 19,980원

    롯데카드/신한카드 4,000원 할인
    바이탈슬림 리얼컷 아이돌물 녹차꿀맛 슬림(20ml×14포) 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    롯데카드/신한카드 3,000원 추가할인
    잘풀리는집 명품 로션티슈 에센셜(30m×30롤) 16,980원

    롯데카드/신한카드 2,000원 추가할인
    탑스텝 3in1 캡슐 세탁세제(100입) 15,980원

    옹달샘 습기제거제(18입) 2,000원 할인 12,980원

    롯데카드/신한카드 3,000원 할인
    시린메드 검케어(125g×5) 18,980원

    퍼펙트 건조대(대) 5,000원 할인 19,980원

    3M 이지 극세사 막대걸레 세트 (막대걸레 1P+극세사패드4매 구성) 6,000원 할인 18,980원

    제크롤 후면식 선풍기 (JK BF1400) 4,000원 할인 31,800원

    모리츠 메탈 20인치 선풍기 (MO 200MF) 4,000원 할인 55,800원

  </div>
  `,
  `
  <div>
    최대 70,000원 할인
    라코스테 클래식 카라/그래픽 반소매 티셔츠/블랙야크 웨크웨어 반소매 카라/라운드 티셔츠 24,980~59,800원
    
    나이키 브라질리아 더플백 (S, 41L) 3,000원 할인 32,800원

    NEW Arrival

    두툼한 손질 갈치 (400g/냉동/팩/모로코산) 6,880원

    MSC 짜먹는 냉동 명란 (30g×10팩/냉동/원산지 별도표기) 12,800원

    간편 고등어구이 (90g×4봉/냉동/원산지 별도표기) 13,800원

    뚜껑과 빨때를 제거해 환경까지 생각한 마이카페라떼 그린!
    매일카페라떼 마일드 컴포리드 (200ml×8입) 9,480원

    뉴트리 에버콜라겐 고백 (750mg×84정) 32,980원

    헤어플러스 아쿠아 워터 에센스 (200ml×2) 19,980원

    에이센트 3X 고농축 퍼퓸드 섬유유연제(4L) 15,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
];

/* 758,759 maxx(~12/11) */
const maxxNewData = [
  `
  <h1>LOTTE Mart maxx</h1>
  <div>
    ·전단적용기간 : 2024. 11. 28(목) ~ 2024. 12. 11(수)
    ·롯데마트맥스 : 금천, 목포, 상무, 송천, 영등포, 창원중앙점

    24년 maxx 연말결산 BEST

    축산 BEST

    L.POINT 20% 할인
    호주산 곡물비육 부채살(100g/냉장/호주산) 2,224원

    L.POINT 25% 할인
    호주산 곡물비육 척아이롤(100g/냉장/호주산) 1,687원

    가공식품 BEST
    
    롯데카드/신한카드 2개 이상 50%
    동원 그릴리 초리조 부어스트(840g) 해당카드 2개 이상 구매시 1개당 각6,240원
    ※1개 구매시 12,480원

    수산 BEST

    롯데카드/신한카드 50% 할인
    활대게(100g/냉장/러시아산) 4,490원
    ※산지 시황에 따라 조기 품절될 수 있습니다

    생활 BEST

    3,000원 할인 -> 롯데카드/신한카드 3,000원 추가할인
    헬로 3겹 천연펄프 화장지(35m×30롤) 11,980원

    롯데카드/신한카드 3,000원 할인
    타이벡 감귤(2.5kg/박스/국산) 13,980원

    롯데카드/신한카드 3,000원 할인
    설향 딸기(500g/팩/국산) 13,980원

    1등급 왕란(18입/국산) 4,480원
    ※기간 : 11/28(목)~12/4(수)

    2,000원 할인
    1등급 구운란(25입/1kg/국산) 7,980원

    귀한쌀(20kg/국산) 49,980원

    L.POINT 10% 할인
    스페셜 초밥(28P/팩/원산지 별도표기) 17,982원

    캐나다 돼지 삼겹살/목심(각 100g/냉장/캐나다산) 각1,280원
    ※기간 : 11/28(목)~12/4(수)

    L.POINT 25% 할인
    핫 스파이시 윙봉 구이(팩/원산지 별도표기) 11,985원

    롯데카드/신한카드 5,000원 할인
    서울우유 체다 슬라이스 치즈(1.8kg) 16,480원

    2,000원 할인
    뮤엘올리바 엑스트라버진 올리브유/뮤엘올리바 퓨어 올리브유(각 2L) 35,980/30,280원

    2,000원 할인
    델몬트 팜앤홈(제주감귤 1.2L×4입) 4,980원

    롯데카드/신한카드 4,000원 할인
    MAXX 과테말라 SHB/에티오피아 예가체프 G2(각 1kg) 각16,480원

    3,500원 할인
    구워만든 곡물 그대로 21(1,100g) 9,980원

    3,800원 할인
    사조 살코기 마일드 참치(150g×6입+150g×2입) 11,980원

    2,000원 할인
    고려은단 비타민C 골드 플러스(1,120mg×120정) 19,580원

    3,000원 할인
    백선생 왕타 통칫솔(12입) 11,980원

    2,000원 할인
    파워브라이트 액체세제/아로마뷰 섬유유연제(5L/4L) 각8,980원

    L.POINT 1,400원 할인
    함마톤 스텐 편수 냄비(18cm) 8,580원

    각 2,000원 할인
    뉴발란스 로고 파일 장목 양말(각 4매입, 여성/남성) 각12,980원

    단, 4일간 이 가격
    기간 : 11/28(목) ~ 12/1(일)

    L.POINT 40% 할인
    호주산 냉장 찜갈비(100g/냉장/호주산) 1,968원

    L.POINT 30% 할인
    1등급 한우 국거리/불고기(각 100g/냉장/국내산 한우) 각2,646원

    2개 이상 50%
    요즘 플레인 그릭 요거트(450g) 2개 이상 구매시 1개당 각7,490원 ※1개 구매시 14,980원

    종근당건강 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ★ "를 확인하세요
    ※결제시 계산대에서 수령

    롯데카드/신한카드 1,000원 할인

    아임비타 멀티비타민 이뮨 플러스(23g×7ea) 9,980원

    겨울맞이 추천 먹거리!

    문어 해물탕(1.3kg 내외/팩/냉장/원산지 별도표기) 17,980원

    L.POINT 7,000원 할인
    한우 곰탕 모둠팩(4.5kg/냉동/국내산 한우) 32,800원

    L.POINT 3,000원 할인
    미국산 우삼겹구이/척아이롤 샤브샤브(각 1kg/냉동/미국산) 16,980/21,980원
    ※소스가 동봉되어 있습니다

    2,000원 할인
    사응원 옛날 꿀호떡(1.6kg) 11,980원

    롯데카드/신한카드 2,000원 할인
    오리온 참붕어빵(29g×18입)/호떡품은 참붕어빵(31g×18입) 각5,980원

    12월 한달내내 이 가격!
    기간 : 12/1(일) ~ 12/31(화)

    3,500원 할인
    캐나다산 카무트(2kg) 18,980원

    호두(1kg/미국산) 8,980원

    1,500원 할인
    대만 누가 비스켓(14g×32입) 8,980원

    3,500원 할인
    Basso 해바라기씨 오일(2L) 6,480원

    10,000원 할인
    블랙야크 워크웨어 기모 티셔츠/워크웨어 보아플리스 점퍼(95/100/105) 각39,800원

    롯데/신한 제휴카드 결제 고객 대상 놀라운 혜택을 만나보세요
    ▶ 해당카드 : 롯데마트&MAXX카드,LOCA for 롯데마트카드, 롯데마트맥스 신한카드

    미국산 소고기 전품목 20%

    토마토/미니토마토 전품목 20%
    ※스테비아 토마토 제외
    ※점별 운영품목 상이

    인기 시즌차 9종 30%

    ※정상가란? 2024년 11월 7일(목) ~ 11월 27일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    제스프리 골드키위(8~11입/팩/국산)/샤인머스캣(2kg/박스/국산) 각16,980원

    양파(3kg/국산) 4,580원

    GAP 양송이버섯(350g/팩/국산) 5,280원

    GAP 파프리카(700g/봉/국산)/과일 파프리카(500g/팩/국산) 3,980/8,280원

    친환경 어린잎(90g×2입/국산) 2,780원

    50% 
    풀무원 순두부 찌개 3종(바지락 602g/얼큰 600g/정통 602g) 각2,190원
    ※원산지 별도표기

    L.POINT 10% 할인
    광어회(500g 내외/팩/원산지 별도표기) 35,982원

    데친문어(100g/냉장/칠레산) 2,480원

    L.POINT 각 2,000원 할인
    올리브유 쥐포채/리얼스테이크육포(400g/170g, 원산지 별도표기) 9,980/8,980원

    L.POINT 각 10% 할인
    한우 리테일팩(각 100g/냉장/국내산 2등급 한우)
    ※등심/안심/채끝 품목에 한함

    L.POINT 5,000원 할인 
    호주산 곡물비육치마살(1kg/냉장/호주산) 34,800원
    
    L.POINT 7,000원 할인 
    수원식 양념돼지 왕갈비구이(1.7kg/냉장/원산지별도표기) 22,980원

    L.POINT 각 3,000원 할인 
    즉석포차 제육볶음/소불고기/한입칼집구이(각 400g×4/냉동/원산지 별도표기)

    2개 이상 45%
    노랑오리 마늘치킨 훈제슬라이스(400g/냉장/원산지 별도표기) 2개 이상 구매시 1개당 각4,345원
    ※1개 구매시 7,900원

    각 1,500원 할인
    샐러드 3품목(원산지 별도표기) 7,480~8,480원
    ※쉬림프망고/생파인애플리코타/치킨텐더

    신상품
    궁중 고기잡채(팩/돼지고기 : 국내산) 14,980원

    1,000원 할인
    톡톡 깨먹는 티라미수(3입/1팩/원산지 별도표기) 8,900원
    ※롯데마트맥스 금천점, 영등포점, 송천점에 한함

    990원 할인
    프렌치 퓨어버터 크로아상(1입/원산지 별도표기) 1,000원
    ※롯데마트맥스 상무점, 목포점에 한함

    롯데카드/신한카드 4,000원 할인
    복음자리 딸기잼(1kg) 9,980원

    2,500원 할인
    삼립 폭신폭신 플레인 치즈케익(70g×9입) 5,980원

    2개 이상 50%
    시아와세도 밀크 케이크 外 3종(상품별 상이) 2개 이상 구매시 1개당 각2,990원
    ※1개 구매시 각5,980원
    ※4종 교차구매 가능
    ※점별 재고상이 ※재고소진 시 행사 종료

    5,000원 할인
    마니커 리얼치킨 통살카라아게(1,000g) 9,980원
    
    5,000원 할인
    쉐푸드 등심 통돈까스(600g) 9,980원

    2개 이상 50%
    풀무원 납작지짐만두(700g) 2개 이상 구매시 1개당 각4,740원 ※1개 구매시 9,480원

    최대 2,000원 할인
    청정원 태양초 찰고추장(현미)/청정원 굴소스(1.5kg×2입/500g×2입) 14,980/10,880원

    2개 이상 50%
    청정원 맛선생 국물내기 한알(멸치디포리 160g) 2개 이상 구매시 1개당 각7,490원
    ※1개 구매시 14,980원

    2,000원 할인
    CJ 스팸 라이트(200g×6입) 19,980원

    2개 이상 10%
    농심 신라면 툼바(4개입) 2개 이상 구매시 1개당 각4,122원 ※1개 구매시 4,580원

    각 2,000원 할인
    델몬트 오렌지드링크/망고드링크(각 190ml×32입) 각8,980원

    2,000원 할인
    코카콜라(215ml×30입) 18,480원

    1+1 
    델리 코코넛 쌀크래커(150g×5입) 9,980원
    ※2개 결제시 1개 증정

    2,000원 할인
    대산 생강젤리(1.2kg) 9,980원

    1+1
    오늘좋은 눈건강 루테인(400mg×180캡슐) 23,980원
    ※점별 재고 상이

    롯데카드/신한카드 3,000원 할인
    뉴트리원 프리미엄 프로폴리스 (500mg×300캡슐) 19,980원

    셀렉스 해당 품목 2개 이상 구매 시 5천원 롯데상품권 증정! 
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ♥ "를 확인하세요 ※결제시 계산대에서 수령

    5,000원 할인
    셀렉스 프로틴 음료 오리지널/로우슈거 2종(190ml×16입) 19,980원

    L.POINT 5,000원 할인
    탈리스커 포트리(700ml) 86,800원

    크리스마스 케이크 사전예약!
    ※사전예약기간 : 12/5(목)~12/18(수)
    ※상품수령기간 : 12/21(토)~12/25(수)

    10% 할인 -> 롯데카드/신한카드 3,000원 추가할인
    딸기풍년 케익/초코딸기 케익(각 2호/원산지 별도표기) 24,000원
    ※롯데마트맥스 목포, 상무점 제외

    5,000원 할인
    패스트레인 고층주차장 세트 34,800원
    
    5,000원 할인
    유앤미 캐슬 플레이 하우스 24,980원

    각 3,000원 할인
    에너자이저 맥스 건전지(각 14입, AA/AAA) 각11,980원
    
    각 3,000원 할인
    메리 사각/직사각 체크 쿠션(43×43cm/50×30cm) 각6,980원

    각10,000원 할인
    무자계 TP 전기요(싱글/더블) 59,800/69,800원

    10,000원 할인
    전기 찜질매트(브라운) 35,800원

    각 1,000원 할인
    프리미엄 화롯불 대형핫팩/파스형(140g×30입/40g×30입) 12,980/8,980원

    20,000원 할인
    베어파우 양털부츠 알리샤 59,800원

    각 5,000원 할인
    베이직엘르 여성 면스판(7매)/모달 스판 팬티(5매) 14,980/12,980원
    
    각 3,000원 할인
    베이직엘르 남성/여성 폴리기모 내의세트, BLACK 각10,980원

    최대 7,000원 할인
    보덤 비스트로 머그컵 4P(300ml)/보덤 파비나 유리컵 2P(350ml) 27,800/13,580원

    3,000원 할인
    도루코 PACE 7 프레쉬 대용량 기획세트(기1+날13) 23,980원

    1,000원 할인
    바세린 24h 더블바디로션 모이스춰(1L) 8,980원

    70,000원 할인
    SOUP 여성 프리미엄 폭스 후드 덕 다운 99,800원

    각 3,000원 할인
    브리더랩 져키&스틱(각 1kg, 오리/치킨) 각11,980원

    3,000원 할인
    다우니 에이프릴 프레쉬(3.83L) 18,980원
    
    ※정상가란? 2024년 11월 7일(목) ~ 11월 27일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
 
  </div> 
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getVicData = () => {
  const dataArray = [];
  vicData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `빅마켓 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getMaxxData = () => {
  const dataArray = [];
  maxxData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `maxx 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getVicNewData = () => {
  const dataArray = [];
  vicNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `빅마켓 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getMaxxNewData = () => {
  const dataArray = [];
  maxxNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `maxx 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L705: {
    title: "스마트전단지-빅마켓705",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202405_5/07/",
    contents: getVicData(),
  },
  L755: {
    title: "스마트전단지-빅마켓755",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202405_5/07/",
    contents: getVicData(),
  },
  L738: {
    title: "스마트전단지-맥스738",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202412_3/07",
    contents: getMaxxData(),
  },
  L703: {
    title: "스마트전단지-빅마켓703",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202406_3/07/",
    contents: getVicNewData(),
  },
  L735: {
    title: "스마트전단지-빅마켓735",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202406_3/07/",
    contents: getVicNewData(),
  },
  L758: {
    title: "스마트전단지-맥스758",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202411_5/07",
    contents: getMaxxNewData(),
  },
  L759: {
    title: "스마트전단지-맥스759",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202411_5/07",
    contents: getMaxxNewData(),
  },
};

export default data;
