import React from "react";
import { StyledFixedImage } from "./styles";

function FixedImage() {
  return (
    <StyledFixedImage>
      <img
        src={`${process.env.REACT_APP_BASE_S3_IMAGE}/leaflet/common/leaflet_common_img.jpg`}
        alt="롯데마트X롯데슈퍼 금주의행사 *점별 운영 상품 및 가격이 상이할 수 있습니다."
      />
    </StyledFixedImage>
  );
}

export default FixedImage;
