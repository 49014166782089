import { LEAFLET_URL } from "../constants";

/* 601 생활공감 컨텐츠 */
const L601data = [
  "<h1>vol.09 롯데마트가 전하는 생활 속 공감 이야기 생활공감 행복의 온도</h1>",
  "<h1>일상의 행복을 찾아가는 여정 행복의 온도</h1>",
  "<h1>목차</h1>",
  "<h1>행복의 온도</h1>",
  "<h1>내입이 가장 행복한 온도를 찾아서</h1>",
  "<h1>뜨겁거나 혹은 차갑거나</h1>",
  "<h1>재료마다 요리마다 맛있는 온도는 따로있다</h1>",
  "<h1>재료마다 음식마다 사람들이 맛있다고 생각하는 온도는 따로 있다</h1>",
  "<h1>온도가 빚어내는 맛의 비밀</h1>",
  "<h1>롯데마트 FIC센터 강레오셰프 인터뷰</h1>",
  "<h1>롯데마트 FIC센터 강레오셰프 인터뷰</h1>",
  "<h1>롯데마트 FIC센터 강레오셰프 인터뷰</h1>",
  "<h1>맛있는온도 + 전골 95도, 온가족이 둘러 앉아 끓이는 보글보글 행복한 전골</h1>",
  "<h1>전골사진</h1>",
  "<h1>손이 가요 손이가, 보글보글 맛있는 꽃게탕</h1>",
  "<h1>기대 이상의 간편식이 에어프라이어의 맛있는 온도를 만났을때 쫄깃한 찹쌀 탕수육</h1>",
  "<h1>참쌀 모짜렐라 치즈볼</h1>",
  "<h1>칠리새우</h1>",
  "<h1>후라이드 치킨봉</h1>",
  "<h1>풍미가 좋은 프리미엄 오일로 요리를 더 맛있게</h1>",
  "<div>풍미가 좋은 프리미엄 오일로 요리를 더 맛있게</div>",
  "<div>트러플 오일로 만든 페퍼론치노 파스타</div>",
  "<div>올리브 오일로 만든 참외 샐러드</div>",
  "<div>산지에서 식탁으로 달고 시원한 제철 참외를</div>",
  "<div>대한민국 산지뚝심</div>",
  "<div>골이 깊고 색이 진할수록 싱싱하고 맛있는 참외랍니다.</div>",
  "<div>인터뷰</div>",
  "<div>삶의 온기를 마음의 온기를. 우리모두 해옥해지는 온도를 찾아서</div>",
  "<div>나누고 나누고</div>",
  "<div>사람들이 가장 기분좋게 느끼는 일상의 온도</div>",
  "<div>행복의 온도는?</div>",
  "<div>수의사 김명철이 말하는 반려동물과 함께 따뜻한 삶의 온기 느끼기</div>",
  "<div>김명철 수의사</div>",
  "<div>김명철 수의사 인터뷰</div>",
  "<div>김명철 수의사 인터뷰. 반려동물을 책임진다는 것은 아이를 낳고 성인이 될때까지 돌봐야 하는 것과 같아요.</div>",
  "<div>슬기로운 반려생활 반려동물의 온다 check, check!</div>",
  "<div>반려동물의 체온, 반려동물이 좋아하는 온도, 반려동물 체온계, 반려동물이 체온을 유지하는 방법</div>",
  "<div>내손으로 만드는 반려견묘 수제간식 초간단 요거트 아이스 쿠키</div>",
  "<div>멍냥이가 좋아하는 닭가슴살 소세지</div>",
  "<div>사랑하는 사람들과 함께 행복의 온도를 높이는 미니멀 캠핑</div>",
  "<div>미니멀 캠핑이란? 짐의 물리적 부피를 최대한 줄이고 가볍께 떠나는 캠핑</div>",
  "<div>캠린이를 위한 캠핑 장비를 보여줘!</div>",
  "<div>전문가의 플렉스를 보여줘!</div>",
  "<div>캠핑의 달인 유투버들에게서 배우는 갬핑의 온도 지키기</div>",
  "<div>피크니 캠프 </div>",
  "<div>캠핑장에서 색다른 요리를 당신의 캠핑을 요리하다.</div>",
  "<div>Inter갬핑장에서 맛보는 얼큰한 마라탕면view</div>",
  "<div>이것만은 포기못해, 숯불향 입은 떡갈비</div>",
  "<div>손쉽게 휘리릭 볶아먹는 치즈 스테이크 볶음밥</div>",
  "<div>우리 모두 행복하게 지구의 온도를 지켜요!</div>",
  "<div>지구의 온도가 1도씨 올라간다면</div>",
  "<div>친환경 시대를 선도해온 롯데마트의 발걸음</div>",
  "<div>eco, we go together, lotte mart</div>",
  "<div>집콕하는 당신만을 위한 자유공간</div>",
  "<div>온리프라이스 스파클링 워터 eco 2종 아트테라피를 완성하고 인스타그램 이벤트에 응모하세요.</div>",
  "<div>생활공감</div>",
  "<div>생활공감</div>",
  "<div>롯데푸드</div>",
];

/* 901 룸바이홈 컨텐츠 */
const L901data = [
  "<h1>기 능 주 의 디 자 인 룸바이홈</h1>",
  "<h1>Less, But Better</h1>",
  "<h1>Functionism [펑셔니즘]</h1>",
  "<h1>EARTHY, KINFOLK</h1>",
  "<h1>URBAN, COZY, PURE</h1>",
  "<h1>우드스틸 첼시</h1>",
  "<h1>우드스틸 첼시 선반, 내추럴 갤러리 액자, 우드스틸 첼시 바 테이블/스툴, 우드스틸 첼시 선반행거</h1>",
  "<h1>Earthy look [나무 흙 돌 바람]</h1>",
  "<h1>헤리티지 대용량 모던디퓨저, 샤인 차콜그레이 메탈 벽시계, 센티드가든 디퓨저, 헤리티지 대용량 모던디퓨저, 분갈이가 필요없는 도자기 화분, 플랜트 박스</h1>",
  "<h1>룸바이홈 쇼핑 캐리어. 트렌디 아이템으로 진화된 실용성 높은 카트 장바구니 라인.</h1>",
  "<h1>원터치 폴링웨건 그린, 우드테이블카트, 미니 폴딩박스, 롤링 캐리어, 접이식 핸드캐리어, 특대형 타포린백</h1>",
  "<h1>룸바이홈 홈패브릭. 복고를 즐기는 뉴트로 트렌드를 반영한 깅엄 민트+옐로우 체크 라인.</h1>",
  "<h1>우드스틸 소호 책상, 우드스틸 소호 책상세트, 우드스틸 소호 선반, 내추럴 갤러리 액자, 가구+수납 모듈, 라탄의 텍스쳐를 구현</h1>",
  "<h1>룸바이홈 캠프닉 봄 여름 나들이 필수품 룸바이홈 피크닉&캠핑 아이템.</h1>",
  "<h1>캠핑비치타월 스트라이프/에스닉, 스텐식기, 휴대가 용이한 이불</h1>",
  "<h1>룸바이홈 보온텀블러 오피스와 아웃도어, 수험생 죽통까지 갖춘 룸바이홈 핫앤쿨 라인.</h1>",
  "<h1>룸바이홈 오일로드팬. 바닥 면의 오일로드 패턴이 기름이 한쪽 방향으로 쏠리는 현상을 막아주어 적은 양의 오일로 조리가 가능한 건강팬.</h1>",
  "<h1>룸바이홈 스트롱골드팬:오랜시간 사용이 가능하고 세척이 편리한 주방에 엣지를 더해주는 기능팬.</h1>",
  "<h1>프리미엄 식기건조대 1단,프리미엄 식기건조대 2단 </h1>",
  "<h1>룸바이홈 유로그린 세라믹 인덕션 냄비, 룸바이홈 소창 행주</h1>",
  "<div>체크 주방장갑, 체크 앞치마, 우드 손잡이 도마, 우드 도마</div>",
  "<div>룸바이홈 프리미엄 크리스탈 와인잔. 독일 Stozel(社)의 명품 크리스탈 와인잔으로 볼 아랫부분이 넓어 와인의 향을 향상시키고 스웰링하기 편리한 디자인. 와인을 잘 음미할 수 있도록 네가지 쉐입으로 구성.</div>",
  "<div>와인 액세서리 용품 와인을 맛있게 즐길 수 있는 와인 액세서리 라인.</div>",
  "<div>룸바이홈 킨포크 식기 소박하지만 풍요로운 Kinfolk 라이프스타일을 반영한 라인.</div>",
  "<div>룸바이홈 가온 식기 단아하고 차분한 한 상 차림으로 마음의 여유를 가질수 있는 온기를 담은 라인.</div>",
  "<div>골드라인 식기 시리즈. 뉴본 도자기 소재와 조각패턴의 글라스에 골드림을 둘러 홈파티에 어울리는 라인.</div>",
  "<div>룸바이홈 데일리 식기. 깨끗한 화이트 도자기 소재로 어디에나 잘 어울리는 데일리 라인.</div>",
  "<div>룸바이홈 안티알러지 침구 집먼지 진드기 투과가 방지되는 고밀도의 원단을 사용한 기능성 이불로, 잔사가 적은 장섬유를 꼬아 제직해 먼지 날림이 적어 위생적인 이불.</div>",
  "<div>룸바이홈 안티더스트침구:부드러운 피치스킨 원단과 순면 100%의 원단에 안티더스트 가공을 더해 먼지가 쉽게 달라붙지 않아 쾌적하게 사용 가능한 침구.</div>",
  "<div>룸바이홈 안티더스트 호텔 침구:불순물을 제거해 더욱 부드러우며, 안티더스트 가공을 더해 먼지가 쉽게 달라붙지않는 침구.</div>",
  "<div>룸바이홈 피치스킨 침구:돌돌 말려 이동이 편리하고 양면이 다른 패턴으로 다양한 연출이 가능한 리버시블 침구 & 베개.</div>",
  "<div>룸바이홈 여름 침구 시리즈:접촉 냉감 소재 & 시어커서 소재로 흡한속건 기능이 뛰어나 여름에 사용하기 좋은 침구.</div>",
  "<div>룸바이홈 에어셀 시리즈:계란판 모양의 에어셀 토퍼가 체중을 분산해주고, 접이식 디자인으로 보관이 용이하여 침대 위에 사용해도 되고, 손님 접대용으로도 사용하기 좋은 토퍼.</div>",
  "<div>룸바이홈 스토리지:공간 효율성을 고려한 모던 스토리지 라인.</div>",
  "<div>스틸 틈새 선반, 스틸 사이드 선반, 트롤리 이동식 선반, 스틸 와이어 슬림선반</div>",
  "<div>룸바이홈 세탁용품:좁은 공간 활용이 좋은 빨래 건조대, 열 전도율이 높아 다림질이 빠른 다리미판.눈금이 있어 분무량 조절이 쉬운 분무기.</div>",
  "<div>욕실소품 블랙 시리즈</div>",
  "<div>ROOM X HOME 롯데마트 GO 앱 및 롯데마트몰 룸바이홈 특화관에서 구매 가능합니다.</div>",
];

/* 903 전점 설 사전예약 (~1/15) */
const L903data = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    롯데마트
    2025 설레는 그날

    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다!
    건강하고 행복 가득한 한 해 되시길 기원합니다.
    사전예약 기간 2024/12/12(목) ~ 2025/1/15(수)

  </div>
  `,
  `
  <div>
    2025 설레는 그날
    미리 준비할수록

    ※사전예약 기간 : 2024. 12. 12(목) ~ 2025. 1. 15(수)
    
    생활비 다이어트 롯데마트 & MAXX카드/롯데마트맥스 신한카드/롯데카드/비씨카드/KB국민카드/신한카드/NH농협카드/하나카드/현대카드/삼성카드/우리카드/IBK기업은행/JB카드/KJ카드

    최대 120만원 상품권 증정 또는 즉시할인
    ※해당 기간에 한함

    1차 2024/12/12(목) ~ 2025/1/8(수)
    30만원 이상 3만 6천원 ※하단 세부내용 참고
    50만원 이상 6만원
    100만원 ~ 1,000만원 미만 100만원 당 12만원
    1,000만원 이상 120만원

    2차 2025/1/9(목) ~ 2025/1/15(수)
    30만원 이상 2만 5천원
    50만원 이상 4만원
    100만원 ~ 1,000만원 미만 100만원 당 8만원
    1,000만원 이상 80만원
    ※상품권 : 3만원 5천원 상품권 + 1천원 추가할인(사전예약 1차 기간 내 30만원 이상에 한함)

    ※자세한 내용은 매장 내 고지물 및 롯데마트GO앱 참조
    ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간내) ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외
    ※단일카드로 전액 결제시에 한함 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※당일 영수증에 한하며, 1人 1日 1회 비연속식 증정
    ※상품권 증정 프로모션은 변경될 수 있습니다

    롯데마트몰은 할인쿠폰으로 즉시 할인 (최대 120만원씩 5회 할인 가능)
    ※롯데마트몰은 행사카드 및 적용 대상 상품이 롯데마트 매장과 상이할 수 있습니다
    ※자세한 행사내용은 롯데마트몰에서 확인하세요
    +
    최대 50% 할인혜택 카드할인 아이콘 확인
    ※법인/체크카드 포함 ※기프트카드/선불카드 제외 ※해당 단일카드로 전액 결제시 적용
    ※해당상품에 한함 ※상품별 할인율 상이 ※상품권 증정 혜택과 중복 적용

    L.POINT 회원 특별할인
    ※해당상품에 한함

    구매 수량에 따라 덤 증정 하나 더 ※해당상품에 한함

    3만원 이상 구매시 전국 무료배송 
    ※일부품목 제외 (일부 도서 산간 지역 제외)
    ※결제금액 기준
    ※롯데마트몰은 무료배송 기준 별도 운영

    ※자세한 내용은 매장 내 고지물 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음
    
  </div>
  `,
  `
  <div>
    더 큰 혜택!

    명절에 가장 받고 싶은 최고의 선물 롯데상품권

    구입 장소 전국 롯데마트 상품권 데스크

    상품권 종류 지류상품권 - 5천원/1만원/3만원/5만원/10만원/30만원/50만원
    ※3만원권은 점별 보유 수량 상이
    
    결제 방법 현금(자기앞수표 포함), 법인카드, 체크카드
    ※체크카드/자기앞수표는 100만원 한도

    사용 장소
    쇼핑 롯데마트(전점 및 문화센터, 토이저러스) 롯데슈퍼, 롯데백화점, 롯데면세점, 롯데하이마트 등
    
    호텔 롯데호텔, 시그니엘(서울, 부산), 롯데시티호텔, 그랜드워커힐, 서울프라자, 경주힐튼호텔, 메종글래드제주 등

    외식 T.G.I FRIDAY’S, 아웃백 스테이크하우스, 빕스 크리스피 크림도넛, 매드포갈릭, 딘타이펑 등
    
    레저 롯데월드, 롯데JTB, 롯데리조트(부여, 속초, 제주), 서울랜드 파라다이스 스파 도고, 한솔오크밸리, 한화리조트 등
    
    골프 롯데스카이힐 C.C(부여, 제주), 프라자 C.C(용인, 설악, 제주) 베어즈베스트 청라 골프클럽 등
    
    문화/예술 롯데시네마, 롯데문화센터 롯데콘서트홀, 롯데뮤지엄, 영풍문고

    ※사용처별 일부매장 제외 ※사용처는 변경될 수 있습니다 ※롯데상품권 관련 세부내용은 롯데백화점 홈페이지 참고

  </div>
  `,
  `
  <div>
    9의 자격, 오직 1%의 한우에게만
    MARBLE9 (마블나인)은 1++ 한우 (7~9등급) 중에서도 근내지방도 19% 이상만을 엄선한 최상급(9등급)만을 선보입니다.
    
    롯데마트, 롯데슈퍼, 롯데온

    마블나인 한우는 왜 특별한가요?
    1 투쁠 한우 중에서도 가장 높은 9등급 한우만 취급
    2 합리적인 가격을 위해 경매부터 정형까지 직접
    3 축소된 롯데만의 물류단계로 더 빠르게 더 신선하게

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    10+1 L.POINT 50,000원 할인
    마블나인 등심채끝양지 혼합세트 699,000원
    ▶ 1++(9)등급 한우 등심 500g×2, 채끝/안심 각 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    마블나인 등심 혼합세트 1호(1++(9)등급) 499,000원
    ▶ 1++(9)등급 한우 등심 500g×2, 채끝 500g×2, 양지 500g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    마블나인 구이세트 2호(1++(9)등급) 439,000원
    ▶ 1++(9)등급 한우 등심 500g×2, 채끝/부채살 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    마블나인 구이세트 3호(1++(9)등급) 349,000원
    ▶ 1++(9)등급 한우 등심, 채끝, 설깃, 찹스테이크 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    마블나인 한우 갈비세트(1++(9)등급) 299,000원
    ▶ 1++(9)등급 한우 찜갈비 700g×5, 냉동, 국내산 한우고기 ※전국 택배 운영

    온라인단독 3구 세트 99,000원

    등안채 세트 
    등심 안심 채끝
    각 1++(9)등급, 200g 냉장, 국내산 한우고기

    차업치 세트
    차돌박이 업진살 치마살
    각 1++(9)등급, 150g 냉장, 국내산 한우고기

    꽃늑토 세트
    꽃/본갈비살 늑간(갈비)살 토시살
    각 1++(9)등급, 150g 냉장, 국내산 한우고기

    온라인단독 6구 세트 179,000원
    등안채+차업치 세트
    등심, 안심, 채끝 + 차돌박이, 업진살, 치마살
    1++(9)등급, 등심/안심/채끝, 각 200g 그 외 각 150g, 냉장, 국내산 한우고기

    등안채+꽃늑토 세트
    등심, 안심, 채끝 + 꽃/본갈비살 늑간(갈비)살, 토시살
    1++(9)등급, 등심/안심/채끝, 각 200g 그 외 각 150g, 냉장, 국내산 한우고기

    롯데마트몰에서 편하게 구매하세요!
    카카오톡 선물하기에서도 롯데 푸드 마켓 or 마블나인을 검색해보세요!
    
    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    신선을 새롭게 
    롯데마트만의 새로운 신선함,

    신선식품의 모든 과정을 다시 연구하고 새롭게 바꾸는 RE:FRESH 프로젝트를 통해 가장 신선한 경험을 전달합니다

    신선을 새롭게 사과에 전부를 걸었다
    매일 먹는 사과니까 3단계 선별로 껍질까지 완벽하게
    롯데마트 올인사과

    L.POINT 10,000원 할인
    AI로 선별한 영주 소백산 사과 89,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    L.POINT 20,000원 할인
    충주 김택성 농부의 GAP 사과 109,900원 
    ▶ 사과 11~12입(4.5kg 내외), 국산

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    RE:FRESH를 만나보세요

    신선을 새롭게
    전문 MD가 직접 경매에 참가하여 엄선한 품질 좋은 한우 선물세트! 
    불고기/국거리에 적합한 최적의 부위와 두께를 엄선!
    10+1 L.POINT 50,000원 할인
    한우 등심정육세트 2호 179,000원
    ▶ 1등급 한우 등심 500g×2, 국거리/불고기 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    신선을 새롭게
    최적의 갈비 두께 12mm! 롯데 신선품질혁신센터 꽃갈비 상품화 공정 강화를 통한 업계 최상의 품질 제공 가능!
    ※상품권 증정 제외
    L.POINT 30,000원 할인
    호주산 소 LA식 갈비세트 139,000원
    ▶ 호주산 냉동 꽃갈비 1.5kg×2
    ※전국 택배 운영

    신선을 새롭게
    제주 수산물 명인이 전통 채낚기 방식으로 어획하여 건강하게 은빛 비늘이 살아있는 은갈치!
    10+1 L.POINT 10,000원 할인
    산지뚝심 제주 은갈치 세트 159,000원
    ▶ 4마리(1.8kg 내외), 냉동, 갈치 : 국산

    신선을 새롭게
    귀경길 손쉽게 들고 갈 수 있는 핸드캐리형 선물세트!
    ※상품권 증정 제외
    ※택배 불가
    충주사과 29,900원
    ▶ 사과 12~14입(3.0kg 내외), 국산

    나주배 39,900원
    ▶ 배 4~6입(3.0kg 내외), 국산

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    2025 설레는 그날
    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다! 건강하시고 행복 가득한 한해 되시길 기원합니다

    MD'S PICK 전문가가 추천하는 선물세트

    L.POINT 10,000원 할인
    프라임 사과, 배 79,900원
    ▶ 사과 4입(1.2kg 내외), 배 6입(3.6kg 내외), 국산
    
    L.POINT 10,000원 할인
    천안 배 59,900원
    ▶ 배 5~6입(5.0kg 내외), 국산
    
    L.POINT 10,000원 할인
    황금사과 79,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산
    
    L.POINT 10,000원 할인
    밀양얼음골 사과 89,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    ※상품권 증정 제외
    L.POINT 30,000원 할인
    미국산 소 LA식 갈비세트 129,000원
    ▶ 미국산 냉동 꽃갈비 1.5kg×2
    ※전국 택배 운영
    
    10+1 L.POINT 20,000원 할인
    미국산 소 프라임 LA식 갈비세트 108,182원
    ▶ 미국산 냉동 프라임등급 꽃갈비 1.2kg×2
    ※1,100세트 한정 ※전국 택배 운영
    
    10+1 L.POINT 30,000원 할인
    한우 정육세트 2호 99,000원
    ▶ 1등급 한우 국거리, 불고기 각 500g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 30,000원 할인
    한우 실속 정육세트 99,000원
    ▶ 1등급 한우 국거리, 불고기, 산적 각 600g, 양념소스, 냉동, 국내산 한우고기 ※전국 택배 운영
    
    L.POINT 10,000원 할인
    롯데호텔 미국산 초이스 LA갈비 세트 129,000원
    ▶ 미국산 초이스 등급 냉동 꽃갈비 1kg×2, 롯데호텔 라세느 양념소스 200ml×4 ※500세트 한정
    
    10+1 L.POINT 30,000원 할인
    한우 알꼬리 선물세트 99,000원
    ▶ 한우 알꼬리 2kg, 냉동, 국내산 한우고기
    ※전국 택배 운영

    뉴질랜드산 소 LA식 갈비세트 99,000원
    ▶ 뉴질랜드산 냉동 꽃갈비 1.5kg×2 
    ※800세트 한정 ※전국 택배 운영
    
    10+1 L.POINT 10,000원 할인
    제주 갈치 옥돔 혼합세트 109,000원
    ▶ 갈치 2마리(600g 내외), 옥돔 2마리(400g 내외), 냉동, 갈치/옥돔/천일염 : 국산
    
    10+1 L.POINT 10,000원 할인
    실속 제주 은갈치 세트 109,000원
    ▶ 4마리(330g 내외), 냉동, 갈치 : 국산
    
    10+1 L.POINT 10,000원 할인
    제주 옥돔세트 109,000원
    ▶ 옥돔 400g(2미)×2팩, 냉동, 옥돔/천일염 : 국산
    
    7+1
    자연담은 칠산소금 굴비 실속세트 89,900원
    ▶ 10마리(0.9kg 내외), 냉동, 참조기/천일염 : 국산
    
    10+1 L.POINT 10,000원 할인
    대한민국 명장 장석준 명란 세트 89,900원
    ▶ 300g×2, 냉동, 원산지 별도표기
    
    카드할인 30%
    대천 곱창김 혼합1호 32,830원
    ▶ 곱창 전장김 25g×8봉, 곱창김 캔김 30g×3캔, 원산지 별도표기

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    MD'S PICK 전문가가 추천하는 선물세트

    L.POINT 10,000원 할인
    6년근 뿌리 많은 인삼 세트 49,900원
    ▶ 난발용 인삼 500g, 국산

    L.POINT 10,000원 할인
    실속형 금산 인삼 더덕 혼합세트 49,900원 
    ▶ 인삼 300g, 더덕 400g, 국산
    
    L.POINT 20,000원 할인
    자연향 버섯 혼합세트 2호 39,900원
    ▶ 동고 40g, 표고채 30g, 건목이 40g, 흰목이 30g, 표고칩 35g, 향신 35g, 국산

    L.POINT 20,000원 할인
    자연향 버섯 혼합세트 1호 49,900원
    ▶ 동고 70g, 표고채 55g×2, 건목이 40g, 흰목이 30g, 표고칩 30g, 표고버섯가루 60g, 국산
    
    L.POINT 20,000원 할인
    매일견과 하루한줌 80봉 29,900원
    ▶ 매일견과 18g×80봉(구운 아몬드 20%, 호두 30%, 구운 캐슈넛 12%, 건포도 38%,원산지 별도표기)
     
    L.POINT 10,000원 할인
    The 고소한 마카다미아 매일견과 50봉 29,900원
    ▶ 매일견과 20g×50봉(호두 30%, 구운아몬드 30%, 건포도 25%, 마카다미아 15%,원산지 별도표기)
    
    L.POINT 10,000원 할인
    명작 견과&건과일 10종 세트 39,900원
    ▶ 구운 아몬드 170g, 호두 110g, 구운 피스타치오 120g, 대추야자 160g 外, 원산지 별도표기
    
    L.POINT 10,000원 할인
    BEST 견과 10종 세트 49,900원
    ▶ 구운 아몬드 170g, 호두 110g, 구운 캐슈넛 150g, 피칸 120g 外, 원산지 별도표기
    
    카드할인 20%
    정관장 홍삼정명 71,200원
    ▶ 100g×2입
    
    1+1
    농협 한삼인 대보선물세트 3호 77,000원
    ▶ 홍기천 50ml×10포, 홍삼대보 75ml×8병, 홍삼대보환 3.75g×12환
    
    1+1
    종근당건강 발효홍삼력진 21병 79,800원
    ▶ 20ml×21병
    
    C.Y.O.C. Private Bottle No.1 벤로막 2014 239,000원
    ▶ C.Y.O.C. Private Bottle No.1 벤로막 2014 700ml

    L.POINT 26,800원 할인
    조니워커 블루 뱀띠 에디션 368,000원
    ▶ 조니워커 뱀띠에디션 750ml, 스템프
    
    L.POINT 5,000원 할인
    애경 산양유 바디케어 기프트 11,900원 
    ▶ 샤워메이트 산양유 바디워시 800ml×2, 샤워메이트 산양유 비누 80g×2
    
    카드할인 30%
    엘지 히말라야 핑크솔트 에센셜 세트 27,230원 
    ▶ 히말라야 핑크솔트 샴푸 200ml×2, 히말라야 핑크솔트 바디워시 민트향 190ml 外
    
    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니
    
  </div>
  `,
  `
  <div>
    2025 설레는 그날
    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다! 건강하시고 행복 가득한 한해 되시길 기원합니다.
    새해에는 소중한 분들께 건강을 선물하세요

    카드할인 20%
    정관장 홍삼원기 29,600원
    ▶ 50ml×30포
    
    해당카드 3+1
    정관장 홍삼진고 이뮨스틱 46,000원
    ▶ 10g×30포

    카드할인 25%
    정관장 홍삼진본 36,750원
    ▶ 40ml×20포
    
    카드할인 20%
    정관장 활삼골드 31,200원
    ▶50ml×30포
    
    1+1
    씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    1+1
    씨제이 한뿌리 홍삼대보 44,800원 
    ▶ 40ml×24입

    1+1
    씨제이 한뿌리 흑삼아르기닌과 흑마늘 69,800원 
    ▶ 100ml×6병, 50ml×12입

    1+1
    씨제이 한뿌리 홍삼아르기닌 59,800원
    ▶ 100ml×14병
    
    1+1
    농협 한삼인 홍삼본 골드 39,800원 
    ▶ 50ml×30포
    
    1+1
    농협 한삼인 홍삼진 굿데이스틱 59,600원
    ▶ 10ml×60포
    
    1+1
    씨제이 관절오만보 강본진액 MSM 119,000원 
    ▶ 80ml×5포×6입
    
    1+1
    서울약사신협 허니로얄제리 59,000원 
    ▶ 70ml×30포
    
    1+1
    종근당건강 활력녹용보 43,800원 
    ▶ 50ml×30포
    
    1+1
    종근당건강 홍삼기력골드 39,800원
    ▶ 50ml×30포
    
    28,000원 할인
    종근당건강 락토핏 당케어 3입 세트 41,900원
    ▶ 50포×3입
     
    13,000원 할인
    종근당건강 아임비타 멀티비타민 세트 33,700원
    ▶ 이뮨플러스 14병, 멀티비타민 60정

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
  
  </div>
  `,
  `
  <div>
    새해에는 소중한 분들께 건강을 선물하세요

    1+1
    서울약사신협 흑마늘진액 59,000원 
    ▶ 70ml×30포
    
    1+1 
    엘지 리튠 배도라지진액 골드 49,900원
    ▶ 50ml×24포
    
    카드할인 20%
    정관장 홍삼원 골드 40,000원
    ▶100ml×24입
    
    18,000원 할인
    종근당건강 프로메가 알티지 오메가3 듀얼세트 37,700원
    ▶ 520mg×60캡슐×3입
    
    1+1 
    풍기인삼 황풍정 산삼배양근 수 49,000원
    ▶ 50ml×30포
    
    1+1
    엘지 리튠 홍삼진 골드 39,900원
    ▶ 50ml×24포
    
    1+1
    엘지 리튠 홍삼진 제로 골드 39,900원
    ▶ 50ml×24포
    
    L.POINT 6,000원 할인
    뉴트리원 올인원 멀티비타 이뮨플러스 선물세트 43,900원
    ▶ 21.5g× 7병×4입
    
    L.POINT 30%
    엘지 프로폴리테라 에센셜 세트 35,630원
    ▶ 엘라스틴 프로폴리테라 샴푸 500ml, 엘라스틴 프로폴리테라 손상용 샴푸 500ml 外
    
    L.POINT 30%
    엘지 피지오겔 DMT 크림 기획세트 35,630원 
    ▶ 피지오겔 DMT 크림 150ml, 피지오겔 DMT 에센스인토너 50ml 外
    
    L.POINT 30%
    엘지 홈스파 벨먼 기획세트 35,700원
    ▶ 벨먼 스파 수분광채 바디워시 로즈 400g, 벨먼 스파 수분광채 바디워시 자스민 400g 外
    
    L.POINT 30%
    엘지 시그니처 컬렉션 그린 39,830원
    ▶ 닥터그루트 집중케어 지성용 샴푸 330ml, 피지오겔 크림 75ml, 피지오겔 로션 50ml 外
    
    카드할인 30%
    품격과 사랑을 담은 닥터그루트 헤어세트 40,950원
    ▶ 닥터그루트 샴푸 300ml×2 , 닥터그루트 샴푸 180ml, 토닉 10ml 外
    
    L.POINT 30%
    엘지 피지오겔 DMT 크림로션 2종 기획세트 48,930원
    ▶ 피지오겔 DMT 로션 200ml, 피지오겔 DMT 크림 150ml 外
    
    L.POINT 30%
    엘지 시그니처 컬렉션 퍼플 49,630원 
    ▶ 닥터그루트 집중케어 샴푸 330ml, 피지오겔 DMT 클렌징 젤 150ml 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    2025 설레는 그날
    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다! 건강하시고 행복 가득한 한해 되시길 기원합니다.
    새해에는 소중한 분들께 건강을 선물하세요

    L.POINT 10,000원 할인
    왕더덕 세트 159,000원
    ▶ 더덕 1.5kg(뿌리당 90g~120g), 국산
    
    L.POINT 10,000원 할인
    6년근 프리미엄 인삼세트 159,000원
    ▶ 6년근 인삼 700g, 국산
    
    L.POINT 20,000원 할인
    팔각 어울림 버섯 119,000원
    ▶ 백화고100g, 흑화고100g×2, 국산
    
    팔각 백화고 159,000원
    ▶ 백화고 100g×4, 국산

    L.POINT 15,000원 할인
    친환경 표고버섯 혼합1호 49,900원
    ▶ 친환경 흑화고 100g, 친환경 표고채70g×2, 국산
    
    L.POINT 20,000원 할인
    자연향 백화고 혼합세트 54,900원
    ▶ 사각칩 40g×2, 상황채 40g, 영지채 50g, 백화고 90g, 동고 70g×2, 국산
    
    L.POINT 10,000원 할인
    자연향 동고 혼합세트 29,900원
    ▶ 표고칩 75g, 동고 70g, 표고채 70g, 국산
    
    L.POINT 10,000원 할인
    웰빙표고 실속세트 2호 44,900원
    ▶ 동고 70g, 표고채 60g, 표고사각 30g, 핑크솔트 140g, 흑후추 70g, 원산지 별도표기
    
    L.POINT 10,000원 할인
    실속형 건버섯 혼합세트 1호 59,900원
    ▶ 향고 40g×2, 표고채 35g×2, 상황슬라이스 50g, 상황분말 20g, 노루궁뎅이분말 60g,국산
    
    L.POINT 10,000원 할인
    실속형 건버섯 혼합세트 2호 49,900원
    ▶ 향고40g×2, 표고채 35g×2,목이 15g, 새송이 10g, 느타리 8g, 국산
    
    L.POINT 20,000원 할인
    자연담은 건버섯&견과 혼합세트 1호 69,900원
    ▶ 흑화고 80g×2, 호두 90g, 대추야자 140g, 구운아몬드 140g 外,원산지 별도표기
    
    L.POINT 20,000원 할인
    자연담은 건버섯&견과 혼합세트 2호 39,900원
    ▶ 동 고 50g, 표고사각 30g, 표고채 35g,구운캐슈넛 100g, 호두 80g, 구운아몬드90g, 원산지 별도표기
    
    L.POINT 50,000원 할인
    자연향 건버섯&견과 혼합세트 199,900원
    ▶ 황잣 400g, 국산 호두 300g, 백화고 150g, 흑화고 110g, 국산
    
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    인삼&버섯&견과 선물세트

    1+1
    넛츠박스 매일견과 세트(20봉) 19,900원
    ▶ 매일견과 20g×20봉(구운 아몬드 25%, 호두 25%, 구운땅콩 25%, 건포도 25%, 원산지 별도표기)

    L.POINT 20,000원 할인
    데일리넛츠 하루한봉 80봉 27,900원
    ▶ 매일견과 18g×80봉(구운 땅콩 25%, 해바라기씨 25%, 건포도 20%, 호두 15%,구운 아몬드 15%, 원산지 별도표기)

    L.POINT 10,000원 할인
    HBAF 먼투썬 6주 31,900원
    ▶ HBAF 먼투썬 하루 견과 20g×42봉, 원산지 별도표기
    
    L.POINT 10,000원 할인
    견과&건과일 6종 세트 29,900원
    ▶ 구운 아몬드 190g, 호두 120g, 건포도 200g, 건자두 190g 外, 원산지 별도표기
    
    L.POINT 10,000원 할인
    특선 견과 선물세트 6종 39,900원
    ▶ 구운 아몬드 150g, 호두 100g, 마카다미아 140g, 브라질넛 150g 外, 원산지 별도표기
    
    L.POINT 10,000원 할인
    HBAF 원담 견과세트 6호 44,900원
    ▶ 꿀홍삼땅콩 130g, 마누카 허니마카다미아 130g, 호지차아몬드 130g 外, 원산지 별도표기
    
    L.POINT 15,000원 할인 카드할인 10,000원 할인
    흑화고 혼합세트 49,900원
    ▶ 흑화고 80g, 표고채 50g×2, 목이버섯 40g 外, 국내산
    
    L.POINT 20,000원 할인
    자연담은 건강버섯 세트 59,900원
    ▶ 은이버섯 25g, 상황슬라이스 25g, 녹각영지 20g 外, 원산지 별도표기
    
    L.POINT 10,000원 할인
    건강버섯 특선세트 99,000원
    ▶ 국내산 상황버섯 60g, 국내산 영지 180g, 차가버섯 200g 外, 원산지 별도표기
    
    L.POINT 9,000원 할인
    쌀명당 8개입 선물세트 21,900원
    ▶ 더고슬미 400g, 더찰진미 400g, 더향진미 400g×2입, 현미 400g, 찰현미 400g, 혼합9곡 400g, 찹쌀 400g, 원산지 별도표기
    
    L.POINT 11,000원 할인
    쌀명당 시그니처 선물세트 29,900원
    ▶ 더고슬미 400g, 더찰진미 400g, 더향진미 400g×2입, 현미 400g, 찰현미 400g, 혼합9곡 400g, 찹쌀 400g, 주걱, 원산지 별도표기

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    2025 설레는 그날
    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다! 건강하시고 행복 가득한 한해 되시길 기원합니다.

    한우/수입육 구이&정육 선물세트

    10+1 L.POINT 50,000원 할인
    한우 홈파티팩 선물세트 199,000원
    ▶ 1등급 한우 알등심/새우살 각 300g×2, 냉장, 국내산 한우고기 ※산지택배(전국배송) 가능

    10+1 L.POINT 50,000원 할인
    미경산 한우 암소 선물세트 (1+등급) 289,000원
    ▶ 1+등급 한우 등심/채끝 각 300g×3, 냉장, 국내산 한우고기 ※산지택배(전국배송) 가능
    
    10+1 L.POINT 50,000원 할인
    암소 에이징 한우 선물세트 179,000원 
    ▶ 2등급 한우 등심, 샤또 브리앙, 채끝 각 200g×2, 냉장, 국내산 한우고기 ※산지택배(전국배송) 가능
    
    L.POINT 50,000원 할인
    마리네이드 한우 채끝 스테이크 세트 169,000원
    ▶ 1등급 한우 채끝 스테이크 0.5kg, 찹스테이크 0.3kg, 냉장, 국내산 한우고기 ※산지택배(전국배송) 전용
    
    10+1 L.POINT 50,000원 할인
    1+등급 한우 갈비세트 199,000원 
    ▶ 1+등급 한우 찜갈비 700g×4, 냉동, 양념소스, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    1등급 한우 갈비세트 149,000원
    ▶ 1등급 한우 찜갈비 700g×3, 냉동, 양념소스, 국내산 한우고기 ※전국 택배 운영
    
    L.POINT 50,000원 할인
    페퍼 우대 갈비 세트 1.8kg 139,000원
    ▶ 미국산 초이스 등급 페퍼 우대갈비 0.9kg×2, 냉동
    ※전국 택배 운영
    
    L.POINT 50,000원 할인
    제주 흑돼지 모둠구이 선물세트 2.0kg 89,000원
    ▶ 국내산 냉장 흑돼지 삼겹살 500g×2, 흑돼지 목심, 앞다리 각 500g
    ※산지택배(전국배송) 전용
    
    ※상품권 증정 제외
    L.POINT 30,000원 할인
    청정와규 세트 3호 109,000원
    ▶ 호주산 냉장 와규 부채살 0.4kg×2, 꾸리살 0.4kg, 삼각살 0.4kg ※전국 택배 운영
    
    L.POINT 60,000원 할인
    냉장 시즈닝 스테이크 모둠 세트 1.8kg 99,000원
    ▶ 시즈닝 척아이롤/부채살/살치살 각 0.6kg
    ※산지택배(전국배송) 가능
    
    롯데가 기른
    L.POINT 30,000원 할인
    Lgrow 와규 선물세트 1호 119,000원
    ▶ 호주산 냉장 와규 윗등심 0.4kg×2,본갈비살 0.4kg, 앞치마살 0.4kg ※전국 택배 전용
    
    롯데가 기른
    L.POINT 20,000원 할인
    Lgrow 와규 선물세트 2호 149,000원
    ▶ 호주산 냉장 와규 윗등심 0.4kg×2, 치마살 0.4kg, 살치살 0.4kg
    ※전국 택배 전용

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    인기 만점 육즙 가득한
    소고기&돼지고기 선물세트

    10+1 L.POINT 50,000원 할인
    한우 등심채끝 혼합세트 239,000원
    ▶ 1등급 한우 등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    한우 등심정육세트 1호 219,000원
    ▶ 1등급 한우 등심, 국거리, 불고기 각 500g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    10+1 L.POINT 50,000원 할인
    한우 한끼 구이세트 199,000원
    ▶ 1등급 한우 등심 200g×2, 안심/채끝/치마살/부채살 각 200g, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    한우 한끼 스테이크 세트 239,000원
    ▶ 1등급 한우 등심 200g×6, 채끝 200g×6, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    한우 등심세트 1호 339,000원
    ▶ 1등급 한우 등심 500g×6, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    한우 등심안심 혼합 세트 249,000원
    ▶ 1등급 한우 등심 500g×2, 안심 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    한우 구이 스페셜 세트 229,000원
    ▶ 1등급 한우 등심 500g, 채끝/안심/부채살 각 400g, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    L.POINT 60,000원 할인
    한우 양념 불고기 모둠 세트 119,000원 
    ▶ 한우 양념 불고기/언양식 불고기 각 1.2kg, 냉동 ※센터택배(전국배송) 전용
    
    10+1 L.POINT 50,000원 할인
    한우 LA식 갈비세트 249,000원
    ▶ 1등급 한우 LA식 갈비 1.5kg×2 냉동, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    무항생제 등심정육세트 5호(1+등급) 209,000원
    ▶ 무 항생제 1+등급 한우등심 500g×2, 국거리/불고기 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    무항생제 한우 등심채끝 혼합 세트 269,000원 
    ▶ 무 항생제 1+등급 등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    10+1 L.POINT 50,000원 할인
    한우 등심세트 2호 239,000원 
    ▶ 1등급 한우 등심 500g×4, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    ※상품권 증정 제외
    L.POINT 70,000원 할인
    호주산 양념 LA식 꽃갈비 세트 119,000원 
    ▶ 호주산 소고기 양념 LA식 꽃갈비 1.2kg×2, 냉동 ※전국택배 운영
    
    10+1 L.POINT 30,000원 할인
    한우 정육세트 1호 169,000원
    ▶ 1 등급 한우 국거리 500g×3, 불고기 500g×3, 냉장, 국내산 한우고기 ※전국 택배 운영
    
    L.POINT 10,000원 할인
    캐나다산 인증 돼지고기 선물세트 49,000원
    ▶ 캐나다산 냉장 돼지고기 삼겹살, 목심 각 1kg,
    ※전국 택배 운영
    
    10+1 L.POINT 20,000원 할인
    무항생제 한우 사골/꼬리반골세트 각59,000원
    ▶ 무 항생제 한우 사골/꼬리반골 각 5kg, 냉동, 국내산 한우고기 ※전국 택배 운영

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    2025 설레는 그날
    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다! 건강하시고 행복 가득한 한해 되시길 기원합니다
    신선함을 담았습니다

    7+1
    자연담은 칠산소금 참굴비세트 특호 199,000원
    ▶ 1.2kg/10미, 냉동, 참조기/천일염 : 국산

    7+1
    자연담은 칠산소금 굴비 세트 2호 119,000원
    ▶ 1.0kg/10미, 냉동, 참조기/천일염 : 국산
    
    7+1 
    자연담은 칠산소금 굴비 세트 3호 169,000원
    ▶ 1.1kg/10미, 냉동, 참조기/천일염 : 국산
    
    7+1
    프리미엄 굴비세트 2호 349,000원
    ▶ 1.5kg/10미, 냉동, 참조기/천일염 : 국산

    7+1
    민어 굴비 세트 79,900원
    ▶ 1.5kg/5미, 냉동, 민어/천일염 : 국산
    
    10+1 L.POINT 10,000원 할인
    으뜸 대왕 갈치 세트 289,000원
    ▶ 4마리 3.2kg 내외, 국산
    
    7+1 L.POINT 20,000원 할인
    ASC인증 완도전복세트 199,000원 
    ▶ ASC 인증 완도전복 2kg 14~15마리 (7미 사이즈) 마리당 140g 내외
    
    L.POINT 30,000원 할인
    생선 스테이크 세트 99,000원
    ▶ 삼치, 도미, 농어, 광어, 민어, 코다리, 각 300g 냉동/원산지 별도표기
    
    L.POINT 10,000원 할인
    홈마카세 세트 (실속) 49,000원
    ▶ 우니 100g, 단새우 60g×2팩, 감태 4g×2팩, 회초장 40g, 와사비 3g×4, 간장 6g×4, 냉동, 원산지 별도표기
    
    10+1 L.POINT 10,000원 할인
    반건조 생선 선물세트 59,000원
    ▶ 우럭 2~300g/1미, 조기 2~300g/1미, 참돔 250g/1미, 서대 100g/3미, 냉동, 원산지 별도표기
    
    10+1 L.POINT 10,000원 할인
    노르웨이 고등어 필렛세트 49,000원
    ▶ 고등어 필렛 1.5kg(750g×2팩, 팩당 5~6필렛), 냉동, 노르웨이산

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    인기 수산물 선물세트

    L.POINT 25,000원 할인
    남해바다 정치망 멸치세트 74,900원
    ▶ 볶음용멸치 200g, 볶음조림용 멸치 180g, 조림용 멸치 150g, 국물용 멸치 180g, 멸치 : 국산
    
    L.POINT 10,000원 할인
    킹타이거 선물세트 79,000원
    ▶ 블랙타이거 1kg(총 4미, 1미당 250g), 냉동, 기니산
    
    L.POINT 5,000원 할인
    해우촌 진도 곱창김 세트 39,900원
    ▶ 구운 곱창김 12g×8봉 , 곱창 돌김 35g×2캔, 원산지 별도표기
    
    L.POINT 5,000원 할인
    대천 명품 캔김 세트(15캔) 59,900원 
    ▶ 곱창김 30g×15캔, 원산지 별도표기
    
    10+1
    동원 양반 들기름김세트 9,900원
    ▶ 들기름김 4g×18봉, 원산지 별도표기
    
    10+1
    CJ 비비고 토종김 5호 9,900원
    ▶ 비비고 참기름 도시락김 4g×18봉, 원산지 별도표기
    
    L.POINT 30%
    CJ비비고 초사리 곱창돌김 특호 49,700원
    ▶ 초사리 곱창돌김 구운생김 15g×10 , 초사리 곱창돌김 캔 25g×3, 원산지 별도표기
    
    L.POINT 30%
    CJ비비고 감태 캔김 41,930원
    ▶ 감태김 캔 25g× 8, 원산지 별도표기
    
    L.POINT 5,000원 할인
    성경 종합 P호 17,900원
    ▶ 들기름 전장김 20g×4봉 , 들기름 식탁김 4g×12봉, 들기름 김자반 50g×2봉, 원산지 별도표기
    
    L.POINT 30%
    동원 곱창돌김 세트 41,930원
    ▶ 곱창돌김전장 15g×6봉, 곱창캔김 25g×3, 원산지 별도표기
    
    광천 명작 유기원초 재래김세트 14,900원
    ▶ 명작 유기원초 재래김 4.5g×30봉, 원산지 별도표기

    L.POINT 5,000원 할인
    광천 자연나라 혼합 캔김세트 19,900원
    ▶ 재래 캔김 30g , 파래 캔김 30g , 곱창 캔김 30g,무조미 곱창 캔김 30g, 원산지 별도표기

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    2025 설레는 그날 
    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다! 건강하시고 행복 가득한 한해 되시길 기원합니다.
    신선함을 담았습니다

    L.POINT 10,000원 할인
    GAP 사과, 배(情) 49,900원 
    ▶ 사과 5입(1.4kg 내외), 배 4입(2.2kg 내외), 국산 

    L.POINT 4,000원 할인
    충주사과, 천안배 85,900원
    ▶ 사과 6입(1.7kg 내외), 배 6입(3.6kg 내외),국산
    
    L.POINT 20,000원 할인
    샤인머스캣, 사과, 배, 한라봉 89,900원
    ▶ 샤인머스캣 1송이(0.7kg 내외), 사과 3입(0.9kg 내외), 배 3입(1.8kg 내외), 한라봉 3입(0.8kg 내외), 국산
    
    샤인머스켓, 메론 99,900원
    ▶ 샤인머스캣 2송이(2.0kg 내외), 머스크 메론 2입(3.2kg 이상), 국산

    L.POINT 20,000원 할인
    황금사과 품은 사과,배 109,900원
    ▶ 사과 8입(2.4kg 내외), 배 6입(3.9kg 내외), 국산
    
    L.POINT 20,000원 할인
    한라봉 품은 사과,배 109,900원
    ▶ 사과 4입(1.2kg 내외), 배 6입(3.9kg 내외), 한라봉 4입(1.4kg 내외),국산
    
    L.POINT 10,000원 할인
    샤인머스캣, 사과 69,900원
    ▶ 샤인머스캣 3송이(2.1kg 내외), 사과 6입(2.0kg 내외), 국산
    
    L.POINT 20,000원 할인
    샤인머스켓, 사과, 배 119,900원
    ▶ 샤인머스캣 2송이(1.4kg 내외) 사과 4입(1.2kg 내외), 배 6입(3.9kg 내외), 국산
    
    L.POINT 4,000원 할인
    나주 청미래 배 75,900원
    ▶ 배 7~9입(5.0kg 내외), 국산
    
    L.POINT 10,000원 할인
    정성담은 신고배 79,900원
    ▶ 배 9~11입(6.5kg 내외), 국산
    
    L.POINT 20,000원 할인
    나주 배 89,900원
    ▶ 배 9~11입(6.5kg 내외), 국산
    
    천안 지순태 농부의 GAP 배 129,900원
    ▶ 배 8~9입(6.7kg 내외), 국산

    L.POINT 10,000원 할인
    충주 프레샤인 사과 49,900원
    ▶ 사과 17~20입(5.0kg 내외), 국산
    
    L.POINT 10,000원 할인
    문경 사과 69,900원
    ▶ 사과 12~14입 (4.5kg 내외), 국산
    
    L.POINT 10,000원 할인
    정성담은 사과 69,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산
    
    L.POINT 10,000원 할인
    충주 사과 69,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    과일 선물세트

    L.POINT 10,000원 할인
    제주 천혜향 49,900원
    ▶ 천혜향 8~13입(3.0kg 내외), 국산
    
    L.POINT 10,000원 할인
    샤인머스켓 3kg 59,900원
    ▶ 샤인머스캣 3.0kg 내외, 국산

    샤인머스켓 3kg 79,900원
    ▶ 샤인머스캣 3.0kg 내외, 국산
    
    L.POINT 10,000원 할인
    제주 천혜향, 레드향, 한라봉 69,900원
    ▶ 천혜향 4입(1.4kg 내외), 레드향 4입(1.3kg 내외), 한라봉 4입(1.3kg 내외), 국산
    
    L.POINT 4,000원 할인
    제스프리 제주골드키위 35,900원
    ▶ 골드키위 20입(2.0kg 내외), 국산
    
    페루 애플망고 59,900원
    ▶ 애플망고 9입 (3.6kg 내외), 페루산

    망고 혼합 69,900원
    ▶ 옐로우 망고 3입(1.0kg 내외), 애플망고 6입(2.4kg내외), 원산지 별도표기

    태국산 망고 79,900원
    ▶ 옐로우 망고 9입(3.2kg 내외), 태국산

    상주 곶감(福) 29,990원
    ▶ 곶감 30입(0.9kg 내외), 국산

    L.POINT 10,000원 할인
    GAP 청도 반건시 49,900원
    ▶ 곶감 30입 (1.5kg 내외), 국산
    
    L.POINT 10,000원 할인
    상주 곶감(情) 59,900원
    ▶ 곶감 30입 (1.2kg 내외), 국산
    
    L.POINT 10,000원 할인
    하루한알 둥시곶감 59,900원
    ▶ 곶감 24입(1.1kg 내외), 국산
    
    L.POINT 10,000원 할인
    산청곶감(情) 69,900원
    ▶ 곶감 32입 (1.1kg 내외), 국산
    
    L.POINT 10,000원 할인
    상주 무농약 왕곶감 79,900원
    ▶ 곶감 24입 (1.2kg 내외), 국산
    
    L.POINT 20,000원 할인
    크림치즈와 호두 품은 곶감 99,900원
    ▶ 호두치즈곶감 375g×2팩, 곶감12입(0.4kg 내외), 국산
    
    L.POINT 20,000원 할인
    상주 왕곶감 109,900원
    ▶ 곶감 32입 (1.6kg 내외), 국산

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    2025 설레는 그날 
    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다! 건강하시고 행복 가득한 한해 되시길 기원합니다.

    요리에 맛을 살리는

    기순도 대한민국 전통식품 명인 제 35호 기순도,370년 종가의 전통과 명인의 손맛이 선사하는 품격있는 선물세트

    L.POINT 10%
    기순도 미식예가 실속 2호 61,200원
    ▶ 기순도 전통 간장 300ml, 맛장 300ml, 현미보리된장 200g, 현미고추장 200g
    
    L.POINT 10%
    기순도 미식예가 실속 3호 62,100원
    ▶ 기순도 전통고추장 320g, 죽염 250g, 전통간장 300ml, 맛장 300ml

    L.POINT 10%
    기순도 명인의 장 다복 11호 63,900원
    ▶ 기순도 현미보리된장 250g, 현미고추장 250g, 죽염 200g, 전통간장 250ml, 맛장 250ml
    
    L.POINT 10%
    기순도 미식예가 오복 2호 71,100원
    ▶ 기순도 전통된장 320g, 전통고추장 320g, 쌀조청 200g, 죽염 160g, 맛장 300ml
    
    청정원
    5+1
    청정원 현미고추장 선물세트 79,000원
    ▶ 우리쌀 현미고추장 2kg(도자기)

    비온뒤 첫소금
    청정해역 신안에서 비가 온 뒤 가장 깨끗해진 토판 위에서 정성껏 수확한 명품 소금 선물세트

    L.POINT 10%
    비온뒤 첫소금 어간장 세트 19,800원
    ▶ 요리엔담다 어간장 250ml×2
    
    L.POINT 10%
    비온뒤 첫소금 프리미엄 6호 22,500원
    ▶ 프리미엄 함초소금 400g, 프리미엄 후추소금 380g, 프리미엄 구운소금 400g
    
    L.POINT 10%
    비온뒤첫소금 실속 2호 27,000원
    ▶ 3년숙성 천일염 135g, 토판소금 150g, 함초소금 150g, 구운소금 150g
    
    L.POINT 10%
    비온뒤 첫소금 프리미엄 2호 45,000원
    ▶ 3년숙성 천일염 135g, 구운소금 150g, 함초소금 150g, 토판염 150g, 신안천일염 400g×2, 스푼 동봉
    
    바로담아
    깐깐하게 엄선한 국산 참, 들깨를 전통 압착 방식으로 단 한번 착유하여 깨 본연의 고소하고 진한 풍미가 가득한 참·들기름 선물세트
    
    L.POINT 10%
    바로담아 국산 참들기름 1호 56,970원
    ▶ 국산 참기름 180ml, 국산 들기름 180ml, 국산볶음참깨 105g
    
    L.POINT 10%
    바로담아 국산 참들기름 3호 63,900원
    ▶ 국산 참기름 180ml, 국산 들기름 180ml, 국산볶음참깨 105g, 국산 깨소금 100g
    
    L.POINT 10%
    바로담아 국산 참들기름 2호 73,980원
    ▶ 국산 참기름 180ml×2, 국산 들기름 180ml

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    프리미엄 장류&오일 선물세트

    주세페주스티
    17대째 내려오는 400년이 넘는 전통의 현존하는 가장 오래된 하이엔드 발사믹 브랜드

    1 메달 6년 숙성 2 메달 8년 숙성 3메달 12년 숙성

    1 메달
    L.POINT 20%
    주세페주스티 올리브유 6년숙성 발사믹식초 세트 73,600원
    ▶ 엑스트라버진 올리브오일 250ml, 1메달 6년산 발사믹식초 250ml

    2 메달
    L.POINT 20%
    주세페주스티 올리브유 8년숙성 발사믹식초 세트 86,400원
    ▶ 엑스트라버진 올리브오일 250ml, 2메달 8년산 발사믹식초 250ml
    
    3 메달
    L.POINT 20%
    주세페주스티 올리브유 12년숙성 발사믹식초 세트 109,600원
    ▶ 엑스트라버진 올리브오일 250ml, 3메달 12년산 발사믹식초 250ml
    
    1 메달
    L.POINT 20%
    주세페주스티 6년숙성 발사믹&화이트발사믹 세트 68,000원
    ▶ 1메달 6년산 발사믹식초 250ml, 화이트 발사믹식초 250ml

    파미고
    세계최고 올리브오일 마스터 Pepe Cano 의 손 끝에서 완성되는 스페인 No.1 올리브오일 브랜드

    L.POINT 10%
    파미고 엑스트라버진 올리브유 선물세트 37,800원
    ▶ 엑스트라버진 올리브유 500ml×2

    올리타리아 이탈리아 쉐프들이 가장 선호하는 No.1 브랜드 올리타리아

    L.POINT 10%
    올리타리아 스페셜 5호 14,310원
    ▶ 해바라기유 500ml×2
    
    L.POINT 10%
    올리타리아 스페셜 33호 36,810원
    ▶ 엑스트라버진올리브유 500ml, 포도씨유 500ml, 해바라기유500ml
    
    L.POINT 10%
    올리타리아 스페셜 4호 42,210원
    ▶ 엑스트라버진올리브유 500ml×2

    L.POINT 10%
    올리타리아 스페셜 79호 43,110원
    ▶ 엑스트라버진올리브유 1L, 해바라기유 1L
    
    L.POINT 10%
    올리타리아 스페셜62호 43,110원
    ▶ 엑스트라버진올리브유 500ml, 포도씨유 500ml, 트러플오일 250ml

    신선한 올리브 열매를 수확한 즉시 선별, 압착한 황금(Oro) 올리브유
    L.POINT 10%
    올리타리아 스페셜 92호 47,610원
    ▶ 오로 올리브오일 500ml×2
    
    L.POINT 10%
    올리타리아 스페셜 41호 49,230원
    ▶ 엑스트라버진올리브유 500ml, 포도씨유 500ml, 아보카도오일 250ml
    
    L.POINT 10%
    올리타리아 스페셜 20호 49,410원
    ▶ 엑스트라버진 올리브유 1L, 포도씨유1L

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    2025 설레는 그날
    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다! 건강하시고 행복 가득한 한해 되시길 기원합니다.

    프리미엄 소스/오일 &홈스토랑 선물세트

    L.POINT 10%
    티바인 제로슈가 쿠킹시럽 선물세트 28,800원
    ▶ 제로슈가 쿠킹시럽, 메이플시럽, 허니시럽 각 520g
    
    L.POINT 10%
    올리에 엑스트라버진 아보카도오일 선물세트 31,500원
    ▶ 올리에 엑스트라버진 아보카도오일 250ml×2, 멕시코산
    
    L.POINT 20%
    그로브 엑스트라버진 아보카도오일 세트 1호 32,800원
    ▶ 그로브 엑스트라버진 아보카도 오일 250ml×2, 뉴질랜드산
    
    L.POINT 20%
    데니그리스 유기농 사과식초 2호 24,800원
    ▶ 데니그리스 유기농 사과식초 500ml×3 
    
    L.POINT 10,000원 할인 
    하이디라오 훠궈세트 49,800원
    ▶ 하이디라오 마라훠궈소스 110g×5, 마라탕소스 110g×5, 마라야채 간편훠궈 272g, 토마토야채 간편훠궈 282g, 스테인레스 국자, 냄비 동봉
    
    쿠캣 시그니처 냉동떡 선물세트 46,000원
    ▶ 딸기쏙우유 찹쌀떡 540g×2,티라미수쏙 찹쌀떡 540g, 초코쏙녹차 찹쌀떡 540g

    시루조아 쌀카스테라 호박인절미 선물세트 30,900원
    ▶ 쌀 카스텔라 단호박 인절미 50g×24봉

    카드할인 20%
    몽탄 우대갈비 선물세트 111,200원
    ▶ 몽탄 우대갈비 1.2kg(3대)
    
    천하일미 한우 불고기 선물세트 69,900원 
    ▶ 천하일미 한우 1+불고기 180g×10입

    한만두 2025년 설 1호 29,900원
    ▶ 오리지널 갈비만두 300g, 한만두 짬뽕만두 330g, 고기듬뿍 왕교자 330g, 김치아삭 왕교자 330g, 푸짐한 왕만두 280g, 횡성 한우사골 400g

    VIPS 홈레스토랑 선물세트 2호 89,900원
    ▶ 오리지널 바비큐 폭립, 왕갈비 바비큐폭립, 단호박 찹스테이크, 척아이롤스테이크, 쉬림프로제 원팬 파스타, 미트라자냐

    VIPS 홈레스토랑 선물세트 4호 87,900원
    ▶ 오리지널 바비큐 폭립 600g, 스파이시 바비큐 폭립, 플랫아이언 스테이크, 쉬림프 로제 파스타, 미트 라자냐

    카드할인 30%
    한성 특3호세트 63,000원
    ▶ 골드 명란젓갈 300g, 창란젓갈 300g,오징어젓갈 300g
    
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    온가족 함께 즐기는 홈스토랑 선물세트

    ※ 선물용 쇼핑백 혹은 전용 보냉백과 함께 택배로 배송됩니다 ※상품권 및 즉시할인 제외
    
    사미헌 LA갈비&야채불고기 선물세트 57,500원
    ▶ LA갈비 500g×2팩, 야채불고기 400g

    사미헌 탕 선물세트 47,600원
    ▶ 사미헌 갈비탕 1kg×1팩, 맑은곰탕 500g×2팩, 아롱사태곰탕 500g×2팩

    사미헌 갈비탕 &소불고기 선물세트 49,000원
    ▶ 사미헌 갈비탕 1kg×2팩, 야채불고기 400g×2팩    

    사미헌 베스트 갈비탕 선물세트 68,000원 
    ▶ 갈비탕 1kg×5팩

    설성목장 명품 이동갈비 세트 129,000원
    ▶ 소 이동 본갈비 500g×2팩, 소 이동 한입 LA갈비 500g×2팩

    설성목장 이동본갈비 세트 64,900원
    ▶ 소 이동 본갈비 500g×2팩

    카드할인 30%
    더본코리아 본가 선물세트 105,000원
    ▶ 본가 소갈비찜 500g×4, 본가 소불고기 250g×6, 본가 너비아니 150g×6팩
    
    카드할인 30%
    더본코리아 빽쿡 선물세트 69,300원
    ▶ 인생 삼계탕 1.2kg×2, 본가 소갈비찜 500g×2, 본가 너비아니 150g×5
    
    경복궁 한우불고기 갈비 혼합세트 69,000원
    ▶ 한우 불고기 600g, 칼집 LA갈비 600g

    카드할인 5,000원 할인
    경복궁 명절 한상 선물세트 74,000원
    ▶ 칼집LA갈비 600g, 영양갈비탕 850g, 양념불고기 600g, 한우사골곰탕 600g
    
    경복궁 궁중 본갈비 선물세트 89,000원
    ▶ 궁중 본갈비 600g×2팩

    카드할인 6,000원 할인
    경복궁 따뜻한 국물 보양 선물세트 39,000원
    ▶ 한우 사골곰탕 600g×2팩,양곰탕 600g, 꼬리곰탕 600g
    
    경복궁 양념불고기 선물세트 49,000원 
    ▶ 양념불고기 600g×2팩

    경복궁 소갈비찜세트 89,000원
    ▶ 소갈비찜 800g×2팩

    카드할인 10,000원 할인
    경복궁 소양념 본갈비 칼집LA 특대세트 139,000원 
    ▶ 소양념 본갈비 580g×2팩

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    2025 설레는 그날
    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다! 건강하시고 행복 가득한 한해 되시길 기원합니다.

    선물하는 분과 받는 분의 품격을 높이는 주류 선물세트

    ※ 하기 이미지는 연출컷으로 실제 상품과 상이할 수 있습니다 ※온라인몰 상품 주문 시, 매장에서 픽업 수령 가능합니다
    디아지오 2024 스페셜릴리즈 8종 
    세계 최고 위스키 장인들이 엄선한 캐스크 스트랭스 한정 컬렉션을 만나보세요
    ※ 운영점에는 2024 스페셜 릴리즈 벤리네스/몰트락/로즈아일/오반 상품을 포함한 더 많은 상품이 준비되어 있습니다

    라가불린 12년 2024 스페셜릴리즈 220,800원
    ▶ 라가불린 12년 2024 스페셜릴리즈 700ml

    싱글톤 글렌오드 14년 2024 스페셜릴리즈 190,800원
    ▶ 싱글톤 글렌오드 14년 2024 스페셜릴리즈 700ml
    
    쿨일라 11년 2024 스페셜릴리즈 135,800원
    ▶ 쿨일라 11년 2024 스페셜릴리즈 700ml

    탈리스커 8년 2024 스페셜릴리즈 129,800원
    ▶ 탈리스커 8년 2024 스페셜릴리즈 700ml

    L.POINT 26,000원 할인 
    조니워커 그린 73,800원
    ▶ 조니워커 그린 700ml, 노징글라스 2개
    
    L.POINT 68,200원 할인 
    글렌리벳 15년 108,800원
    ▶ 더글렌리벳 15년 700ml, 디켄더 1개, 노징글라스 2개

    L.POINT 38,100원 할인 
    글렌 그란트 12년 71,800원
    ▶ 글렌그란트 12년 700ml,노징글라스 2개

    L.POINT 21,100원 할인 
    더 글렌드로낙 12년 93,900원
    ▶ 더 글렌드로낙 12년 700ml, 튤립잔2개, 온더락잔2개

    2묶음 세트
    레뱅 스페인 1호 세트 29,900원
    ▶ 아이모니아 레드 750ml, 아이모니아 화이트 750ml

    2묶음 세트
    칠성 프랑스 론 와인 2종 세트 39,900원
    ▶ 앙리 드 플로뢰 750ml, 셰리에 도르 750ml

    L.POINT 20%
    아영 이탈리아 인기와인 세트 43,920원
    ▶ 그랑 파씨오네 750ml, 유원프리미티보 디 만두리아 750ml

    L.POINT 10%
    나라 미국 덕혼 디코이 세트 89,910원
    ▶ 덕혼 디코이 까베르네 소비뇽 750ml,덕혼 디코이 메를로 750ml

    ※12/18(수)이후 매장 수령 가능
    화요 41% 테이스팅잔 패키지 46,960원
    ▶ 화요 41% 375ml×2병, 테이스팅 글라스 1개

    ※12/26(목)이후 매장 수령 가능
    L.POINT 5,020원 할인 
    화요 53% 청사 에디션  69,980원
    ▶ 화요 53% 청사 에디션 750ml

    북극곰의 눈물-전용 도자기잔 세트 24,900원
    ▶ 북극곰의 눈물 준마이 720ml, 전용 도자기잔 2개

    시메이 전용잔 패키지 21,500원
    ▶ 시메이 레드/화이트/블루 각 330ml, 시메이 전용잔 1개

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    식탁을 더욱 풍성하게!
    어묵&수제햄&바베큐 선물세트

    ※ 선물용 쇼핑백 혹은 전용 보냉백과 함께 택배로 배송됩니다
    고래사어묵 선물세트 3호 40,000원
    ▶ 고래바 65g×2, 통새우말이 90g, 치즈빵빵 80g, 떡말이 90g, 소시지말이 90g 外

    고래사어묵 선물세트 5호 60,000원
    ▶ 고래바 65g×2, 통새우말이 90g×2 치즈빵빵 80g,떡말이 90g, 새우봉어묵 150g, 크랩모아 140g 外

    고래사어묵 선물세트 7호 80,000원
    ▶ 용궁어묵 180g, 전복어묵 120g, 통새우말이 90g×2,치즈빵빵 80g×2, 떡말이 90g, 새우봉어묵 150g 外    

    고래사어묵 선물세트 10호 100,000원
    ▶ 통새우말이 90g×2, 치즈빵빵 80g×2, 떡말이 90g×2,도톰야채사각 130g, 고래사어묵면 우동 225g 外

    오뗄 수제햄 특선 1호 68,900원
    ▶ 바베큐 안심햄 300g, 화이트본레스햄 300g,차슈 300g, 부어스트에센 105g×2 外

    오뗄 수제햄 실속 1호 43,900원
    ▶ 바베큐안심햄 300g, 화이트본레스햄 300g,차슈 300g, 모타델라 300g, 부어스트에센 105g

    카드할인 10%
    건국햄 독일식 수제세트 1호 49,500원
    ▶ 비어슁켄 270g, 헌트 270g, 리오나 270g, 카바노치 200g×2, 복부어스트 160g×2개, 링부어스트 200g

    카드할인 10%
    건국햄 독일식 수제세트 특호 75,600원
    ▶ 필렛 350g, 누스햄 300g, 비어슁켄 270g, 헌트 270g, 리오나 270g,복부어스트 160g×2개, 레버케제 300g, 링부어스트200g

    카드할인 20%
    상하농원 수제햄&치즈 실속세트 47,920원
    ▶ 스틱비엔나 120g×2, 고구마프랑크 180g,그릴드 치즈 125g, 스틱치즈 50g×3

    카드할인 20%
    상하농원 햄공방 실속세트 79,200원
    ▶ 클래식비엔나 120g×2, 화이트비엔나 120g,스모크프랑크 180g, 치즈프랑크 180g 外

    카드할인 20%
    상하농원 수제 햄&치즈 고급세트 96,000원
    ▶ 클래식비엔나 120g, 화이트 비엔나 120g, 스모크 프랑크 180g, 스모크라운드햄 160g 外

    카드할인 10%
    존쿡 프리미엄 수제델리미트 세트 90,000원
    ▶ 훈제 장작 삼겹바베큐 350g, 훈제목살바베큐 200g, 텐더로인 350g,비어슁켄 300g, 모타델라 300g, 델리부어스트 160g, 데브리치너 160g, 트러블파테 100g, 잠봉LESS 100g×2, 쿠겔 500g, 홀그레인머스타드소스 190g 外

    카드할인 10%
    존쿡델리미트 브런치 세트 35,100원
    ▶ 킬바사 270g, 잠봉 100g, 치킨브레스트치즈베이글 160g,나트륨을줄인마일드베이컨 120g, 치킨&초리조토마토스프 480g, 브로첸150g

    카드할인 10%
    존쿡델리미트 시그니처 세트 49,500원
    ▶ 슈바인학센 650g, 브렉퍼스트소시지 210g,비프핫도그 300g, 킬바사 270g, 컨츄리베이컨 120g, 잠봉 100g, 맥앤치즈 200g, 핫도그번 165g

    카드할인 10%
    존쿡델리미트 바베큐 세트 71,100원
    ▶ 슈바인학센 650g, 바베큐백립 450g, 킬바사270g,스모크브랏 320g, 부랏부어스트 320g, 수비드포크밸리250g,컨츄리베이컨스테이크 80g, 바비큐풀드포크 150g

    카드할인 10%
    존쿡델리미트 하몽샤퀴테리 세트 71,100원
    ▶하 몽세보데캄포핸드컷 50g, 하몽세라노 그란 레세르바 120g, 코파 50g, 이탈리안살라미 50g, 카바노치 50g, 트러플치즈딥 200g, 크래커 120g, 올리브 290g, 미니잼 28g

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    2025 설레는 그날
    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다! 건강하시고 행복 가득한 한해 되시길 기원합니다.

    세련된 감각의 테이블 완성!

    치즈 청정 아일랜드 목초유 100%로 만든 구워먹는 치즈와, 바베끌레떼&전용 보냉백이 포함된 프리미엄 치즈 세트

    카드할인 15%
    앙투어 솔레 치즈 세트 56,100원
    ▶ 보스카 바베끌레떼, 앙투어 솔레 100g×3×3,앙투어솔레 보냉백

    카드할인 10%
    치즈 플레이트 세트 39,600원
    ▶ 보스카 치즈 보드S, 브리에뜨 크리미앤 마일드 125g, 아페리프레코트다쥐르 100g, 에스뿌나 살치촌 슬라이스 50g, 그린올리브 마리네이티드 60g, 고다 포션 인 네트 100g, 고메 스모크드 치즈 200g, 연성 치즈나이프

    카드할인 10%
    치즈&샤퀴테리 세트 32,400원
    ▶ 르 갈 크림치즈 플레인 150g, 베르기어 포션 믹스 120g, 포스 칼라마타 피티드 올리브 마리네이티드 60g, 마블스 과일치즈 멜론&망고 100g, 에스푸나 초리조 엑스트라 50g,
    에스뿌나 살치촌 슬라이스 50g, 미니 토스트 80g, 아그네스 스파게티 N.3 500g

    카드할인 10%
    치즈 패밀리 세트 45,000원
    ▶ 체리페퍼 위드 망고 크림치즈 280g, 엠보그 치즈 트라이앵글 140g, 파르네제 그라나 파다노 150g, 고메 스모크드 치즈 200g, 베르기어 포션믹스 120g,
    쁘띠 까망베르 125g, 그린 올리브 절임 200g, 연성 치즈나이프

    카드할인 10%
    와인 페어링 치즈 세트 49,500원
    ▶ 아페리프레 프로방스 100g, 파르네제 파르미지아노 레지아노 150g, 베르기어 포션 믹스 120g,
    쁘띠 브리 125g, 그린 올리브 절임 200g, APOLLO 과일치즈(메론/망고) 125g, 에스뿌나 초리조 슬라이스 50g, 아스뿌나 살치촌 슬라이스 50g, 연성치즈 나이프

    임실 치즈세트 1호 64,000원
    ▶ 임실생치즈 500g, 임실구워먹는치즈 125g×2, 임실 모짜렐라 슬라이스 치즈 100g×3,벨라유 포션치즈 120g×4(플레인/단호박/아몬드/양파)

    임실 스모크 치즈 특호 85,000원
    ▶ 임실 크림치즈 200g, 벨라유 포션치즈 120g×4(플레인/단호박/아몬드/양파), 임실 모짜렐라 슬라이스 치즈 100g×4, 임실 스트링 치즈 100g, 임실 구워먹는 치즈 250g, 임실 스모크 치즈 64g×2

    임실 치즈 선물세트 특호 95,000원
    ▶ 임실 크림치즈 200g, 벨라유 포션치즈 120g×4(플레인/단호박/아몬드/양파), 임실 모짜렐라 슬라이스 치즈 100g×4, 임실 구워먹는 치즈 250g×2,
    임실 스트링치즈 100g×2

    스타벅스

    카드할인 20%
    스타벅스 원두믹스 세트 1호 29,960원
    ▶ 다크 10T×3, 미디엄 10T×2입, 다크 30T, 화이트머그 370ml

    카드할인 20%
    스타벅스 드립백 선물세트 22,400원
    ▶ 드립백 하우스블렌드 5T×2입,드립백 파이크 플레이스 5T, 화이트 머그 370ml

    스타벅스 카페모먼트 선물세트 32,000원 
    ▶ 브라이트 1병, 스무스 1병, 글라스머그 370ml

    4+1
    스타벅스 원두믹스 세트 2호 20,990원
    ▶ 다크 10T×2입, 미디엄 10T×2입, 화이트머그 370ml

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    치즈&커피&차 선물세트

    L.POINT 34,000원 할인 
    네스카페 돌체구스토 지니오 S플러스 85,000원
    ▶ 네스카페 돌체구스토 지니오 S플러스 블랙머신1입

    10+1
    커피빈 실속 선물세트 13,700원
    ▶ 커피빈 캡틴 아메리카노 미니 10T×3개, 콜롬비아 아메리카노 미니 10T×3개
    
    1+1
    담터 허브티컬렉션 31,980원
    ▶ 마 테차, 누룽지둥굴레차, 우엉차, 국화차, 도라지차, 쟈스민차, 옥수수수염차, 루이보스, 페퍼민트, 캐모마일, 보이차, 히비스커스 각 5T

    1+1
    담터 모과도라지 쌍화스틱 49,000원
    ▶ 모과 도라지 쌍화스틱 10ml×50입

    카드할인 20%
    담터 꿀차 플러스 세트 29,920원
    ▶ 쌍 화차 플러스/생강차 플러스/대추차 플러스/모과도라지차 플러스 각 15T 아카시아벌꿀/야생화벌꿀 각 10입

    카드할인 20%
    녹차원 건강담은 홍삼차세트 18,320원
    ▶ 홍삼진골드 100ml×6병, 홍삼 배도라지 70ml×4포,녹용진 70ml×4포, 쌍화차 10포, 생강차 10포

    10+1
    녹차원 차다움세트 9,900원
    ▶ 꿀유자차 350g, 생강대추배차 7T,호두아몬드율무차 7T

    10+1
    녹차원 포시즌 베스트 세트 9,900원
    ▶ 쌍화차/생강차/캐모마일/페퍼민트/히비스커스/보이차 각 5T

    1+1
    녹차원 허브차 세트 24,900원
    ▶ 맛있는녹차 작설/캐모마일/루이보스/페퍼민트/ 얼그레이/쟈스민/스위트 후르츠/히비스커스 각 5 T

    L.POINT 20%
    초록원 액상차 3입세트 16,720원
    ▶ 유자차 580g, 생강차 560g, 제주한라봉차 560g

    카드할인 20%
    햄스테드 유기농티 선물세트 35,120원
    ▶ 오가닉 얼그레이, 잉글리스 브렉퍼스트, 페퍼민트&스피어민트, 카모마일, 레몬&진저, 웰, 로즈힙&히비스커스, 루이보스 각 7T

    5+1
    맥코이 파이니스트 프리미엄 티 콜렉션 36,000원
    ▶ 애플카라멜향티/초콜릿향 스트로베리티/얼그레이허니향티 각 2g×20T

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    우리 전통 한과 선물세트

    호정가
    5+1
    유영군 명인한과 1호 34,900원
    ▶ 유과(찹쌀/백년초/단호박/대잎),다식(단호박/백년초/대잎/콩),강정(쌀/백년초/치자/대잎/쌀아몬드/흑미), 약과(미니)

    10+1
    유영군 명인한과 2호 59,900원
    ▶ 유과(찹쌀/백년초/단호박/대잎), 정과(오렌지), 다식(단호박/백년초/대잎/콩) 外

    1+1
    창평한과 면앙정 69,900원 
    ▶ 유과(찹쌀/백년초/단호박), 다식(단호박/백년초/대잎/콩) 강정(쌀/백년초/치자/대잎/쌀아몬드/딸기/흑미), 약과(미니)

    7+1
    창평한과 식영정 49,900원
    ▶ 유과(찹쌀/백년초/단호박), 매작과(치자백년초),약과(미니), 강정(쌀/백년초/치자/대잎/쌀아몬드/딸기/흑미/흑미쌀/보리) 外

    7+1 
    창평한과 식영정 49,900원
    ▶ 유과(찹쌀/백년초/단호박), 매작과(치자백년초), 약과(미니), 강정(쌀/백년초/치자/대잎/쌀아몬드/딸기/흑미/흑미쌀/보리) 外
    
    7+1
    창평한과 송강정 70,000원
    ▶ 유과(찹쌀, 백년초, 단호박, 대잎), 매작과(치자백년초), 약과(미니), 강정(쌀, 백년초, 치자, 대잎, 참깨, 들깨, 땅콩) 外
    ※사전예약 한정 운영

    7+1
    창평한과 소쇄원 108,000원
    ▶ 유 과(찹쌀, 백년초, 단호박, 대잎), 강정(쌀, 치자, 백년초, 대잎, 딸기, 참깨, 들깨, 땅콩, 흑미쌀, 오란다), 다식(단호박, 백년초, 콩, 대잎) 外
    ※사전예약 한정 운영

    7+1
    창평한과 명옥헌 135,000원
    ▶ 유 과(찹쌀/백년초/단호박/대잎), 매작과(치자백년초/치자대잎),정과(오렌지/치자연근), 다식(단호박/백년초/대잎/콩), 조청산자 外
    ※사전예약 한정 운영

    화과방
    ※ 선물용 쇼핑백과 함께 택배로 배송됩니다
    ※상품권 및 즉시할인 제외
    L.POINT 10%
    화과방 더담아 생과자 1호 34,650원
    ▶ 밤, 자두, 살구, 병아리콩 4가지 앙금에 원물이 통째로 들어가 있는 프리미엄 생과자 세트

    ※ 선물용 쇼핑백과 함께 택배로 배송됩니다
    ※상품권 및 즉시할인 제외
    L.POINT 10%
    화과방 홍삼양갱 2호 29,250원
    ▶ 홍삼의 맛과 향이 그대로 담긴 프리미엄 양갱 세트

    ※ 선물용 쇼핑백과 함께 택배로 배송됩니다
    ※상품권 및 즉시할인 제외
    L.POINT 10%
    화과방 지현 양갱 3호 32,310원
    ▶ 흑임자, 흑당팥, 군고구마, 복분자, 쑥 5가지 맛으로 구성된 프리미엄 양갱 세트

    7+1
    호정 찹쌀유과세트 28,900원
    ▶ 찹쌀유과 500g×2입

    한입전병 선물세트 13,980원
    ▶ 흑임자 전병 50g x 5입, 김맛 전병 50g x 5입

    ※상품권 및 즉시할인 제외
    호정가 찹쌀약과 세트 1kg 12,900원
    ▶ 찹쌀약과 1kg

    넛쯔공방 오란다 선물세트 혼합 2호 24,000원
    ▶ 오 리지널 오란다 25g x 6입, 땅콩 오란다 28g x 6입 크랜베리 오란다 28g x 6입, 초코 오란다 28g x 6입

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    꿀&잼 선물세트

    카드할인 20%
    꽃샘 아카시아 야생화꿀 세트 47,920원
    ▶ 아카시아꿀 600g, 야생화꿀 600g

    L.POINT 25%
    프리미엄 고가구 아카시아꿀 세트 74,250원
    ▶ 아카시아 꿀 1.2kg

    카드할인 20%
    꽃샘 밤꿀세트 54,400원
    ▶ 밤꿀 1.2kg

    ※상품권 및 즉시할인 제외
    L.POINT 20%
    한국양봉 강녕 아카시아꿀 스틱세트 23,920원
    ▶ 아카시아꿀 12g×30입

    L.POINT 20%
    한국양봉 제주 야생화꽃 꿀스틱세트 23,920원
    ▶ 야생화꽃꿀 12g×30입

    L.POINT 20%
    비담 허니스틱 3구 선물세트 39,000원
    ▶ 야생화꿀 10g×30입, 아카시아꿀 10g×15입

    L.POINT 25%
    한국양봉 강녕 자연꿀 3종 세트 26,250원
    ▶ 아 카시아꿀, 때죽나무꿀, 피나무꿀 각 12g×10입

    L.POINT 40%
    콤비타 UMF 5+ 마누카꿀&클로바꿀 세트 51,600원
    ▶ UMF 5+ 마누카꿀 250g, 클로바꿀 250g

    L.POINT 10%
    23.5 천연 벌집꿀 세트 31,500원
    ▶ 벌집꿀 밀납포함 250g, 국산

    L.POINT 20,000원 할인
    23.5 천연꽃꿀 선물세트 79,900원
    ▶ 아카시아, 야생화, 밤꿀 각 500g

    카드할인 10%
    샹달프 미니잼 12종세트 17,900원
    ▶ 딸기/블루베리/크랜베리/위드블루베리 外 9종

    카드할인 10%
    퀸즈트리 피넛버터 3종 세트 23,100원
    ▶ 퀸즈트리 피넛버터 크런치 2개, 크리미 1개

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    1~2만원대 선물세트

    10+1
    동원 양반김 M10호 16,900원
    ▶ 구운 파래김 20g×3봉 ,들기름 식탁김 4g×12봉
    
    10+1
    CJ 비비고 토종김 1호 16,900원
    ▶ 담백 구운김 전장 10g×3봉 비비고 참기름 도시락김 4g×12봉

    L.POINT 10,000원 할인
    6년근 실속 금산인삼세트 29,900원
    ▶ 6년근 인삼 350g(난발),국산

    L.POINT 30%
    동원 양반김 M20호 25,970원
    ▶ 들기름 전장김 20g×6봉 , 침치액(진)900g

    L.POINT 30%
    CJ 비비고 토종김 4호 25,130원
    ▶ 비비고 참기름김 20g×4봉×2EA ,카놀라유 900ml

    L.POINT 3,000원 할인
    대천 곱창 도시락김 세트 22,900원
    ▶ 곱창 식탁김 5g×54봉

    L.POINT 30%
    동원 양반김 M30호 29,960원
    ▶ 양반 더 바삭한김 25g×6봉 , 살코기 참치 90g×6캔, 요리유 480ml

    L.POINT 30%
    CJ 비비고 토종김 S-1호 29,120원
    ▶ 담백 구운김 전장10g×4봉×2EA , 죽염 돌김 캔 50g×2EA

    L.POINT 2,000원 할인
    대천 명품 캔김 세트 6캔 29,900원
    ▶ 곱창김 30g×6캔

    L.POINT 30%
    CJ 비비고 토종김 복합 2호 29,330원
    ▶ 직화 참기름김 전장 20g×6입 , 스팸 120g×4입 , 카놀라유 500ml
    
    L.POINT 30%
    CJ 비비고 직화구이김 혼합 1호 29,960원
    ▶ 직화 참기름김 전장 20g×3 , 직화 참기름김 캔 25g× 2 , 직화 참기름김 도시락 4.5g×8

    L.POINT 5,000원 할인
    환경을 생각하는 ASC인증 기장미역 세트 24,900원
    ▶ ASC 인증 기장 실미역 100g×2

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    행사카드 구매금액별 최대 120만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    종근당건강 락토핏 골드 더블 세트 21,800원
    ▶ 50포×2입

    종근당건강 콘드로이친 세트 27,900원
    ▶ 850mg×30캡슐×3입

    L.POINT 50%
    CMG제약 본래원 흑염소진액 24,900원
    ▶ 50ml×30포

    L.POINT 13,000원
    뉴트리원 비비랩 NFC착즙 유기농 레몬즙 세트 23,900원
    ▶ 20g×10포×3입

    카드할인 30%
    정관장 데워먹는 홍삼원 18,900원
    ▶ 120ml×12포

    L.POINT 4,000원
    키위 혼합 25,900원
    ▶ 해금골드키위 9입(1.0kg 내외), 참다래 9입(1.0kg 내외), 국산

    1+1
    아내의 착즙주스 배도라지즙/사과즙/당근사과즙/감귤사과즙/착즙하는남자 양배추 사과즙 각29,900원
    ▶ 각 100ml×21입 ※교차구매 가능

    듀얼 카스텔라 선물세트 18,900원
    ▶ 벌꿀 카스텔라(1입), 녹차 카스텔라(1입) , 원산지 별도표기
    ※ 온라인몰 전점 운영 , 오프라인 베이커리팩토리/쁘띠르뽀미에/풍미소 입점점 운영

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    1~2만원대 선물세트

    동원
    10+1
    동원 포도씨유 혼합 23호 15,900원
    ▶ 카놀라유 480ml×2, 포도씨유 480ml
    
    카드할인 30%
    동원 프리미엄 M70호 19,950원
    ▶ 더바삭한 전장김 25g×3봉, 살코기참치 135g×3, 건강요리유 480ml

    카드할인 30%
    동원 프리미엄 1호 19,950원
    ▶ 리챔 120g, 살코기참치 90g, 사과식초 500ml, 올리고당 700g, 카놀라유 480ml, 건강요리유 480ml

    카드할인 30%
    동원 프리미엄 55호 19,950원
    ▶ 살코기참치 90g x 2, 카놀라유 480ml,참기름 160ml, 구운소금 210g, 올리고당 700g

    카드할인 30%
    동원 스페셜 2호 19,950원
    ▶ 살코기참치 90g×4, 리챔 더블라이트 120g×2, 건강요리유 480ml×2

    카드할인 30%
    동원 스페셜 1호 25,970원
    ▶ 살코기참치 135g×2, 리챔 120g×4, 건강요리유 480ml×2

    카드할인 30%
    동원 스페셜 5호 29,890원 
    ▶ 살코기참치 90g×6, 리챔 120g×3, 카놀라유 480ml×2

    카드할인 30%
    동원 프리미엄 62호 29,960원
    ▶ 살코기참치 90g x 5, 리챔 120g x 2, 동원 참치액진 240g, 참기름 75ml, 카놀라유 480ml x 2

    카드할인 30%
    동원 스페셜 49호 29,960원
    ▶ 살코기참치 90g×4, 리챔 더블라이트 120g×4, 참기름 330ml

    청정원
    10+1
    청정원 고급유 6호 13,900원
    ▶ 카놀라유 500ml×2, 요리유 500ml

    카드할인 30%
    청정원 스페셜S호 19,950원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2, 진간장골드 500ml, 요리올리고당 700g, 사과식초 500ml

    카드할인 30%
    청정원 L1호 22,260원
    ▶ 카 놀라유 500ml, 옥수수유 500ml, 잇츠팜 115g x 2, 맛술 생강&매실 410ml, 요리올리고당 700g

    카드할인 30%
    청정원 L2호 24,360원
    ▶ 카놀라유 500ml, 잇츠팜 115g×4, 옥수수유 500ml, 올리고당 700g

    카드할인 30%
    청정원 L6호 27,860원
    ▶ 카놀라유 500ml×2, 우리팜델리 115g×4, 올리고당 700g, 어간장 410ml

    카드할인 30%
    청정원 L7호 29,960원
    ▶ 카 놀라유 500ml, 잇츠팜 115g x 5, 고소한 건강생각 참기름 160ml, 천일염 가는소금 190g, 요리올리고당 700g

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    행사카드 구매금액별 최대 120만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음
    
    씨제이 제일제당

    씨제이 백설 프리미엄 5호 12,900원
    ▶ 바삭한요리유 500ml×2, 해바라기씨유 500ml

    10+1
    씨제이 백설 프리미엄 14호 15,900원 
    ▶ 카놀라유 500ml×2, 해바라기씨유 500ml

    5+1
    씨제이 백설 프리미엄 23호 18,900원
    ▶ 카놀라유 500ml×2, 건강한요리유 500ml×2

    씨제이 특별한선택 I호 19,900원
    ▶ 카놀라유 500ml, 요리올리고당 700g, 사과식초 500ml, 스팸 120g x 2, 맛술 생강 500ml

    카드할인 30%
    씨제이 특별한선택 K-4호 21,630원
    ▶ 카놀라유 500ml×2, 올리고당 500g, 스팸 120g×4

    카드할인 30%
    씨제이 특별한선택 E-1호 24,990원
    ▶ 카놀라유 500ml, 스팸 200g x 3, 요리올리고당 500g, 육수엔1분링 80g x 2(사골, 멸치)

    5+1
    씨제이 고소함 가득 참기름 A호 26,900원
    ▶ 고소함가득참기름 330ml×2, 천일염 170g×2

    카드할인 30%
    씨제이 특별한선택 L-3호 28,630원
    ▶ 카놀라유 500ml×2, 요리올리고당 700g, 스팸 120g×6

    카드할인 30%
    씨제이 특별한선택 K-2호 29,330원
    ▶ 카놀라유 500ml, 요리올리고당 700g, 스팸 200g x 2, 스팸 라이트 200g x 2, 스팸 120g x 2

    카드할인 30%
    씨제이 특별한선택 L-1호 29,330원
    ▶ 카 놀라유 500ml x 2, 사과식초 500ml, 요리올리고당 700g, 스팸 200g, 육수엔1분링(멸치) 60g, 맛술 생강 500ml, 백설 참치액 500ml

    1+1
    씨제이 백설 육수에는 1분링 세트 29,900원
    ▶ 육수에는1분링 멸치디포리 80g×2, 사골 80g

    사조

    사조 고급유 3호 11,900원
    ▶ 카놀라유 500ml×3

    카드할인 30%
    사조 안심특선 92호 19,950원
    ▶ 사 과식초 500ml, 안심팜 115g x 2, 참기름 55ml, 바삭요리유 500ml, 구운소금 200g, 맛술 500ml

    카드할인 30%
    사조 안심특선 95호 25,900원
    ▶ 카 놀라유 500ml, 바삭요리유 500ml, 살코기참치 85g x 4, 안심팜 115g x 2, 사과식초 500ml, 맛술 500ml

    카드할인 30%
    사조 안심특선 94호 26,530원
    ▶ 카 놀라유 500ml, 사과식초 500ml, 안심팜 115g x 2, 구운소금 200g, 진간장 500ml, 맛술 500ml, 참기름 55ml, 올리고당 700g

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    1~2만원대 선물세트

    동서 & 담터

    5+1
    카누 아메리카노 8호 28,400원
    ▶ 카누미니 마일드 10T× 6개, 카누미니 다크 10T×2개, 카누미니 라이트 10T× 2개, 투게더머그

    5+1
    카누 블렌드 7호 24,000원
    ▶ 카누 더블샷 라떼 10T, 카누 라떼 10T, 카누 티라미수 라떼 8T,카누 바닐라 라떼 8T, 카누 미니 마일드 10T×2입

    카드할인 30%
    담터 다정 종합세트 19,320원
    ▶ 호두아몬드율무차, 단호박마차, 쌍화차플러스 생강차플러스, 대추차플러스 각 15T

    카드할인 30%
    담터 다담 종합세트 24,850원
    ▶ 호두아몬드율무차, 단호박마차, 쌍화차플러스, 생강차플러스 각 15T, 캐모마일 15T, 꿀유자차 770g

    카드할인 10%
    담터 정담라이트 20,880원
    ▶ 호두아몬드율무차 18g× 50T,쌍화차라이트 15g × 15T, 생강차라이트 15g × 15T

    양말선물세트

    1+1
    유렉시아 명화 양말 세트 각6,900/8,900원
    ▶ 2매/3매

    1+1
    헤드/휠라 양말 세트 각7,900원
    ▶ 2매

    5+1 카드할인 30%
    피에르가르뎅 다이아 양말 세트 각6,230원 
    ▶ 3매

    5+1 카드할인 30%
    휠라 캐주얼 자수 양말 세트 각6,930원
    ▶ 3매

    5+1 카드할인 30%
    네파 스포츠 양말 세트 각6,930원
    ▶ 3매

    5+1 카드할인 30%
    컬럼비아 중목 양말 세트 각7,630원
    ▶ 3매

    5+1 카드할인 30%
    네파 남녀 스포츠 양말 세트 10,430원
    ▶ 6매

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    행사카드 구매금액별 최대 120만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    일상용품 세트

    엘지 프로폴리스 기프트 프리미엄 9,990원
    ▶ 페리오 프로폴리스 치약 90g×4, 온더바디 프로폴리스 비누 80g×2 外

    10+1
    애경 리미티드 셀렉션 11호 11,900원
    ▶ 케라시스 데미지 클리닉 샴푸 400ml, 샤워메이트 유자 바디워시 400ml 外

    10+1
    엘지 생활의 품격 1호 13,900원
    ▶ 엘라스틴 10X 볼륨 샴푸/컨디셔너 200ml, 오가니스트 체리블라썸 바디워시 200g 外

    카드할인 20%
    아모레퍼시픽 섬김 1호 19,920원
    ▶ 해피바스 오리지널 컬렉션 라벤더 바디워시 490g, 려 함빛 손상&영양 셀렉션 샴푸 400ml 外

    카드할인 60%
    아모레퍼시픽 해피바스 플라워테라피 바디 기프트 19,960원
    ▶ 해피바스 플라워 바디워시 라벤더 760g×2, 해피바스 플라워 바디워시 프리지아 760g×2 外

    카드할인 60%
    엘지 정성담은 프리미엄 1호 21,560원
    ▶ 엘라스틴 데미지케어 10X 샴푸 200ml×2,오가니스트 바디워시 라벤더향 190ml×2 外

    카드할인 30%
    엘지 생활의 품격 4호 21,630원
    ▶ 닥터그루트 집중케어 샴푸 180ml, 오가니스트 수분샴푸 200ml, 오가니스트 바디워시 라벤더향 190ml×2 外

    L.POINT 50%
    엘지 생활의 품격 기프트 3호 24,950원
    ▶ 닥터그루트 샴푸 180ml, 오가니스트 샴푸 200ml 오가니스트 바디워시 200g×2 外

    카드할인 50%
    아모레퍼시픽 섬김 2호 25,950원
    ▶ 해피바스 오리지널컬렉션 라벤더 바디워시 490g 려 흑운 두피&볼륨 셀렉션 샴푸 400ml×2 外

    카드할인 30%
    엘지 생활의 품격 5호 26,530원
    ▶ 닥터그루트 집중케어 샴푸/컨디셔너 180ml, 리엔 자윤 중건성용 샴푸 200ml×2 外

    1+1
    케라시스 퍼퓸셀렉션 26호 26,900원
    ▶ 케라시스 러블리 퍼퓸샴푸 400ml×2 케라시스 러블리 퍼퓸 린스 400ml, 샤워메이트 비누80g×3

    카드할인 50%
    아모레퍼시픽 섬김3호 29,900원 
    ▶ 해피바스 오리지널 컬렉션 라벤더 바디워시 490g, 려 함빛 손상&영양 셀렉션 샴푸 400ml 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    3~4만원대 선물세트

    L.POINT 10,000원 할인
    깨끗이 씻어나온 GAP 사과 39,900원
    ▶ 사과 11~12입(3.0kg 내외), 국산

    L.POINT 10,000원 할인
    샤인머스캣 2.5kg 49,900원
    ▶샤인머스캣 (2.5kg 내외), 국산

    L.POINT 10,000원 할인
    미국산 오렌지 39,900원
    ▶오렌지 12입 (3.6kg 내외), 미국산

    L.POINT 10,000원 할인
    이스라엘 자몽 39,900원
    ▶자몽 12입 (4.8kg 내외), 이스라엘산

    L.POINT 10,000원 할인
    프리미엄 정과 & 스무스 세트 49,900원
    ▶ 호두정과 250g, 피칸정과 250g, 아몬드 스프레드 스무스 400g, 피넛버터 스무스 400g, 원산지: 별도표기

    L.POINT 30%
    동원 양반 돌김세트 39,970원 
    ▶ 돌김 30g×8캔

    L.POINT 20%
    동원 양반김 M6호 32,960원
    ▶ 더 바삭한 전장김 25g×6봉 , 돌김 30g×2캔

    L.POINT 30%
    CJ 초사리 곱창돌김 1호 39,830원
    ▶ 초사리 곱창돌김 전장 20g×3봉 , 초사리 곱창돌김 25g×2캔 , 초사리 곱창돌김 도시락김 5g×8봉

    L.POINT 30%
    동원 양반김 M40호 44,940원
    ▶ 들기름 전장김 20g×6봉, 돌김 30g×2캔, 살코기 참치 135g×8캔

    L.POINT 30%
    동원 감태김 M11호 34,230원
    ▶ 감태김 전장 20g×3 , 감태김 캔 30g×2 , 감태김 식탁 4g×8

    L.POINT 30%
    CJ 비비고 감태김·초사리 곱창돌김 세트 49,980원
    ▶ 초사리 전장김20g×6봉 , 감태김 25g×4캔 , 감태 도시락김 4g×4봉

    L.POINT 30%
    비비고 감태김 혼합 1호 34,230원
    ▶ 감태김 전장 20g×3봉 , 감태김 25g×2캔 , 감태 도시락김 4g×8봉

    L.POINT 10,000원 할인 
    인삼, 꿀 혼합세트 39,900원
    ▶ 인삼 500g, 홍삼꿀 550g, 대추 30g, 도라지30g, 국산

    카드할인 10,000원 할인
    깊은향 더덕 49,900원
    ▶ 더덕 1kg, 뿌리당 30~50g, 국산

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    행사카드 구매금액별 최대 120만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    1+1
    서울약사신협 홍삼액진 39,000원
    ▶ 70ml×30포

    1+1
    서울약사신협 산삼배양근진액 39,000원
    ▶ 70ml×30포

    1+1
    서울약사신협 생녹용진액 39,000원
    ▶ 70ml×30포

    1+1
    엘지 리튠 흑마늘진액 골드 49,900원
    ▶ 50ml×24포

    1+1
    농협 한삼인 홍삼본플러스 49,800원
    ▶ 50ml×30포

    1+1
    농협 한삼인 홍삼환골드 49,800원
    ▶ 3.75g×30환

    L.POINT 5,000원 할인
    성경 녹차곱창돌김세트 39,900원
    ▶ 녹차곱창돌김 캔김 30g×4캔 , 녹차곱창돌김 전장김 20g×4봉

    L.POINT 40%
    리얼스테이크 육포세트 35,940원
    ▶ 160g×3봉 , 외국산 우육

    L.POINT 7,000원 할인
    코주부 프리미엄 육포세트 2호 32,900원
    ▶ 40g×7봉 , 외국산 우육

    L.POINT 12,000원 할인
    궁 쇠고기육포 선물세트 47,900원
    ▶ 70g×6봉 , 외국산 우육

    L.POINT 30%
    CJ 제일명인 정통 육포세트 48,230원
    ▶ 70g×6봉 , 외국산 우육

    L.POINT 16,000원 할인
    유명산지 통영여수 멸치세트 43,900원
    ▶ 볶음용멸치 240g , 볶음조림용 멸치 220g ,조림용 멸치 210g , 국물용 멸치 210g, 국산

    L.POINT 15,000원 할인
    청정곳간 유명산지 통영 멸치세트 41,900원
    ▶ 어린이멸치 230g , 볶음용멸치 230g , 조림용멸치 210g , 국물용멸치 210g, 국산

    L.POINT 15,000원 할인
    유명산지 멸치·견과 혼합세트 41,900원
    ▶ 볶음용 220g , 볶음조림용 100g , 조림용 100g , 호두 130g , 볶음 아몬드 170g, 멸치 : 국산 / 호두, 볶음아몬드 : 미국산

    L.POINT 15,000원 할인
    청정곳간 유명산지 멸치·새우 혼합세트 41,900원
    ▶ 볶음용멸치 140g , 볶음조림용멸치 170g , 국물용멸치 170g , 두절 꽃새우 80g, 멸치 : 국산, 새우 : 국산

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    3~4만원대 선물세트

    동원

    10+1 카드할인 20%
    동원 스페셜 47호 31,920원
    ▶ 살코기참치 90g x 9, 카놀라유 480ml x 2

    카드할인 30%
    동원 튜나리챔 30호 34,930원
    ▶ 살코기참치 90g×12, 리챔 더블라이트 120g×3

    카드할인 30%
    동원 프리미엄 60호 34,930원
    ▶ 살코기참치 90g x 9, 동원 참치액진 240g, 참기름 75ml x 2, 건강요리유 480ml x 2

    카드할인 30%
    동원 프리미엄 45호 34,930원 
    ▶ 살코기참치 90g x 5, 참기름 160ml, 카놀라유 480ml, 건강요리유 480 ml, 올리고당 700g, 참치액 240g, 구운소금 210g, 사과식초 500ml

    L.POINT 20%
    동원참치 M14호 38,960원
    ▶ 살코기참치 90g×4, 135g×10

    카드할인 30%
    동원 리챔 G9호 38,990원
    ▶ 리챔 200g×9

    카드할인 20%
    동원 튜나리챔 64호 39,920원
    ▶ 고추참치 90g×6, 살코기참치 135g×6, 리챔 120g×4

    카드할인 30%
    동원 스페셜 38호 39,970원
    ▶ 살코기참치 135g x 6, 리챔 더블라이트 200g x 4, 리챔 120g x 4, 건강요리유 480ml x 2

    카드할인 30%
    동원 스페셜 S호 39,970원
    ▶ 살코기참치 135g x 8, 리챔 340g x 3, 건강요리유 480ml x 2

    L.POINT 30%
    동원 프리미엄 20호 40,950원
    ▶ 살코기참치 90g x 12, 리챔 120g x 4, 동원 참치액진 240g x 2, 건강요리유 480ml x 2

    카드할인 20%
    동원참치 T14호 42,960원
    ▶ 살코기참치 135g x 10, 고추참치 135g x 4

    1+1
    동원 리챔 더블라이트 M9호 49,900원
    ▶ 리챔 더블라이트 120g x 3, 리챔 더블라이트 200g x 6

    카드할인 30%
    동원 튜나리챔 100호 49,910원
    ▶ 살코기참치 135g×12, 리챔 200g×4

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    행사카드 구매금액별 최대 120만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    씨제이 제일제당

    카드할인 30%
    씨제이 스팸복합 1호 33,530원
    ▶ 카놀라유 500ml×2, 스팸 200g×6

    카드할인 30%
    씨제이 스팸복합 5호 33,530원
    ▶ 카놀라유 500ml×2, 스팸 120g×9

    카드할인 30%
    씨제이 특별한선택 스페셜 A호 34,930원
    ▶ 카놀라유 500ml, 바삭한요리유 500ml, 사과식초 500ml, 천일염 170g, 구운소금 180g, 스팸 200g x 4, 참치액 진 500ml, 올리고당 500g

    3+1
    씨제이 백설 고소함가득 참기름 2호 35,900원
    ▶ 고소함가득 참기름 450ml, 참기름(병) 320ml×2

    카드할인 30%
    씨제이 특별한선택 스페셜 S호 38,430원
    ▶ 카놀라유 500ml x 2, 고소함가득참기름 330ml, 천일염 170g, 구운소금 180g, 스팸 200g x 5

    카드할인 30%
    씨제이 스팸복합 S호 39,830원
    ▶ 카놀라유 500ml x 2, 스팸 200g x 5, 스팸 340g x 2, 스팸 120g x 2

    카드할인 30%
    씨제이 특별한선택 L-2호 40,530원
    ▶ 카놀라유 500ml x 2, 요리올리고당 700g, 스팸 200g x 6, 스팸 120g x 4

    카드할인 30%
    씨제이 특별한선택 N호 41,230원
    ▶ 카놀라유 500ml×2, 고소함가득참기름 330ml, 스팸 200g×7

    카드할인 20%
    씨제이 스팸 8K호 41,520원
    ▶ 스팸 200g×6, 스팸 라이트 20

    2+1
    씨제이 특별한선택 K호 43,900원
    ▶ 카놀라유 500ml , 바삭한요리유 500ml, 요리올리고당 700g x 2, 스팸 라이트 120g x 2, 스팸 120g x 4

    카드할인 30%
    씨제이 특별한선택 JH호 44,730원
    ▶ 카놀라유 500ml x 2, 요리올리고당 500g, 스팸 200g x 6, 스팸 120g x 4, 올리고당 500g

    카드할인 30%
    씨제이 특별한선택 L-5호 49,630원
    ▶ 카놀라유 500ml x 2, 해바라기씨유 500ml, 스팸 200g x 8, 스팸 120g x 4

    L.POINT 30%
    씨제이 스팸복합 K호 49,910원
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 120g×6

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    3~4만원대 선물세트

    청정원

    5+1
    청정원 맛선생 국물 티백 세트 37,000원
    ▶ 맛선생 국물내기티백 멸치 10g×6, 해물 9g×6, 야채 6g×6

    10+1
    청정원 맛선생 한알육수 세트 40,000원
    ▶ 국물내기 한알 멸치디포리 100g, 야채 100g, 사골 87.5g

    10+1 카드할인 30%
    청정원 스페셜 S2호 36,260원
    ▶ 카놀라유 500ml x 2, 잇츠팜 115g, 맛술 생강&매실 410ml, 요리올리고당 700g, 소갈비양념 280g, 정통현미식초 470ml, 진간장골드 500m

    10+1 카드할인 30%
    청정원 L8호 37,660원
    ▶ 카놀라유 500ml, 맛선생 참치액 560g, 잇츠팜 115g x 6, 정통사과식초 470ml, 요리올리고당 700g

    카드할인 30%
    청정원 L9호 39,760원
    ▶ 카놀라유 500ml x 2, 잇츠팜 115g x 2, 잇츠팜 190g x 2, 우리팜델리 330g x 2, 요리올리고당 700g

    카드할인 30%
    청정원 L10호 43,960원
    ▶ 카놀라유 500ml, 잇츠팜 115g x 4, 맛선생 참치액 560g, 정통사과식초 470ml, 요리올리고당 700g, 우리팜델리 115g x 2, 순살참치 135g x 2

    카드할인 30%
    청정원 L11호 46,760원
    ▶ 카놀라유 500ml x 2, 잇츠팜 115g x 4, 우리팜델리 115g x 8, 요리올리고당 700g

    카드할인 30%
    청정원 L12호 49,560원
    ▶ 순 살참치 135g x 5, 카놀라유 500ml x 2, 정통사과식초 470ml, 요리올리고당 700g, 우리팜델리 115g, 참기름 160ml, 천일염가는소금 190g

    사조

    카드할인 30%
    사조 안심특선 99호 34,300원 
    ▶ 살코기참치 135g×6, 살코기참치 85g×6, 안심팜 115g×4

    카드할인 30%
    사조 안심특선 98호 34,720원
    ▶ 카 놀라유 500ml, 바삭요리유 500ml, 참치액 500g, 사과식초 500ml, 구운소금 200g, 살코기참치 85g x 5, 안심팜 115g x 2

    카드할인 30%
    사조 살코기참치 6호 38,850원 
    ▶ 살코기참치 135g x 15

    녹차원

    1+1
    녹차원 액상차 세트 32,900원
    ▶ 꿀유자차 350g, 홍삼담은 레몬차 350g , 꿀자몽차 350g, 홍삼담은 석류차 350g, 통곡물율무 5T, 통곡물마차 5T

    1+1
    녹차원 정성가득 홍삼차세트 39,900원
    ▶ 홍삼진골드 4병, 꿀유자차 300g, 꿀레몬차 300g, 홍삼배도라지 4포 * 2입, 작두콩차 5입, 호박팥차 5입

    1+1
    녹차원 홍삼 배도라지 세트 37,900원
    ▶ 홍삼배도라지 70 ml×24입

    1+1
    녹차원 녹용진 세트 37,900원
    ▶ 녹용진 70 ml×24입

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    행사카드 구매금액별 최대 120만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    1+1
    엘지 보타닉 퍼퓸컬렉션 30,900원
    ▶ 엘라스틴 보타닉 퍼퓸 샴푸/컨디셔너 480ml 벨먼 보타닉 퍼퓸 바디워시 480ml 外

    카드할인 30%
    엘지 생활의 품격 7호 31,430원
    ▶ 닥터그루트 집중케어 샴푸/컨디셔너 180ml, 오가니스트 바디워시 라벤더향 190ml×2 外

    카드할인 30%
    엘지 히말라야 핑크솔트 로즈에디션 32,830원
    ▶ 히말라야 핑크솔트 로즈 샴푸 380ml×2, 히말라야 핑크솔트 로즈 바디워시 380ml×2 外

    카드할인 50%
    아모레퍼시픽 섬김 4호 33,900원
    ▶ 해피바스 오리지널 컬렉션 라벤더 바디워시 490g, 려 함빛 손상&영양 셀렉션 샴푸 400ml×2 外

    1+1
    엘지 샴푸&바디세트 프리미엄 33,900원
    ▶ 엘라스틴 데일리 리페어링 샴푸 400ml×2, 온더바디 보타닉 레몬바디워시 480g 外

    1+1
    애경 내추럴밀크 33호 33,900원
    ▶ 샤 워메이트 산양유 바디워시 400ml×3 케라시스 오트 밀크 샴푸 400ml, 산양유 비누 80g×3

    2+1
    엘지 정성담은 36호 36,600원
    ▶ 닥터그루트 밀도케어 샴푸 190ml, 오가니스트 바디워시 190ml×2 外

    1+1
    애경 프로폴리스 36호 36,900원
    ▶ 케라시스 로얄 프로폴리스 샴푸 400ml×2, 샤워메이트 프로폴리스 바디워시 400ml 外

    카드할인 30%
    엘지 히말라야 핑크솔트 프리미엄 37,730원
    ▶ 히말라야 핑크솔트 샴푸 500ml, 벨먼스파 핑크솔트 클렌징폼 200ml 外

    카드할인 30%
    엘지 정성담은 모던 클래식 40,530원
    ▶ 오가니스트 모던 샴푸 400ml×2, 온더바디 모던 바디워시 레몬향 385ml 外

    1+1
    엘지 생활의 감동 모던 라이프 41,900원
    ▶ 오가니스트 모던 샴푸 400ml, 온더바디 모던 바디워시 레몬향 385ml 外

    카드할인 25%
    아모레퍼시픽 섬김 5호 43,425원
    ▶ 해피바스 오리지널컬렉션 라벤더 바디워시 490g, 려 함빛 손상&영양 셀렉션 샴푸 400ml×2 外

    3+1 카드할인 30%
    엘지 보타닉 퍼퓸 플라워페스타 44,030원
    ▶ 엘라스틴 보타닉 퍼퓸 샴푸 480ml×2, 온더바디 보타닉 퍼퓸 바디워시 480ml 外

    1+1
    엘지 프로폴리스 라이프 45,900원
    ▶ 엘라스틴 프로폴리스 샴푸/컨디셔너 385ml, 벨먼 프로폴리스 바디워시 190ml 外

    L.POINT 30%
    엘지 생활의 품격 9호 46,830원
    ▶ 리엔 자윤 중건성 샴푸/컨디셔너 400ml 닥터그루트 집중케어 샴푸 400ml 外

    1+1
    엘지 생활의 감동 바디세트 라벤더 49,900원
    ▶ 온더바디 더 내추럴 바디워시 라벤더 500g×2 온더바디 더 내추럴 바디로션 라벤더 400ml 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    5~6만원대 선물세트

    L.POINT 4,000원 할인
    전주 한옥토 배 65,900원
    ▶ 배 6~8입(5.0kg 내외), 국산

    L.POINT 10,000원 할인
    보은사과 59,900원
    ▶ 사과 14~16입(4.5kg 내외), 국산

    L.POINT 10,000원 할인
    샤인머스캣 4kg 69,900원
    ▶ 샤인머스캣 4.0kg 내외, 국산

    L.POINT 10,000원 할인
    대봉시 곶감 59,900원
    ▶ 대봉시 18입(1.4kg 내외), 국산

    L.POINT 10,000원 할인
    유명산지 더덕,산마 세트 59,900원
    ▶ 더덕 600g, 산마 1.4kg

    L.POINT 10,000원 할인
    툰드라 차가버섯 69,900원
    ▶ 차가버섯(러시아산) 1kg,러시아

    3+1
    홍대감 채끝살 육포세트 54,900원
    ▶ 60g×5봉 , 외국산 우육

    3+1
    코주부 우리소 육포세트 69,900원
    ▶ 55g×7봉 , 국내산 우육

    3+1
    코주부 프리미엄 육포세트 4호 59,900원
    ▶ 50g×8봉 , 외국산 우육

    3+1
    동원 쇠고기 육포 선물세트 65,900원
    ▶ 60g×6봉 , 외국산 우육

    L.POINT 20%
    CJ 제일명인 한우 육포세트 69,920원
    ▶ 한우 육포 70g×6봉

    카드할인 20%
    정관장 홍삼원기 54,400원
    ▶ 50ml×60포

    카드할인 25%
    정관장 홍삼진활력 54,750원
    ▶ 35ml×30포

    L.POINT 50%
    CMG제약 본래원 관절 활력진환 64,500원
    ▶ 4g×40환

    1+1
    씨제이 자연건강 석류콜라겐 젤리 57,800원
    ▶ 10g×40포

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    행사카드 구매금액별 최대 120만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    카드할인 30%
    동원 스페셜 102호 52,990원 
    ▶ 살코기참치 135g×9, 리챔 더블라이트 120g×8, 건강요리유 480ml×2

    카드할인 30% 
    동원 튜나리챔 102호 54,950원
    ▶ 살코기참치 135g×9, 리챔 200g×8

    L.POINT 25%
    동원 튜나리챔 11호 59,850원
    ▶ 살코기참치 135g×12, 고추참치 90g×8, 리챔 더블라이트 200g×4

    L.POINT 20%
    동원참치 T20호 59,920원
    ▶ 살코기참치 135g x 12, 고추참치 135g×8

    카드할인 20%
    동원 튜나리챔 10호 69,920원
    ▶ 살코기참치 135g×18, 리챔 200g×2, 리챔 340g

    카드할인 30%
    씨제이 스팸복합 100호 51,730원
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 340g×2, 스팸 120g×2

    카드할인 30%
    씨제이 특별한선택 스페셜 L-6호 51,730원
    ▶ 카놀라유 500ml x 2, 고소함가득참기름 330ml, 구운소금 180g x 2, 스팸 200g x 3, 스팸 라이트 200g x 6, 스팸 120g x 2

    카드할인 30%
    씨제이 최고의선택 레드라벨 67,830원
    ▶ 카놀라유 500ml x 2, 고소함가득참기름 330ml, 스팸 200g x 8, 스팸 120g x 5

    씨제이 스팸 6호 69,900원 
    ▶ 스팸 200g×12

    1+1
    Save Earth Choice호 69,900원
    ▶ 안달루시안 엑스트라버진 올리브유 500ml, 퓨어 올리브유 500ml, 해바라기씨유 500ml

    카드할인 30%
    청정원 L13호 54,460원
    ▶ 카놀라유 500ml x 2, 정통사과식초 470ml, 천일염가는소금 190g, 맛선생 참치액 560g, 맛술 생강&매실 410ml, 요리통후추그라인더 35g, 요리올리고당 700g, 잇츠팜 115g x 7

    카드할인 30% 
    청정원 L14호 59,990원
    ▶ 카놀라유 500ml, 정통현미식초 470ml, 맛선생 참치액 250g, 고소한건강생각참기름 350ml, 프리미엄 굴소스 260g, 어간장 410ml, 요리올리고당 700g, 잇츠팜 115g x 6, 우리팜델리 115g x 4

    카드할인 30%
    엘지 히말라야 핑크솔트 클래식 50,330원
    ▶ 히말라야 핑크솔트 샴푸 500ml, 히말라야핑크솔트 바디워시 480ml 外

    카드할인 30%
    엘지 닥터그루트 밀도케어 세트 58,030원
    ▶ 닥터그루트 밀도케어 샴푸 자스민 400ml, 닥터그루트 밀도케어 샴푸 바질향 400ml 外

    카드할인 30%
    엘지 시그니처 컬렉션 59,430원
    ▶ 닥터그루트 밀도케어 샴푸 바질향 400ml, 피지오겔 레드수딩 AI 크림 100ml 外

    10+1
    엘지 명작 프리미엄 59호 59,900원
    ▶ 테크 세탁세제 975ml, 샤프란 핑크 1L, 히말라야 핑크솔트 샴푸 380ml 外

    카드할인 30%
    엘지 닥터그루트 VIP 헤어 세트 69,300원
    ▶ 닥터그루트 바이옴 샴푸 400ml 닥터그루트 집중케어 샴푸 330ml×2 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    7~9만원대 선물세트

    L.POINT 10,000원 할인
    안성마춤 배, 밀양얼음골 사과 89,900원
    ▶ 사과 6입(1.7kg 내외), 배 6입(3.6kg 내외), 국산

    L.POINT 10,000원 할인
    정성담은 사과, 배 99,900원
    ▶ 사과 8입(2.6kg 내외), 배 6입(3.9kg 내외), 국산

    샤인머스캣, 한라봉 79,900원
    ▶ 샤인머스캣 2송이 (1.7kg 내외), 한라봉 6입 (2.0kg 내외), 국산

    10+1 L.POINT 30,000원 할인
    호주산 소 냉동 찜갈비 세트 99,000원
    ▶ 호주산 냉동 찜갈비 0.8kg×3, 양념소스
    ※600세트 한정 ※전국 택배 운영
    ※ 10+1 구매시 개당 90,000원

    7+1
    마른 부세 굴비 세트 89,900원
    ▶ 1.0kg, 5미, 부세굴비: 중국산/천일염 :국산

    1+1
    담터 발효홍삼 녹용쌍화K 89,000원 
    ▶ 쌍화농축액 50ml x 30병

    L.POINT 10,000원 할인
    상황 영지버섯 99,000원
    ▶ 상황버섯 130g, 영지버섯 300g, 국내산

    금산 인삼, 홍천 더덕 세트 99,900원
    ▶ 인삼 400g, 더덕 600g, 국내산

    카드할인 10,000원 할인
    4년근 인삼 세트 99,000원
    ▶ 4년근 인삼 700g(뿌리당 60g 이상), 국산

    L.POINT 10,000원 할인
    국내산 사양벌집꿀 99,000원
    ▶ 국내산 벌집꿀(밀랍포함) 1.5kg, 국산

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    행사카드 구매금액별 최대 120만원 상품권 증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    ※상품권 증정 제외
    L.POINT 40,000원 할인
    청정와규 세트 1호 99,000원
    ▶ 호주산 냉장 와규 윗등심 0.4kg×2, 양지 국거리 0.4kg, 정육 불고기 0.4kg ※전국 택배 운영

    ※상품권 증정 제외
    L.POINT 20,000원 할인
    청정와규 세트 2호 79,000원
    ▶ 호주산 냉장 와규 양지 국거리 0.4kg×2, 정육 불고기 0.4kg×2 ※전국 택배 운영

    L.POINT 20,000원 할인
    프리미엄 무염 견과 9종 세트 79,900원
    ▶ 호두 160g, 구운아몬드 210g, 구운캐슈넛 180g, 브라질너트 250g 外, 원산지 별도표기

    L.POINT 10,000원 할인
    세계 견과/건과일 10종 세트 89,900원
    ▶ 구운 아몬드 190g, 구운 캐슈너트 170g, 호두 130g, 구운 피스타치오 150g 外, 원산지 별도표기

    L.POINT 20,000원 할인
    하이그레이드 6종 견과선물세트 74,900원
    ▶ 구운아몬드 220g, 호두 160g, 구운 캐슈너트 210g 外, 원산지 별도표기

    전복장(800g), 간장새우장(600g), 양념게장(500g), 간장게장(500g), 밥도둑 반찬 4종 실속 종합세트
    장 종합 선물세트 79,000원
    ▶ 원산지 별도표기 ※오프라인 잠실점 운영 
    ※온라인몰 전점 운영(페이지 상단 QR코드 연결 시 온라인몰 구입 가능)

    카드할인 25%
    정관장 홍삼진황 75,000원
    ▶ 50ml×20포

    L.POINT 50%
    CMG제약 본래원 침향진환 골드 72,000원
    ▶ 3.75g×60환

    1+1
    풍기인삼 자연의 기운을 품은 심1호 71,000원
    ▶ 산삼배양근 보 75ml×8입, 산삼배양근 단 3.75g×8입, 6년근 봉밀홍삼절편 20g×3입

    1+1
    농협 한삼인 홍삼진 기운담은 선물세트 70,000원
    ▶ 홍삼진골드 10포, 앰플 5병, 침향환골드 12환

    1+1
    농협 한삼인 홍삼진 굿데이앰플 70,000원
    ▶ 20g×20병

    씨제이 스팸 3K호 79,900원
    ▶ 스팸 200g×6, 스팸 300g×6

    카드할인 20%
    동원 튜나리챔 6호 99,920원
    ▶ 포도씨유참치 150g×12, 살코기참치 135g×12, 리챔 200g×8

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    10만원대 이상 선물세트

    L.POINT 20,000원 할인
    자이언트 사과, 배 129,900원
    ▶ 사과 5입(2.1kg 내외), 배 4입(3.6kg 내외), 국산

    L.POINT 20,000원 할인
    자이언트 배 149,900원
    ▶ 배 9입(8.2kg 내외), 국산

    L.POINT 20,000원 할인
    자이언트 사과 109,900원
    ▶ 사과 12입(5.3kg 내외), 국산

    천안배, 충주사과 249,900원
    ▶ 사과 8입(2.9kg 내외), 배 6입(4.9kg 내외), 국산

    3대 유명산지 곶감 139,900원 
    ▶ 곶감 45입(2.1kg 내외), 국산

    L.POINT 30,000원 할인
    미국산 소 냉동 혼합 갈비 세트 119,000원
    ▶ 미국산 냉동 꽃갈비/찜갈비 각 1.5kg ※800세트 한정 ※전국 택배 운영

    7+1
    참굴비 세트 3호 139,000원
    ▶ 1.8kg/20미, 참조기 , 천일염 : 국산

    10+1 L.POINT 20,000원 할인
    대한민국 으뜸 제주 옥돔 세트 189,000원
    ▶ 3마리(1.5kg 내외), 냉동, 옥돔/천일염 : 국산

    L.POINT 30,000원 할인
    명품 국산 잣 호두 세트 149,000원
    ▶ 황잣 200g, 백잣 200g, 통호두 300g, 국산
    ※조기품절될 수 있습니다

    풍기인삼 황풍정 6년근 홍삼정과 107,000원
    ▶ 700g

    1+1
    농협 한삼인 홍삼침향환골드 100 110,000원
    ▶ 3.75g×100입

    10+1
    씨제이 스팸 블랙라벨 109,900원
    ▶ 안달루시안 엑스트라버진 올리브유 500ml×2, 스팸 340g×9

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    2025 설레는 그날
    설 선물세트 사전예약 안내

    1차 사전예약 24.12.12(목) ~ 25.1.8(수)
    2차 사전예약 25.1.9(목) ~ 25.1.15(수)

    롯데마트 GO앱 활용한 사전예약 방법

    1 명절 선물세트 카탈로그 확인
    2 롯데마트GO 앱에서 주이용점포로 문의하기클릭
    3 위치 확인 후 점포 방문
    4 사전예약 혜택받고 구매

    TIP! 
    주이용점포로 문의하기 버튼을 클릭하면 점포의 선물세트 상담팀장과의 유선 상담이 가능해요! 선물세트를 배송하실 고객님은 배송 받으실 분의 성함과 연락처, 주소를 미리 준비해주세요!

  </div>
  `,
  `
  <div>
    설날 선물세트도 역시 롯데마트몰에서!
    클릭 한번으로 두 손은 가볍게! 혜택은 무겁게 가득 받아가세요!    

    최대 50% 해당상품 카드할인

    주문 1건당 최대 120만원 즉시할인

    한 번의 결제로 여러 곳에 배송

    다양한 선물세트로 폭넓은 선택

  </div>
  `,
];

/* 793 맥스 설 사전예약 (~1/15) */
const L793data = [
  `<h1>LOTTE Mart MAXX</h1>
  <div>
    2025 설레는 그 날, 설날

    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다!
    건강하고 행복 가득한 한 해 되시길 기원합니다.

    사전예약기간: 2024/12/12(목) ~ 2025/1/15(수)
  </div>
  `,
  `
  <div>
    미리 준비할수록 더 큰 혜택!

    ※사전예약 기간: 2024.12.12(목) ~ 2025.1.15(수)

    120만원 최대 상품권 증정 또는 즉시할인
    1차 
    2024/12/12(목) ~ 2025/1/8(수)
    30만원 이상: 3만 6천원 ※하단 세부내용 참고
    50만원 이상: 6만원
    100만원 ~ 1,000만원 미만: 100만원 당 12만원
    1,000만원 이상: 120만원

    2차
    2025/1/9(목) ~ 2025/1/15(수)
    30만원 이상: 2만 5천원
    50만원 이상: 4만원
    100만원 ~ 1,000만원 미만: 100만원 당 8만원
    1,000만원 이상: 80만원

    ※해당 기간에 한함 
    ※상품권 : 3만원 5천원 상품권 + 1천원 추가할인(사전예약 1차 기간 내 30만원 이상에 한함)
  
    ※자세한 내용은 매장 내 고지물 및 롯데마트GO앱 참조
    ※법인/체크카드 포함  ※카드할인, 상품권 중복 증정(사전예약 기간내)  ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외
    ※단일카드로 전액 결제시에 한함  ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외  ※당일 영수증에 한하며, 1人 1日 1회 비연속식 증정
    ※상품권 증정 프로모션은 변경될 수 있습니다

    상품권 증정 아이콘 확인


    +
    30% 최대 할인혜택
    카드할인 아이콘 확인
    ※법인/체크카드 포함 ※기프트카드/선불카드 제외 ※해당 단일카드로 전액 결제시 적용
    ※해당상품에 한함 ※상품별 할인율 상이 ※상품권 증정 혜택과 중복 적용

    L.POINT 회원 특별할인
    ※해당상품에 한함

    구매 수량에 따라 덤 증정 하나 더
    ※해당상품에 한함

    3만원 이상 구매시 전국 무료배송
    ※일부품목 제외 (일부 도서 산간 지역 제외)
    ※결제금액 기준
    ※롯데마트몰은 무료배송 기준 별도 운영

    ※자세한 내용은 매장 내 고지물 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음
</div>
  `,
  `
  <div>
    maxx 추천 신선식품

    사전예약 상품권 증정
    카드할인 20%
    사과 배 혼합 세트 카드할인가 71,840원
    ▶ 사과 6입, 배 6입(5.5kg 내외), 국산

    사전예약 상품권 증정
    카드할인 15,000원 할인
    정성담은 표고버섯 견과 혼합 카드할인가 63,800원
    ▶ 흑화고 80g×2, 표고채 60g×2, 구운캐슈넛 150g外 원산지 별도표기

    사전예약 상품권 증정
    카드할인 10,000원 할인
    하루한줌 프리미엄 80봉 견과 카드할인가 29,800원
    ▶ 하루한줌 20g×80봉, 원산지 별도표기(프리미엄, 오리지널, 믹스넛, 넛츠앤베리 총 4종)
    
    사전예약 상품권 증정
    카드할인 8,000원 할인
    대천 곱창김 세트 카드할인가 24,980원
    ▶ 곱창김(캔) 30g×4, 곱창김(전장) 25g×5봉,
    김 : 국산, 원산지 별도표기

    사전예약 상품권 증정
    카드할인 20%
    칠산소금 참굴비 세트 2호 카드할인가 103,000원
    ▶ 1.0kg 내외(10마리), 냉동 참조기 : 국산, 천일염 : 국산

    사전예약 상품권 증정
    카드할인 30,000원
    호주산 LA식 꽃갈비 세트 카드할인가 148,000원
    ▶ 호주산 LA식 꽃갈비(냉동)2kg×2

    사전예약 상품권 증정
    롯데가 기른
    L.POINT 30,000원 할인
    Lgrow 와규 선물세트 1호 L.POINT할인가 108,000원
    ▶ 호주산 냉장 와규 윗등심 0.4kg×2,본갈비살 0.4kg, 앞치마살 0.4kg ※전국 택배 전용
    
    사전예약 상품권 증정
    L.POINT 40,000원 할인
    한우 등심 정육 세트 2호 L.POINT할인가 158,000원
    ▶ 1등급 한우 등심 500g×2, 국거리/불고기 각 500g, 냉장, 국내산 한우고기

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    2025 설레는 그 날

    2025 푸른 뱀의 해(을사년) 새해가 밝았습니다!
    건강하시고 행복 가득한 한해 되시길 기원합니다.
    maxx 창고형 전용 선물세트

    유명산지 완도에서 직접 양식하여 좋은 품질만을 엄선하여 선별한!
    ASC 인증이란?
    안전안 환경과 품질 관련 까다로운 인증절차를 거쳐 믿을 수 있는 국제 수산물 인증제도
  
    사전예약 상품권 증정
    산소포장으로 더욱 신선한 活力(활력)!
    ASC인증 완도 活 전복 세트 74,900원
    ▶ 2.0 kg 내외(특대, 마리당 90g 내외, 22마리), 국산

    사전예약 상품권 증정
    청정환경에서 키운 우리한우로 만들어 진한 육향과 부드러운 식감이 일품
    L.POINT 10,000원 할인
    지리산 순한한우 육포세트 L.POINT할인가 69,800원
    ▶한우 60g×5봉, 원산지 별도표기

    사전예약 상품권 증정
    성경 보성녹차 정성L호 9,980원
    ▶ 녹차 식탁김 4g×3봉×8개,원산지 별도표기

    1+1
    자임 침향녹용보/아내의 착즙주스 배도라지즙, 감귤사과즙/유기농 그대로 사과주스, 당근주스
    각 29,800원
    ▶ 자임 침향녹용보 100ml×30입/아내의 착즙주스 각 100ml×21입(배도라지, 감귤사과)/
    유기농 그대로 주스 각 80ml×21입(사과, 당근) ※5가지 상품 교차구매 가능

    사전예약 상품권 증정
    카드할인 20%
    청정원 스페셜 7호 카드할인가 30,320원
    ▶ 카놀라유 500ml×2입, 생강매실맛술 410ml, 요리올리고당 700g 外

    사전예약 상품권 증정
    카드할인 30%
    청정원 9호 카드할인가 32,130원
    ▶ 카놀라유 500ml×2입, 요리올리고당 700g, 정통사과식초 470ml 外

    사전예약 상품권 증정
    트리코 고프레 신년 패키지 14,980원
    ▶ 비엔나 커피맛, 스트로베리맛 450g

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
  </div>
  `,
  `
  <div>
    탐스럽게 영근 알찬 과실만 모은 과일 선물세트

    사전예약 상품권 증정
    카드할인 10,000원 할인
    유명산지 신고배 세트 카드할인가 79,980원
    ▶ 배 10~11입(6.5kg 내외), 국산
    
    사전예약 상품권 증정
    유명산지 사과세트 64,800원
    ▶ 사과 15~16입(4.5kg 내외), 국산

    사전예약 상품권 증정
    카드할인 20%
    큰사과 큰배 혼합세트 카드할인가 87,840원
    ▶ 사과 6입, 배 6입(6.4kg 내외), 국산

    사전예약 상품권 증정
    황금당도 샤인머스캣 세트 59,800원
    ▶ 샤인머스캣 3~4입(3kg), 국산

    사전예약 상품권 증정
    명품 한라봉 세트 59,800원
    ▶ 한라봉 9~15입(4kg 내외), 국산

    사전예약 상품권 증정
    제주 레드향 세트 44,800원
    ▶ 레드향 8~13입(3kg), 국산

    사전예약 상품권 증정
    샤인머스캣, 사과, 배 세트 69,800원
    ▶ 사과 4입, 배 3입, 샤인머스캣 1입(3.6kg 내외), 국산

    사전예약 상품권 증정
    상주 왕 곶감 89,800원
    ▶ 왕곶감 32입(1.6kg 내외), 국산

    사전예약 상품권 증정
    제스프리 골드키위 세트 39,800원
    ▶ 제주골드키위 18~20입(2.7kg 내외), 국산

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    땅의 기운을 오롯이 담은 더덕ㆍ인삼ㆍ버섯ㆍ견과 세트

    사전예약 상품권 증정
    6년 동안 정성스레 재배한 인삼을 엄선 선별한 귀한삼
    카드할인 20,000원 할인
    6년근 프리미엄 인삼 카드할인가 108,000원
    ▶ 6년근 인삼 750g, 국산

    사전예약 상품권 증정
    해풍맞고 자란 울릉도 특산품 속심이 적고 아린맛이 덜한 고유의 특별함
    울릉도 섬더덕 79,800원
    ▶ 더덕 800g, 국산
  
    사전예약 상품권 증정
    인삼더덕 혼합세트 87,800원
    ▶ 강원도 더덕 800g, 6년근 인삼 350g, 원산지 별도표기

    사전예약 상품권 증정
    카드할인 15,000원 할인
    자연담은 건강버섯 어울림 카드할인가 59,800원
    ▶ 상황버섯 50g, 영지버섯 130g, 녹각 45g 外, 원산지 별도표기

    사전예약 상품권 증정
    카드할인 13,000원 할인
    자연담은 표고버섯 어울림 49,800원
    ▶ 표고채 60g, 흑화고 80g×2, 목이버섯 40g 外, 원산지 별도표기

    사전예약 상품권 증정
    팔각 명품 백화고 139,000원
    ▶ 백화고 400g, 국산

    사전예약 상품권 증정
    지리산 벌집꿀 79,800원
    ▶ 집채꿀 1.5kg, 국산

    사전예약 상품권 증정
    카드할인 10,000원 할인
    빅너츠 앤 베리 카드할인가 44,800원
    ▶ 구운아몬드 310g, 호두강정 200g 外, 원산지 별도표기

    사전예약 상품권 증정
    L.POINT 20,000원 할인
    데일리넛츠 하루한봉 80봉 L.POINT 27,900원
    ▶ 18g×80봉(구운땅콩 25%, 해바라기씨 25%, 건포도 20%, 호두 15%, 구운아몬드 15%), 원산지 별도표기
    
    사전예약 상품권 증정
    카드할인 10,000원 할인
    정성 담은 웰빙견과 10종 카드할인가 39,800원
    ▶ 볶음 아몬드 190g, 볶음 캐슈넛 160g, 호두 120g 外, 원산지 별도표기

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
  </div>
  `,
  `
  <div>
    귀한 분께 언제나 축산 선물세트

    사전예약 상품권 증정
    카드할인 30,000원 할인
    호주산 혼합갈비 세트 카드할인가 128,000원
    ▶ 호주산 LA식 꽃갈비(냉동)2kg, 호주산 찜갈비(냉동)2kg
    
    사전예약 상품권 증정
    카드할인 30,000원 할인
    미국산 LA식 꽃갈비 세트 카드할인가 148,000원
    ▶ 미국산 LA식 꽃갈비(냉동)2kg×2

    사전예약 상품권 증정
    L.POINT 30,000원 할인
    청정와규 세트 1호 L.POINT할인가 88,000원
    ▶ 호주산 냉장 와규 윗등심 0.4kg×2, 양지 국거리 0.4kg, 정육 불고기 0.4kg

    사전예약 상품권 증정
    L.POINT 40,000원 할인
    한우 갈비 세트 L.POINT할인가 128,000원
    ▶ 한우 갈비(냉동) 700g×3, 양념소스, 국내산 한우고기

    사전예약 상품권 증정
    L.POINT 20,000원 할인
    무항생제 한우 꼬리반골 세트 L.POINT할인가 49,800원
    ▶ 무항생제 한우 꼬리반골(냉동) 5kg, 국내산 한우고기

    L.POINT 50,000원 할인
    한우 한끼 구이세트 L.POINT할인가 178,000원
    ▶ 1등급 한우 등심 200g×2, 안심/채끝/치마살/부채살 각 200g, 냉장, 국내산 한우고기

    사전예약 상품권 증정
    L.POINT 50,000원 할인
    한우 등심정육세트 1호 L.POINT할인가 188,000원
    ▶ 1등급 한우 등심, 국거리, 불고기 각 500g×2, 냉장, 국내산 한우고기
    
    사전예약 상품권 증정
    L.POINT 50,000원 할인
    한우 등심 세트 2호 L.POINT할인가 208,000원
    ▶ 1등급 한우 등심 500g×4, 냉장, 국내산 한우고기
    
    사전예약 상품권 증정
    L.POINT 50,000원 할인
    한우 등심채끝 혼합세트 L.POINT할인가 218,000원
    ▶ 1등급 한우 등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기
    
    사전예약 상품권 증정
    L.POINT 50,000원 할인
    한우 등심안심 혼합 세트 L.POINT할인가 198,000원
    ▶ 1등급 한우 등심 500g×2, 안심 400g×2, 냉장, 국내산 한우고기

    사전예약 상품권 증정
    L.POINT 50,000원 할인
    한우 구이 스페셜 세트 L.POINT할인가 208,000원
    ▶ 1등급 한우 등심 500g, 채끝/안심/부채살 각 400g, 냉장, 국내산 한우고기 

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    바다의 신선함을 선물하세요 수산 선물세트

    사전예약 상품권 증정  
    칠산소금 굴비 특호 176,250원
    ▶ 1.2kg 내외(10마리), 냉동 참조기 : 국산, 천일염 : 국산  

    사전예약 상품권 증정
    칠산소금 참굴비 세트 1호 76,880원
    ▶ 0.8kg 내외(10마리), 냉동 참조기 : 국산, 천일염 : 국산 

    사전예약 상품권 증정  
    카드할인 20%  
    영광 법성포 참굴비 세트 1호 카드할인가 69,520원
    ▶ 1.4kg 내외(20마리), 냉동 참조기 : 국산, 천일염 : 국산

    사전예약 상품권 증정    
    카드할인 20%  
    제주 프리미엄 갈치 세트 카드할인가 159,200원
    ▶ 2.2kg(550g×4팩), 냉동, 국산

    사전예약 상품권 증정    
    제주 갈치/옥돔 혼합 세트 99,000원
    ▶ 1.16kg 내외(갈치 400g×2, 옥돔 180g×2), 냉동, 국산

    사전예약 상품권 증정    
    노르웨이 고등어 필렛 세트 43,800원
    ▶ 고등어 필렛 330g×4팩, 냉동, 고등어:노르웨이산  
    
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 
  </div>
  `,
  `
  <div>
    햇살과 바람이 키운 건식품 선물세트

    사전예약 상품권 증정
    제주 추자도 거친 파도 속에서 자생하는 자연산 돌미역을 해녀가 직접 채취한 귀한 미역과 통영산 멸치로 구성
    카드할인 15,000원 할인
    추자도미역 통영멸치세트 카드할인가 49,800원
    ▶ 추자도미역 80g, 볶음용멸치 270g, 볶음조림용멸치 240g, 국물용멸치 240g, 멸치:국산

    사전예약 상품권 증정
    L.POINT 25,000원 할인
    남해바다 정치망 멸치세트 L.POINT할인가 74,800원
    ▶ 볶음용멸치 200g, 볶음조림용멸치 180g, 조림용멸치 150g, 국물용멸치 180g, 멸치:국산
    
    사전예약 상품권 증정
    L.POINT 10,000원 할인
    대천 캔김 세트 L.POINT할인가 59,800원
    ▶ 대천 캔김 세트 15캔, 원산지 별도표기

    사전예약 상품권 증정
    L.POINT 17,000원 할인
    동원 쇠고기 육포세트 L.POINT할인가 48,800원
    ▶ 60g×6봉, 원산지 별도표기

    사전예약 상품권 증정
    카드할인 10,000원 할인
    코주부 육포세트 2호 카드할인가 44,800원
    ▶ 60g×8봉, 원산지 별도표기

    사전예약 상품권 증정
    L.POINT 10,000원 할인
    국내산 소고기 육포세트 L.POINT할인가 39,800원
    ▶ 국내산 육우 50g×6봉, 원산지 별도표기

    사전예약 상품권 증정
    L.POINT 10,000원 할인
    홍대감 채끝살 육포세트 L.POINT할인가 39,800원
    ▶ 60g×5봉 , 외국산 우육

    사전예약 상품권 증정
    카드할인 7,000원 할인
    코주부 육포세트 1호 카드할인가 36,800원
    ▶ 60g×6봉, 원산지 별도표기

    사전예약 상품권 증정
    L.POINT 5,000원 할인
    성경 녹차 곱창돌김세트 L.POINT할인가 39,800원
    ▶ 녹차 곱창 돌김 캔김 30g×4캔, 녹차 곱창 돌김 전장김 20g×4봉, 원산지 별도표기

    사전예약 상품권 증정
    카드할인 10,000원 할인
    프리미엄 지도표 성경 김세트 카드할인가 29,980원
    ▶ 재래김/녹차김 각 30g×4봉 外, 원산지 별도표기

    사전예약 상품권 증정
    L.POINT 10,000원 할인
    동원 양반김 M30호 L.POINT할인가 29,880원
    ▶ 더바삭한김전장김 20g×6봉,살코기참치 90g×6 外, 원산지 별도표기
    사전예약 상품권 증정
    제주 추자도 거친 파도 속에서 자생하는 자연산 돌미역을 해녀가 직접 채취한 귀한 미역과 통영산 멸치로 구성
    카드할인 15,000원 할인
    추자도미역 통영멸치세트 카드할인가 49,800원
    ▶ 추자도미역 80g, 볶음용멸치 270g, 볶음조림용멸치 240g, 국물용멸치 240g, 멸치:국산

    사전예약 상품권 증정
    L.POINT 25,000원 할인
    남해바다 정치망 멸치세트 L.POINT할인가 74,800원
    ▶ 볶음용멸치 200g, 볶음조림용멸치 180g, 조림용멸치 150g, 국물용멸치 180g, 멸치:국산
    
    사전예약 상품권 증정
    L.POINT 10,000원 할인
    대천 캔김 세트 L.POINT할인가 59,800원
    ▶ 대천 캔김 세트 15캔, 원산지 별도표기

    사전예약 상품권 증정
    L.POINT 17,000원 할인
    동원 쇠고기 육포세트 L.POINT할인가 48,800원
    ▶ 60g×6봉, 원산지 별도표기

    사전예약 상품권 증정
    카드할인 10,000원 할인
    코주부 육포세트 2호 카드할인가 44,800원
    ▶ 60g×8봉, 원산지 별도표기

    사전예약 상품권 증정
    L.POINT 10,000원 할인
    국내산 소고기 육포세트 L.POINT할인가 39,800원
    ▶ 국내산 육우 50g×6봉, 원산지 별도표기

    사전예약 상품권 증정
    L.POINT 10,000원 할인
    홍대감 채끝살 육포세트 L.POINT할인가 39,800원
    ▶ 60g×5봉 , 외국산 우육

    사전예약 상품권 증정
    카드할인 7,000원 할인
    코주부 육포세트 1호 카드할인가 36,800원
    ▶ 60g×6봉, 원산지 별도표기

    사전예약 상품권 증정
    L.POINT 5,000원 할인
    성경 녹차 곱창돌김세트 L.POINT할인가 39,800원
    ▶ 녹차 곱창 돌김 캔김 30g×4캔, 녹차 곱창 돌김 전장김 20g×4봉, 원산지 별도표기

    사전예약 상품권 증정
    카드할인 10,000원 할인
    프리미엄 지도표 성경 김세트 카드할인가 29,980원
    ▶ 재래김/녹차김 각 30g×4봉 外, 원산지 별도표기

    사전예약 상품권 증정
    L.POINT 10,000원 할인
    동원 양반김 M30호 L.POINT할인가 29,880원
    ▶ 더바삭한김전장김 20g×6봉,살코기참치 90g×6 外, 원산지 별도표기

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    건강가득, 받는 분의 감동이 더 커지는 건강식품 선물세트

    사전예약 상품권 증정
    건강식품
    해당카드 3+1
    정관장 홍삼진고 이뮨스틱 46,000원
    ▶ 10g×30포

    사전예약 상품권 증정
    카드할인 20% 
    정관장 홍삼원기 카드할인가 29,600원
    ▶ 50ml×30포

    사전예약 상품권 증정
    카드할인 25% 
    정관장 홍삼진본 카드할인가 36,750원
    ▶ 40ml×20포

    사전예약 상품권 증정
    L.POINT 6,000원 할인
    뉴트리원 올인원 멀티비타 이뮨플러스 세트 L.POINT할인가 43,900원
    ▶ 21.5g×7병×4입

    사전예약 상품권 증정
    L.POINT 6,000원 할인
    뉴트리원 프로&프리바이오틱스 세트 L.POINT할인가 33,800원
    ▶ 5g×30포×3개입

    사전예약 상품권 증정
    L.POINT 5,000원 할인
    뉴트리원 리포좀 비타민C 골드 세트 L.POINT할인가 32,800원
    ▶ 1,100mg×72정

    사전예약 상품권 증정
    13,000원 할인
    종근당건강 아임비타 멀티비타민 세트 33,700원
    ▶ 이뮨플러스 14병, 멀티비타민 60정

    사전예약 상품권 증정
    종근당건강 락토핏 당케어 3입 세트 41,900원
    ▶ 50포×3입

    사전예약 상품권 증정
    1+1
    종근당건강 발효홍삼력진 21병 79,800원
    ▶ 20ml×21병

    사전예약 상품권 증정
    1+1
    종근당건강 홍삼기력골드 39,800원
    ▶ 50ml×30포

    사전예약 상품권 증정
    1+1
    씨제이 한뿌리 홍삼대보 44,800원
    ▶ 40ml×24입

    사전예약 상품권 증정
    1+1
    씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    사전예약 상품권 증정
    1+1
    한삼인 홍삼본 골드 39,800원
    ▶ 50ml×30포
    
    사전예약 상품권 증정
    1+1
    한삼인 홍삼진 굿데이스틱 59,600원
    ▶ 10ml×60포

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
  </div>
  `,
  `
  <div>
    사전예약 상품권 증정    
    카드할인 20%
    정관장 홍삼정명 카드할인가 71,200원
    ▶ 100g×2입

    사전예약 상품권 증정    
    카드할인 20%
    정관장 활삼골드 카드할인가 31,200원
    ▶50ml×30포
    
    사전예약 상품권 증정    
    카드할인 20%
    정관장 홍삼원 골드 카드할인가 40,000원
    ▶100ml×24입

    사전예약 상품권 증정    
    1+1
    농협 한삼인 홍삼진 기운담은 선물세트 70,000원
    ▶홍삼진골드 10포, 앰플 5병,침향환골드 12환

    사전예약 상품권 증정    
    1+1
    농협 한삼인 홍삼진 굿데이앰플 70,000원
    ▶ 20g×20병

    사전예약 상품권 증정    
    1+1
    농협 한삼인 대보선물세트 3호 77,000원
    ▶ 홍기천 50ml×10포, 홍삼대보 75ml×8병, 홍삼대보환 3.75g×12환

    사전예약 상품권 증정    
    L.POINT 50%
    CMG제약 본래원 관절 활력진환 L.POINT할인가 64,500원
    ▶ 4g×40환

    사전예약 상품권 증정    
    L.POINT 50%
    CMG제약 본래원 흑염소진액 L.POINT할인가 24,900원
    ▶ 50ml×30포

    사전예약 상품권 증정    
    L.POINT 50%
    CMG제약 본래원 침향진환 L.POINT할인가 69,900원
    ▶ 3.75g×60환

    사전예약 상품권 증정   
    1+1
    풍기인삼 황풍정 산양배양근 수 49,000원
    ▶ 50ml×30포
    
    사전예약 상품권 증정   
    1+1
    풍기인삼 자연의 기운을 품은 심1호 71,000원
    ▶산삼배양근 보 75ml×8입, 산삼배양근 단 3.75g×8입, 6년근 봉밀홍삼절편 20g×3입
    
    사전예약 상품권 증정   
    1+1
    풍기인삼 자연의 기운을 품은 심2호 65,000원
    ▶산삼배양근 보 75ml×8입, 봉밀홍삼절편 20g×5입

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
        온 가족 함께 즐기는 홈스토랑 선물세트

    ※선물용 쇼핑백 혹은 전용 보냉백과 함께 택배로 배송됩니다 ※상품권 및 즉시할인 제외

    사미헌 LA갈비&야채불고기 선물세트 57,500원
    ▶ LA갈비 500g×2팩, 야채불고기 400g

    사미헌 갈비탕 &소불고기 선물세트 49,000원
    ▶ 사미헌 갈비탕 1kg×2팩, 야채불고기 400g×2팩

    VIPS 홈레스토랑 선물세트 2호 89,900원
    ▶ 오리지널 바비큐 폭립, 왕갈비 바비큐폭립, 단호박 찹스테이크, 척아이롤스테이크, 쉬림프로제 원팬 파스타, 미트라자냐
  
    VIPS 홈레스토랑 선물세트 4호 87,900원
    ▶ 오리지널 바비큐 폭립 600g, 스파이시 바비큐 폭립, 플랫아이언 스테이크, 쉬림프 로제 파스타, 미트 라자냐
  
    설성목장 명품 이동갈비 세트 129,000원
    ▶ 소 이동 본갈비 500g×2팩, 소 이동 한입 LA갈비 500g×2팩

    설성목장 이동본갈비 세트 64,900원
    ▶ 소 이동 본갈비 500g×2팩

    카드할인 30%
    더본코리아 본가 선물세트 카드할인가 105,000원
    ▶ 본가 소갈비찜 500g×4, 본가 소불고기 250g×6, 본가 너비아니 150g×6팩

    카드할인 30%
    더본코리아 빽쿡 선물세트
    카드할인가 69,300원
    ▶ 인생 삼계탕 1.2kg×2, 본가 소갈비찜 500g×2, 본가 너비아니 150g×5 

    경복궁 한우불고기 갈비 혼합세트 69,000원
    ▶ 한우 불고기 600g, 칼집 LA갈비 600g

    카드할인 5,000원 할인
    경복궁 명절 한상 선물세트 카드할인가 74,000원
    ▶ 칼집LA갈비 600g, 영양갈비탕 850g, 양념불고기 600g, 한우사골곰탕 600g

    경복궁 궁중 본갈비 선물세트 89,000원
    ▶ 궁중 본갈비 600g×2팩

    카드할인 6,000원 할인
    경복궁 따뜻한 국물 보양 선물세트 카드할인가 39,000원
    ▶ 한우 사골곰탕 600g×2팩, 양곰탕 600g, 꼬리곰탕 600g

    경복궁 양념불고기 선물세트 49,000원
    ▶ 양념불고기 600g×2팩

    경복궁 소갈비찜 89,000원
    ▶ 소갈비찜 800g×2팩

    카드할인 10,000원 할인
    경복궁 소양념 본갈비 칼집LA 특대세트 카드할인가 139,000원
    ▶ 소양념 본갈비 580g×2팩, 칼집LA갈비 600g×2팩

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다

  </div>
  `,
  `
  <div>
    받으시는 분의 소중한 품격을 더한 프리미엄 선물세트

    최고의 미식 경험

    사전예약 상품권 증정
    특대 사이즈 자연산 새우로만 구성한 프리미엄 선물세트
    킹블랙타이거 새우세트 131,250원
    ▶ 1 kg 내외(왕특대, 5마리/냉동), 기니아산

    사전예약 상품권 증정
    1++(9)등급, 1+등급으로 즐기는 고품격 한우 갈비세트
    L.POINT 50,000원 할인
    마블나인 한우 갈비세트(1++(9)등급) L.POINT할인가 258,000원
    ▶ 1++(9) 등급 한우 찜갈비 700g×5, 냉동, 국내산 한우고기

    사전예약 상품권 증정
    L.POINT 50,000원 할인
    1+등급 한우갈비세트 L.POINT할인가 258,000원
    ▶ 1+등급 한우 찜갈비 700g×4, 냉동, 양념소스, 국내산 한우고기

    어묵&수제햄

    고래사어묵 선물세트 3호 40,000원
    ▶ 고래바 65g×2, 통새우말이 90g, 치즈빵빵 80g, 떡말이 90g, 소시지말이 90g 外

    고래사어묵 선물세트 5호 60,000원
    ▶고래바 65g×2, 통새우말이 90g×2 치즈빵빵 80g, 떡말이 90g, 새우봉어묵 150g, 크랩모아 140g 外

    삼진어묵 프리미엄 명품세트 50,000원
    ▶ 당면도톰네모, 야채도톰네모, 부추잡채도톰네모, 핫땡초말이 外

    삼진어묵 이금복 명품세트 80,000원
    ▶ 명품 소라어묵, 명품 새우어묵, 명품 오징어어묵, 명품바(소시지치즈, 새우치즈) 外

    카드할인 10%
    존쿡델리미트 하몽샤퀴테리 세트 카드할인가 71,100원
    ▶하몽세보데캄포핸드컷 50g, 하몽세라노 그란 레세르바 120g, 코파 50g, 이탈리안살라미 50g, 카바노치 50g, 트러플치즈딥 200g, 크래커 120g, 올리브 290g, 미니잼 28g

    카드할인 10%
    존쿡델리미트 시그니처 세트 카드할인가 49,500원
    ▶슈바인학센 650g, 브렉퍼스트소시지 210g, 비프핫도그 300g, 킬바사 270g, 컨츄리베이컨 120g, 잠봉 100g, 맥앤치즈 200g, 핫도그번 165g

    사전예약 상품권 증정
    카드할인 20%
    상하농원 수제 햄&치즈 세트 카드할인가 47,920원
    ▶스틱비엔나 120g×2, 고구마프랑크 180g, 그릴드 치즈 125g, 스틱치즈 50g×3

    사전예약 상품권 증정
    카드할인 20%
    상하농원 수제 햄&치즈 고급세트 카드할인가 96,000원
    ▶클래식비엔나 120g, 화이트 비엔나 120g, 스모크 프랑크 180g, 스모크라운드햄 160g 外
  
    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    요리에 맛을 살리는 프리미엄 장류&오일 선물세트
    대한민국 전통식품 명인 제 35호 기순도,
    370년 종가의 전통과 명인의 손맛이 선사하는 품격있는 선물세트

    L.POINT 10% 할인 기순도 미식예가 실속 2호
    L.POINT할인가 61,200원
    ▶ 기순도 전통 간장 300ml, 맛장 300ml, 현미보리된장 200g, 현미고추장 200g

    L.POINT 10% 할인 기순도 미식예가 실속 3호
    L.POINT할인가 62,100원
    ▶ 기순도 전통고추장 320g, 죽염 250g, 전통간장 300ml, 맛장 300ml

    L.POINT 10% 할인 기순도 명인의 장 다복 11호
    L.POINT할인가 63,900원
    ▶ 기순도 현미보리된장 250g, 현미고추장 250g, 죽염 200g, 전통간장 250ml, 맛장 250ml

    L.POINT 10% 할인 기순도 미식예가 오복 2호
    L.POINT할인가 71,100원
    ▶ 기순도 전통된장 320g, 전통고추장 320g, 쌀조청 200g, 죽염 160g, 맛장 300ml

    5+1 청정원 현미고추장 선물세트 79,000원
    ▶ 우리쌀 현미고추장 2kg(도자기)

    비온뒤 첫소금 
    청정해역 신안에서 비가 온 뒤
    가장 깨끗해진 토판 위에서
    정성껏 수확한 명품 소금 선물세트

    L.POINT 10% 할인 비온뒤 첫소금 어간장 세트
    L.POINT할인가 19,800원
    ▶ 요리엔담다 어간장 250ml×2

    L.POINT 10% 할인 비온뒤 첫소금 프리미엄 6호
    L.POINT할인가 22,500원
    ▶ 프리미엄 함초소금 400g, 프리미엄 후추소금 380g,프리미엄 구운소금 400g

    L.POINT 10% 할인 비온뒤첫소금 실속 2호
    L.POINT할인가 27,000원
    ▶ 3년숙성 천일염 135g, 토판소금 150g, 함초소금 150g, 구운소금 150g

    L.POINT 10% 할인 비온뒤 첫소금 프리미엄 2호
    L.POINT할인가 45,000원
    ▶ 3년숙성 천일염 135g, 구운소금 150g, 함초소금 150g, 토판염 150g, 신안천일염 400g×2, 스푼 동봉

    바로담아
    깐깐하게 엄선한 국산 참, 들깨를 전통 압착
    방식으로 단 한번 착유하여 깨 본연의
    고소하고 진한 풍미가 가득한 참·들기름 선물세트

    L.POINT 10% 할인 바로담아 국산 참들기름 1호
    L.POINT할인가 56,970원
    ▶ 국산 참기름 180ml, 국산 들기름 180ml, 국산볶음참깨 105g

    L.POINT 10% 할인 바로담아 국산 참들기름 3호
    L.POINT할인가 63,900원
    ▶ 국산 참기름 180ml, 국산 들기름 180ml, 국산볶음참깨 105g, 국산 깨소금 100g

    L.POINT 10% 할인 바로담아 국산 참들기름 2호
    L.POINT할인가 73,980원
    ▶ 국산 참기름 180ml×2, 국산 들기름 180ml

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
  </div>
  `,
  `
  <div>
    주세페주스티
    17대째 내려오는 400년이 넘는 전통의
    현존하는 가장 오래된 하이엔드 발사믹 브랜드

    1메달6년숙성 L.POINT 20% 할인 주세페주스티 올리브유 6년숙성 발사믹식초 세트
    L.POINT할인가 73,600원
    ▶ 엑스트라버진 올리브오일 250ml,1메달 6년산 발사믹식초 250ml

    2메달8년숙성 3메달12년숙성 L.POINT 20% 할인 주세페주스티 올리브유 8년숙성 발사믹식초 세트
    L.POINT할인가 86,400원
    ▶ 엑스트라버진 올리브오일 250ml,2메달 8년산 발사믹식초 250ml

    3메달 L.POINT 20% 할인 주세페주스티 올리브유 12년숙성 발사믹식초 세트
    L.POINT할인가 109,600원
    ▶ 엑스트라버진 올리브오일 250ml, 3메달 12년산 발사믹식초 250ml

    1메달 L.POINT 20% 할인 주세페주스티 6년숙성 발사믹&화이트발사믹 세트
    L.POINT할인가 68,000원
    ▶ 1메달 6년산 발사믹식초 250ml, 화이트 발사믹식초 250ml

    파미고
    세계최고 올리브오일 마스터
    Pepe Cano 의 손 끝에서 완성되는
    스페인 No.1 올리브오일 브랜드

    L.POINT 10% 할인 파미고 엑스트라버진 올리브유 선물세트
    L.POINT할인가 37,800원
    ▶ 엑스트라버진 올리브유 500ml×2

    올리타리아
    이탈리아 쉐프들이 가장 선호하는
    No.1 브랜드 올리타리아

    L.POINT 10% 할인 올리타리아 스페셜 5호
    L.POINT할인가 14,310원
    ▶ 해바라기유 500ml×2

    L.POINT 10% 할인 올리타리아 스페셜 33호
    L.POINT할인가 36,810원
    ▶ 엑스트라버진올리브유 500ml,포도씨유 500ml, 해바라기유500ml

    L.POINT 10% 할인 올리타리아 스페셜 4호
    L.POINT할인가 42,210원
    ▶ 엑스트라버진올리브유 500ml×2

    L.POINT 10% 할인 올리타리아 스페셜 79호
    L.POINT할인가 43,110원
    ▶ 엑스트라버진올리브유 1L, 해바라기유 1L

    L.POINT 10% 할인 올리타리아 스페셜62호
    L.POINT할인가 43,110원
    ▶ 엑스트라버진올리브유 500ml,포도씨유 500ml, 트러플오일 250ml

    L.POINT 10% 할인 신선한 올리브 열매를 수확한 즉시 선별, 압착한 황금(Oro) 올리브유
    올리타리아 스페셜 92호
    L.POINT할인가 47,610원
    ▶ 오로 올리브오일 500ml×2

    L.POINT 10% 할인 올리타리아 스페셜 41호
    L.POINT할인가 49,230원
    ▶ 엑스트라버진올리브유 500ml,포도씨유 500ml, 아보카도오일 250ml

    L.POINT 10% 할인 올리타리아 스페셜 20호
    L.POINT할인가 49,410원
    ▶ 엑스트라버진 올리브유 1L, 포도씨유1L

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    세련된 감각의 테이블 완성! 치즈&커피&차 선물세트

    치즈&디저트
    청정 아일랜드 목초유 100%로 만든 구워먹는 치즈와,
    바베끌레떼&전용 보냉백이 포함된 프리미엄 치즈 세트

    카드할인 10% 앙투어 솔레 치즈 세트
    카드할인가 56,100원
    ▶ 보스카 바베끌레떼, 앙투어 솔레 100g×3×3,앙투어솔레 보냉백

    카드할인 10% 치즈 플레이트 세트
    카드할인가 39,600원
    ▶ 보스카 치즈 보드S, 브리에뜨 크리미앤 마일드 125g, 아페리프레코트다쥐르 100g,에스뿌나 살치촌 슬라이스 50g, 그린올리브 마리네이티드 60g, 고다 포션 인 네트 100g, 고메 스모크드 치즈 200g, 연성 치즈나이프

    카드할인 10% 치즈&샤퀴테리 세트
    카드할인가 32,400원
    ▶ 르 갈 크림치즈 플레인 150g, 베르기어 포션 믹스 120g, 포스 칼라마타 피티드 올리브마리네이티드 60g, 마블스 과일치즈 멜론&망고 100g, 에스푸나 초리조 엑스트라 50g,에스뿌나 살치촌 슬라이스 50g, 미니 토스트 80g, 아그네스 스파게티 N.3 500g

    카드할인 10% 치즈 패밀리 세트
    카드할인가 45,000원
    ▶ 체리페퍼 위드 망고 크림치즈 280g, 엠보그 치즈 트라이앵글 140g,파르네제 그라나 파다노 150g, 고메 스모크드 치즈 200g, 베르기어 포션믹스 120g,쁘띠 까망베르 125g, 그린 올리브 절임 200g, 연성 치즈나이프

    카드할인 10% 와인 페어링 치즈 세트
    카드할인가 49,500원
    ▶ 아페리프레 프로방스 100g, 파르네제 파르미지아노 레지아노 150g, 베르기어 포션 믹스 120g,쁘띠 브리 125g, 그린 올리브 절임 200g, APOLLO 과일치즈(메론/망고) 125g,에스뿌나 초리조 슬라이스 50g, 아스뿌나 살치촌 슬라이스 50g, 연성치즈 나이프

    임실 치즈세트 1호 64,000원
    ▶ 임실생치즈 500g, 임실구워먹는치즈 125g×2, 임실 모짜렐라 슬라이스 치즈 100g×3,
    벨라유 포션치즈 120g×4(플레인/단호박/아몬드/양파)

    임실 스모크 치즈 특호 85,000원
    ▶ 임실 크림치즈 200g, 벨라유 포션치즈 120g×4(플레인/단호박/아몬드/양파),
    임실 모짜렐라 슬라이스 치즈 100g×4, 임실 스트링 치즈 100g,
    임실 구워먹는 치즈 250g, 임실 스모크 치즈 64g×2

    임실 치즈 선물세트 특호 95,000원
    ▶ 임실 크림치즈 200g, 벨라유 포션치즈 120g×4(플레인/단호박/아몬드/양파),
    임실 모짜렐라 슬라이스 치즈 100g×4, 임실 구워먹는 치즈 250g×2,
    임실 스트링치즈 100g×2

    신라명과 골든쿠키 17,900원
    ▶ 쵸코칩라운드쿠키 120g, 코코넛팜쿠키 90g, 모카쿠키 115g 外

    신라명과 셀레브르 쿠키(중) 22,900원
    ▶ 쵸코칩라운드쿠키120g, 빠똥자망드 치즈쿠키90g 外

    신라명과 셀레브르 쿠키(대) 30,900원
    ▶ 빠똥자망드 치즈쿠키 90g, 빠똥자망드 초코쿠키 120g,
    밀라네즈 쿠키 80g 外

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
    
  </div>
  `,
  `
  <div>
    사전예약 상품권 증정 L.POINT 34,000원 할인 네스카페 돌체구스토 지니오 S플러스
    L.POINT할인가 85,000원
    ▶ 네스카페 돌체구스토 지니오 S플러스 블랙머신1입

    사전예약 상품권 증정 10+1 커피빈 실속 선물세트 13,700원
    ▶ 커피빈 캡틴 아메리카노 미니 10T×3개,
    콜롬비아 아메리카노 미니 10T×3개

    사전예약 상품권 증정 1+1 담터 모과도라지 쌍화스틱 49,000원
    ▶ 모과 도라지 쌍화스틱 10ml×50입

    사전예약 상품권 증정 카드할인 20% 담터 꿀차 플러스 세트
    카드할인가 29,920원
    ▶ 쌍 화차 플러스/생강차 플러스/대추차 플러스/모과도라지차 플러스 각 15T
    아카시아벌꿀/야생화벌꿀 각 10입

    1+1 녹차원 정성가득 홍삼차세트 39,900원
    ▶ 홍삼진골드 4병, 꿀유자차 300g, 꿀레몬차 300g,
    홍삼배도라지 4포×2입, 작두콩차 5입, 호박팥차 5입

    사전예약 상품권 증정 10+1 녹차원 차다움세트 9,900원
    ▶ 꿀유자차 350g, 생강대추배차 7T, 호두아몬드율무차 7T

    사전예약 상품권 증정 10+1 녹차원 포시즌 베스트 세트 9,900원
    ▶ 쌍화차/생강차/캐모마일/페퍼민트/히비스커스/보이차 각 5T

    카드할인 20% 스타벅스 원두믹스 세트 1호
    카드할인가 29,960원
    ▶ 다크 10T×3, 미디엄 10T×2입,
    다크 30T, 화이트머그 370ml

    L.POINT 20% 할인 초록원 액상차 3입세트
    L.POINT할인가 16,720원
    ▶ 유자차 580g, 생강차 560g,
    제주한라봉차 560g

    1+1 다농원 분말차 세트 1호 36,800원
    ▶ 마카다미아 호두아몬드 율무차 10T×2입,
    마카다미아 호두 아몬드 천마차, 쌍화차, 단호박차, 생강차 각 10T×1입

    5+1 맥코이 파이니스트 프리미엄 티 콜렉션 36,000원
    ▶ 애플카라멜향티/초콜릿향 스트로베리티/얼그레이허니향티 각 2g×20T


    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    한과&꿀&쨈

    사전예약 상품권 증정 5+1 유영군 명인한과 1호 34,900원
    ▶ 유과(찹쌀/백년초/단호박/대잎),
    다식(단호박/백년초/대잎/콩),
    강정(쌀/백년초/치자/대잎/쌀아몬드/흑미), 약과(미니)

    사전예약 상품권 증정 10+1 유영군 명인한과 2호 59,900원
    ▶ 유과(찹쌀/백년초/단호박/대잎),
    오렌지 정과, 다식(단호박/백년초/대잎/콩) 外

    사전예약 상품권 증정 1+1 창평한과 면앙정 69,900원
    ▶ 유과(찹쌀/백년초/단호박),
    다식(단호박/백년초/대잎/콩)
    강정(쌀/백년초/치자/대잎/쌀아몬드/딸기/흑미), 약과(미니)

    사전예약 상품권 증정 7+1 창평한과 식영정 49,900원
    ▶ 유 과(찹쌀/단호박/백년초/대잎),
    치자백년초매작과, 미니약과,
    강정(쌀/백년초/치자/대잎/쌀아몬드/딸기/흑미쌀/보리) 外

    사전예약 상품권 증정 7+1 창평한과 찹쌀유과세트 1KG 28,900원
    ▶ 찹쌀유과 500g 봉지×2개

    창평한과 찹쌀약과 1KG 12,900원
    ▶ 약과 1kg(개별포장)

    L.POINT 10% 할인 화과방 더담아 생과자 1호
    L.POINT할인가 34,650원
    ▶ 밤, 자두, 살구, 치크피 각 5개

    L.POINT 2,000원 할인 옛날애 종합 전병세트
    L.POINT할인가 9,980원
    ▶ 김말이 전병, 생강맛전병, 맛도라, 땅콩맛전병,
    부채형전병(800g)

    사전예약 상품권 증정 카드할인 20% 꽃샘 아카시아 야생화꿀 세트
    카드할인가 47,920원
    ▶ 아카시아꿀 600g, 야생화꿀 600g

    사전예약 상품권 증정 카드할인 20% 꽃샘 밤꿀세트
    카드할인가 54,400원
    ▶ 밤꿀 1.2kg

    카드할인 20% 피나무 벌꿀 세트
    카드할인가 63,840원
    ▶ 피나무 벌꿀 1.2kg

    카드할인 10% 샹달프 미니잼 12종 세트
    카드할인가 17,910원
    ▶ 미니잼 28g×12입
    (금귤/딸기/석류/체리/블루베리/크랜베리/라즈베리/무화과/블랙커런트/후르츠/망고&패션후르츠/애플시나몬)

    카드할인 10% 퀸즈트리 피넛버터 3종 세트
    카드할인가 23,310원
    ▶ 퀸즈트리 피넛버터 크런치 2입, 크리미 1입

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
  </div>
  `,
  `
  <div>
    선물하는 분과 받는 분의 품격을 높이는 주류 선물세트

    C.Y.O.C. Private Bottle No.1 벤로막 2014 239,000원
    ▶ C.Y.O.C. Private Bottle No.1 벤로막 2014 700ml

    L.POINT 35,000원 할인 조니워커 블루 뱀띠 에디션
    L.POINT할인가 359,800원
    ▶ 조니워커뱀띠에디션 750ml, 스템프 1개

    L.POINT 28,000원 할인 조니워커 그린
    L.POINT할인가 70,800원
    ▶ 조니워커그린 700ml, 노징 글라스 2개

    L.POINT 41,000원 할인 글렌리벳 15년
    L.POINT할인가 108,800원
    ▶ 더글렌리벳 15년 700ml, 디켄터 1개, 노징글라스 2개

    L.POINT 38,000원 할인 글렌그란트 12년
    L.POINT할인가 71,800원
    ▶ 글렌그란트 12년 700ml, 노징글라스 2개

    L.POINT 10% 할인 나라 미국 덕혼 디코이 세트
    L.POINT할인가 89,910원
    ▶ 덕혼 디코이 까베르네 소비뇽 750ml,
    덕혼 디코이 메를로 750ml

    2묶음 세트 레뱅스페인 1호 세트 29,900원
    ▶ 아이모니아 레드 750ml, 아이모니아 화이트 750ml

    2묶음 세트 칠성 프랑스 론 와인 2종 세트 39,900원
    ▶ 앙리 드 플로뢰 750ml, 셰리에 도르 750ml

    L.POINT 20% 할인 아영 이탈리아 인기와인 세트
    L.POINT할인가 43,920원
    ▶ 그랑 파씨오네 750ml,유원프리미티보 디 만두리아 750ml

    북극곰의 눈물 전용 도자기잔 세트 24,900원
    ▶ 북극곰의 눈물 준마이 720ml, 전용 도자기잔 2개

    L.POINT 6,980원 할인 화요 41% 테이스팅잔 패키지
    L.POINT할인가 39,980원
    ▶ 화요 41% 375ml 2병, 테이스팅글라스 1개

    L.POINT 5,020원 할인 화요 53% 청사 에디션
    L.POINT할인가 69,980원
    ▶ 화요 53% 청사 에디션 750ml

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    1~2만원대 선물세트 
    행사카드 구매 금액대별 최대 120만원 상품권증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    사전예약 상품권 증정 카드할인 30% 정관장 데워먹는 홍삼원
    카드할인가 18,900원
    ▶ 120ml×12입

    L.POINT 2,000원 할인 고려은단 비타민C 골드 플러스
    L.POINT할인가 19,580원
    ▶ 1,120mg×120정
    
    사전예약 상품권 증정 5,000원 할인 종근당건강 생유산균 락토핏 코어 더블세트
    판매가 32,800 ▶ 27,800원
    ▶ 60포×2입

    사전예약 상품권 증정 7,000원 할인 종근당건강 콘드로이친 세트
    판매가 34,900 ▶ 27,900원
    ▶ 850mg×30정×3입

    사전예약 상품권 증정 L.POINT 13,000원 할인 뉴트리원 비비랩 NFC착즙 유기농 레몬즙 세트
    L.POINT 할인가 23,900원
    ▶20g×10포×3입

    사조 고급유 3호 11,900원
    ▶ 카놀라유 500ml×3

    카드할인 30% 사조 안심특선 92호
    카드할인가 19,950원
    ▶ 안심팜 115g×2, 참기름 55ml, 바삭요리유 500ml,
    구운소금 200g, 맛술 500ml 外

    카드할인 30% 사조 안심특선 95호
    카드할인가 25,900원
    ▶ 카놀라유 500ml, 바삭요리유 500ml,
    살코기참치 85g×4, 안심팜 115g×2 外

    카드할인 30% 사조 안심특선 94호
    카드할인가 26,530원
    ▶ 카놀라유 500ml, 안심팜 115g×2,
    진간장 500ml, 맛술 500ml, 참기름 55ml 外

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
  </div>
  `,
  `
  <div>
    10+1 동원 포도씨유 혼합 23호 15,900원
    ▶ 카놀라유 480ml×2, 포도씨유 480ml

    카드할인 30% 동원 양반김 M70호
    카드할인가 19,950원
    ▶ 더바삭한 전장김 25g×3봉,
    살코기참치 135g×3, 건강요리유 480ml

    카드할인 30% 동원 프리미엄 1호
    카드할인가 19,950원
    ▶ 살코기참치 90g, 리챔 120g,
    사과식초 500ml, 올리고당 700g 外

    카드할인 30% 동원 프리미엄 55호
    카드할인가 19,950원
    ▶ 살코기참치 90g×2, 카놀라유 480ml,
    참기름 160ml, 구운소금 210g 外

    카드할인 30% 동원 스페셜 1호
    카드할인가 25,970원
    ▶ 살코기참치 135g×2, 리챔 120g×4,
    건강요리유 480ml×2

    카드할인 30% 동원 스페셜 5호
    카드할인가 29,890원
    ▶ 살코기참치 90g×6, 리챔 120g×3,
    카놀라유 480ml×2

    카드할인 30% 동원 프리미엄 62호
    카드할인가 29,960원
    ▶ 살코기참치 90g×5, 리챔 120g×2,
    참치액 진 240g, 참기름 75ml 外

    카드할인 30% 동원 스페셜 49호
    카드할인가 29,960원
    ▶ 살코기참치 90g×4,
    리챔 더블라이트 120g×4, 참기름 330ml

    10+1 청정원 고급유 6호 13,900원
    ▶ 카놀라유 500ml×2, 요리유 500ml

    카드할인 30% 청정원 스페셜 S호
    카드할인가 19,950원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2, 진간장골드 500ml,
    요리올리고당 700g, 사과식초 500ml

    카드할인 30% 청정원 L1호
    카드할인가 22,260원
    ▶ 카놀라유 500ml, 옥수수유 500ml,
    잇츠팜 115g×2 外

    카드할인 30% 청정원 L2호
    카드할인가 24,360원
    ▶ 카놀라유 500ml, 잇츠팜 115g×4,
    옥수수유 500ml, 올리고당 700g

    카드할인 30% 청정원 L6호
    카드할인가 27,860원
    ▶ 카놀라유 500ml×2, 우리팜델리 115g×4,
    올리고당 700g, 어간장 410ml

    카드할인 30% 청정원 L7호
    카드할인가 29,960원
    ▶ 카놀라유 500ml, 잇츠팜 115g×5,
    요리올리고당 700g 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    1~2만원대 선물세트 
    행사카드 구매 금액대별 최대 120만원 상품권증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    씨제이 백설 프리미엄 5호 12,900원
    ▶ 바삭한요리유 500ml×2,해바라기씨유 500ml

    10+1 씨제이 백설 프리미엄 14호 15,900원
    ▶ 카놀라유 500ml×2, 해바라기씨유 500ml

    5+1 씨제이 백설 프리미엄 23호 18,900원
    ▶ 카놀라유 500ml×2,요리유 500ml×2

    씨제이 특별한선택 I호 19,900원
    ▶ 요리올리고당 700g, 사과식초 500ml, 스팸
    120g×2, 맛술 500ml 外

    카드할인 30% 씨제이 특별한선택 K-4호
    카드할인가 21,630원
    ▶ 카놀라유 500ml×2, 올리고당 500g, 스팸 120g×4

    5+1 씨제이 고소함 가득 참기름 A호 26,900원
    ▶ 고소함가득참기름 330ml×2, 천일염 170g×2

    카드할인 30% 씨제이 특별한선택 L-3호
    카드할인가 28,630원
    ▶ 카놀라유 500ml×2,
    요리올리고당 700g, 스팸 120g×6

    카드할인 30% 씨제이 특별한선택 K-2호
    카드할인가 29,330원
    ▶ 카놀라유 500ml, 요리올리고당 700g,
    스팸 200g×2 外

    사전예약 상품권 증정 5+1 카누 아메리카노 8호 27,980원
    ▶ 카누미니 마일드 10T× 6개, 카누미니 다크 10T×2개,
    카누미니 라이트 10T× 2개, 투게더머그

    사전예약 상품권 증정 카드할인 30% 담터 다정 종합세트
    카드할인가 19,320원
    ▶ 호두아몬드율무차 ,단호박마차, 쌍화차플러스
    생강차플러스, 대추차플러스 각 15T

    사전예약 상품권 증정 카드할인 30% 담터 다담 종합세트
    카드할인가 24,850원
    ▶ 호두아몬드율무차, 단호박마차, 쌍화차플러스, 생강차플러스
    각 15T, 캐모마일 15T, 꿀유자차 770g

    사전예약 상품권 증정 1+1 담터 허브티컬렉션 31,980원
    ▶ 마테차, 누룽지둥굴레차, 우엉차, 국화차, 도라지차, 쟈스민차,
    옥수수수염차, 루이보스, 페퍼민트, 캐모마일, 보이차, 허비스커스 각 5T

    사전예약 상품권 증정 카드할인 10% 담터 정담라이트
    카드할인가 20,880원
    ▶ 호두아몬드율무차 18g×50T, 쌍화차라이트 15g×15T,
    생강차라이트 15g×15T
    
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
    </div>
  `,
  `
  <div>
    일상용품 세트

    엘지 프로폴리스 기프트 프리미엄 9,990원
    ▶ 페리오 프로폴리스 치약 90g×4,   온더바디 프로폴리스 비누 80g×2 外

    10+1 애경 리미티드 셀렉션 11호 11,900원
    ▶ 케라시스 데미지 클리닉 샴푸 400ml, 샤워메이트 유자 바디워시 400ml 外

    10+1 엘지 생활의 품격 1호 13,900원
    ▶ 엘라스틴 10X 볼륨 샴푸/컨디셔너 200ml,
    오가니스트 체리블라썸 바디워시 200g 外

    L.POINT 30% 엘지 홈스파 벨먼 기획세트
    L.POINT할인가 35,700원
    ▶ 벨먼 스파 수분광채 바디워시 로즈 400g,
    벨먼 스파 수분광채 바디워시 자스민 400g 外

    카드할인 30% 엘지 히말라야 핑크솔트 에센셜세트
    카드할인가 27,230원
    ▶ 히말라야 핑크솔트 샴푸 200ml×2,
    히말라야 핑크솔트 바디워시 민트향 190ml 外

    카드할인 50% 아모레퍼시픽 섬김3호
    카드할인가 29,900원
    ▶ 해피바스 오리지널 컬렉션 라벤더 바디워시 490g,
    려 함빛 손상&영양 셀렉션 샴푸 400ml 外

    양말 선물세트
    스케쳐스 남성 목포인트/옆로고 단목 양말세트 각 5,980원 ▶ 멀티색상/2족
    PAT 남성 립중목/여성 다이아 중목 양말 세트 각 5,980원 ▶ 멀티색상/2족
    PAT 남성 다이아 중목/여성 다선컬러 중목 양말 세트 각 7,980원 ▶ 멀티색상/3족
    스케쳐스 여성 무지 단목 양말 세트 각 5,980원 ▶ 멀티색상/2족
    1+1 유렉시아 남성 고흐 점선 명화 양말 세트 6,900원 ▶ 멀티색상/2족 ※ 동일상품에 한함 (교차불가)
    1+1 유렉시아 남성 고흐 다이아 명화 양말 세트 8,900원 ▶ 멀티색상/3족 ※ 동일상품에 한함 (교차불가)
    1+1 유렉시아 여성 클림트 명화 양말/덧신 세트 각 6,900원 ▶ 멀티색상/2족 ※ 동일상품에 한함 (교차불가)
    3+1 레노마 성인 패턴 양말 세트 각 9,900원 ▶ 멀티색상/2족 ※ 동일상품에 한함 (교차불가)

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    3~4만원대 선물세트
    행사카드 구매 금액대별 최대 120만원 상품권증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    10+1 카드할인 20% 동원 스페셜 47호 
    카드할인가 31,920원
    ▶ 살코기참치 90g x 9, 카놀라유 480ml x 2
    
    카드할인 30% 동원 튜나리챔 30호
    카드할인가 34,930원
    ▶ 살코기참치 90g×12,
    리챔 더블라이트 120g×3

    카드할인 30% 동원 프리미엄 60호
    카드할인가 34,930원
    ▶ 살코기참치 90g x 9, 동원 참치액진 240g,
    참기름 75ml x 2, 건강요리유 480ml x 2

    카드할인 30% 동원 프리미엄 45호
    카드할인가 34,930원
    ▶ 살코기참치 90g x 5, 참기름 160ml, 카놀라유 480ml, 건강요리유 480 ml,
    올리고당 700g, 참치액 240g, 구운소금 210g, 사과식초 500ml

    L.POINT 20% 동원참치 M14호
    L.POINT할인가 38,960원
    ▶ 살코기참치 90g×4, 135g×10

    카드할인 30% 동원 리챔 G9호
    카드할인가 38,990원
    ▶ 리챔 200g×9

    카드할인 20% 동원 튜나리챔 64호
    카드할인가 39,920원
    ▶ 고추참치 90g×6, 살코기참치 135g×6, 리챔 120g×4

    카드할인 30% 동원 스페셜 38호
    카드할인가 39,970원
    ▶ 살코기참치 135g x 6, 리챔 더블라이트 200g x 4,
    리챔 120g x 4, 건강요리유 480ml x 2

    카드할인 30% 동원 스페셜 S호
    카드할인가 39,970원
    ▶ 살코기참치 135g x 8, 리챔 340g x 3, 건강요리유 480ml x 2

    L.POINT 30% 할인 동원 프리미엄 20호
    L.POINT할인가 40,950원
    ▶ 살코기참치 90g x 12, 리챔 120g x 4,
    동원 참치액진 240g x 2, 건강요리유 480ml x 2

    카드할인 20% 동원참치 T14호
    카드할인가 42,960원
    ▶ 살코기참치 135g x 10, 고추참치 135g x 4
    
    1+1 동원 리챔 더블라이트 M9호 49,900원
    ▶ 리챔 더블라이트 120g x 3, 리챔 더블라이트 200g x 6

    카드할인 30% 동원 튜나리챔 100호
    카드할인가 49,910원
    ▶ 살코기참치 135g×12, 리챔 200g×4

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
  </div>
  `,
  `
  <div>
    카드할인 30% 씨제이 스팸복합 1호
    카드할인가 33,530원
    ▶ 카놀라유 500ml×2, 스팸 200g×6

    카드할인 30% 씨제이 스팸복합 5호
    카드할인가 33,530원
    ▶ 카놀라유 500ml×2, 스팸 120g×9

    카드할인 30% 씨제이 특별한선택 스페셜 A호
    카드할인가 34,930원
    ▶ 카놀라유 500ml, 바삭한요리유 500ml, 사과식초 500ml, 천일염 170g,
    구운소금 180g, 스팸 200g x 4, 참치액 진 500ml, 올리고당 500g

    3+1 씨제이 백설 고소함가득 참기름 2호 35,900원
    ▶ 고소함가득 참기름 450ml, 참기름(병) 320ml×2

    카드할인 30% 씨제이 특별한선택 스페셜 S호
    카드할인가 38,430원
    ▶ 카놀라유 500ml x 2, 고소함가득참기름 330ml,
    천일염 170g, 구운소금 180g, 스팸 200g x 5

    카드할인 30% 씨제이 스팸복합 S호
    카드할인가 39,830원
    ▶ 카놀라유 500ml x 2, 스팸 200g x 5, 스팸 340g x 2,
    스팸 120g x 2

    카드할인 30% 씨제이 특별한선택 L-2호
    카드할인가 40,530원
    ▶ 카놀라유 500ml x 2, 요리올리고당 700g,
    스팸 200g x 6, 스팸 120g x 4
    
    카드할인 30% 씨제이 특별한선택 N호
    카드할인가 41,230원
    ▶ 카놀라유 500ml×2, 고소함가득참기름 330ml,
    스팸 200g×7

    카드할인 20% 씨제이 스팸 8K호
    카드할인가 41,520원
    ▶ 스팸 200g×6, 스팸 라이트 200g×3

    2+1 씨제이 특별한선택 K호 43,900원
    ▶ 카놀라유 500ml , 바삭한요리유 500ml, 요리올리고당 700g x 2,
    스팸 라이트 120g x 2, 스팸 120g x 4

    카드할인 30% 씨제이 특별한선택 JH호
    카드할인가 44,730원
    ▶ 카놀라유 500ml x 2, 요리올리고당 500g,
    스팸 200g x 6, 스팸 120g x 4, 올리고당 500g

    카드할인 30% 씨제이 특별한선택 L-5호
    카드할인가 49,630원
    ▶ 카놀라유 500ml x 2, 해바라기씨유 500ml,
    스팸 200g x 8, 스팸 120g x 4

    L.POINT 30% 씨제이 스팸복합 K호
    L.POINT 할인가 49,910원
    ▶ 카놀라유 500ml×2, 스팸 200g×8,
    스팸 120g×6

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    3~4만원대 선물세트
    행사카드 구매 금액대별 최대 120만원 상품권증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    5+1 청정원 맛선생 국물 티백 세트 37,000원
    ▶ 맛선생 국물내기티백 멸치 10g×6,
    해물 9g×6, 야채 6g×6

    10+1 청정원 맛선생 한알육수 세트 40,000원
    ▶ 국물내기 한알 멸치디포리 100g,
    야채 100g, 사골 87.5g

    10+1 카드할인 30% 청정원 스페셜 S2호
    카드할인가 36,260원
    ▶ 카놀라유 500ml x 2, 잇츠팜 115g, 맛술 생강&매실 410ml, 요리올리고당
    700g, 소갈비양념 280g, 정통현미식초 470ml, 진간장골드 500ml

    10+1 카드할인 30% 청정원 L8호
    카드할인가 37,660원
    ▶ 카놀라유 500ml, 맛선생 참치액 560g, 잇츠팜 115g x 6,
    정통사과식초 470ml, 요리올리고당 700g

    카드할인 30% 청정원 L9호
    카드할인가 39,760원
    ▶ 카놀라유 500ml x 2, 잇츠팜 115g x 2, 잇츠팜 190g x 2,
    우리팜델리 330g x 2, 요리올리고당 700g

    카드할인 30% 청정원 L10호
    카드할인가 43,960원
    ▶ 카놀라유 500ml, 잇츠팜 115g x 4, 맛선생 참치액 560g, 정통사과식초
    470ml, 요리올리고당 700g, 우리팜델리 115g x 2, 순살참치 135g x 2

    카드할인 30% 청정원 L11호
    카드할인가 46,760원
    ▶ 카놀라유 500ml x 2, 잇츠팜 115g x 4,
    우리팜델리 115g x 8, 요리올리고당 700g

    카드할인 30% 청정원 L12호
    카드할인가 49,560원
    ▶ 순 살참치 135g x 5, 카놀라유 500ml x 2, 정통사과식초 470ml,
    요리올리고당 700g, 우리팜델리 115g, 참기름 160ml, 천일염가는소금 190g

    카드할인 30% 사조 안심특선 99호
    카드할인가 34,300원
    ▶ 살코기참치 135g×6, 살코기참치 85g×6,
    안심팜 115g×4

    카드할인 30% 사조 안심특선 98호
    카드할인가 34,720원
    ▶ 카 놀라유 500ml, 바삭요리유 500ml, 참치액 500g, 사과식초 500ml,
    구운소금 200g, 살코기참치 85g x 5, 안심팜 115g x 2

    카드할인 30% 사조 살코기참치 6호
    카드할인가 38,850원
    ▶ 살코기참치 135g x 15

    L.POINT 10% 올리에 엑스트라버진 아보카도오일 선물세트
    L.POINT할인가 31,500원
    ▶ 올리에 엑스트라버진 아보카도오일 250ml×2,
    멕시코산

    L.POINT 20% 그로브 엑스트라버진 아보카도오일 세트 1호
    L.POINT할인가 32,800원
    ▶ 그로브 엑스트라버진 아보카도 오일 250ml×2,
    뉴질랜드산

    L.POINT 10,000원 할인 하이디라오 훠궈세트
    L.POINT할인가 49,800원
    ▶ 하이디라오 마라훠궈소스 110g×5, 마라탕소스 110g×5,
    마라야채 간편훠궈 272g, 토마토야채 간편훠궈 282g, 스테인레스 국자, 냄비 동봉

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
  </div>
  `,
  `
  <div>
    1+1 엘지 보타닉 퍼퓸컬렉션 30,900원
    ▶ 엘라스틴 보타닉 퍼퓸 샴푸/컨디셔너 480ml
    벨먼 보타닉 퍼퓸 바디워시 480ml 外

    카드할인 30% 엘지 생활의 품격 7호
    카드할인가 31,430원
    ▶ 닥터그루트 집중케어 샴푸/컨디셔너 180ml,
    오가니스트 바디워시 라벤더향 190ml×2 外

    카드할인 30% 엘지 히말라야 핑크솔트 로즈에디션
    카드할인가 32,830원
    ▶ 히말라야 핑크솔트 로즈 샴푸 380ml×2,
    히말라야 핑크솔트 로즈 바디워시 380ml×2 外

    카드할인 50% 아모레퍼시픽 섬김 4호
    카드할인가 33,900원
    ▶ 해피바스 오리지널 컬렉션 라벤더 바디워시 490g,
    려 함빛 손상&영양 셀렉션 샴푸 400ml×2 外

    1+1 엘지 샴푸&바디세트 프리미엄 33,900원
    ▶ 엘라스틴 데일리 리페어링 샴푸 400ml×2,
    온더바디 보타닉 레몬바디워시 480g 外

    1+1 애경 내추럴밀크 33호 33,900원
    ▶ 샤 워메이트 산양유 바디워시 400ml×3
    케라시스 오트 밀크 샴푸 400ml, 산양유 비누 80g×3

    2+1 엘지 정성담은 36호 36,600원
    ▶ 닥터그루트 밀도케어 샴푸 190ml,
    오가니스트 바디워시 190ml×2 外

    1+1 애경 프로폴리스 36호 36,900원
    ▶ 케라시스 로얄 프로폴리스 샴푸 400ml×2,
    샤워메이트 프로폴리스 바디워시 400ml 外

    카드할인 30% 엘지 히말라야 핑크솔트 프리미엄
    카드할인가 37,730원
    ▶ 히말라야 핑크솔트 샴푸 500ml,
    벨먼스파 핑크솔트 클렌징폼 200ml 外

    카드할인 30% 엘지 정성담은 모던 클래식
    카드할인가 40,530원
    ▶ 오가니스트 모던 샴푸 400ml×2,
    온더바디 모던 바디워시 레몬향 385ml 外

    1+1 엘지 생활의 감동 모던 라이프 41,900원
    ▶ 오가니스트 모던 샴푸 400ml,
    온더바디 모던 바디워시 레몬향 385ml 外

    카드할인 25% 아모레퍼시픽 섬김 5호
    카드할인가 43,425원
    ▶ 해피바스 오리지널컬렉션 라벤더 바디워시 490g,
    려 함빛 손상&영양 셀렉션 샴푸 400ml×2 外

    3+1 카드할인 30% 엘지 보타닉 퍼퓸 플라워페스타
    카드할인가 44,030원
    ▶ 엘라스틴 보타닉 퍼퓸 샴푸 480ml×2,
    온더바디 보타닉 퍼퓸 바디워시 480ml 外

    1+1 엘지 프로폴리스 라이프 45,900원
    ▶ 엘라스틴 프로폴리스 샴푸/컨디셔너 385ml,
    벨먼 프로폴리스 바디워시 190ml 外

    L.POINT 30% 엘지 생활의 품격 9호
    L.POINT할인가 46,830원
    ▶ 리엔 자윤 중건성 샴푸/컨디셔너 400ml
    닥터그루트 집중케어 샴푸 400ml 外

    1+1 엘지 생활의 감동 바디세트 라벤더 49,900원
    ▶ 온더바디 더 내추럴 바디워시 라벤더 500g×2
    온더바디 더 내추럴 바디로션 라벤더 400ml 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    5~6만원대 선물세트
    행사카드 구매 금액대별 최대 120만원 상품권증정 또는 즉시할인
    ※최대 120만원은 2025년 설 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    사전예약 상품권 증정 카드할인 20% 정관장 홍삼원기
    카드할인가 54,400원
    ▶ 50ml×60포

    사전예약 상품권 증정 카드할인 25% 정관장 홍삼진활력
    카드할인가 54,750원
    ▶ 35ml×30포

    사전예약 상품권 증정 1+1 씨제이 한뿌리 흑삼아르기닌 흑마늘 69,800원
    ▶ 100ml×6병, 50ml×12입

    카드할인 30% 동원 스페셜 102호
    카드할인가 52,990원
    ▶ 살코기참치 135g×9, 리챔 더블라이트 120g×8, 건강요리유 480ml×2

    카드할인 30% 동원 튜나리챔 102호
    카드할인가 54,950원
    ▶ 살코기참치 135g×9, 리챔 200g×8

    카드할인 30% 동원 프리미엄 86호
    카드할인가 54,950원
    ▶ 살코기참치 90g×7, 동원 참치액진 500g,
    동원 참치액순 500g, 리챔 120g×2 外

    L.POINT 25% 할인 동원 튜나리챔 11호
    L.POINT할인가 59,850원
    ▶ 살코기참치 135g×12, 고추참치 90g×8,
    리챔 더블라이트 200g×4

    1+1 동원 리챔 더블라이트 M9호 59,900원
    ▶ 리챔 더블라이트 120g×3,
    리챔 더블라이트 200g×6

    카드할인 30% 동원 스페셜 25호
    카드할인가 59,990원
    ▶ 살코기참치 90g×6, 리챔 120g×13,카놀라유 480ml×2

    카드할인 20% 동원 튜나리챔 10호
    카드할인가 69,920원
    ▶ 살코기참치 135g×18,
    리챔 200g×2, 리챔 340g

    카드할인 30% 씨제이 스팸복합 100호
    카드할인가 51,730원
    ▶ 카놀라유 500ml×2, 스팸 200g×8,
    스팸 340g×2, 스팸 120g×2

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
    </div>
  `,
  `
  <div>
    카드할인 30% 씨제이 특별한선택 스페셜 L-6호
    카드할인가 51,730원
    ▶ 카놀라유 500ml x 2, 고소함가득참기름 330ml, 구운소금 180g x 2,
    스팸 200g x 3, 스팸 라이트 200g x 6, 스팸 120g x 2

    카드할인 30% 씨제이 최고의선택 레드라벨
    카드할인가 67,830원
    ▶ 카놀라유 500ml×2,
    스팸 200g×8, 스팸 120g×5 外

    1+1 씨제이 Save Earth Choice LM호 69,900원
    ▶ 안달루시안 올리브유 500ml,
    퓨어 올리브유 500ml, 해바라기씨유 500ml

    카드할인 30% 청정원 L13호
    카드할인가 54,460원
    ▶ 카놀라유 500ml×2, 잇츠팜 115g×7,
    정통사과식초 470ml 外

    카드할인 30% 청정원 L14호
    카드할인가 59,990원
    ▶ 카놀라유 500ml, 어간장 410ml,
    잇츠팜 115g×6, 우리팜델리 115g×4 外

    씨제이 스팸 6호 69,900원
    ▶ 스팸 200g×12

    카드할인 30% 엘지 히말라야 핑크솔트 클래식
    카드할인가 50,330원
    ▶ 히말라야 핑크솔트 샴푸 500ml,
    히말라야핑크솔트 바디워시 480ml 外

    카드할인 30% 엘지 닥터그루트 밀도케어 세트
    카드할인가 58,030원
    ▶ 닥터그루트 밀도케어 샴푸 자스민 400ml,
    닥터그루트 밀도케어 샴푸 바질향 400ml 外

    카드할인 30% 엘지 시그니처 컬렉션
    카드할인가 59,430원
    ▶ 닥터그루트 밀도케어 샴푸 바질향 400ml,
    피지오겔 레드수딩 AI 크림 100ml 外

    10+1 엘지 명작 프리미엄 59호 59,900원
    ▶ 테크 세탁세제 975ml, 샤프란 핑크 1L,
    히말라야 핑크솔트 샴푸 380ml 外

    카드할인 30% 엘지 닥터그루트 프리미엄 세트
    카드할인가 65,100원
    ▶ 닥터그루트 힘없는 모발용 샴푸 400ml,
    닥터그루트 바이옴 캡슐 샴푸 400ml 外

    ※카드할인가는 롯데마트&MAXX/롯데마트맥스 신한/롯데/BC/KB국민/신한/NH농협/하나/현대/삼성/우리/기업/광주은행/전북은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    7~10만원대 이상 선물세트
    
    장 종합 선물세트 79,000원 전복장(800g), 간장새우장(600g), 양념게장(500g), 간장게장(500g), 밥도둑 반찬 4종 실속 종합세트
    ▶ 원산지 별도표기 ※온라인몰 전점 운영(페이지 상단 QR코드 연결 시 온라인몰 구입 가능), ※오프라인 잠실점 운영

    사전예약 상품권 증정 1+1 담터 발효홍삼 녹용쌍화K 89,000원
    ▶ 쌍화농축액 50 g x 30병

    씨제이 스팸 3K호 79,900원
    ▶ 스팸 200g×6, 스팸 300g×6

    카드할인 20% 동원 튜나리챔 6호
    카드할인가 99,920원
    ▶ 포도씨유참치 150g×12, 살코기참치 135g×12,
    리챔 200g×8

    풍기인삼 황풍정 6년근 홍삼정과 107,000원 ▶ 700g

    씨제이 스팸 블랙라벨 109,900원
    ▶ 안달루시안 올리브유 500ml×2, 스팸 340g×9

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다
  </div>
  `,
  `
  <div>
    명절에 가장 받고 싶은 최고의 선물 롯데상품권

    구입 장소 : 롯데마트 MAXX 매장 상품권 데스크
    상품권 종류 : 지류상품권 - 5천원/1만원/3만원/5만원/10만원/30만원/50만원 ※3만원권은 점별 보유 수량 상이
    결제 방법 : 현금(자기앞수표 포함), 법인카드, 체크카드 ※체크카드/자기앞수표는 100만원 한도
    사용 장소 
    쇼 핑 : 롯데마트 MAXX, 롯데마트(전점 및 문화센터, 토이저러스, 롯데하이마트), 롯데슈퍼, 롯데백화점, 롯데면세점, 롯데하이마트 등
    호 텔 : 롯데호텔, 시그니엘(서울, 부산), 롯데시티호텔, 그랜드워커힐, 서울프라자, 경주힐튼호텔, 메종글래드제주 등
    외 식 : T.G.I FRIDAY’S, 아웃백 스테이크하우스, 빕스, 크리스피 크림도넛, 매드포갈릭, 딘타이펑 등
    골 프 : 롯데스카이힐 C.C(부여, 제주), 프라자 C.C(용인, 설악, 제주), 베어즈베스트 청라 골프클럽 등
    레 저 : 롯데월드, 롯데JTB, 롯데리조트(부여, 속초, 제주), 서울랜드, 파라다이스 스파 도고, 한솔오크밸리, 한화리조트 등
    문화/예술 : 롯데시네마, 롯데문화센터, 롯데콘서트홀, 롯데뮤지엄, 영풍문고

    ※사용처별 일부매장 제외 ※사용처는 변경될 수 있습니다 ※롯데상품권 관련 세부내용은 롯데백화점 홈페이지 참고
  </div>
  `,
  `
  <div>
    롯데마트맥스 지점 안내

    롯데마트맥스 금천점
    서울시 금천구 두산로 71
    Tel :  02-809-2500

    롯데마트맥스 상무점
    광주 서구 시청로 40
    Tel : 062-602-2500

    롯데마트맥스 영등포점
    서울시 영등포구 영중로 125
    Tel :02-2165-2500

    롯데마트맥스 목포점
    전남 목포시 원형서로 15
    Tel : 061-280-2500

    롯데마트맥스 송천점
    전북 전주시 덕진구 송천중앙로 82
    Tel : 063-219-2500

    롯데마트맥스 창원중앙점
    경남 창원시 성산구 중앙대로 123
    Tel : 055-240-2500
  </div>
  `,
];

/* 909 슈퍼 설 사전예약 (~1/15) */
const L909data = [
  `
  <h1>LOTTE Super</h1>
  <div>
    2025년 설레는 그날
    2025년 푸른 뱀의 해(을사년) 새해가 밝았습니다!
    건강하고 행복 가득한 한 해 되시길 기원합니다.
    사전예약 기간 2024년 12월 12일 목요일부터 2025년 1월 15일 수요일까지
  </div>
  `,
  `
  <div>
    미리 준비할수록 더 큰 혜택!
    ※사전예약 기간 : 2024. 12. 12(목) ~ 2025. 1. 15(수)
    롯데카드, 신한카드, 삼성카드, 국민카드, 비씨카드, 농협카드, 하나카드
    최대 120만원 상품권 증정
    1차 사전예약 2024년 12월 12일 화요일부터 2025년 1월 8일 수요일까지 10만원 이상 1만원, 20만원이상 1만원, 30만원이상 3만6천원(하단 세부사항 참고), 50만원이상 6만원, 100만원~1,000만원미만 100만원당 12만원, 1,000만원이상 120만원
    2차 사전예약 2025년 1월 9일 화요일부터 2025년 1월 15일 수요일까지 10만원 이상 5천원, 20만원이상 1만원, 30만원이상 2만5천원, 50만원이상 4만원, 100만원~1,000만원미만 100만원당 8만원, 1,000만원이상 80만원
    ※해당 기간에 한함 ※상품권 : 3만원 5천원 상품권 + 1천원 추가할인(사전예약 1차 기간 내 30만원 이상에 한함)
    ※자세한 내용은 매장 내 고지물 및 롯데마트GO앱 참조
    ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간내) ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외
    ※단일카드로 전액 결제시에 한함 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※당일 영수증에 한하며, 1人 1日 1회 비연속식 증정
    ※상품권 증정 프로모션은 변경될 수 있습니다
    플러스 최대 50% 할인혜택. 카드할인 아이콘확인.
    ※법인/체크카드 포함 ※기프트카드/선불카드 제외 ※해당 단일카드로 전액 결제시 적용
    ※해당상품에 한함 ※상품별 할인율 상이 ※상품권 증정 혜택과 중복 적용
    L.POINT회원 특별할인 ※해당상품에 한함
    구매 수량에 따라 덤 증정 하나 더 ※해당상품에 한함
    3만원 이상 구매시 전국 무료배송 ※일부품목 제외 (일부 도서 산간 지역 제외) ※결제금액 기준 ※롯데마트몰은 무료배송 기준 별도 운영
    ※자세한 내용은 매장 내 고지물 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음
    </div>
  `,
  `
  <div>
    마블나인 9의 자격, 오직 1%의 한우에게만
    마블나인 한우는 왜 특별한가요?
    1. 투쁠 한우 중에서도 가장 높은 9등급 한우만 취급
    2. 합리적인 가격을 위해 경매부터 정형까지 직접
    3. 축소된 롯데만의 물류단계로 더 빠르게 더 신선하게
    온라인단독 3구 세트 99,000원 
    등안채 세트(등심, 안심, 채끝) 각 1++(9)등급, 200g 냉장, 국내산 한우고기
    차업치 세트(차돌박이, 업진살, 치마살) 각 1++(9)등급, 150g 냉장, 국내산 한우고기
    꽃늑토 세트(꽃/본갈비살, 늑간(갈비)살, 토시살) 각 1++(9)등급, 150g 냉장, 국내산 한우고기
    온라인단독 6구 세트 179,000원
    등안채+차업치 세트 (등심, 안심, 채끝 + 차돌박이, 업진살, 치마살) 1++(9)등급, 등심/안심/채끝, 각 200g 그 외 각 150g, 냉장, 국내산 한우고기
    등안채+꽃늑토 세트 (등심, 안심, 채끝 + 꽃/본갈비살, 늑간(갈비)살, 토시살)) 1++(9)등급, 등심/안심/채끝, 각 200g 그 외 각 150g, 냉장, 국내산 한우고기
    롯데마트몰에서 편하게 구매하세요!
    카카오톡 선물하기에서도 롯데 푸드 마켓 or 마블나인을 검색해보세요!
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    2025년 설레는 그 날, 최상급 프리미엄 한우 마블나인을 선물하세요. 대한민국 한우 구이, 정육 세트
    마블나인 9의 자격, 오직 1%의 한우에게만
    마블나인은 1++ 한우 (7-9등급) 중에서도 근내지방도 19% 이상만을 엄선한 최상듭(9등급)만을 선보입니다.
    ※23년 한우 전체 등급 낙찰 두수 53.1만두 중 롯데마트 한우 MD 직접 경매 1++(9)등급 마블나인 두수 801두
    10+1 L.POINT 50,000원 할인 마블나인 프리미엄 혼합세트 1++(9)등급 판매가 749,000원 L.POINT할인가 699,000원 1++(9)등급 한우 등심 500g×2, 안심, 채끝 각 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 구이세트 3호 1++(9)등급 판매가 399,000원 L.POINT할인가 349,000원 1++(9)등급 한우 등심, 채끝, 설깃 스테이크, 찹스테이크 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 등심채끝양지 혼합세트 1++(9등급) 판매가 549,000원 L.POINT할인가 499,000원 1++(9)등급 한우 등심 500g×2, 채끝 500g×2, 양지 500g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 구이세트 2호 1++(9)등급 판매가 499,000원 L.POINT할인가 439,000원 1++(9)등급 한우 등심 500g×2, 채끝, 부채 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 한우 갈비세트 1++(9)등급 (냉동) 판매가 349,000원 L.POINT할인가 299,000원 1++(9)등급 한우 냉동 찜갈비 700g×5, 냉동, 국내산 한우고기 ※전국 택배 운영
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외
    </div>
  `,
  `
  <div>
    10+1 L.POINT 30,000원 할인 마블나인 한우 정육세트 1호 판매가 199,000원 L.POINT할인가 169,000원 1등급 한우 국거리, 불고기 각 500g×3, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 30,000원 할인 마블나인 한우 정육세트 2호 판매가 129,000원 L.POINT할인가 99,000원 1등급 한우 국거리, 불고기 각 500g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 무항생제 한우 정육세트 3호(1+등급) 판매가 99,000 L.POINT할인가 149,000원 무항생제 1+등급 한우 국거리, 불고기 각 500g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 한우 등심 안심 혼합세트 판매가 279,000원 L.POINT할인가 229,000원 1등급 한우 등심 500g×2, 안심 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 한우 구이 스페셜 세트 판매가 289,000원 L.POINT할인가 239,000원 1등급 한우 등심 500g, 채끝, 안심, 부채살 각 400g, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 한우 등심세트 1호 판매가 389,000원 L.POINT할인가 339,000원 1등급 한우 등심팩 500g×6, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 한우 등심세트 2호 판매가 289,000원 L.POINT할인가 239,000원 1등급 한우 등심팩 500g×4, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 한우 등심 채끝 혼합세트 판매가 299,000원 L.POINT할인가 249,000원 1등급 한우 등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 무항생제 한우 등심 채끝 혼합세트(1+등급) 판매가 319,000원 L.POINT할인가 269,000원 무항생제 1+등급 한우 등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 한우 등심 정육세트1호 판매가 269,000원 L.POINT할인가 219,000원 1등급 한우 등심, 국거리, 불고기 각 500g×2, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 무항생제 한우 등심 정육세트 5호(1+등급) 판매가 259,000원 L.POINT할인가 209,000원 무항생제 1+등급 한우 등심 500g×2, 국거리, 불고기 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영
    10+1 L.POINT 50,000원 할인 마블나인 무항생제 한우 등심세트 1호(1+등급) 판매가 369,000원 L.POINT할인가 319,000원 무항생제 1+등급 한우 등심 500g×6, 냉장, 국내산 한우고기 ※전국 택배 운영
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    </div>
  `,
  `
  <div>
    신선을 새롭게 롯데슈퍼의 새로운 신선함 RE:FRESH를 만나보세요

    신선식품의 모든 과정을 다시 연구하고
    새롭게 바꾸는 RE:FRESH 프로젝트를 통해
    가장 신선한 경험을 전달합니다

    신선식품의 소싱 단계와 품질 기준을 강화하며,
    매장 내 진열 및 신선도 관리 등을 철저하고 꼼꼼하게 재설계합니다.
    신선식품 전체(축산/수산/과일/채소)에 적용되며,
    온/오프라인에서 다양한 RE:FRESH 상품을 만나보실 수 있습니다.
    
    사과에 전부를 걸었다
    매일 먹는 사과니까 3단계 선별로 껍질까지 완벽하게 롯데슈퍼 올인사과

    L.POINT 10,000원 할인 AI로 선별한 영주 소백산 사과 판매가 99,900원 L.POINT할인가 89,900원 사과 12~14입(4.2kg 내외), 국산
    L.POINT 20,000원 할인 충주 김택성 농부의 GAP 사과 판매가 129,900원 L.POINT할인가 109,900원 사과 11~12입(4.5kg 내외), 국산
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외
  </div>
  `,
  `
  <div>
    전문 MD가 직접 경매에 참가하여 엄선한
    품질 좋은 한우 선물세트!
    불고기/국거리에 적합한
    최적의 부위와 두께를 엄선!
    10+1 L.POINT 50,000원 할인 한우 등심정육세트 2호 판매가 229,000원 L.POINT할인가 179,000원 ▶ 1등급 한우 등심 500g×2, 국거리, 불고기 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영

    최적의 갈비 두께 12mm!
    롯데 신선품질혁신센터
    꽃갈비 상품화 공정 강화를 통한
    업계 최상의 품질 제공 가능!
    ※상품권 증정 제외
    L.POINT 30,000원 할인 호주산 소 LA식 갈비세트 판매가 169,000원 L.POINT할인가 139,000원 호주산 냉동 꽃갈비 1.5kg×2 ※전국 택배 운영 ※250세트 한정

    제주 수산물 명인이
    전통 채낚기 방식으로 어획하여
    건강하게 은빛 비늘이 살아있는 은갈치!
    10+1 L.POINT 10,000원 할인 실속 갈치세트 판매가 119,000원 L.POINT할인가 109,000원 갈치 330g 내외×4팩, 냉동, 국산

    귀경길 손쉽게 들고 갈 수 있는
    핸드캐리형 선물세트!
    ※상품권 증정 제외
    ※택배 불가
    충주사과 29,900원 사과 12~14입(3.0kg 내외), 국산
    나주배 39,900원 배 4~6입(3.0kg 내외), 국산

    바다째로 퍼와버린 롯데슈퍼 집념전복
    하다하다 해수까지 그대로 운반하고 보관하다
    롯데슈퍼 집념전복
    300세트 한정 7+1 L.POINT 20,000원 할인 ASC 인증 완도 전복세트 판매가 219,000원 L.POINT할인가 199,000원 ASC 인증 완도전복 2kg, 전복 : 국산 14~15마리, 7미 사이즈, 마리당 140g 내외
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    신선함을 담았습니다 과일 선물세트
    L.POINT 10,000원 할인 GAP 사과, 배(情) 판매가 59,900원 L.POINT할인가 49,900원 사과 5입(1.4kg 내외), 배 4입(2.2kg 내외), 국산
    L.POINT 10,000원 할인 프라임 사과, 배 판매가 89,900원 L.POINT할인가 79,900원 사과 4입(1.2kg 내외), 배 6입(3.6kg 내외), 국산
    L.POINT 4,000원 할인 충주사과, 천안배 판매가 89,900원 L.POINT할인가 85,900원 사과 6입(1.7kg 내외), 배 6입(3.6kg 내외), 국산
    L.POINT 20,000원 할인 황금사과 품은 사과, 배 판매가 129,900원 L.POINT할인가 109,900원 사과 8입(2.4kg 내외), 배 6입(3.9kg 내외), 국산
    L.POINT 10,000원 할인 정성담은 사과, 배 판매가 109,900원 L.POINT할인가 99,900원 사과 8입(2.6kg 내외), 배 6입(3.9kg 내외),국산
    L.POINT 10,000원 할인 안성맞춤 배, 밀양얼음골 사과 판매가 L.POINT할인가 89,900원 사과 6입(1.7kg 내외), 배 6입(3.6kg 내외), 국산
    샤인머스캣, 메론  판매가 99,900원 샤인머스캣 2송이(2.0kg 내외), 머스크 메론 2입(3.2kg 이상), 국산
    L.POINT 20,000원 할인 샤인머스캣, 사과, 배, 한라봉 판매가 109,900원 L.POINT할인가 89,900원 샤인머스캣 1송이(0.7kg 내외), 사과 3입(0.9kg 내외), 배 3입(1.8kg 내외), 한라봉 3입(0.8kg 내외), 국산
    L.POINT 20,000원 할인 한라봉 품은 사과, 배 판매가 129,900원 L.POINT할인가 109,900원 사과 4입(1.2kg 내외), 배 6입(3.9kg 내외), 한라봉 4입(1.4kg 내외),국산
    L.POINT 10,000원 할인 샤인머스캣, 사과 판매가 79,900원 L.POINT할인가 69,900원 샤인머스캣 3송이(2.1kg내외), 사과 6입(2.0kg 내외), 국산
    L.POINT 20,000원 할인 샤인머스캣, 사과, 배 판매가 139,900원 L.POINT할인가 119,900원 샤인머스캣 2송이(1.4kg 내외), 사과 4입(1.2kg 내외), 배 6입(3.9kg 내외), 국산
    L.POINT 20,000원 할인 자이언트 사과, 배 판매가 149,900원 L.POINT할인가 129,900원 사과 5입(2.1kg 내외), 배 4입(3.6kg 내외), 국산
    L.POINT 4,000원 할인 나주 청미래 배 판매가 79,900원 L.POINT할인가 75,900원 배 7~9입(5.0kg 내외), 국산
    L.POINT 10,000원 할인 정성담은 신고배 판매가 89,900원 L.POINT할인가 79,900원 배 9~11입(6.5kg 내외), 국산
    L.POINT 20,000원 할인 나주 배 판매가 109,900원 L.POINT할인가 89,900원 배 9~11입(6.5kg 내외), 국산
    산지뚝심 천안 지순태 농부의 GAP 배  판매가 129,900원 배 8-9입(6.7kg 내외), 국산
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외
  </div>
  `,
  `
  <div>
    L.POINT 10,000원 할인 충주 프레샤인 사과 판매가 59,900원 L.POINT할인가 49,900원 사과 17~20입(5.0kg 내외), 국산
    L.POINT 10,000원 할인 보은사과 판매가 69,900원 L.POINT할인가 59,900원 사과 14~16입(4.5kg 내외), 국산
    L.POINT 10,000원 할인 문경사과 판매가 79,900원 L.POINT할인가 69,900원 사과 12~14입(4.5kg 내외), 국산
    L.POINT 10,000원 할인 정성담은 사과 판매가 79,900원 L.POINT할인가 69,900원 사과 12~14입(4.2kg 내외), 국산
    L.POINT 10,000원 할인 충주 사과 판매가 79,900원 L.POINT할인가 69,900원 사과 12~14입(4.2kg 내외), 국산
    L.POINT 10,000원 할인 황금사과 판매가 89,900원 L.POINT할인가 79,900원 사과 12~14입(4.2kg 내외), 국산
    L.POINT 10,000원 할인 밀양얼음골 사과 판매가 99,900원 L.POINT할인가 89,900원 사과 12~14입(4.2kg 내외), 국산
    L.POINT 10,000원 할인 제주 천혜향 판매가 59,900원 L.POINT할인가 49,900원 천혜향 8~13입(3.0kg 내외), 국산
    L.POINT 10,000원 할인 샤인머스캣 3kg 판매가 69,900원 L.POINT할인가 59,900원 샤인머스캣 3kg 내외, 국산
    L.POINT 10,000원 할인 샤인머스캣 4kg 판매가 79,900원 L.POINT할인가 69,900원 샤인머스캣 4kg 내외, 국산
    L.POINT 10,000원 할인 제주 천혜향, 레드향, 한라봉 판매가 79,900원 L.POINT할인가 69,900원 4kg(천혜향 4입(1.4kg 내외), 레드향 4입(1.3kg 내외), 한라봉 4입(1.3kg 내외)), 국산
    L.POINT 4,000원 할인 제스프리 제주골드키위 판매가 39,900원 L.POINT할인가 35,900원 골드키위 20입(2.0kg내외), 국산
    페루 애플망고 판매가 59,900원 애플망고 9입(3.6kg 내외), 페루산
    망고 혼합 판매가 69,900원 옐로우 망고 3입(1.0kg 내외), 애플망고 6입(2.4kg내외), 원산지 별도표기
    태국산 망고 판매가 79,900원 옐로우 망고 9입 (3.2kg 내외), 태국산
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    곶감 선물세트
    상주 곶감(福) 29,990원 곶감 30입(0.9kg 내외), 국산
    L.POINT 10,000원 할인 GAP 청도 반건시 판매가 59,900원 L.POINT할인가 49,900원 청도 반건시 30입 (1.5kg 내외), 국산
    L.POINT 10,000원 할인 상주 곶감(情) 판매가 69,900원 L.POINT할인가 59,900원 곶감 30입(1.2kg 내외), 국산
    L.POINT 10,000원 할인 산청 곶감 (情) 판매가 79,900원 L.POINT할인가 69,900원 곶감 32입 (1.1kg 내외),국산
    L.POINT 10,000원 할인 상주 무농약 왕곶감 판매가 89,900원 L.POINT할인가 79,900원 곶감 24입 (1.2kg 내외), 국산
    L.POINT 20,000원 할인 상주 왕곶감 판매가 129,900원 L.POINT할인가 109,900원 곶감 32입 (1.6kg 내외), 국산
    L.POINT 20,000원 할인 크림치즈와 호두 품은 곶감 판매가 119,900원 L.POINT할인가 99,900원 호두치즈곶감 375g×2팩, 곶감 12입(0.4kg 내외), 원산지 별도표기
    3대 유명산지 곶감 139,900원 곶감 45입 (2.1kg 내외), 국산
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외
  </div>
  `,
  `
  <div>
    견과류 선물세트
    1+1 넛츠박스 매일견과 세트 (20봉) 19,900원 매일견과 20g×20봉, 호두 5g(미국), 구운아몬드 5g(미국), 구운땅콩 5g(미국), 건포도 5g(칠레), 원산지 별도표기
    L.POINT 20,000원 할인 매일견과 하루한줌(80봉) 판매가 49,900원 L.POINT할인가 29,900원 매일견과 18g×80봉(호두 30%, 구운 아몬드 20%, 구운 캐슈넛 12%, 건포도 38%), 원산지별도표기
    L.POINT 20,000원 할인 데일리넛츠 하루한봉 (80봉) 판매가 49,900원 L.POINT할인가 29,900원 매일견과 18g×80봉(구운 땅콩 25%, 해바라기씨 25%, 건포도 20%, 호두 15%, 아몬드 15%), 원산지 별도 표기
    L.POINT 10,000원 할인 더 고소한 마카다미아 매일견과(50봉) 판매가 39,900원 L.POINT할인가 29,900원 매일견과 20g×50봉, 호두 30%, 구운아몬드 30%, 건포도(칠레) 25%, 마카다미아(호주) 15%, 원산지 별도표기
    L.POINT 7,000원 할인 HBAF 먼투썬 하루견과세트 판매가 39,900원 L.POINT할인가 32,900원 바프 먼투썬 하루견과 20g×42봉, 원산지 별도 표기
    L.POINT 10,000원 할인 명작 견과 & 건과일 10종 세트 판매가 49,900원 L.POINT할인가 39,900원 구 운아몬드 170g(미국), 호두 110g(미국), 구운캐슈넛 150g(베트남), 구운피스타치오 120g(미국) 外, 원산지 별도표기
    L.POINT 10,000원 할인 HBAF 원담 견과세트 6호 판매가 54,900원 L.POINT할인가 44,900원 꿀홍삼땅콩 130g, 마누카 허니마카다미아 130g, 호지차아몬드 130g 外, 원산지 별도표기
    L.POINT 10,000원 할인 특선 견과 선물세트 6종 판매가 54,900원 L.POINT할인가 44,900원 구운아몬드 150g(미국), 호두 100g(미국), 구운캐슈넛 140g(베트남) 外, 원산지 별도표기
    L.POINT 10,000원 할인 BEST 견과 10종 세트 판매가 59,900원 L.POINT할인가 49,900원 구운아몬드 170g(미국), 호두 110g(미국), 구운캐슈넛 150g(베트남), 피칸 120g(미국) 外, 원산지 별도표기
    L.POINT 10,000원 할인 프리미엄 정과 & 스무스 세트 판매가 59,900원 L.POINT할인가 49,900원 호두정과 250g, 피칸정과 250g,아몬드 스프레드 스무스 400g, 피넛버터 스무스 400g, 원산지 별도 표기
    L.POINT 20,000원 할인 하이그레이드 6종 견과선물세트 판매가 99,900원 L.POINT할인가 79,900원 구운 아몬드 220g, 호두 160g, 구운캐슈너트 210g, 피칸 170g, 브라질너트 240g, 마카다미아 210g, 원산지 별도 표기
    L.POINT 20,000원 할인 프리미엄 무염 견과 9종 세트 판매가 99,900원 L.POINT할인가 79,900원 호두 160g(미국), 구운아몬드 210g(미국), 구운캐슈넛 180g(베트남) 外, 원산지 별도 표기
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    인기만점 육즙 가득한 소고기&돼지고기 선물세트
    L.POINT 30,000원 할인 미국산 소 냉동 혼합 갈비 세트(3kg) 판매가 149,000원 L.POINT할인가 119,000원 미국산 냉동 꽃갈비, 찜갈비 각 1.5kg ※전국 택배 운영 ※100세트 한정
    L.POINT 30,000원 할인 미국산 소 냉동 LA식 갈비 세트(3kg) 판매가 159,000원 L.POINT할인가 129,000원 미국산 냉동 꽃갈비 구이용1.5kg×2 ※전국 택배 운영 ※900세트 한정
    L.POINT 15,000원 할인 제주 흑돈 구이세트 1kg 판매가 69,000원 L.POINT할인가 54,900원 국내산 냉장 흑돼지 삼겹살/목심 각 500g ※산지택배(전국택배) 전용
    L.POINT 50,000원 할인 제주 흑돼지 모둠구이 선물세트 2kg 판매가 139,000원 L.POINT할인가 89,000원 국내산 냉장 흑돼지 삼겹살 500g×2, 흑돼지 목심, 앞다리 각 500g ※산지택배(전국택배) 전용
    L.POINT 10,000원 할인 캐나다 인증 돼지고기 선물세트 판매가 59,000원 L.POINT할인가 49,000원 캐나다산 냉장 돼지고기 삼겹살, 목심 각 1kg ※전국 택배 전용
    L.POINT 30,000원 할인 호주산 냉동 찜갈비 세트(2.4kg) 판매가 129,000원 L.POINT할인가 99,000원 호주산 냉동 찜갈비 800g×3, 양념소스 ※전국 택배 운영 ※850세트 한정
    L.POINT 30,000원 할인 한우 냉동 실속 정육세트 판매가 129,000원 L.POINT할인가 99,000원 한우 냉동 국거리 600g, 불고기 600g, 산적 600g, 양념소스, 냉동,국내산 한우고기 ※전국 택배 운영
    L.POINT 50,000원 할인 시즈닝스테이크세트(1.8kg, 냉장) 판매가 159,000원 L.POINT할인가 109,000원 미국산 시즈닝 채끝 스테이크 200g×3, 미국산 시즈닝 부채살 스테이크 200g×3 外 ※산지택배(전국배송) 전용
    L.POINT 50,000원 할인 양념 한우불고기 모둠세트(2.4kg, 냉동) 판매가 179,000원 L.POINT할인가 129,000원 양념 한우불고기 1.2kg, 한우 언양식 불고기 1.2kg ※전국 택배 전용
    L.POINT 60,000원 할인 양념 소LA식 갈비세트 판매가 189,000원 L.POINT할인가 129,000원 2.4kg, 냉동, 호주산 ※전국 택배 전용
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외
  </div>
  `,
  `
  <div>
    L.POINT 40,000원 할인 호주산 청정와규 선물세트 1호 판매가 139,000원 L.POINT할인가 99,000원 호주산 냉장 와규 윗등심 400g×2, 양지 국거리 400g, 정육 불고기 400g ※전국 택배 운영
    L.POINT 20,000원 할인 호주산 청정와규 선물세트 2호 판매가 99,000원 L.POINT할인가 79,000원 호주산 냉장 와규 양지 국거리 400g×2, 정육 불고기 400g×2 ※전국 택배 운영
    L.POINT 30,000원 할인 호주산 청정와규 선물세트 3호 판매가 139,000원 L.POINT할인가 109,000원 청정와규 냉장 부채살 400g×2, 삼각살, 꾸리살 각 400g ※전국 택배 운영
    L.POINT 30,000원 할인 한우 갈비세트 2호 판매가 129,000원 L.POINT할인가 99,000원 한우 냉동 찜갈비 600g×2, 양념소스, 냉동, 국내산 한우고기 ※전국 택배 운영
    L.POINT 50,000원 할인 한우 냉동 갈비세트 판매가 199,000원 L.POINT할인가 149,000원 한우 냉동 찜갈비700g×3, 양념소스, 냉동, 국내산 한우고기 ※전국 택배 운영
    L.POINT 60,000원 할인 양념 소 LA식 갈비 세트 (2.4kg) (냉동) 판매가 189,000원 L.POINT할인가 129,000원 호주산 양념 소 LA식 갈비 2.4kg ※센터택배(전국배송) 전용
    L.POINT 20,000원 할인 무항생제 한우 꼬리반골세트 판매가 79,000원 L.POINT할인가 59,000원 무항생제 1+등급 한우 꼬리반골 5kg, 냉동, 국내산 한우고기 ※전국 택배 운영
    L.POINT 20,000원 할인 무항생제 한우 사골세트 판매가 79,000원 L.POINT할인가 59,000원 무항생제 1+등급 한우 사골 5kg, 냉동, 국내산 한우고기 ※전국 택배 운영
    L.POINT 30,000원 할인 한우 알꼬리 선물세트 판매가 129,000원 L.POINT할인가 99,000원 한우 알꼬리 2kg, 냉동, 국내산 한우고기 ※전국 택배 운영
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    생선&멸치&수산물 선물세트
    10+1 L.POINT 10,000원 할인 노르웨이 고등어 필렛 세트 판매가 59,000원 L.POINT할인가 49,000원 고등어필렛 1.5kg(750g×2팩, 팩당 5~6필렛), 냉동, 노르웨이산
    L.POINT 10,000원 할인 홈마카세 세트 (실속) 판매가 59,000원 L.POINT할인가 49,000원 우니 100g, 단새우 60g×2팩, 감태 4g×2팩, 회초장 40g, 와사비 3g×4, 간장 6g×4, 냉동, 원산지 별도표기
    7+1 L.POINT 10,000원 할인 반건조 생선 선물세트 판매가 69,000원 L.POINT할인가 59,000원 우럭 2~300g/1미, 조기 2~300g/1미, 참돔 250g/1미, 서대 100g/3미, 냉동, 원산지 별도표기
    7+1 자연담은 칠산소금 참굴비 실속 89,900원 10마리 (0.9kg 내외), 냉동, 참조기/천일염 : 국산 
    7+1 자연담은 칠산소금 참굴비 특호 199,000원 10마리 (1.2kg 내외), 냉동, 참조기/천일염 : 국산
    10+1 L.POINT 10,000원 할인 제주 은갈치/옥돔 혼합 세트 판매가 119,000원 L.POINT할인가 109,000원 갈치 2마리 600g 내외, 옥돔 2마리 400g 내외, 냉동, 국산
    10+1 L.POINT 20,000원 할인 으뜸 옥돔세트 판매가 209,000원 L.POINT할인가 189,000원 3마리 1.5kg 내외/ 냉동, 옥돔 : 국산
    7+1 마른부세굴비세트 89,900원 1.0kg (5마리), 부세 : 중국산, 천일염 : 국산
    L.POINT 15,000원 할인 청정곳간 유명산지 통영 멸치세트 판매가 56,900원 L.POINT할인가 41,900원 어린이 멸치 230g, 볶음용 멸치 230g, 조림용 멸치 210g, 국물용 멸치 210g, 멸치 : 국산
    L.POINT 15,000원 할인 청정곳간 유명산지 멸치·새우 혼합세트 판매가 56,900원 L.POINT할인가 41,900원 볶음용 멸치 140g, 볶음조림용 멸치 170g, 국물용 멸치 170g, 두절 꽃새우 80g, 멸치 : 국산 , 새우 : 국산
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외
  </div>
  `,
  `
  <div>
    추석 인기 선물 프리미엄 김 선물세트
    10+1 동원 양반 들기름김세트 9,900원 들기름 식탁김 4g×18봉, 원산지 별도표기
    10+1 CJ 비비고 토종김 5호 9,900원 비비고 참기름 도시락김 4g×18봉, 원산지 별도표기
    L.POINT 5,000원 할인 성경 종합 P호 판매가 22,900원 L.POINT할인가 17,900원 들기름 전장김 20g×4봉 , 들기름 식탁김 4g×12봉, 들기름 김자반 50g×2봉, 원산지 별도표기
    광천 명작 유기원초 재래김세트 14,900원 명작 유기원초 재래김 4.5g×30봉, 원산지 별도표기
    L.POINT 5,000원 할인 광천 자연나라 혼합 캔김세트 판매가 24,900원 L.POINT할인가 19,900원 재래 캔김 30g , 파래 캔김 30g , 곱창 캔김 30g, 무조미 곱창 캔김 30g, 원산지 별도표기
    L.POINT 3,000원 할인 대천 곱창 도시락김 세트 판매가 25,900원 L.POINT할인가 22,900원 곱창 도시락김 5g×54봉, 원산지 별도표기
    L.POINT 30% 할인 동원 양반 돌김세트 판매가 57,100원 L.POINT할인가 39,970원 돌김 캔 30g×8입, 원산지 별도표기
    L.POINT 30% 할인 CJ 초사리 곱창돌김 1호 판매가 56,900원 L.POINT할인가 39,830원 초사리 곱창돌김 전장 20g×3봉 , 초사리 곱창돌김 25g×2캔, 초사리 곱창돌김 도시락김 5g×8봉, 원산지 별도표기
    L.POINT 5,000원 할인 성경 녹차 곱창돌김세트 판매가 44,900원 L.POINT할인가 39,900원 녹차곱창돌김 캔김 30g×4캔 , 녹차곱창돌김 전장김 20g×4봉, 원산지 별도표기
    L.POINT 7,000원 할인 대천 곱창김 혼합 1호 판매가 46,900원 L.POINT할인가 39,900원 곱창 전장김 25g×8봉, 곱창김 캔 30g×3개, 원산지 별도표기
    L.POINT 3,000원 할인 대천 명품 캔김 세트 6캔 판매가 31,900원 L.POINT할인가 29,900원 곱창김 30g×6캔, 원산지 별도표기
    L.POINT 5,000원 할인 대천 명품 캔김세트 15캔 판매가 64,900원 L.POINT할인가 59,900원 곱창김 캔 30g×15, 원산지 별도표기
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    버섯&인삼&홍삼
    L.POINT 10,000원 할인
    자연향 동고 혼합세트 29,900원
    ▶ 동고 70g, 표고채 70g, 표고칩 75g, 국산

    L.POINT 20,000원 할인
    자연향 버섯 혼합세트 2호 39,900원
    ▶ 동고 40g, 표고채 30g, 건목이 40g, 흰목이 30g, 표고칩 35g, 향신 35g, 국산

    L.POINT 10,000원 할인
    6년근 실속 금산 인삼세트 29,900원
    ▶ 6년근 인삼 난발 350g, 국산

    L.POINT 10,000원 할인 
    웰빙표고 실속세트 2호 44,900원
    ▶ 동고 70g, 표고채 60g, 표고사각 30g, 핑크솔트140g, 흑후추 70g, 원산지 별도표기

    카드할인 10,000원 할인
    4년근 금산인삼세트 99,000원
    ▶4년근 인삼 700g, 국내산

    카드할인 10,000원 할인
    깊은향 더덕세트 49,900원
    ▶더덕 1kg, 국산

    L.POINT 10,000원 할인
    국내산 벌집꿀 99,000원
    ▶ 1.5kg, 밀랍포함, 국산

    L.POINT 15,000원 할인
    친환경 표고버섯 혼합1호 49,900원
    ▶ 친환경 흑화고 100g, 친환경표고채 70g×2, 국산

    L.POINT 20,000원 할인
    팔각 어울림 버섯 119,000원
    ▶ 흑화고 100g×2, 백화고 100g×2, 국산

    금산인삼 홍천 더덕세트 99,900원
    ▶ 인삼400g, 더덕 600g,국산

    L.POINT 10,000원 할인
    상황 영지버섯 99,000원
    ▶ 상황버섯 130g, 영지버섯 300g, 국산

    L.POINT 10,000원 할인
    왕더덕 세트 159,000원
    ▶ 더덕 1.5kg(뿌리당 90~120g), 국산

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외

  </div>
  `,
  `
  <div>
    카드할인 20%
    정관장 홍삼원기 29,600원
    ▶ 50ml×30포

    1+1
    씨제이 한뿌리 홍삼대보 44,800원
    ▶ 40ml×24입

    1+1
    씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    카드할인 20%
    정관장 홍삼원 골드 40,000원
    ▶100ml×24입

    1+1
    농협 한삼인 대보선물세트 3호 77,000원
    ▶ 홍기천 50ml×10포, 홍삼대보 75ml×8병, 홍삼대보환 3.75g×12환

    1+1
    농협 한삼인 홍삼침향환골드 100 110,000원
    ▶ 3.75g×100입

    1+1 농협 한삼인 홍삼본 골드 39,800원 ▶ 50ml×30포
    1+1 농협 한삼인 홍삼진 굿데이스틱 59,600원 ▶ 10ml×60포
    1+1 농협 한삼인 홍삼진 굿데이앰플 70,000원 ▶ 20g×20병
    1+1 풍기인삼 자연의 기운을 품은 심 1호 71,000원 ▶ 산삼배양근 8병, 산삼배양근 단 8입, 절편 3입
    1+1 풍기인삼 자연의 기운을 품은 심 2호 65,000원 ▶ 산삼배양근 보 8입, 봉밀홍삼절편 5입
    1+1 풍기인삼 황풍정 산삼배양근 수 49,000원 ▶ 50ml×30포
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    인기 브랜드 햄&오일&
    씨제이 제일제당
    카드할인 30%
    씨제이 특별한선택 L-3호 28,630원 ▶ 카놀라유 500ml×2, 요리올리고당 700g, 스팸 120g×6
    씨제이 백설 프리미엄 5호 12,900원 ▶ 바삭유500ml×2, 해바라기씨 500ml×1
    카드할인 30% 씨제이 특별한선택 K-4호 21,630원 ▶ 카놀라유 500ml×2, 올리고당 500g, 스팸 120g×4
    1+1 씨제이 백설 육수에는 1분링 세트 29,900원 ▶ 멸치1분링 80g×2, 사골1분링 80g×1
    카드할인 30% 씨제이 특별한선택 스페셜 A호 34,930원 ▶ 카놀라유 500ml, 스팸 200g×4, 올리고당 500g 外
    카드할인 30% 씨제이 스팸복합 1호 33,530원 ▶ 카놀라유 500ml×2, 스팸 200g×6
    카드할인 30% 씨제이 스팸복합 5호 33,530원 ▶ 카놀라유 500ml×2, 스팸 120g×9
    카드할인 30% 씨제이 스팸복합 S호 39,830원 ▶ 카놀라유 500ml×2, 스팸 200g×5, 스팸 340g×2 外 
    카드할인 30% 씨제이 특별한선택 L-2호 40,530원 ▶ 카놀라유 500ml×2, 스팸 200g×6, 요리올리고당 700g 外
    카드할인 30% 씨제이 특별한선택 N호 41,230원 ▶ 카놀라유 500ml×2, 고소함가득참기름 330ml, 스팸 200g×7
    카드할인 20% 씨제이 스팸 8K호 41,520원 ▶ 스팸 200g×6, 스팸 라이트 200g×3
    L.POINT 30% 씨제이 스팸복합 K호 49,910원 ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 120g×6
    카드할인 30% 씨제이 스팸복합 100호 51,730원 ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 340g×2, 스팸 120g×2
    카드할인 30% 씨제이 특별한선택 스페셜 L-6호 51,730원 ▶ 카놀라유 500ml×2, 고소함가득 참기름 330ml, 구운소금 180g×2, 스팸라이트 200g×6 外
    카드할인 30% 씨제이 최고의선택 레드라벨 67,830원 ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 120g×5 外

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제

  </div>
  `,
  `
  <div>
    통조림 선물세트
    동원
    카드할인 30% 동원 스페셜 5호 29,890원 ▶ 살코기참치 90g×6, 리챔 120g×3, 카놀라유 480ml×2
    카드할인 30% 동원 프리미엄 1호 19,950원 ▶ 살코기참치 90g, 리챔 120g, 사과식초 500ml, 올리고당 700g 外
    카드할인 30% 동원 스페셜 2호 19,950원 ▶ 살코기90g×4, 리챔(더블)120g×2, 건강요리유 480ml×2
    카드할인 30% 동원 스페셜 1호 25,970원 ▶ 살코기참치 135g×2, 리챔 120g×4, 건강요리유 480ml×2
    카드할인 30% 동원 스페셜 49호 29,960원 ▶ 살코기참치 90g×4, 리챔 더블라이트 120g×4, 참기름 330ml
    카드할인 30% 동원 프리미엄 62호 29,960원 ▶ 살코기참치 90g×5, 리챔 120g×2, 참치액 진 240g, 참기름 75ml 外
    카드할인 30% 동원 프리미엄 60호 34,930원 ▶ 살코기참치 90g×9, 동원 참치액 진 240g, 건강요리유 480ml×2 外
    카드할인 30% 동원 튜나리챔 30호 34,930원 ▶ 살코기참치 90g×12, 리챔 더블라이트 120g×3
    L.POINT 20% 동원 참치 M14호 38,960원 ▶ 살코기참치 90g×4, 살코기참치 135g×10
    카드할인 20% 동원 튜나리챔 64호 39,920원 ▶ 고추참치 90g×6, 살코기참치 135g×6, 리챔 120g×4
    카드할인 30% 동원 스페셜 38호 39,970원 ▶ 살코기참치 135g×6, 리챔 더블라이트 200g×4 外
    L.POINT 30% 동원 튜나리챔 25호 44,940원 ▶ 살코기 135g×13, 리챔오리지널120g×3
    카드할인 30% 동원 튜나리챔 100호 49,910원 ▶ 살코기참치 135g×12, 리챔 200g×4
    10+1 카드할인 20% 동원 튜나런천미트 67호 50,960원 ▶ 살코기 135g×12, 런천 200g×4
    카드할인 30% 동원 튜나리챔 102호 54,950원 ▶ 살코기참치 135g×9, 리챔 200g×8
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    인기 브랜드 햄&오일 선물세트
    청정원
    카드할인 30% 청정원 스페셜 S호 19,950원 ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 두번달여더진한 진간장골드 500ml 外
    10+1 청정원 고급유 6호 13,900원 ▶ 카놀라유 500ml×2개, 요리유 500ml
    1+1 청정원 L3호 49,800원 ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 올리고당 700g 外
    1+1 청정원 L3호 49,800원 ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 올리고당 700g 外
    카드할인 30% 청정원 L1호 22,260원 ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 맛술 생강&매실 410ml, 요리올리고당 700g 外
    10+1 카드할인 30% 청정원 L8호 37,660원 ▶ 카놀라유 500ml, 맛선생 참치액 560g, 잇츠팜 115g×6개, 정통 사과식초 470ml 外
    카드할인 30% 청정원 L2호 24,360원 ▶ 카놀라유 500ml, 잇츠팜 115g×4개, 올리고당 700g, 옥수수유 500ml 
    카드할인 30% 청정원 L6호 27,860원 ▶ 카놀라유 500ml×2, 우리팜델리 115g×4, 올리고당 700g, 어간장 410ml
    카드할인 30% 청정원 L7호 29,960원 ▶ 카놀라유 500ml, 잇츠팜115g×5개, 고소한 건강생각 참기름 160ml 外
    카드할인 30% 청정원 L13호 54,460원 ▶ 카놀라유 500ml×2개, 정통사과식초 470ml, 천일염가는소금 190g 外
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외
  
  </div>
  `,
  `
  <div>
    이색적인 상품 
    소금&오일&과일즙&홍삼진액 선물세트
    L.POINT 10% 바로담아 국산 참들기름선물세트 56,970원 ▶ 국산 참기름 180ml, 국산 들기름 180ml, 국산볶음참깨105g
    L.POINT 10% 비온뒤첫소금 프리미엄 6호 22,500원 ▶ 프리미엄 함초소금 400g, 프리미엄 후추소금 380g, 프리미엄 구운소금 400g
    L.POINT 20% 데니그리스 유기농 사과식초 2호 24,800원 ▶ 데니그리스 유기농 사과식초 500ml×3
    L.POINT 20% 그로브 아보카도 오일 1호 32,800원 ▶ 그로브 아보카도 오일 엑스트라 버진 250ml×2병
    L.POINT 10% 올리타리아 스페셜 5호 14,310원 ▶ 해바라기유 500ml×2
    L.POINT 10% 올리타리아 스페셜 33호 36,810원 ▶ 올리브유 500ml, 해바라기유 500ml, 포도씨유 500ml
    L.POINT 10% 올리타리아 스페셜 4호 42,210원 ▶ 올리브유 500ml×2
    L.POINT 10% 올리타리아 스페셜 41호 49,230원 ▶ 엑스트라버진 올리브유 500ml, 포도씨유 500ml, 아보카도오일 250ml
    1+1 고려인삼 6년근 홍삼진액골드 89,000원 ▶ 70ml×60포
    고려인삼 녹용침향액 39,000원 ▶ 75ml×12병
    농협 프리미엄 녹용진 27,000원 ▶ 50ml×30포
    L.POINT 13,000원 할인 뉴트리원 비비랩 NFC착즙 유기농 레몬즙 세트 23,900원 ▶ 20g×10포×3입
    1+1 아내의 착즙주스 배도라지즙/사과즙/당근사과즙/감귤사과즙 착즙하는남자 양배추 사과즙 각29,900원 ▶ 각 100ml×21입 ※교차구매 가능
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    선물하는 분과 받는 분의 품격을 높이는 주류 선물세트 
    L.POINT 26,800원 할인 조니워커 블루 뱀띠 에디션 368,000원 ▶ 조니워커뱀띠에디션 750ml, 스템프 1개
    L.POINT 40,900원 할인 로얄살루트21년 500ml 169,000원 ▶ 로얄살루트21년 500ml
    L.POINT 26,000원 할인 조니워커 그린 73,800원 ▶ 조니워커그린 700ml, 노징 글라스 2개
    L.POINT 30,100원 할인 글렌그란트 10년 57,800원 ▶ 글렌그란트 10년 700ml, 하이볼잔 1개, 노징글라스 1개
    북극곰의 눈물 - 전용 도자기잔 세트 24,900원 ▶ 북극곰의 눈물 준마이 720ml, 전용 도자기잔 2 PCS
    2묶음세트 레뱅 스페인 1호 29,900원 ▶ 아이모니아 레드 750ml, 아이모니아 화이트 750ml (묶음세트 프로모션)
    2묶음세트 칠성 프랑스 론 와인 2종 39,900원 ▶ 앙리 드 플로뢰 750ml, 셰리에 도르 750ml (묶음세트 프로모션)
    L.POINT 20% 아영 이탈리아 인기와인 세트 43,920원 ▶ 그랑 파씨오네 750ml, 유원프리미티보 디 만두리아 750ml
    L.POINT 10% 나라 미국 덕혼 디코이 세트 89,910원 ▶ 덕혼디코이 까베르네 소비뇽 750ml, 덕혼디코이 메를로 750ml
    L.POINT 11,000원 할인 뵈브클리코 옐로우라벨 애로우 서울 99,000원 ▶ 뵈브클리코 옐로우라벨 애로우 서울 750ml ※운영점에 한함
    L.POINT 5,020원 할인 화요 53% 청사에디션 69,980원 ▶ 화요 53% 750ml ※12월 26일부터 수령가능(점별상이)
    화요 41% 테이스팅잔기획 49,900원 ▶ 화요 41% 375ml×2본입, 테이스팅잔 1개 ※12월 18일부터 수령가능(점별상이)

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외

  </div>
  `,
  `
  <div>
    식탁을 더욱 풍성하게! 어묵&수제햄&바베큐 선물세트
    ※ 선물용 쇼핑백 혹은 전용 보냉백과 함께 택배로 배송됩니다※상품권 증정 제외
    카드할인 10% 치즈 플레이트 세트 39,600원 ▶ 보스카 치즈 보드S, 브리에뜨 크리미앤 마일드 125g, 아페리프레 코트다쥐르 100g 外
    카드할인 10% ※상품권 증정 제외 와인 페어링 치즈 세트 49,500원 ▶ 아페리프레 프로방스 100g, 파르네제 파르미지아노 레지아노 150g 外
    카드할인 10% 존쿡 델리미트 바베큐 세트 71,100원 ▶ 슈바인학센650g, 바베큐백립450g, 킬바사270g, 스모크브랏320g, 부랏부어스트320g, 수비드포크밸리250g, 컨츄리베이컨스테이크80g, 바비큐풀드포크150g
    카드할인 20% ※상품권 증정 제외 몽탄 우대갈비 선물세트 111,200원 ▶ 몽탄 우대갈비 1.2kg(3대), 무생채
    카드할인 10% 존쿡 델리미트 하몽 샤퀴테리 세트 71,100원 ▶ 하몽세보데캄포핸드컷 50g, 하몽세라노그란레세르바120g, 코파50g, 이탈리안살라미50g, 카바노치50g, 트러플치즈딥200g, 크래커120g, 올리브290g, 미니잼28g
    카드할인 15% ※상품권 증정 제외 청정 아일랜드 목초유 100%로 만든 프리미엄 치즈와 구워먹는 치즈 도구인 바라끌레뜨, 전용 보냉백이 포함된 치즈 세트
    앙투어 솔레 치즈 세트 56,100원 ▶ 보스카 바베끌레페, 앙투어 솔레 100g×3, 앙투어솔레 보냉백 外
    프리미엄 어묵
    고래사어묵 선물세트 3호 40,000원 ▶ 고래바 65g×2, 통새우말이 90g, 치즈빵빵 80g, 떡말이 90g, 소시지말이 90g 外
    고래사어묵 선물세트 5호 60,000원 ▶ 고래바 65g×2, 통새우말이 90g×2 치즈빵빵 80g, 떡말이 90g, 새우봉어묵 150g, 크랩모아 140g 外
    고래사어묵 선물세트 7호 80,000원 ▶ 용궁어묵 180g, 전복어묵 120g, 통새우말이 90g×2, 치즈빵빵 80g×2, 떡말이 90g, 새우봉 어묵 150g 外
    프리미엄 수제햄
    오뗄 수제햄 실속 1호 43,900원 ▶ 바베큐 안심햄 300g, 화이트본레스햄 300g, 차슈 300g, 모타델라 300g, 부어스트에센 105g
    프리미엄 수제햄
    오뗄 수제햄 특선 1호 68,900원 ▶ 바베큐안심햄 300g, 화이트본레스햄 300g, 차슈 300g, 모타델라 300g, 부어스트에센 105g×2, 바비큐 슁켄 300g, 비어슁켄 300g, 쿡살라미 300g, 화이트로스햄 300g
    홈스토랑
    경복궁 양념불고기 선물세트 49,000원 ▶ 양념불고기 600g×2팩 ※상품권 증정 제외

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    온가족 담소시간! 전통과자&커피&차
    L.POINT 2,900원 동서 맥심 20호 세트 27,200원 ▶ 맥심 모카 골드 마일드 믹스 12g×200입 ※상품권 증정 제외
    5+1 카누 블렌드 7호 24,000원 ▶ 카누 더블샷 라떼 10T, 카누 라떼 10T, 카누 티라미수 라떼 8T, 카누 바닐라 라떼 8T, 카누 미니 마일드 10T×2입
    5+1 카누 아메리카노 8호 28,400원 ▶ 카누미니 마일드 10T× 6개, 카누미니 다크 10T×2개, 카누미니 라이트 10T× 2개, 투게더머그 ※사전예약 한정 운영
    카드할인 30% 담터 다정 종합세트 19,320원 ▶ 호두아몬드율무차 18g×15T,단호박마차 17g×15T, 쌍화차플러스 15g×15T 外
    10+1 녹차원 차다움세트 9,900원 ▶ 꿀유자차 350g, 생강대추배차 7T, 호두아몬드율무차 7T ※사전예약 한정 운영
    창평한과 송강정 70,000원 ▶ 유과(찹쌀, 백년초, 단호박, 대잎 外), 매작과(치자백년초 外) 外 ※사전예약 한정 운영 ※상품권 증정 제외
    창평한과 소쇄원 108,000원 ▶ 유과(찹쌀, 백년초, 단호박, 대잎) 外 ※사전예약 한정 운영 ※상품권 증정 제외
    1+1 녹차원 향기담은 허브차 세트 24,900원 ▶ 쟈스민 허브차 5입, 맛있는녹차작설 5입, 얼그레이 5입 外 ※사전예약 한정 운영
    카드할인 20% 꽃샘 아카시아 야생화꿀 세트 47,920원 ▶ 아카시아꿀 600g, 야생화꿀 600g ※사전예약 한정 운영
    카드할인 20% 꽃샘 밤꿀세트 54,400원 ▶ 밤꿀 1.2kg ※사전예약 한정 운영

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외

  </div>
  `,
  `
  <div>
    바디케어 선물세트
    10+1 LG 생활의 품격 1호 13,900원 ▶ 엘라스틴 10× 샴푸/컨디셔너200ml, 온더바디 체리블라썸 바디워시 200ml, 페리오 캐비티 토탈 치약 90g×4 外
    아모레 섬김 2호 25,950원 ▶ 해피바스 오리지널컬렉션 라벤더 바디워시 490g, 려 함빛 손상 &영양 샴푸 400ml, 려 흑운 두피&볼륨 샴푸 400ml×2 外
    1+1 LG 보타닉퍼퓸 컬렉션스페셜 30,900원 ▶ 보타닉 퍼퓸컬렉션 샴푸 480ml×2, 보타닉 퍼퓸컬렉션 컨디셔너 480ml 外
    LG 히말라야 핑크솔트 에센셜 세트 27,230원 ▶ 히말라야 핑크솔트 샴푸 200ml×2, 히말라야 핑크솔트 바디워시 190ml, 히말라야 핑크솔트 치약(아이스)100g×2 外
    LG 정성담은두배세트 13,930원 ▶ 오가니스트 체리 샴푸 200ml, 오가니스트 체리 컨디셔너 200ml, 온더바디 더내추럴바디워시 라벤더향 190ml×2 外
    1+1 LG 샴푸&바디세트 프리미엄 33,900원 ▶ 엘라스틴 데일리 리페어링 샴푸 400ml×2, 엘라스틴 데일리 리페어링 컨디셔너 400ml 外
    L.POINT 30% LG 생활의품격 7호 31,430원 ▶ 닥터그루트 샴푸 180ml, 닥터그루트 컨디셔너 180ml, 온더바디 더내추럴 바디워시 190ml×2 外
    1+1 LG 생활의 감동 바디케어세트 라벤더 49,900원 ▶ 온더바디 더내추럴 바디워시 라벤더향 500ml×2, 온더바디 더내추럴 바디로션 라벤더 400ml 外
    1+1 LG 정성담은 36호 48,900원 ▶ 리엔 로열젤리 샴푸/컨디셔너200ml, 죽염 청신원 치약 90g×6 外
    1+1 LG 생활의 감동 모던 라이프 41,900원 ▶ 온더바디 레몬 버베나 바디워시 400ml, 오가니스트 체리 수분 샴푸 200ml 外
    1+1 애경 케라시스 퍼퓸셀렉션 26호 26,900원 ▶ 케라시스 러블리 데이지 퍼퓸 샴푸 400ml×2, 린스 400ml, 샤워메이트 실키 모이스춰 비누 80g×3
    L.POINT 30% LG 생활의 품격 4호 21,630원 ▶ 닥터그루트 샴푸 180ml, 온더바디 더내추럴 바디워시 라벤더향 190ml×2, 페리오 캐비티 토탈 치약 90g×6 外

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    4만원대 이하 선물세트
    10+1 동원 양반김 M10호 16,900원 ▶ 구운 파래김 20g×3봉 , 들기름 식탁김 4g×12봉
    10+1 CJ 비비고 토종김 1호 16,900원 ▶ 담백 구운김 전장 10g×3봉 , 비비고 참기름 도시락김 4g×12봉
    LG 프로폴리스 기프트세트 프리미엄 9,990원 ▶ 페리오 프로폴리스향 치약 90g×4, 온더바디 프로폴리스 비누 80g×2, 페리오 프로폴리스 칫솔×2
    L.POINT 50% 종근당건강 홍삼기력골드 19,900원 ▶ 50ml×30포
    L.POINT 50% 종근당건강 활력녹용보 21,900원 ▶ 50ml×30포
    13,000원 할인 종근당건강 아임비타 멀티비타민 세트 33,700원 ▶ 이뮨플러스 14병, 멀티비타민 60정
    L.POINT 9,000원 할인 쌀명당 8개입 선물세트 할인가 21,900원 ▶ 더고슬미 400g, 더찰진미 400g, 더향진미 400g×2, 현미 400g, 찰현미 400g, 찹쌀 400g, 혼합9곡 400g, 원산지 별도표기
    L.POINT 11,000원 할인 쌀명당 시그니처 선물세트 29,900원 ▶ 더고슬미 400g, 더찰진미 400g, 더향진미 400g×2입, 현미 400g, 찰현미 400g, 찹쌀 400g, 혼합9곡 400g, 주걱, 원산지 별도표기

    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외

  </div>
  `,
  `
  <div>
    롯데카드, 신한카드, 삼성카드, 국민카드, 비씨카드, 농협카드, 하나카드
    행사카드 구매 금액대별 최대 120만원 상품권 증정
    ※최대 120만원은 2025년 설날 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음
    10+1 애경리미티드셀렉션11호 11,900원 ▶ 케라시스 데미지 샴푸 200ml, 샤워메이트 유자 바디워시 200ml, 2080알파치약90g 外    
    LG 히말라야 핑크솔트 로즈에디션 32,830원 ▶ 히말라야 핑크솔트 샴푸 380ml×2, 히말라야 핑크솔트 바디워시 380ml×2, 히말라야 핑크솔트 비누 90g 外
    L.POINT 10,000원 할인 6년근 실속 금산인삼세트 29,900원 ▶ 6년근 인삼 350g(난발),국산
    L.POINT 10,000원 할인 인삼,꿀 혼합세트 39,900원 ▶ 인삼 500g, 홍삼꿀 550g, 대추 30g, 도라지,30g, 국산
    카드할인 25% 정관장 홍삼진고 이뮨스틱 34,500원 ▶ 10g×30포
    카드할인 6,000원 할인 뉴트리원 올인원 멀티비타 이뮨플러스 43,900원 ▶ 7병×4세트
    카드할인 10% 존쿡 델리미트 브런치 세트 35,100원 ▶ 킬바사270g, 잠봉100g, 치킨브레스트치즈베이글160g, 나트륨을줄인마일드베이컨120g, 치킨&초리조토마토스프480g, 브로첸150g
    카드할인 20% 상하농원 수제 햄&치즈 실속세트 47,920원 ▶ 스틱비엔나120g×2, 고구마프랑크180g, 그릴드치즈125g, 스틱치즈50g×3
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    4만원대 이하 선물세트
    L.POINT 7,000원 할인 코주부 프리미엄 육포세트 2호 32,900원 ▶ 40g×7봉 , 수입산 우육(원산지 별도표기)
    L.POINT 40% 리얼스테이크 육포세트 35,940원 ▶ 160g×3봉 , 수입산 우육(원산지 별도표기)
    L.POINT 12,000원 할인 궁 쇠고기육포 선물세트 47,900원 ▶ 70g×6봉 , 수입산 우육(원산지 별도표기)
    2+1 닥터리브 유기농 레몬스틱 선물세트 34,900원 ▶ 20g×10포×3입
    L.POINT 10,000원 할인 6년근 뿌리 많은 인삼 세트 49,900원 ▶ 6년근 인삼난발 500g, 국산
    L.POINT 20,000원 할인 자연담은 건버섯&견과 혼합세트 2호 39,900원 ▶ 동고 50g, 표고사각 30g, 표고채 35g, 구운캐슈넛 100g, 호두 80g, 구운아몬드 90g, 원산지 별도표기
    L.POINT 10,000원 할인 실속형 금산 인삼 더덕 혼합세트 49,900원 ▶ 인삼 300g, 더덕 400g, 국산
    L.POINT 20,000원 할인 자연향 버섯 혼합세트 1호 49,900원 ▶ 동고 70g, 흰목이 30g, 표고채 55g×2 外, 국산
    L.POINT 15,000원 할인  카드할인 10,000원 할인 흑화고 혼합세트 49,900원 ▶ 목이버섯 40g, 표고버섯가루 90g, 표고사각 50g, 표고채 50g×2, 흑화고 80g
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외

  </div>
  `,
  `
  <div>
    롯데카드, 신한카드, 삼성카드, 국민카드, 비씨카드, 농협카드, 하나카드
    행사카드 구매 금액대별 최대 120만원 상품권 증정
    ※최대 120만원은 2025년 설날 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음

    풍기인삼 에너지허브 이뮨 더블업 세트 39,900원 ▶ 5병×4세트
    L.POINT 10,000원 할인 깨끗이 씻어나온 GAP 사과 39,900원 ▶ 사과 11~12입(3.0kg 내외), 국산
    L.POINT 10,000원 할인 미국산 오렌지 39,900원 ▶ 오렌지 12입 (3.6kg 내외), 미국산
    L.POINT 10,000원 할인 이스라엘 자몽 39,900원 ▶ 자몽 12입 (4.8kg 내외), 이스라엘산
    카드할인 10% 치즈&샤퀴테리 세트 32,400원 ▶ 르 갈 크림치즈 플레인 150g, 베르기어 포션 믹스 120g 外 ※상품권 증정 제외
    카드할인 10% 존쿡 델리미트 시그니처 세트 49,500원 ▶ 슈바인학센650g, 브렉퍼스트소시지210g, 비프핫도그300g, 킬바사270g, 컨츄리베이컨120g, 잠봉100g, 맥앤치즈 200g, 핫도그번165g
    1+1 녹차원 건강 담은 액상차 세트 32,900원 ▶ 꿀유자차 350g, 홍삼담은석류차 350g, 꿀자몽차 350g, 홍삼담은레몬차 350g, 통곡물율무차 18g×5입, 통곡물마차 18g×5입 ※사전예약 한정 운영
    1+1 녹차원 정성가득 홍삼차 세트 39,900원 ▶ 홍삼진골드 100 ml×4병, 꿀유자차 300g, 꿀레몬차 300g, 홍삼배도라지 70 ml×4포×2, 작두콩차 5입, 호박팥차 5입 ※사전예약 한정 운영
    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    5만원대 이상 선물세트
    L.POINT 10,000원 할인 천안 배 59,900원 ▶ 배 5~6입(5.0kg 내외), 국산 
    L.POINT 4,000원 할인 전주 한옥토 배 65,900원 ▶ 배 6~8입(5.0kg 내외), 국산
    L.POINT 20,000원 할인 자이언트 배 149,900원 ▶ 배 9입(8.2kg 내외), 국산
    L.POINT 20,000원 할인 자이언트 사과 109,900원 ▶ 사과 12입(5.3kg 내외), 국산
    샤인머스캣, 한라봉 79,900원 ▶ 샤인머스캣 2송이(1.7kg 내외), 한라봉 6개(2.0kg 내외), 국산
    샤인머스캣 3kg 79,900원 ▶ 샤인머스캣 3.0kg 내외, 국산
    3+1 코주부 프리미엄 육포세트 4호 59,900원 ▶ 50g×8봉 , 외국산 우육
    3+1 코주부 우리소 육포세트 69,900원 ▶ 55g×7봉 , 국내산 우육
    경복궁 한우불고기 갈비 혼합세트 69,000원 ▶ 한우 불고기 600g, 칼집 LA갈비 600g ※상품권 증정 제외
    L.POINT 10,000원 할인 킹타이거 선물세트 79,000원 ▶ 블랙타이거 1kg (총 4미, 1미당 250g), 냉동, 기니산
    카드할인 5,000원 할인 경복궁 명절 한상 선물세트 74,000원 ▶ 칼집LA갈비 600g, 영양갈비탕 850g, 양념불고기 600g, 한우사골곰탕 600g ※상품권 증정 제외
    ※ 발행일(2024년 12월 12일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 제외

  </div>
  `,
  `
  <div>
    롯데카드, 신한카드, 삼성카드, 국민카드, 비씨카드, 농협카드, 하나카드
    행사카드 구매 금액대별 최대 120만원 상품권 증정
    ※최대 120만원은 2025년 설날 사전예약 해당 기간에 한함 ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간 내) ※단일카드로 전액 결제시에 한함 ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외 ※당일 영수증에 한함
    ※1人 1日 1회 비연속식 증정 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※상품권 증정 프로모션은 변경 될 수 있음 ※자세한 내용은 매장내 고지물 및 롯데마트GO앱 참조 ※위 조건은 발행일 '24. 12. 12 기준이며, 일부 변경 될 수 있음
    3+1 동원 쇠고기육포 선물세트 65,900원 ▶ 60g×6봉 , 외국산 우육
    3+1 홍대감 채끝살 육포세트 54,900원 ▶ 60g×5봉 , 외국산 우육
    L.POINT 10,000원 할인 건강버섯 특선세트 99,000원 ▶ 상황버섯60g 外 원산지 별도표기
    카드할인 20% 정관장 홍삼원기 54,400원 ▶ 50ml×60포
    풍기인삼 황풍정 홍삼정과 107,000원 ▶ 700g
    L.POINT 10,000원 할인 6년근 프리미엄 인삼세트 159,900원 ▶ 6년근 인삼 700g, 국산
    L.POINT 20,000원 할인 자연향 백화고 혼합세트 54,900원 ▶ 백화고 90g, 동고 70g×2, 사각칩 40g×2 外, 국산
    L.POINT 20,000원 할인 자연담은 건강버섯 세트 59,900원 ▶ 상황슬라이스 25g, 노루궁뎅이 30g, 영지슬라이스 40g 은이버섯 1송이(25g), 차가버섯 80g, 녹각영지 20g
    L.POINT 50,000원 할인 자연향 건버섯&견과 혼합세트 199,000원 ▶ 황잣 400g, 국산호두 300g, 백화고 150g, 흑화고 110g, 국산
    L.POINT 10,000원 할인 유명산지 더덕 산마 세트 59,900원 ▶ 더덕 600g, 산마 1.4kg
    10+1 L.POINT 10,000원 할인 대한민국 명장 장석준 명란 세트 89,000원 ▶ 명란 600g×2 (냉동) 원산지 별도표시
    L.POINT 20,000원 할인 자연담은 건버섯&견과 혼합세트 1호 69,900원 ▶ 흑화고 80g×2,표고채 60g, 구운아몬드 140g,호두 90g, 호두강정 90g,대추야자140g) 원산지 별도표기

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다
    
  </div>
  `,
  `
  <div>
    롯데슈퍼
  </div>
  `,
];

/* 920 슈퍼 백일산점 (~9/6) */
const L920data = [
  `
  <h1>LOTTE Super</h1>
  <div>
    통큰 한가위

    사전예약 기간 2024/8/1(목) ~ 2024/9/6(금)

    롯데백화점 일산점 식품관
    FOOD MARKET

  </div>
  `,
  `
  <div>
    통큰 한가위
    미리 준비할수록 더 큰 혜택!
    ※사전예약 기간 : 2024. 8. 1(목) ~ 2024. 9. 6(금)

    롯데카드, 비씨카드, 삼성카드, 신한카드, KB국민카드, NH농협카드, 하나카드
    최대 120만원 상품권 증정 또는 즉시할인 ※해당 기간에 한함

    1차 8/1(목) ~ 8/23(금)
    30만원 이상 2만 5천원
    50만원 이상 4만원
    100만원 ~ 1,000만원 미만 100만원 당 10만원
    1,000만원 이상 120만원

    2차 8/24(토) ~ 8/30(금)
    30만원 이상 2만 5천원
    50만원 이상 4만원
    100만원 ~ 1,000만원 미만 100만원 당 8만원
    1,000만원 이상 80만원

    3차 8/31(토) ~ 9/6(금)
    30만원 이상 1만 5천원
    50만원 이상 2만 5천원
    100만원 ~ 1,000만원 미만 100만원 당 5만원
    1,000만원 이상 80만원

    ※자세한 내용은 매장 내 고지물 참조
    ※법인/체크카드 포함 ※카드할인, 상품권 중복 증정(사전예약 기간내) ※롯데상품권카드, 각 카드사별 기프트카드, 선불카드 제외
    ※단일카드로 전액 결제시에 한함 ※주류/정관장/화장품 선물세트 등 일부 선물세트 제외 ※당일 영수증에 한하며, 1人 1日 1회 비연속식 증정
    ※상품권 증정 프로모션은 변경될 수 있습니다

    최대 30% 할인혜택
    ※법인/체크카드 포함 ※기프트카드/선불카드 제외 ※해당 단일카드로 전액 결제시 적용
    ※해당상품에 한함 ※상품별 할인율 상이 ※상품권 증정 혜택과 중복 적용

    L.POINT회원 특별할인
    ※해당상품에 한함
    
    구매 수량에 따라 덤 증정 하나 더
    ※해당상품에 한함

    3만원 이상 구매시 전국 무료배송
    ※일부품목 제외 (일부 도서 산간 지역 제외)
    ※결제금액 기준

    ※자세한 내용은 매장 내 고지물 참조 ※위 조건은 발행일 '24. 8. 1 기준이며, 일부 변경 될 수 있음

  </div>
  `,
  `
  <div>
    통큰 한가위 프리미엄 선물세트

    장수 사과 L.POINT 10,000원 할인 79,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    자이언트 사과, 배 129,900원
    ▶ 사과 5입(2.1kg 내외), 배 4입(3.6kg 내외), 국산

    자이언트 사과 129,900원
    ▶ 사과 12입(5.3kg 내외), 국산

    자이언트 배 L.POINT 20,000원 할인 129,900원
    ▶ 배 9입(8.2kg 내외),국산

    프라임 배 L.POINT 10,000원 할인 89,900원
    ▶ 배 9입(6.8kg 내외),국산

    프라임 사과 L.POINT 10,000원 할인 89,900원
    ▶ 사과 12입(4.5kg 내외), 국산

    샤인머스캣 L.POINT 10,000원 할인 79,900원
    ▶ 샤인머스캣 4kg 내외, 국산

    견과 3종 GIFT(진) 250,000원
    ▶ 산청곶감 14개(45~49g), 백잣 350g, 호두 370g, 국산

    견과종합 GIFT 220,000원
    ▶ 백잣 250g, 호두 230g, 감말랭이 300g, 곶감호두말이 300g, 국산

    견과 3종 GIFT(향) 210,000원
    ▶ 산청곶감 6개(45~49g), 백잣 350g, 호두 370g, 국산

    정과원육포 GIFT (향) 130,000원
    ▶ 호주산 쇠고기 육포 700g, 원산지 별도포기

    정과원육포 GIFT (수) 90,000원
    ▶ 호주산 쇠고기 육포 400g, 원산지 별도포기

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    최상급 프리미엄 한우 
    마블나인, 예술을 선물하세요

    9의 자격, 오직 1%의 한우에게만
    MARBLE 9(마블나인)은 1++한우(7~9등급) 중에서도 근내지방도 19% 이상만을 엄선한 최상급(9등급)만을 선보입니다.

    마블나인 프리미엄 혼합세트(1++9등급) L.POINT 50,000원 할인 749,000원
    ▶ 등심 500g×2, 안심, 채끝 각 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    마블나인 구이세트 3호(1++9등급) L.POINT 50,000원 할인 399,000원
    ▶ 등심, 채끝, 설깃 스테이크, 찹스테이크 각 500g, 냉장, 국내산 한우고기
    ※전국 택배 운영

    마블나인 등심 혼합 세트 1호(1++9등급) L.POINT 50,000원 할인 549,000원
    ▶ 등심, 국거리, 채끝 각 500g×2, 냉장, 국내산 한우고기
    ※전국 택배 운영

    마블나인 구이세트 2호(1++9등급) L.POINT 50,000원 할인 489,000원
    ▶ 등심 500g×2, 채끝, 부채 각 500g, 냉장, 국내산 한우고기
    ※전국 택배 운영

    마블나인 한우 갈비세트 (냉동)(1++9등급) L.POINT 50,000원 할인 349,000원
    ▶ 마블나인 한우 냉동 찜갈비 700g×5, 냉동, 국내산 한우고기
    ※전국 택배 운영

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    대한민국 한우 구이, 정육 세트

    마블나인 한우세트 (6구, 1++9등급) 1,000,000원
    ▶ 마블나인 한우 1++(9)등급 등심, 채끝 각 1kg, 안심, 특수부위 각 500g

    마블나인 한우 종합세트 (6구, 1++9등급) 600,000원
    ▶ 마블나인 한우 1++(9)등급 등심 1.5kg, 국거리 1kg, 불고기 500g

    마블나인 한우구이세트 (6구, 1++9등급) 750,000원
    ▶ 마블나인 한우 1++(9)등급 등심 1.5kg, 채끝 1.5kg

    마블나인 한우구이세트 (4구, 1++9등급) 450,000원
    ▶ 마블나인 한우 1++(9)등급 등심 1kg, 채끝 1kg

    마블나인 한우모듬세트(4구, 1++9등급) 350,000원
    ▶ 마블나인 한우 1++(9)등급 등심 1kg, 국거리 500g, 불고기 500g

    마블나인 한우실속세트 (4구, 1++9등급) 300,000원
    ▶ 마블나인 한우 1++(9)등급 국거리, 불고기 각 1kg

    마블나인 한우알뜰세트 1호(3구, 1++9등급) 350,000원
    ▶ 마블나인 한우 1++(9)등급 등심 500g, 채끝 500g, 안심 500g

    마블나인 한우알뜰세트 2호(3구, 1++9등급) 250,000원
    ▶ 마블나인 한우 1++(9)등급 등심 500g, 국거리 500g, 불고기 500g

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    신선함을 담았습니다. 과일 선물세트

    나주 최종기 농부의 하우스배 89,900원
    ▶ 배 7~11입(6.5kg 내외), 국산

    나주 최종기 농부의 자이언트 하우스배 L.POINT 20,000원 할인 129,900원
    ▶ 배 9입 (8.0kg 내외)국산

    충주사과, 나주 하우스 배 111,900원
    ▶ 사과 6입(1.8kg 내외), 배 6입(3.9kg 내외), 국산

    GAP 사과, 배(情) L.POINT 10,000원 할인 59,900원 
    ▶ 사과 5입(1.4kg 내외), 배 4입(2.2kg 내외), 국산

    샤인머스캣 L.POINT 10,000원 할인 79,900원
    ▶ 샤인머스캣 3kg 내외, 국산

    샤인머스캣, 사과, 배, 황금향 99,900원
    ▶ 샤인머스캣 1송이 (0.7 kg 내외),사과 3입 (0.9 kg 내외), 배 3입 (1.8 kg 내외),황금향 3입 (0.8 kg 내외), 국산

    샤인머스캣, 사과 L.POINT 10,000원 할인 89,900원 
    ▶ 샤인머스캣 3송이(2.1kg내외), 사과 6입(2.0kg 내외), 국산

    샤인머스캣, 메론 99,900원
    ▶ 샤인머스캣 2송이(2.0kg 내외), 머스크 메론 2입(3.2kg 이상), 국산

    샤인머스캣, 사과, 배 129,900원
    ▶ 샤인머스캣 2송이(1.4kg 내외), 사과 4입(1.2kg 내외), 배 6입(3.9kg 내외), 국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    신화, 창조 배 89,900원 
    ▶ 배 12입(7.5kg 내외), 국산

    문경사과 L.POINT 10,000원 할인 69,900원
    ▶ 사과 12~14입(4.5kg 내외), 국산

    프라임 사과, 배 L.POINT 10,000원 할인 69,900원
    ▶ 사과 4입(1.2kg 내외), 배 6입(3.6kg 내외), 국산

    충주사과, 천안배 L.POINT 10,000원 할인 79,900원
    ▶ 사과 6입(1.7kg 내외), 배 6입 (3.6kg 내외), 국산

    당도선별 메론 L.POINT 10,000원 할인 79,900원
    ▶ 머스크 메론 4입(6.4kg 이상), 국산

    망고 혼합 L.POINT 10,000원 할인 89,900원
    ▶ 옐로우 망고 3입(1.0kg 내외), 애플망고 6입(2.4kg내외), 원산지 별도표기

    나주 하우스배, 충주사과 219,900원
    ▶ 사과 8입(2.9kg 내외), 배 6입 (4.9kg 내외), 국산

    밀양얼음골사과 L.POINT 10,000원 할인 79,900원
    ▶ 사과 12-14입(4.2kg 내외), 국산

    영주 아리수 사과 89,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    나주 배 L.POINT 10,000원 할인 69,900원
    ▶ 배 9~11입(6.5kg 내외), 국산

    정성담은 사과 L.POINT 10,000원 할인 69,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    정성담은 신고배 L.POINT 10,000원 할인 69,900원
    ▶ 배 9~11입(4.2kg 내외), 국산

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    신선함을 담았습니다. 과일 선물세트

    충주 김택성 농부의 GAP 사과 109,900원
    ▶ 사과 11~12입(4.5kg 내외), 국산

    천안 지순태 농부의 GAP 배 L.POINT 10,000원 할인 99,900원
    ▶ 배 8-9입(6.7kg 내외), 국산

    제스프리 골드키위 L.POINT 10,000원 할인 69,900원
    ▶ 골드키위 25입(2.5kg 이상), 뉴질랜드산

    정성담은 사과, 배 L.POINT 10,000원 할인 89,900원
    ▶ 사과 8입(2.6kg 내외), 배 6입(3.9kg 내외),국산

    대봉곶감 GIFT(향) 140,000원
    ▶ 곶감 18개, 국산

    상주곶감 GIFT(향) 120,000원
    ▶ 상주곶감 24개(42~49g), 국산

    허니듀메론 L.POINT 10,000원 할인 59,900원
    ▶ 허니듀 메론 4입 (7.2kg 내외), 미국산

    산지곶감혼합 GIFT 230,000원
    ▶ 대봉곶감 8개, 상주곶감 16개, 산청곶감 16개, 국산

    곶감ㆍ반건시 혼합 GIFT 160,000원
    ▶ 상주곶감 12개, 산청곶감 12개, 청도 반건시 8개, 국산

    태국산 망고 L.POINT 10,000원 할인 79,900원
    ▶ 옐로우망고 9입 (3.2kg 내외), 태국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    견과류 선물세트

    잣, 호두명품 GIFT 380,000원
    ▶ 백잣 500g, 황잣 500g, 호두 500g, 국산

    잣, 호두 GIFT(진) 230,000원 
    ▶ 백잣 350g, 황잣 350g, 호두 460g, 국산

    잣, 호두 GIFT(향) 160,000원 
    ▶ 백잣 200g, 황잣 200g, 호두 230g, 국산

    잣, 호두 GIFT(수) 100,000원
    ▶ 백잣 200g, 호두 200g, 국산

    백화고 3종 GIFT 250,000원
    ▶ 백잣 350g, 백화고 180g, 호두 370g, 국산

    삼색 GIFT 140,000원
    ▶ 백잣 200g, 호두 230g, 대추채 130g, 국산

    표고채 견과 GIFT 100,000원
    ▶ 표고채 70g×2, 대추채 60g, 백잣 100g, 원산지 별도표기

    잣 GIFT(진) 160,000원
    ▶ 백잣 350g, 황잣 350g, 원산지 별도표기

    프리미엄 견과 6종 GIFT 120,000원
    ▶ 백잣 100g, 호두 100g, 대추채 60g, 표고채 30g, 볶은서리태콩 120g, 볶은땅콩 130g, 원산지 별도표기

    견과다복 GIFT 130,000원
    ▶ 백잣 220g, 구운아몬드 300g, 호두 220g, 구운캐슈너트 300g, 원산지 별도표기

    견과 6종 GIFT 100,000원
    ▶ 구운아몬드 200g, 호두 130g, 마카다미아 160g, 브라질너트 180g, 구운캐슈너트 180g, 피칸 160g, 원산지 별도표기

    견과 5종 GIFT 80,000원
    ▶ 구운아몬드 290g, 구운캐슈너트 130g, 호두 100g, 피스타치오 110g, 브라질너트 120g, 원산지 별도표기

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    인기만점 육즙 가득한 소고기&돼지고기 선물세트

    미국산 소 냉동 혼합 갈비 세트(3kg) L.POINT 30,000원 할인 169,000원
    ▶ 미국산 냉동 꽃갈비, 찜갈비 각 1.5kg
    ※전국 택배 운영

    한우 냉동 갈비세트(1+등급) L.POINT 50,000원 할인 249,000원
    ▶ 1+등급 한우 냉동 찜갈비 700g×4, 양념소스, 국내산 한우고기

    제주 돼지 혼합세트 L.POINT 14,900원 할인 45,000원
    ▶ 삼겹살 500g, 뒷다리 불고기 500g×2

    명품 제주돼지 구이세트(4구) 149,000원
    ▶ 삼겹살 500g×2, 목심 500g×2

    한우등심+제주 돼지 삼겹살 4구 299,000원
    ▶ 한우 1등급 이상 한우등심 500g×2, 제주 돼지 삼겹살 500g×2

    호주산 냉장 찜갈비 세트(4구) 160,000원
    ▶ 호주산 찜갈비 700g×4

    호주산 냉장 찜갈비 세트(6구) 300,000원
    ▶ 호주산 찜갈비 700g×6

    명품한우 냉장 찜갈비 세트(4구) 500,000원
    ▶ 한우 1등급 이상 한우갈비 700g×4

    명품한우 냉장 찜갈비 세트(6구) 750,000원
    ▶ 한우 1등급 이상 한우갈비 700g×6

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    
  </div>
  `,
  `
  <div>
    L'grow 와규 냉장세트 (6구, MBS8+) 350,000원
    ▶ 와규 등심 2kg, 국거리 500g, 불고기 500g

    L'grow 와규 혼합세트 (4구, MBS8+) 250,000원
    ▶ 와규 등심 1kg, 국거리 500g, 불고기 500g

    한우갈비세트2호 L.POINT 30,000원 할인 149,000원
    ▶ 한우냉동찜갈비 600g×2, 양념소스, 냉동, 국내산 한우고기
    ※전국 택배 운영

    한우냉동갈비세트 L.POINT 50,000원 할인 199,000원
    ▶ 한우냉동찜갈비700g×3, 양념소스, 냉동, 국내산 한우고기 ※전국 택배 운영

    미국산 소 냉동 LA식 갈비세트 3kg L.POINT 30,000원 할인 199,000원
    ▶ 미국산 냉동 꽃갈비(구이용) 3kg

    무항생제 꼬리 반골세트 L.POINT 20,000원 할인 99,000원
    ▶ 5kg, 냉동, 국내산 한우고기
    ※전국 택배 운영

    무항생제 한우 사골 세트 L.POINT 20,000원 할인 99,000원
    ▶ 무항생제 한우 사골 5kg, 냉동, 국내산 한우고기
    ※전국 택배 운영

    한우 알꼬리 선물세트 L.POINT 30,000원 할인 149,000원
    ▶ 한우 알꼬리 2kg, 냉동, 국내산 한우고기
    ※전국 택배 운영

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    제주 옥돔 은갈치세트1kg L.POINT 30,000원 할인 150,000원
    ▶ 옥돔160g×3미, ▶갈치300g×2미, 옥돔/갈치 : 국산

    活 전복세트 L.POINT 15,000원 할인 65,000원
    ▶ 1마리 50g 내외, 20마리, 전복 : 국산
    ※스트로폼 및 바닷물포장

    새천년 법성포 굴비세트(산) L.POINT 50,000원 할인 200,000원
    ▶ 1.1kg, 10미, 참조기/천일염 : 국산
    ※ 마리당 20,000원

    새천년 법성포굴비세트(일) L.POINT 50,000원 할인 300,000원
    ▶ 1.3kg, 10미, 참조기/천일염 : 국산
    ※ 마리당 30,000원

    새천년 법성포 굴비세트(월) L.POINT 60,000원 할인 240,000원
    ▶ 1.2kg, 10미, 참조기/천일염 : 국산
    ※ 마리당 24,000원

    새천년 법성포 굴비세트(리) L.POINT 90,000원 할인 360,000원
    ▶ 1.4kg, 10미, 참조기/천일염 : 국산
    ※ 마리당 36,000원

    바다원 고급 멸치세트 1호 L.POINT 20% 64,000원
    ▶ 볶음용멸치/볶음조림용멸치/조림용멸치/ 국물용멸치 각230g

    바다원 사각 특선 멸치세트 1호 L.POINT 20% 96,000원
    ▶ 볶음용멸치110g×2, 볶음조림용멸치110g×2, 국물용멸치100g×2, 건새우70g×2

    자연향 담은 백화고 혼합세트 L.POINT 20,000원 할인 74,900원
    ▶ 백화고 90g, 동고 70g×2, 사각칩 40g×2 外, 국산

    자연담은 건강버섯 세트 L.POINT 20,000원 할인 79,900원
    ▶ 은이버섯 25g 外, 국산

    대한민국 명장 장석준 명란 세트 10+1 L.POINT 10,000원 할인 89,000원
    ▶명란 600g(300g×2), 원산지 별도표기, 냉동

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    추석 인기 선물 프리미엄 김&육포 세트

    대천 명품캔김세트(15캔) L.POINT 5,000원 할인 84,900원
    ▶ 곱창김 30g×15캔, 원산지 별도표기

    대천 명품캔김세트(6캔) L.POINT 2,000원 할인 53,900원
    ▶ 곱창김 30g×6캔, 원산지 별도표기

    CJ비비고감태김혼합1호 L.POINT 30% 48,930원
    ▶ 감태김 전장 20g×3봉 , 감태김 캔 25g×2캔 , 감태김 도시락 4g×8봉, 원산지 별도표기

    CJ 비비고 초사리곱창돌김 1호 L.POINT 30% 53,830원
    ▶ 초사리 곱창돌김 전장 20g×3봉, 초사리 곱창돌김 캔 25g×2캔, 초사리 곱창돌김 도시락 5g×8봉, 원산지 별도표기

    궁 쇠고기육포선물세트 L.POINT 12,000원 할인 67,900원
    ▶ 육포(홍두깨살) 70g×6봉, 외국산(미국/호주/뉴질랜드)

    한우육포 GIFT 240,000원
    ▶ 한우 육포 700g, 원산지 별도표기

    코주부 비프&너츠프리미엄선물세트 L.POINT 15,000원 할인 79,900원
    ▶ 견과혼합육포120g×4봉
    
    CJ 제일명인정통육포세트 L.POINT 30% 71,120원
    ▶ 육포(홍두깨살) 70g×6봉, 외국산(미국/호주/뉴질랜드)

    CJ 제일명인 한우육포세트 99,000원 ▶ 한우육포70g×6봉

    쇠고기육포 GIFT 180,000원
    ▶ 국내산 쇠고기 육포 700g, 원산지 별도표기

    정과원육포 GIFT (진) 170,000원
    ▶ 호주산 쇠고기 육포 1kg

    금산 인삼 홍천더덕 세트 119,900원
    ▶ 인삼 400g, 더덕 600g

    6년근 프리미엄 인삼세트 L.POINT 10,000원 할인 159,000원
    ▶ 6년근 인삼 700g, 국산

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    자연향담은 동고혼합세트 L.POINT 10,000원 할인 39,900원
    ▶ 동고 70g, 표고채 70g, 표고칩 75g, 국산

    자연향 버섯 혼합세트 2호 L.POINT 20,000원 할인 59,900원
    ▶ 동고 40g, 표고채 30g, 건목이 40g, 흰목이 30g, 표고칩 35g, 향신 35g, 국산

    6년근 실속 금산 인삼세트 L.POINT 20,000원 할인 49,900원
    ▶ 6년근 인삼 난발 350g, 국산

    웰빙표고 실속세트 2호 L.POINT 10,000원 할인 54,900원
    ▶ 동고 70g, 표고채 60g, 표고사각 30g 핑크솔트 140g, 흑후추 70g, 원산지 별도표기

    유명산지 금산 인삼 세트 카드할인 10,000원 할인 79,900원
    ▶4년근 인삼 500g, 국내산

    유명산지 홍천 더덕 세트 카드할인 10,000원 할인 79,900원
    ▶더덕 1.2kg(뿌리당 40~50g),

    국내산 벌집꿀 L.POINT 10,000원 할인 89,900원
    ▶ 1.5kg, 밀랍포함, 국산

    유기농 표고버섯 혼합 1호 L.POINT 15,000원 할인 59,900원
    ▶ 유기농 흑화고 100g, 유기농 표고채 70g×2, 국산

    팔각 어울림 버섯 L.POINT 20,000원 할인 139,000원
    ▶ 백화고100g, 흑화고100g×2, 국산

    홍천 송선진 농부의 고랭지 더덕 세트 179,000원
    ▶ 더덕 1.5kg(뿌리당 70~90g), 국산

    상황 영지버섯 L.POINT 10,000원 할인 99,900원
    ▶ 상황버섯 130g, 영지버섯 300g, 국산

    홍천 왕더덕 세트 L.POINT 10,000원 할인 179,000원
    ▶ 더덕 1.5kg(뿌리당 100~130g), 국산

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    고려인삼 녹용침향액 39,000원
    ▶ 75ml×10병

    1+1 농협 한삼인 홍삼진 굿데이앰플 70,000원
    ▶ 20g×20병

    종근당건강 활력녹용보 L.POINT 50% 21,900원
    ▶ 50ml×30포

    1+1 농협 한삼인 홍삼본 골드 39,800원
    ▶ 50ml×30포

    1+1 농협 한삼인 홍삼진 굿데이스틱 59,600원
    ▶ 10ml×60포 

    3+1 풍기인삼 황풍정 산삼배양근 보 39,000원
    ▶ 75ml×16입

    1+1 씨제이 한뿌리 홍삼대보 44,800원 
    ▶ 40ml×24입

    1+1 씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    2+1 풍기인삼 자연의 기운을 품은 심 1호 54,000원
    ▶ 산삼배양근 8병, 산삼배양근 단 8입, 절편 3입

    풍기인삼 황풍정 홍삼정과 107,000원
    ▶ 700g

    카드할인 4+1
    정관장 홍삼원기 37,000원 ▶ 50ml×30포

    2+1 풍기인삼 자연의 기운을 품은 심 2호 49,000원
    ▶ 산삼배양근 보 8입, 봉밀홍삼절편 5입

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    인기 브랜드 햄/오일/통조림 선물세트

    씨제이 제일제당

    씨제이 특별한선택 L-3호 카드할인 30% 28,630원
    ▶ 카놀라유 500ml×2, 요리올리고당 700g, 스팸 120g×6

    5+1
    씨제이 백설 프리미엄 23호 18,900원
    ▶ 카놀라유 500ml×2, 요리유 500ml×2

    씨제이 특별한선택 K-4호 카드할인 30% 21,630원
    ▶ 카놀라유 500ml×2, 올리고당 500g, 스팸 120g×4

    씨제이 특별한선택 N호 카드할인 30% 41,230원
    ▶ 카놀라유 500ml×2, 고소함가득참기름 330ml, 스팸 200g×7

    씨제이 스팸복합 1호 카드할인 30% 33,530원
    ▶ 카놀라유 500ml×2, 스팸 200g×6

    씨제이 스팸복합 5호 카드할인 30% 33,530원
    ▶ 카놀라유 500ml×2, 스팸 120g×9

    씨제이 특별한선택 스페셜 A호 카드할인 30% 34,930원 
    ▶ 카놀라유 500ml, 스팸 200g×4, 올리고당 500g 外

    씨제이 스팸복합 S호 카드할인 30% 39,830원 
    ▶ 카놀라유 500ml×2, 스팸 200g×5, 스팸 340g×2 外

    씨제이 특별한선택 L-2호 카드할인 30% 40,530원 
    ▶ 카놀라유 500ml×2, 스팸 200g×6, 요리올리고당 700g 外

    씨제이 스팸 8K호 카드할인 30% 41,520원
    ▶ 스팸 200g×6, 스팸 라이트 200g×3

    2+1
    씨제이 특별한선택 K호 43,900원
    ▶ 카놀라유 500ml, 요리올리고당 700g×2, 스팸 120g×4 外

    씨제이 스팸복합 100호 카드할인 30% 51,730원 
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 340g×2, 스팸 120g×2

    씨제이 특별한선택 스페셜 L-6호 카드할인 30% 51,730원
    ▶ 카놀라유 500ml×2, 고소함가득 참기름 330ml, 구운소금 180g×2, 스팸라이트 200g×6 外

    씨제이 최고의선택 레드라벨 카드할인 30% 67,830원
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 120g×5 外

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    동원

    동원 튜나리챔 100호 카드할인 30% 49,910원
    ▶ 살코기참치 135g×12, 리챔 200g×4

    동원 프리미엄 1호 카드할인 30% 19,950원
    ▶ 살코기참치 90g, 리챔 120g, 사과식초 500ml, 올리고당 700g 外

    동원 스페셜 1호 카드할인 30% 25,970원
    ▶ 살코기참치 135g×2, 리챔 120g×4, 건강요리유 480ml×2

    동원 스페셜 5호 카드할인 30% 29,890원
    ▶ 살코기참치 90g×6, 리챔 120g×3, 카놀라유 480ml×2

    동원 프리미엄 62호 카드할인 30% 29,960원
    ▶ 살코기참치 90g×5, 리챔 120g×2, 참치액 진 240g, 참기름 75ml 外

    2+1
    동원 참치액 1호 32,900원
    ▶ 동원 참치액 진 500g×2, 참치액 순 500g×2

    동원 튜나리챔 30호 카드할인 30% 34,930원
    ▶ 살코기참치 90g×12, 리챔 더블라이트 120g×3

    동원 프리미엄 60호 카드할인 30% 34,930원
    ▶ 살코기참치 90g×9, 동원 참치액 진 240g, 건강요리유 480ml×2 外

    동원 참치V20호 카드할인 20% 36,960원
    ▶ 살코기150g×8 + 고추참치100g×4

    2+1
    동원 프리미엄K호 38,900원
    ▶ 동원 살코기 90g×3, 리챔 120g, 구운소금 210g, 히말라야소금100g 外

    동원 튜나리챔 64호 카드할인 20% 39,920원
    ▶ 고추참치 90g×6, 살코기참치 135g×6, 리챔 120g×4

    동원 스페셜 38호 카드할인 30% 39,970원 
    ▶ 살코기참치 135g×6, 리챔 더블라이트 200g×4 外

    동원 튜나리챔25호 카드할인 30% 44,940원
    ▶ 살코기 135g×13, 리챔오리지널120g×3

    10+1 카드할인 20%
    동원 튜나런천미트67호 50,960원
    ▶ 살코기 135g×12, 런천 200g×4

    동원 튜나리챔 102호 카드할인 30% 54,950원
    ▶ 살코기참치 135g×9, 리챔 200g×8

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    통큰 한가위 인기 브랜드 햄/오일 선물세트

    청정원 
    10+1 청정원 고급유 6호 13,900원
    ▶ 카놀라유 500ml×2개, 요리유 500ml

    청정원 스페셜 S호 카드할인 30% 19,950원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 두번달여더진한 진간장골드 500ml 外

    청정원 L1호 카드할인 30% 22,260원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 맛술 생강&매실 410ml, 요리올리고당 700g 外

    청정원 L2호 카드할인 30% 24,360원
    ▶ 카놀라유 500ml, 잇츠팜 115g×4개, 올리고당 700g, 옥수수유 500ml

    청정원 L7호 카드할인 30% 29,960원
    ▶ 카놀라유 500ml, 잇츠팜115g×5개, 고소한 건강생각 참기름 160ml

    청정원 L8호 10+1 카드할인 30% 37,660원
    ▶ 카놀라유 500ml, 맛선생 참치액 560g, 잇츠팜 115g×6개, 정통 사과식초 470ml 外

    1+1 청정원 L3호 49,800원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2개, 올리고당 700g 外

    1+1 청정원 L5호 49,800원
    ▶ 카놀라유 500ml, 요리올리고당 700g, 잇츠팜 115g×4개, 두번달여 더 진한 진간장 골드 200ml

    청정원 L13호 카드할인 30% 54,460원
    ▶ 카놀라유 500ml×2개, 정통사과식초 470ml, 천일염가는소금 190g 外

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    이색적인 상품 소금/오일 선물세트

    비온뒤 첫소금
    청정해역 신안에서 비가 온 뒤 가장 깨끗해진 토판 위에서 정성껏 수확하는 명품 소금 선물세트

    비온뒤 첫소금 프리미엄 2호 L.POINT 10% 45,000원
    ▶ 3년숙성 천일염 135g, 프리미엄 구운소금 150g 外

    비온뒤첫소금 프리미엄 6호 L.POINT 10% 22,500원
    ▶ 프리미엄 함초소금 400g, 프리미엄 후추소금 380g, 프리미엄 구운소금 400g

    올리타리아
    신선한 원재료를 수확 후 24시간 안에 냉압착하여 전통적인 맛과 향이 살아있는 이탈리아 오일 브랜드

    올리타리아 스페셜 5호 L.POINT 10% 14,310원
    ▶ 해바라기유 500ml×2

    올리타리아 스페셜 20호 L.POINT 10% 36,810원
    ▶ 올리브유 500ml, 포도씨유 500ml, 해바라기유500ml

    올리타리아 스페셜 4호 L.POINT 10% 42,210원
    ▶ 올리브유 500ml×2

    그로브 
    100% 뉴질랜드의 신선한 아보카도로 만들어 건강함이 가득하고, 요리 본연의 맛을 살려주는 오일 브랜드

    그로브 아보카도 오일 1호 L.POINT 20% 29,200원
    ▶ 그로브 아보카도 오일 엑스트라 버진 250ml×2병

    바로담아
    엄선한 국산 참들깨를 사용, 전통방식으로 만들어 깨 본연의 고소하고 진한 풍미가 느껴지는 선물세트

    바로담아 국산 참들기름선물세트 L.POINT 10% 53,900원
    ▶ 국산 참기름 180ml, 국산 들기름 180ml, 국산볶음참깨105g

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    선물하는 분과 받는 분의 품격을 높이는 주류 선물세트

    조니워커 블루 L.POINT 32,200원 할인 295,800원
    ▶ 조니워커 블루 750ml

    로얄살루트21년500ml L.POINT 40,800원 할인 169,000원
    ▶ 로얄살루트 500ml

    발베니더블우드 12년 L.POINT 14,800원 할인 110,000원
    ▶ 발베니더블우드12년 700ml

    조니워커그린 L.POINT 20,000원 할인 79,800원
    ▶ 조니워커 그린 750ml, 온더락 글라스, 글라스 캡

    맥캘란더블캐스크 12년 L.POINT 12,000원 할인 110,000원
    ▶ 맥캘란더블캐스크12년 700ml

    발렌타인 21년 L.POINT 64,100원 할인 225,900원
    ▶ 발렌타인 21년 700ml

    샤또글로리아18Y 100,000원
    ▶ 샤또글로리아18Y 750ml

    샤또깡뜨메를르17Y 75,000원
    ▶ 샤또깡뜨메를르17Y 750ml

    샤또라퐁로쉐17Y 99,000원
    ▶ 샤또라퐁로쉐17Y 750ml

    샤또디켐Y19 830,000원
    ▶ 샤또디켐Y19 750ml

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    식탁을 더욱 풍성하게! 어묵&수제햄&바베큐 선물세트

    ※ 선물용 쇼핑백 혹은 전용 보냉백과 함께 택배로 배송됩니다

    치즈 그레이터 세트 카드할인 10% 59,400원
    ▶ 보스카 이지 그레이터, 파르네제 그라나 파다노 150g, 파르네제 파르미지아노 레지아노 150g 外

    치즈&샤퀴테리 세트 카드할인 10% 32,400원
    ▶ 르 갈 크림치즈 플레인 150g, 베르기어 포션 믹스 120g, 에스푸나 세라노 하몽 50g 外
    
    ※상품권 및 즉시할인 제외
    몽탄 우대갈비 선물 세트 139,000원
    ▶ 몽탄 우대갈비 1.2kg (3대), 무생채

    존쿡델리미트 베스트 세트 카드할인 10% 49,500원
    ▶ 바베큐립 460g, 킬바사 270g, 킬바사치즈 270g, 비프핫도그 300g, 컨츄리베이컨 120g 外

    존쿡델리미트 샤퀴테리 세트 카드할인 10% 49,500원
    ▶ 하몽세라노 120g, 까챠토레 150g, 코파 50g, 이탈리안살라미 50g, 카바노치스낵 50g 外

    존쿡델리미트 브런치 세트 카드할인 10% 35,100원 
    ▶ 브렉퍼스트 소시지 210g, 킬바사 270g, 단호박 까망베르스프 160g×3, 마일드베이컨 120g 外

    프리미엄 어묵
    고래사어묵 선물세트 3호 40,000원
    ▶ 고래바 65g×2, 통새우말이 90g, 치즈빵빵 80g, 떡말이 90g, 소시지말이 90g 外

    고래사어묵 선물세트 5호 60,000원
    ▶ 고래바 65g×2, 통새우말이 90g×2 치즈빵빵 80g, 떡말이 90g, 새우봉어묵 150g, 크랩모아 140g 外

    고래사어묵 선물세트 7호 80,000원
    ▶ 용궁어묵 180g, 전복어묵 120g, 통새우말이 90g×2, 치즈빵빵 80g×2, 떡말이 90g 外

    프리미엄 수제햄
    ※상품권 및 즉시할인 제외
    오뗄 수제햄 실속 1호 39,900원
    ▶ 바베큐 안심햄 300g, 화이트본레스햄 300g, 차슈 300g, 모타델라 300g, 부어스트에센 105g

    프리미엄 햄&치즈
    상하농원 수제 햄&치즈 세트 카드할인 20% 79,200원
    ▶ 클래식비엔나 120g×2, 화이트 비엔나 120g, 롤비엔나 120g×2, 치즈프랑크 180g×2

    홈스토랑
    ※상품권 및 즉시할인 제외
    VIPS 홈레스토랑 선물세트 1호 72,400원
    ▶ 오리지널 바비큐 폭립, 척아이롤 스테이크, 명란 까르보나라 원팬 파스타, 뇨끼 감바스, 미트 라자냐

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    온가족 담소시간! 전통과자&커피&차

    ※상품권 및 즉시할인 제외
    동서 맥심 20호 세트 27,450원
    ▶ 맥심 모카 골드 마일드 믹스 12g×200입

    5+1 카누 아메리카노 8호 25,930원
    ▶ 카누미니 마일드 10T× 6개, 카누미니 다크 10T×2개, 카누미니 라이트 10T× 2개, 투게더머그 ※사전예약 한정 운영

    5+1 카누 블렌드 7호 21,900원
    ▶ 카누 더블샷 라떼 10T, 카누 라떼 10T, 카누 말차 라떼 8T, 카누 바닐라 라떼 8T 外 ※사전예약 한정 운영

    스타벅스 원두믹스 머그 선물세트 카드할인 20% 28,080원 
    ▶ 다크 10T×2개, 미디엄 10T, 다크 50T, 화이트머그 260ml

    1+1
    녹차원 향기담은 허브차 세트 24,900원
    ▶ 쟈스민 허브차 5입, 맛있는녹차작설 5입, 얼그레이 5입 外
    ※사전예약 한정 운영

    10+1
    녹차원 차다움세트 9,900원
    ▶ 꿀유자차 350g, 생강대추배차 7T, 호두아몬드율무차 7T
    ※사전예약 한정 운영

    창평한과 송강정 70,000원
    ▶ 유과(찹쌀, 백년초, 단호박, 대잎 外), 매작과(치자백년초 外) 外 ※사전예약 한정 운영

    창평한과 소쇄원 108,000원 
    ▶ 유과(찹쌀, 백년초, 단호박, 대잎) 外
    ※사전예약 한정 운영

    담터 다정 종합세트 카드할인 30% 19,320원
    ▶ 호두아몬드율무차 18g×15T,단호박마차 17g×15T, 쌍화차플러스 15g×15T 外

    꽃샘 아카시아 야생화꿀 세트 카드할인 20% 47,920원
    ▶ 아카시아꿀 600g, 야생화꿀 600g
    ※사전예약 한정 운영

    꽃샘 밤꿀세트 카드할인 20% 54,400원
    ▶ 밤꿀 1.2kg
    ※사전예약 한정 운영

    ※ 발행일(2024년 8월 1일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외

  </div>
  `,
  `
  <div>
    바디케어 선물세트

    애경 산양유 바디케어 기프트 L.POINT 5,000원 할인 11,900원
    ▶ 샤워메이트 산양유 바디워시 800ml×2, 샤워메이트 산양유 비누 90g×2입

    10+1
    LG 정성담은13호 13,900원
    ▶ 엘라스틴 데미지윤기케어10X샴푸 200ml×2, 엘라스틴 데미지윤기케어10X컨디셔너 200ml 外

    1+1
    LG 보타닉퍼퓸 컬렉션스페셜 30,900원
    ▶ 보타닉 퍼퓸컬렉션 샴푸 480ml×2, 보타닉 퍼퓸컬렉션 컨디셔너 480ml 外

    LG 히말라야핑크솔트38호 27,230원
    ▶ 오가니스트 히말라야핑크솔트 샴푸 200ml×3, 히말라야 핑크솔트 바이옴치약 100g×4 外

    LG 정성담은두배세트 13,930원
    ▶ 오가니스트 체리 수분 샴푸 200ml, 컨디셔너 200ml, 오가니스트 히말라야 핑크솔트 샴푸 200ml 外

    1+1
    LG 샴푸&바디세트 프리미엄 33,900원
    ▶ 엘라스틴 데일리 리페어링 샴푸 400ml×2, 엘라스틴 데일리 리페어링 컨디셔너 400ml 外

    LG 정성담은 43호 30,730원 
    ▶ 닥터그루트 샴푸 180ml, 닥터그루트 컨디셔너 180ml, 오가니스트 히말라야핑크솔트 샴푸 200ml×2 外

    LG AI스페셜에디션 프리미엄세트 32,130원 
    ▶ 오가니스트 체리 샴푸 380ml×2, 오가니스트 체리 컨디셔너 380ml×2 外

    2+1
    LG 정성담은 36호 36,900원
    ▶ 리엔 로열젤리 샴푸 200ml, 컨디셔너 200ml, 죽염 청신원 치약 90g×6 外

    1+1
    LG 모던에디션 라이프 41,900원
    ▶ 온더바디 레몬 버베나 바디워시 400ml, 오가니스트 체리 수분 샴푸 200ml 外

    1+1 
    애경 케라시스 퍼퓸셀렉션 26호 26,900원
    ▶ 케라시스 러블리 데이지 퍼퓸 샴푸 400ml×2, 린스 400ml, 샤워메이트 실키 모이스춰 비누 80g×3

    LG 정성담은 29호 20,930원 
    ▶ 닥터그루트 샴푸 180ml,오가니스트 체리 수분 샴푸 200ml, 컨디셔너 200ml 外

    ※카드할인가는 롯데/BC/삼성/신한/KB국민/NH농협/하나카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    롯데백화점 일산점 식품관
    FOOD MARKET

  </div>
  `,
];

/* 905,906 전점 추석 본세트 (~9/17) */
const L905data = [
  `<h1>LOTTE Mart</h1>
  <div>
    ·적용기간 : 2024. 9. 7(토) ~ 2024. 9. 17(화)

    생활비 다이어트 롯데마트&MAXX 카드
    롯데마트맥스 신한카드 롯데카드 비씨카드 KB국민카드 신한카드 NH농협카드 하나카드 우리카드 IBK기업은행 전북은행 JB카드 광주은행 KJ카드

    통큰 한가위
    최대 50만원
    상품권 증정 또는 즉시할인

    최대 30% 할인 혜택
    ▶기간 : 9/7(토) ~ 9/17(화) ※상품권 증정 프로모션은 변경될 수 있습니다 ※상품권 증정 혜택과 중복 제공 불가

    L.POINT 회원 특별할인
    ※해당상품에 한함

    구매 수량에 따라 덤 증정 하나 더
    ※해당상품에 한함

    3만원 이상 구매시 전국 무료배송
    ※일부품목 제외(일부 도서 산간 지역 제외)
    ※결제금액 기준

    L.POINT 50,000원 할인
    마블나인 구이혼합세트(1++(9)등급) 699,000원
    ▶ 1 ++(9)등급 한우 등심 500g×2, 채끝/안심 각 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    L.POINT 50,000원 할인
    한우 홈파티팩 선물세트 199,000원
    ▶ 1등급 한우 새우살 300g×2, 알등심 300g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    L.POINT 50,000원 할인
    1등급 한우 갈비세트 149,000원
    ▶ 1등급 한우 찜갈비 700g×3, 냉동, 국내산 한우고기, 양념소스 ※전국 택배 운영

    L.POINT 50,000원 할인
    한우 등심채끝 혼합세트 249,000원
    ▶ 1등급 한우 등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기 ※전국 택배 운영

    L.POINT 50,000원 할인
    한우 등심정육세트 2호 179,000원
    ▶ 1등급 한우 등심 500g×2, 국거리/불고기 각 500g, 냉장, 국내산 한우고기 ※전국 택배 운영

    L.POINT 30,000원 할인
    한우 정육세트 2호 99,000원
    ▶ 1등급 한우 냉장 국거리/불고기 각 500g×2, 국내산 한우고기 ※전국 택배 운영

    L.POINT 60,000원 할인
    냉장 시즈닝 스테이크 모둠 세트 1.8kg 99,000원
    ▶ 미국산 소고기 시즈닝 척아이롤/부채살/살치살 각 0.6kg
    ※전국 택배 운영 ※상품권 증정 제외

    L.POINT 60,000원 할인
    한우 양념 불고기 모둠 세트(2.4kg) 119,000원
    ▶ 양념 한우 불고기 1.2kg, 한우 언양식 불고기 1.2kg, 냉동
    ※센터택배(전국배송) 가능 ※상품권 증정 제외

    L.POINT 70,000원 할인
    양념 LA식 꽃갈비 세트 119,000원
    ▶ 미국산 초이스 냉동 꽃갈비 1.2kg×2
    ※상품권 증정 제외

    L.POINT 30,000원 할인
    호주산 소 LA식 갈비세트 139,000원
    ▶ 호주산 냉동 꽃갈비 1.5kg×2
    ※상품권 증정 제외

    L.POINT 30,000원 할인
    미국산 소 혼합갈비 세트 119,000원
    ▶ 미국산 냉동 꽃갈비, 짬길비 각 1.5kg
    ※상품권 증정 제외

    L.POINT 10,000원 할인
    롯데호텔 미국산 초이스 LA갈비 세트 129,000원
    ▶ 미국산 초이스 냉동 꽃갈비 1kg×2, 롯데호텔 LA갈비 특제소스 200ml×4 ※상품권 증정 제외

    9의 자격, 오직 1% 한우에게만.
    ※ '23년 한우 전체 등급 낙찰 두수 53.1만두 중 롯데마트 한우 MD 직접 경매 1++(9)등급 Marble9 두수 801두

    마블나인 선물세트 롯데마트몰에서 편하게 구매하세요!
    판매 시작 기간 : 8.15(목) / 배송 시작 기간 : 8.20(화)
    카카오톡 선물하기에서도 마블나인을 검색해보세요!

    9등급 프리미엄 한우 세트 3입 각99,000원
    ※세부 규격 롯데마트몰 참고

    9등급 프리미엄 한우 세트 6입 각189,000원
    ※세부 규격 롯데마트몰 참고

    카드할인 10,000원 할인
    충주 사과, 천안 배 69,900원
    ▶ 사과 6입(1.7kg 내외), 배 6입(3.6kg 내외), 국산

    카드할인 10,000원 할인
    정성담은 사과, 배 79,900원
    ▶ 사과 8입(2.6kg 내외), 배 6입(3.9kg 내외), 국산

    L.POINT 10,000원 할인
    천안 배 39,900원
    ▶ 배 5~6입(5kg 내외), 국산

    카드할인 10,000원 할인
    정성담은 신고배 59,900원
    ▶ 배 9~11입(6.5kg 내외), 국산

    카드할인 10,000원 할인
    충주 프레샤인 사과 39,900원
    ▶ 사과 17~20입(5kg 내외), 국산

    카드할인 10,000원 할인
    정성담은 사과 59,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    L.POINT 10,000원 할인
    샤인머스캣 69,900원
    ▶ 샤인머스캣 3kg 내외, 국산

    L.POINT 10,000원 할인
    당도선별 메론 69,900원
    ▶ 머스크 메론 4입(6.4kg 이상), 국산

    L.POINT 10,000원 할인
    제스프리 골드키위 39,900원
    ▶ 골드키위 25입(2.5kg 이상), 뉴질랜드산

    L.POINT 10,000원 할인
    상주 곶감(福) 49,900원
    ▶ 곶감 24입(35g±4g×24입), 국산

    7+1
    자연담은 칠산소금 참굴비세트 2호 130,000원
    ▶ 마리 당 100g 내외/10마리, 원산지 별도표

    7+1
    참굴비 세트 2호 89,900원
    ▶ 마리당 80g 내외/20마리, 원산지 별도표기

    비비고 토종김 5호 9,900원
    ▶ 참기름 도시락김 4g×18봉, 원산지 별도표기

    L.POINT 4,000원 할인
    성경 보성녹차 정성L호 11,900원
    ▶ 녹차 재래식탁김 4g×24봉, 원산지 별도표기

    10+1
    광천 김수미 재래김 세트 10,900원
    ▶ 재래김 4g×20봉, 원산지 별도표기

    10+1
    동원 양반김 M10호 16,900원
    ▶ 구운 파래김 20g×3봉, 들기름 식탁김 4g×12봉, 원산지 별도표기

    3+1
    코주부 비프앤넛츠 프리미엄 선물세트 49,900원
    ▶ 120g×4봉, 우육 : 외국산(호주/뉴질랜드/미국), 아몬드/호두 : 미국산, 해바라기씨 : 불가리아산

    L.POINT 12,000원 할인
    궁 쇠고기육포 선물세트 47,900원
    ▶ 미국산 우육 70g×6봉, 원산지 별도표기

    L.POINT 15,000원 할인
    유명산지 멸치&견과 혼합세트 41,900원
    ▶ 볶음용 220g, 볶음조림용 100g, 조림용100g, 호두 130g, 아몬드 170g, 원산지 별도표기

    L.POINT 10,000원 할인
    명작 견과&건과일 10종 세트 39,900원
    ▶ 구운아몬드 170g, 구운땅콩 150g, 건포도 160g, 호두 110g,구운피스타치오 120g, 건크랜베리 160g, 대추야자 160g, 씨를 뺀 건자두 160g, 건무화과 150g, 구운캐슈넛 150g, 원산지 별도표기

    1+1
    넛츠박스 매일견과 세트 20봉 19,900원
    ▶ 매일견과 20g×20봉, 원산지 별도표기

    L.POINT 20,000원 할인
    매일견과 하루한봉 80봉 29,900원
    ▶ 매일견과 18g×20봉×4 케이스, 원산지 별도표기

    카드할인 10,000원 할인
    유명산지 금산 인삼 세트 69,900원
    ▶ 4년근 인삼 500g(뿌리당 50g 이상), 국산

    카드할인 10,000원 할인 L.POINT 10,000원 할인
    흑화고 혼합세트 54,900원
    ▶ 흑화고 80g, 표고채 50g×2, 목이버섯 40g,표고버섯가루 90g, 표고사각 50g, 국산

    카드할인 10,000원 할인
    유명산지 홍천 더덕 세트 69,900원
    ▶ 더덕 1.1kg(뿌리당 40~50g), 국산

    L.POINT 20,000원 할인
    자연향 버섯 혼합세트 1호 49,900원
    ▶ 동고 70g, 표고채 55g×2, 건목이 40g, 흰목이30g, 표고칩 30g, 표고버섯가루 60g, 원산지 별도표기

    금산 인삼, 홍천 더덕 세트 99,900원
    ▶ 인삼 400g, 더덕 600g, 국산

    L.POINT 10,000원 할인
    BEST 견과 10종 세트 49,900원
    ▶ 구운캐슈넛 150g, 마카다미아 150g, 구운피스타치오 120g, 해바라기씨 170g, 호두 110g, 호박씨 180g, 구운땅콩 150g, 브라질넛츠 150g, 구운아몬드 170g, 피칸 120g, 원산지 별도표기

    ※정상가란? 2024년 8월 17(토) ~ 9월 16일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    ※카드할인가는 롯데마트&MAXX/롯데마트맥스신한/롯데/BC/KB국민/신한/NH농협/하나/우리/IBK기업/전북은행/광주은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    카드할인 20%
    정관장 홍삼원기(氣) 29,600원
    ▶ 50ml×30포

    카드할인 20%
    정관장 홍삼원기(氣) 54,400원
    ▶ 50ml×60포

    카드할인 20%
    정관장 활삼골드 31,200원
    ▶ 50ml×30포

    L.POINT 3,000원 할인
    종근당건강 생유산균 락토핏 골드 더블세트 20,800원
    ▶ 50포×2입

    L.POINT 6,000원 할인
    뉴트리원 올인원 멀티비타 이뮨플러스 43,900원
    ▶ 7병×4세트

    풍기인삼 에너지허브 이뮨 더블업 세트 39,900원
    ▶ 5병×4세트

    1+1
    한삼인 홍삼본 골드 39,800원
    ▶50ml×30포

    1+1
    한삼인 홍삼진 굿데이스틱 59,600원 
    ▶10ml×60포

    1+1
    농협 한삼인 홍삼진 굿데이앰플 70,000원
    ▶ 20g×20병

    1+1
    약사신협 6년근 고려홍삼액 진 39,000원
    ▶ 70ml×30포

    1+1
    약사신협 내몸애생발란스 산삼배양근진액 39,000원
    ▶ 70ml×30포

    1+1
    씨제이 한뿌리 홍삼대보 44,800원
    ▶ 40ml×24입

    1+1
    씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24입

    1+1
    씨제이 한뿌리 흑삼아르기닌 흑마늘 SET 69,800원
    ▶ 100ml×6병, 50ml×12입

    1+1
    엘지 리튠 홍삼진 골드 제로 39,900원
    ▶ 50ml×24포

    3+1
    풍기인삼 황풍정 산삼배양근 보 39,000원
    ▶ 75ml×16입

    1+1
    농협 한삼인 홍삼침향환 골드 100 110,000원
    ▶ 3.75g×100개

    1+1
    동원 튜나리챔 8호/동원참치 M8호 각49,900원 ※교차구매 가능
    ▶ 튜나리챔 8호 : 살코기참치 90g×4, 리챔 120g×6, 동원참치 M8호 : 살코기참치 90g×2, 살코기참치 135g×6

    L.POINT 30%
    씨제이 스팸복합 K호 49,910원
    ▶ 카놀라유 500ml×2, 스팸 200g×8, 스팸 120g×6

    카드할인 30%
    씨제이 특별한선택 L-2호 40,530원
    ▶ 카놀라유 500ml×2, 요리올리고당 700g, 스팸 200g×6, 스팸 120g×4

    1+1
    청정원 L3호/청정원 L5호
    각49,800원 ※교차구매 가능
    ▶ L3호 : 카놀라유 500ml, 잇츠팜 115g×2, 올리고당 700g, 사과식초 500ml, 진간장골드 500ml, 소갈비양념 280g×2
    L5호 : 카놀라유 500ml, 요리올리고당 700g, 잇츠팜 115g×4, 진간장골드 200ml, 씨간장 양조간장 200ml

    카드할인 30%
    동원 스페셜 38호 39,970원
    ▶ 살코기참치 135g×6, 리챔 더블라이트 200g×4, 리챔 120g×4, 건강요리유 480ml×2

    L.POINT 30%
    동원 프리미엄 20호 39,970원
    ▶ 살코기참치 90g×12, 리챔 120g×4, 건강요리유 480ml×2 , 동원 참치액 진 240g×2

    1+1
    씨제이 스팸 12K호/씨제이 스팸 닭가슴살 12호 각59,800원 ※교차구매 가능
    ▶ 스팸 12K호 : 스팸 200g×8, 스팸 닭가슴살 12호 : 스팸닭가슴살 200g×8

    동서 맥심 커피세트 20호 23,800원
    ▶ 맥심 모카골드 믹스 12g×200입

    카드할인 20%
    스타벅스 원두믹스 머그 선물세트 28,080원
    ▶ 다크 10T×2개, 미디엄 10T, 다크 50T, 화이트머그 260ml

    5+1
    카누 블렌드 7호 21,900원
    ▶ 카누 더블샷 라떼 10T, 카누 라떼 10T, 카누말차라떼 8T, 카누 바닐라라떼 8T, 카누 미니 마일드 10T×2개

    10+1
    유영군 명인한과 2호 59,900원
    ▶ 유과(찹쌀/백년초/단호박/대잎), 정과(오렌지), 강정(쌀/백년초/치자/대잎/흑미/보리/참깨/들깨), 다식(단호박/백년초/대잎/콩), 약과(미니), 매작과(치자백년초)

    1+1
    창평한과 면앙정 69,900원
    ▶ 유과(찹쌀/백년초/단호박), 강정(쌀/백년초/치자/대잎/쌀아몬드/딸기/흑미), 다식(단호박/백년초/대잎/콩), 약과(미니)

    L.POINT 43,000원 할인
    발렌타인 21년 224,800원
    ▶ 700ml

    L.POINT 40,000원 할인
    글렌그란트 12년 69,900원
    ▶ 700m

    ※점포별 입점 상품 상이
    ※조기소진 가능
    2묶음 세트
    칠성 칠레 비냐마올로 파고 2종 세트 29,900원
    ▶ 750ml×2

    ※점포별 입점 상품 상이
    ※조기소진 가능
    2묶음 세트
    레뱅 스페인 1호 29,900원
    ▶ 750ml×2

    ※점포별 입점 상품 상이
    ※조기소진 가능
    2묶음 세트
    금양 프랑스 와인 1호 53,900원
    ▶ 750ml×2

    L.POINT 20,000원 할인
    조니워커 그린 79,800원
    ▶ 700ml

    ※점포별 입점 상품 상이
    ※조기소진 가능
    2묶음 세트
    금양 투조 XO 99,000원
    ▶ 700ml

    ※점포별 입점 상품 상이
    ※조기소진 가능
    금양 1865 헤리티지 세트 (미니 골프백) 55,000원
    ▶ 750ml

    ※점포별 입점 상품 상이
    ※조기소진 가능
    L.POINT 33,000원 할인
    레뱅 이탈리아 우마니론끼 비고르 세트 36,900원
    ▶ 750ml×2

    ※점포별 입점 상품 상이
    ※조기소진 가능
    화요 선물세트 2호 58,000원
    ▶ 200ml×3

    10+1
    엘지 생활의 품격 1호 13,900원
    ▶ 엘라스틴 볼륨 샴푸/컨디셔너 각 200ml, 오가니스트 바디워시 190ml, 온더바디 비누 80g×2, 페리오 치약 90g×2, 페리오칫솔 2개

    카드할인 30%
    엘지 생활의 품격 3호 20,930원
    ▶ 닥터그루트 샴푸 180ml, 오가니스트 샴푸/컨디셔너 각 200ml,오가니스트 바디워시 190ml×2, 온더바디 비누 80g×2, 페리오치약 90g×6

    1+1
    엘지 샴푸&바디세트 프리미엄 33,900원
    ▶ 엘라스틴 리페어링 샴푸 400ml×2, 컨디셔너 400ml, 온더바디 바디워시 500g, 온더바디 비누 80g×2

    카드할인 30%
    엘지 히말라야 핑크솔트 에센셜 세트 27,230원
    ▶ 히말라야 핑크솔트 샴푸 200ml×2, 바디워시 190ml, 비누 90g×2, 히말라야 핑크솔트 치약 민트향 100g×2, 바이옴치약 100g×4

    카드할인 30%
    엘지 히말라야 핑크솔트 클래식 세트 50,330원
    ▶ 히말라야 핑크솔트 샴푸 500ml, 샴푸 자몽 500ml, 바디워시 480ml,샴푸 380ml/200ml 각 1개, 폼클렌징 200ml, 펌핑치약 285g,바이옴치약 100g×5, 비누 90g×2

    1+1 헤드 단목 양말세트 7,900원
    ▶ 각 2매

    5+1 카드할인 30%
    휠라 스포츠 양말세트 6,930원
    ▶ 각 3매

    5+1 카드할인 30%
    컬럼비아 중목 양말 세트 각7,630원
    ▶ 각 3매

    5+1 카드할인 30%
    네파 남녀 스포츠 양말 세트 10,430원
    ▶ 6매

    ※정상가란? 2024년 8월 17(토) ~ 9월 16일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰과 전단지 상의 가격 및 혜택은 상이할 수 있습니다

  </div>
  `,
];

/* 809,810 슈퍼 추석 본세트 (~9/17) */
const L809data = [
  `<h1>LOTTE SUPER</h1>
  <div>
    ·롯데슈퍼 추석 선물세트 적용기간 : 2024. 9. 7(토) ~ 2024. 9. 18(수)

    롯데카드 비씨카드 삼성카드 신한카드 KB국민카드

    통큰 한가위
    최대 50만원
    상품권 증정 또는 즉시할인

    최대 30% 할인 혜택
    ▶기간 : 9/7(토) ~ 9/18(수) ※상품권 증정 프로모션은 변경될 수 있습니다 ※상품권 증정 혜택과 중복 제공 불가

    L.POINT 회원 특별할인
    ※해당상품에 한함

    구매 수량에 따라 덤 증정 하나 더
    ※해당상품에 한함

    3만원 이상 구매시 전국 무료배송
    ※일부품목 제외(일부 도서 산간 지역 제외)
    ※결제금액 기준

    9의 자격, 오직 1% 한우에게만.
    한우 최고 9등급 한우로 맛, 신선도, 가격 모든 면에서 격이 다른 마블나인 선물세트를 선보입니다
    ※ '23년 한우 전체 등급 낙찰 두수 53.1만두 중 롯데마트
    한우 MD 직접 경매 1++(9)등급 Marble9 두수 801두

    9/7(토) ~ 9/11(수) 기간한정! ※ 자세한 사항은 매장 안내데스크로 문의 바랍니다

    3입 세트 99,000원 등안채 세트 1++(9)등급
    등심, 안심, 채끝 각 200g/냉장/국내산 한우고기
    고급 휴대용 패키지

    6입 세트 189,000원 
    1++(9)등급 등심, 안심, 채끝 각 200g
    차돌박이, 업진살, 치마살 각 150g 냉장/국내산 한우고기

    L.POINT 30,000원 할인
    한우정육세트2호 99,000원
    ▶ 한우국거리/불고기 각 500g×2, 냉장, 국내산 한우고기

    L.POINT 30,000원 할인
    한우 정육세트 1호 169,000원
    ▶ 한우 국거리, 불고기 각 500g×3, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우등심세트2호 239,000원
    ▶ 한우등심팩 500g×4, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우등심채끝혼합세트 249,000원
    ▶ 한우등심 500g×2, 채끝 400g×2, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우 등심정육세트 2호 179,000원
    ▶ 1등급 한우 등심 500g×2, 국거리/불고기 각 500g, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우등심정육세트1호 219,000원
    ▶ 한우등심/국거리/불고기 각 500g×2, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우 등심 안심 혼합세트 229,000원
    ▶ 1등급 한우 등심 500g×2, 안심 400g×2, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우 구이 스페셜 세트 239,000원
    ▶ 1등급 한우 등심 500g, 채끝, 안심, 부채살 각 400g, 냉장, 국내산 한우고기

    L.POINT 50,000원 할인
    한우등심세트 1호 339,000원
    ▶ 한우등심팩 500g×6, 냉장, 국내산 한우고기

    L.POINT 30,000원 할인
    한우냉동실속정육세트 99,000원
    ▶ 한우냉동국거리 600g, 불고기 600g, 산적 600g, 양념소스, 냉동, 국내산 한우고기

    L.POINT 50,000원 할인
    한우냉동갈비세트 149,000원
    ▶ 한우냉동찜갈비700g×3, 양념소스, 냉동, 국내산 한우고기

    L.POINT 30,000원 할인
    호주산 냉동 찜갈비 세트(2.4kg) 99,000원
    ▶ 호주산 냉동 찜갈비 800g×3, 양념소스

    L.POINT 50,000원 할인
    양념한우불고기 모둠세트(2.4kg)/(냉동) 129,000원
    ▶ 양념 한우불고기 1.2kg, 한우 언양식 불고기 1.2kg ※상품권 증정 제외

    L.POINT 30,000원 할인
    미국산 소 냉동 LA식 갈비 세트(3kg) 129,000원
    ▶ 미국산 냉동 꽃갈비 구이용1.5kg×2

    L.POINT 5,000원 할인
    충주 사과 64,900원
    ▶ 사과 12~14입(4.2kg 내외), 국산

    L.POINT 15,000원 할인
    정성담은 신고배 64,900원
    ▶ 배 9~11입(6.5kg 내외), 국산

    L.POINT 10,000원 할인
    충주사과, 천안배 69,900원
    ▶ 사과 6입(1.7kg 내외), 배 6입 (3.6kg 내외), 국산

    L.POINT 10,000원 할인
    샤인머스캣 69,900원
    ▶ 샤인머스캣 3kg 내외, 국산

    L.POINT 10,000원 할인
    제스프리 골드키위 39,900원
    ▶ 골드키위 25입 (2.5kg 이상), 뉴질랜드산

    충주 프레샤인 사과 49,900원
    ▶ 사과 17~20입(5.0kg 내외), 국산

    천안배 49,900원
    ▶ 배 5-6입(5.0kg 내외), 국산

    샤인머스캣, 사과, 배 99,900원
    ▶ 샤인머스캣 2송이(1.4kg 내외), 사과 4입(1.2kg 내외), 배 6입(3.9kg 내외), 국산

    제주 황금향 49,900원
    ▶ 황금향 8~12입 (3.0kg 내외), 국산

    L.POINT 10,000원 할인
    상주 둥시 곶감(福) 49,900원
    ▶ 곶감 24입(0.8kg 내외), 국산

    1+1
    넛츠박스 매일견과 20봉 19,900원
    ▶ 매일견과 20g×20봉, 호두 5g(미국), 구운아몬드 5g(미국), 구운땅콩 5g(미국), 건포도 5g(칠레), 원산지 별도표기

    L.POINT 12,000원 할인
    시그니쳐 매일견과 세트 50봉 27,900원
    ▶ 매일견과 20g× 50봉, 구운아몬드 20%, 호두25%, 구운땅콩25%, 구운캐슈너트 20%, 원산지 별도표기

    L.POINT 20,000원 할인
    매일견과 하루한봉 세트 (80봉) 29,900원
    ▶ 매일견과 (18g×20개입)×4입, 건포도 31%, 구운아몬드 28%, 호두 24%, 구운캐슈너트 17%, 원산지 별도표기

    L.POINT 10,000원 할인
    더 고소한 마카다미아 매일견과 50봉 29,900원
    ▶ 매일견과 20g×50봉, 호두 30%, 구운아몬드 30%, 건포도(칠레) 25%, 마카다미아(호주) 15%, 원산지 별도표기

    L.POINT 10,000원 할인
    명작 견과 & 건과일 10종 세트 39,900원
    ▶ 견과류 5종 + 건과일 5종 총 1490g, 원재료 정보 별도표기

    L.POINT 10,000원 할인
    자연향담은 동고혼합세트 29,900원
    ▶ 동고 70g, 표고채 70g, 표고칩 75g, 국산

    L.POINT 20,000원 할인
    6년근 실속 금산 인삼세트 29,900원
    ▶ 6년근 인삼 난발 350g, 국산

    L.POINT 20,000원 할인
    자연향 버섯 혼합세트 2호 39,900원
    ▶ 동고 40g, 표고채 30g, 건목이 40g, 흰목이 30g, 표고칩 35g, 향신 35g, 국산

    L.POINT 20,000원 할인
    자연향 버섯 혼합세트 1호 49,900원
    ▶ 동고 70g, 표고채 55g×2, 건목이 40g, 흰목이 30g, 표고칩 30g, 표고버섯분말 60g, 국내산

    L.POINT 20,000원 할인
    자연향 담은 백화고 혼합세트 54,900원
    ▶ 백화고 90g, 동고 70g×2, 사각칩 40g×2, 상황채 40g, 영지채 50g,국내산

    ※ 발행일(2024년 9월 7일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외 ※카드할인가는 롯데/BC/삼성/신한/KB국민/카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    10+1
    대한민국 명장 장석준 명란세트 99,000원
    ▶ 명란 600g(300g×2), 냉동, 원산지 별도표기

    7+1
    자연담은 칠산소금 참굴비 1호/2호 69,900/130,000원
    ▶ 1 호 : 10마리 (0.8kg 내외), 냉동, 참조기/천일염 : 국산
    2호 : 10마리 (1.0kg 내외), 냉동, 참조기/천일염 : 국산

    10+1
    광천 김수미 재래김 선물세트 10,900원
    ▶ 재래김 4g×20봉

    CJ 비비고 토종김 1호 16,900원
    ▶ 담백 구운김전장 10g×3봉, 비비고 참기름 도시락김 4g×6봉×2ea

    L.POINT 30%
    동원 양반김 M20호 25,970원
    ▶ 들기름 전장김 20g×6봉, 참치액(진) 900g

    비비고 토종김 5호 9,900원
    ▶ 비비고 참기름 도시락김 4g×6번들×3EA

    양반 들기름김세트 9,900원
    ▶ 들기름 김 4g×18봉

    L.POINT 4,000원 할인
    성경 보성녹차 정성L호 11,900원
    ▶ 녹차 재래 식탁김 4g×24봉

    10+1
    동원 양반김 M10호 16,900원
    ▶ 구운 파래김 20g×3봉, 들기름 식탁김 4g×12봉

    L.POINT 7,000원 할인
    코주부 육포세트 2호 32,900원
    ▶ 외국산 우육 40g×7봉, 원산지 별도표기(우육 : 호주/뉴질랜드/미국)

    카드할인 20%
    정관장 홍삼원기 21,900원
    ▶ 50ml×30포

    고려인삼 녹용침향액 39,000원
    ▶ 75ml×10병

    L.POINT 10,000원 할인
    6년근 뿌리 많은 인삼세트 39,900원
    ▶ 6년근 인삼 난발 500g/국산

    L.POINT 10,000원 할인
    실속형 금산 인삼 더덕 혼합세트 49,900원
    ▶인삼 300g, 더덕 400g / 국산

    1+1
    아내의 착즙주스 배도라지즙/사과즙/당근사과즙/감귤사과즙 착즙하는남자 양배추 사과즙 각29,900원
    ▶ 각 100ml×21입 ※교차구매 가능

    1+1
    농협 한삼인 홍삼본 골드 39,800원
    ▶ 50ml×30포

    1+1
    씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    2+1
    풍기인삼 자연의 기운을 품은 심 2호 49,000원
    ▶ 산삼배양근 보 8입, 봉밀홍삼절편 5입

    딱, 필요한 상품으로만, 가성비 선물세트 추천
    카드할인 30%
    씨제이 특별한선택 L-3호 28,630원
    ▶ 카놀라유 500ml×2, 요리올리고당 700g, 스팸 120g×6

    카드할인 30%
    씨제이 스팸복합 1호 33,530원
    ▶ 카놀라유 500ml×2, 스팸 200g×6ㄴ

    카드할인 30%
    씨제이 특별한선택 L-2호 40,530원
    ▶ 카놀라유 500ml×2, 스팸 200g x 6, 스팸 120g×4, 요리올리고당 700g

    카드할인 30%
    씨제이 특별한선택 N호 41,230원 
    ▶ 카놀라유 500ml×2, 고소함가득참기름 330ml, 스팸 200g×7

    카드할인 20%
    씨제이 스팸 8K호 41,520원
    ▶ 스팸 200g×6, 스팸라이트 200g×3

    카드할인 30%
    동원 프리미엄 1호 19,950원
    ▶ 살코기 참치 90g, 리챔 120g, 사과식초 500ml, 올리고당 700g, 건강요리유 480ml, 카놀라유 480ml

    2+1
    동원 프리미엄K호 38,900원
    ▶ 살코기 90g×3, 리챔오리지널 120g, 구운소금 210g, 핑크솔트 100g, 올리고당 700ml, 카놀라유 480ml

    1등 참치와 짜지않은 리챔을 가득 담은 인기세트 추천
    카드할인 30%
    동원 튜나리챔 100호 49,910원
    ▶ 살코기참치 135g×12, 리챔 200g×4

    카드할인 30%
    동원 프리미엄 62호 29,960원
    ▶ 살코기 90g×5, 리챔 120g×2, 참치액 240g, 참기름 75ml, 카놀라유 480ml×2

    카드할인 20%
    동원 튜나리챔 64호 39,920원 
    ▶ 고추참치 90g×6, 살코기참치 135g×6, 리챔 120g×4

    카드할인 30%
    청정원 스페셜 S호 19,950원
    ▶ 카놀라유 500ml, 양조간장 500ml, 프레스햄 115g×2, 발효식초 500ml, 올리고당 700gㄴ

    카드할인 30%
    청정원 L1호 22,260원
    ▶ 옥수수기름 500ml, 카놀라유 500ml, 프레스햄 115g, 올리고당 700g, 생강매실맛술 410ml

    1+1
    청정원 L3호 49,800원
    ▶ 카놀라유 500ml, 잇츠팜 115g×2, 올리고당 700g,사과식초 500ml, 진간장골드 500ml, 소갈비양념 280g×2

    1+1
    청정원 L5호 49,800원
    ▶ 카놀라유 500ml, 올리고당700g, 잇츠팜115g×4, 진간장골드 200ml, 양조간장200ml

    L.POINT 30,000원 할인
    레뱅 이탈리아비고르세트 36,900원
    ▶ 우마니론끼 비고르 레드 750ml, 우마니론끼 비고르 화이트 750ml

    롯데 미국브레드 앤버터2종세트 67,600원
    ▶ 브레드앤버터 까베르네소비뇽 750ml, 브레드앤버터 샤르도네 750ml

    L.POINT 16,200원 할인
    조니워커 블랙 45,800원
    ▶ 조니워커 블랙 700ml, 하이볼글라스, 미니레몬즙 또는 종이코스터 ※점별 구성 상품이 상이할 수 있습니다

    L.POINT 11,000원 할인
    와일드터키 8년 54,900원
    ▶ 와일드터키8년700ml, 온더락글라스, 하이볼글라스

    L.POINT 20,000원 할인
    조니워커그린 79,800원
    ▶ 조니워커 그린 750ml,온더락 글라스, 글라스 캡

    L.POINT 5,000원 할인
    애경 산양유 바디케어 기프트 11,900원
    ▶ 샤워메이트 산양유 바디워시 800ml×2, 샤워메이트 산양유 비누 90g×2입

    10+1
    LG 정성담은13호 13,900원
    ▶ 샴푸 200ml×2, 컨디셔너 200ml, 바디워시(라벤더향) 190ml, 페리오 치약 90g×2, 페리오 칫솔×2(낱개)

    LG 히말라야핑크솔트38호 27,230원
    ▶ 샴푸 200ml×3, 핑크솔트 치약 100g×2, 바이옴 치약 100g×4, 핑크솔트 바디워시 190ml

    1+1 LG 샴푸&바디세트 프리미엄 33,900원
    ▶ 퍼퓸 샴푸 400ml×2, 퍼퓸 컨디셔너 400ml, 레몬 바디워시 500g, 보타닉 비누 80g×2
    
    1+1 LG 모던에디션 라이프 41,900원
    ▶ 바디워시 385ml, 체리블라썸 샴푸 400ml, 체리블라썸 샴푸 200ml, 컨디셔너 200ml, 페리오캐비티 모던 90g×2

    ※ 발행일(2024년 9월 7일)이후 상품 구성 및 상품 가격이 변경될 수 있습니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외 ※카드할인가는 롯데/BC/삼성/신한/KB국민/카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
];

/* 791,792 맥스 추석 본세트 (~9/17) */
const L791data = [
  `<h1>LOTTE Mart maxx</h1>
  <div>
    ·적용기간 : 2024. 9. 7(토) ~ 2024. 9. 17(화)
    ·롯데마트맥스 : 목포, 상무, 송천, 창원중앙, 금천, 영등포점

    생활비 다이어트 롯데마트&MAXX 카드
    롯데마트맥스 신한카드 롯데카드 비씨카드 KB국민카드 신한카드 NH농협카드 하나카드 우리카드 IBK기업은행 전북은행 JB카드 광주은행 KJ카드

    통큰 한가위
    최대 50만원
    상품권 증정 또는 즉시할인

    최대 30% 할인 혜택
    ▶기간 : 9/7(토) ~ 9/17(화) ※상품권 증정 프로모션은 변경될 수 있습니다 ※상품권 증정 혜택과 중복 제공 불가

    L.POINT 회원 특별할인
    ※해당상품에 한함

    3만원 이상 구매시 전국 무료배송
    ※일부품목 제외(일부 도서 산간 지역 제외)
    ※결제금액 기준

    구매 수량에 따라 덤 증정 하나 더
    ※해당상품에 한함

    유명산지 문경 사과세트 49,800원
    ▶ 사과 15~16입/4.5kg 내외, 국산

    유명산지 신고배 세트 49,800원
    ▶ 배 10~11입/6.5kg 내외, 국산

    카드할인 20%
    프리미엄 문경사과/신고배세트 51,840원
    ▶ 사과 6입, 배 6입/5.5kg 내외, 국산

    카드할인 20%
    큰사과 큰배 혼합세트 63,480원
    ▶ 사과 6입, 배 6입/6.4kg 내외, 국산

    샤인머스캣 사과 배 세트 49,800원
    ▶ 사과 4입, 배 3입, 샤인머스캣 1입/3.6kg 내외, 국산

    제스프리 골드키위 세트 29,800원
    ▶ 골드키위 18~20입(2.7kg 내외), 뉴질랜드산

    왕 곶감 세트 99,800원
    ▶ 왕곶감 32입(1.6kg 내외), 국산

    카드할인 13,000원 할인
    자연담은 표고버섯 어울림 49,800원
    ▶ 표고채 60g, 흑화고 80g×2, 목이버섯 40g, 표고버섯가루 90g×2, 국산

    카드할인 15,000원 할인
    정성담은 표고버섯 견과 혼합 63,800원
    ▶ 흑화고 80g×2, 표고채 60g×2 구운캐슈넛 150g, 피칸 120g 外,원산지 별도표기

    카드할인 20,000원 할인
    울릉도 왕더덕 118,000원
    ▶ 더덕 1.5kg, 국산

    카드할인 30,000원 할인
    호주산 소LA식 꽃갈비세트 148,000원
    ▶ 호주산 LA식 꽃갈비(냉동)2kg×2

    카드할인 30,000원 할인
    미국산 소 LA식꽃갈비 세트 148,000원
    ▶ 미국산 LA식 꽃갈비(냉동)2kg×2

    L.POINT 30,000원 할인 상품권 증정
    한우 등심 정육 세트 148,000원 
    ▶ 1등급 한우 등심 500g×2, 국거리/불고기 각 500g, 냉장, 국내산 한우고기

    L.POINT 40,000원 할인 상품권 증정
    한우 등심 세트 2호 208,000원
    ▶ 1등급 한우 등심 500g×4, 냉장, 국내산 한우고기

    L.POINT 30,000원 할인 상품권 증정
    한우 갈비 세트 138,000원 
    ▶ 한우 갈비(냉동) 700g×3, 양념 소스, 국내산 한우고기

    ASC인증 완도활전복세트 74,900원 
    ▶ 2kg 내외(특대/11미)

    킹블랙타이거 새우세트 137,500원
    ▶ 1kg 내외(왕특대, 5마리/냉동), 기니아산

    카드할인 20%
    제주 프리미엄 갈치 세트 159,200원
    ▶ 2.2kg(550g×4팩), 냉동, 국산
    
    카드할인 20%
    칠산소금 참굴비 세트 2호 110,000원 
    ▶ 1.0kg 내외(10마리), 냉동 참조기 : 국산, 천일염 : 국산

    카드할인 20%
    영광 법성포 참굴비 세트 1호 69,520원 
    ▶ 1.4kg 내외(20마리), 냉동 참조기 : 국산, 천일염 : 국산

    제주 추자도 거친 파도 속에서 자생하는 자연산 돌미역을 해녀가 직접 채취한 귀한 미역과 통영산 멸치로 구성
    카드할인 15,000원 할인
    추자도미역 통영멸치세트 49,800원
    ▶ 추자도미역 80g, 볶음용멸치 270g, 볶음조림용멸치 240g, 국물용멸치 240g, 국산

    카드할인 8,000원 할인
    대천 곱창김 세트 24,980원
    ▶ 곱창김(캔) 30g×4, 곱창김(전장) 25g×5봉, 김 : 국산

    카드할인 7,000원 할인
    코주부 육포세트 1호 36,800원
    ▶ 60g×6봉, 원산지 별도표기

    카드할인 10,000원 할인
    정성 담은 웰빙견과 10종 39,800원
    ▶ 볶음 아몬드 190g, 볶음 캐슈넛 160g, 호두 120g, 피스타치오 150g 外, 원산지 별도표기

    카드할인 10,000원 할인
    하루한줌 프리미엄 80봉 견과 29,800원
    ▶ 하루한줌 4종 구성(프리미엄, 믹스넛, 넛츠앤베리, 오리지널 각 20입), 원산지 별도표기

    ※정상가란? 2024년 8월 17(토) ~ 9월 16일(월) 롯데맥스송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※ 명절 한정 판매 상품으로 조기 품절 될 수 있습니다 ※주류/정관장/화장품 등 일부 상품 상품권 증정 및 즉시할인 제외
    ※카드할인가는 롯데마트&MAXX/롯데마트맥스신한/롯데/BC/KB국민/신한/NH농협/하나/우리/IBK기업/전북은행/광주은행카드로 결제시 적용 ※단일카드로 전액 결제시에 한함 ※매장과 온라인 가격은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    카드할인 25%
    정관장 홍삼진본 36,750원
    ▶ 40ml×20포

    1+1 상품권 증정
    한삼인 홍삼진 굿데이스틱 59,600원
    ▶10ml×60포

    1+1 상품권 증정
    씨제이 한뿌리 흑삼진액 제로 39,800원
    ▶ 50ml×24포

    L.POINT 6,000원 할인 상품권 증정
    뉴트리원 올인원 멀티비타 이뮨플러스 선물세트 43,900원
    ▶ 21.5g×7병×4입

    L.POINT 16,000원 할인 상품권 증정
    경남제약 레모나 에스산 선물세트 33,900원
    ▶ 1.5g×90포×3입

    카드할인 30% 상품권 증정
    동원 스페셜 38호 39,970원
    ▶ 살코기 참치 135g×6입, 리챔 더블라이트 200g×4입, 리챔 120g×4입, 건강요리유 480ml×2입

    카드할인 30% 상품권 증정
    동원 튜나리챔 30호 34,930원 
    ▶ 살코기 참치 90g×12입, 리챔 더블라이트 120g×3입

    1+1 상품권 증정
    씨제이 스팸 12K호 59,800원 
    ▶ 스팸 200g×8입

    카드할인 30% 상품권 증정
    씨제이 스팸복합 S호 39,830원
    ▶ 스팸 클래식 200g×5입, 스팸 클래식 340g×2입, 스팸클래식 120g×2입, 카놀라유 500ml×2입

    카드할인 30% 상품권 증정
    청정원 L9호 39,760원
    ▶ 카놀라유 500ml×2입, 잇츠팜 190g×2입, 잇츠팜 115g×2입, 우리팜델리 330g×2입, 요리올리고당 700g

    카드할인 30% 상품권 증정
    사조 안심특선 94호 26,530원
    ▶ 카놀라유 500ml, 사과식초 500ml, 안심팜 115g×2입, 구운소금 200g, 진간장 500ml, 맛술 500ml, 참기름 55ml, 올리고당 700g

    L.POINT 10%
    오성푸드 국내산 누룽지세트 20,610원
    ▶ 쌀 누룽지 150g×5입, 현미 누룽지 150g×5입

    해당상품 2,500원 할인 5+1 상품권 증정
    동서 카누 아메리카노 8호 22,980원
    ▶ 카누미니 마일드 10T×6입, 카누미니 다크 9g×10T×2입, 카누미니 라이트 9g×10T×2입, 트윈머그

    카드할인 30% 상품권 증정
    담터 다정 종합세트 19,320원
    ▶ 호두아몬드율무차 18g×15입, 단호박마차 17g×15입, 생강차플러스 15g×15입, 쌍화차플러스 15g×15입, 대추차플러스 15g×15입

    1+1
    침향녹용보/아내의 배도라지즙, 당근사과즙, 사과즙 각29,800원
    ▶100ml×30입/각 100ml×21입
    ※교차구매 가능

    2묶음 세트
    칠성 칠레 비냐마올로 파고 2종 세트 29,900원
    ▶ 750ml×2

    2묶음 세트
    레뱅 스페인 1호 29,900원
    ▶ 750ml×2

    L.POINT 74,000원 할인
    로얄살루트 21년 227,800원
    ▶ 700ml

    L.POINT 33,000원 할인
    글렌그란트 10년 54,800원
    ▶ 700ml,하이볼 잔 1개, 노징글라스 1개

    L.POINT 8,200원 할인
    화요 선물세트 2호 49,800원
    ▶ 200ml×3 ※17도, 25도, 53도

    엘지 프로폴리스 기프트세트 프리미엄 9,900원
    ▶ 페리오 프로폴리스향 90g×4, 페리오 프로폴리스 칫솔×2, 온더바디 프로폴리스 비누 80g×2

    10+1
    엘지 생활의 품격 1호 13,900원
    ▶ 엘라스틴 볼륨 샴푸/컨디셔너 200ml 각 1개, 온더바디 체리블라썸 바디워시 190ml, 페리오 캐비티 B 90g×2,온더바디 보타닉 비누 A 80g×2, 페리오 초극세모 슬림터치 칫솔 낱개×2

    1+1 엘지 보타닉 퍼퓸컬렉션 30,900원
    ▶ 보타닉 퍼퓸컬렉션 샴푸/컨디셔너/바디워시 각 480ml, 보타닉 퍼퓸컬렉션 비누 80g×2

    1+1 엘지 샴푸&바디세트 프리미엄 33,900원
    ▶ 엘라스틴 리페어링 샴푸 400ml×2, 컨디셔너 400ml, 온더바디 레몬 바디워시 500g, 온더바디 보타닉 비누 80g×2

    1+1 엘지 모던에디션 라이프 41,900원
    ▶ 오가니스트 체리블라썸 샴푸 모던에디션 400ml, 200ml 각 1개, 컨디셔너 200ml, 페리오캐비티 모던에디션 90g×2, 온더바디 더 내추럴레몬 바디워시 모던에디션 385ml

    카드할인 30%
    엘지 생활의 품격 3호 20,930원
    ▶ 닥터그루트 힘없는 모발용 샴푸 180ml, 온더바디 보타닉 비누 B 80g×2, 오가니스트 체리블라썸 샴푸/컨디셔너 200ml 각 1개, 온더바디 바디워시 라벤더향 190ml×2, 페리오캐비티 B 90g×6

    카드할인 30%
    엘지 히말라야 핑크솔트 에센셜 27,230원
    ▶ 오가니스트 핑크솔트 샴푸 200ml×2, 바디워시 190ml, 죽염 히말라야 핑크솔트 치약 아이스카밍민트 100g×2, 바이옴치약 100g×4, 온더바디 벨먼 핑크솔트 비누 90g×2

    카드할인 10%
    제이엠솔루션 꿀광 마스크팩 세트 30매 17,982원
    ▶ 꿀광 로얄 프로폴리스 마스크 플러스 30매

    1+1 
    유렉시아 명화양말 선물세트 전품목
    ※재고 소진시 행사 종료 ※동일상품에 한함

    PAT 남성 립중목, 여성 다이아 중목 양말세트/PAT 남성 다이아중목, 여성 다선컬러 중목 양말세트
    5,980/7,980원 ▶ 각 2족/각 3족

    ※정상가란? 2024년 8월 17(토) ~ 9월 16일(월) 롯데맥스송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰과 전단지 상의 가격 및 혜택은 상이할 수 있습니다
    
  </div>
  `,
];

// 439 그랑그로서리도곡점 (~12/18)
const L439data = [
  `<h1>LOTTE SUPER</h1>
  <div>
    ·전단적용기간 : 2024. 12. 12(목) ~ 2024. 12. 18(수) · 그랑그로서리 도곡점
    LOTTE SUPER GRAND GROCERY 롯데슈퍼 그랑그로서리 도곡
    새단장 오픈 기념! 12.12(목) 앵콜 행사!

    윈터딸기클럽 런칭기념 '달콤한 특가' 딸기 전품목 (상품별 상이/국산) L.POINT회원 3,000원 할인 
    ※점별 운영상품 상이 ※조기 품절될 수 있습니다
    한우 등심(1등급) (100g/냉장/국내산 한우고기) 7,990원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    Quality MEAT
    한우 최고등급, 1++(9)등급만을 엄선한 프리미엄 브랜드 Marble9
    마블등급 MB8+ 청정 와규만 선별

    마블나인 한우 전품목 (1++(9)등급) (각 100g/냉장/국내산 한우고기) L.POINT 30%
    호주 청정지역에서 350일 이상 곡물 먹고 키운 MBS8+등급 호주산 와규 등심 (100g/냉장/호주산 소고기) L.POINT 50% 5,995원

    Fresh SEASONAL 계절과일
    자이언트 & 고당도 보장된 프리미엄 과일 운영
    AI선별/비파괴 당도선별로 철저하게 선별한 고퀄리티 과일을 만나보세요

    황금당도 불로초 감귤 (1.5kg/박스/국산) 19,900원
    타이벡 감귤 (2.5kg/박스/국산) 9,990원
    스위트마운틴 바나나 (송이/필리핀산) 2,990원
    L.POINT 1,000원 롯데/신한/삼성카드 1,000원 추가할인 비파괴 당도선별 샤인머스캣 (1.5kg/박스/국산) ※페이지 하단 카드할인 세부내용 참고 12,990원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    오늘 뭐 먹지? 매일매일의 먹거리 고민 해결사!

    Fresh SEAFOOD 생선
    산지직송된 신선하고 위생적인 수산물 
    매장에서 바로 맛있게 쪄드립니다!

    롯데/신한/삼성카드 50% 킹크랩 (100g/냉장/러시아산) ※페이지 하단 카드할인 세부내용 참고 5,995원
    10,000원 할인 항공직송하여 신선함을 그대로! 피타고니아 생연어 필렛 (500g/냉장/칠레산) 19,900원
    BOTTLE BUNLER Select
    국내 주류 전문 큐레이션샵 보틀벙커에서 엄선한 프리미엄 와인/양주를 한자리에!
    초보자부터 전문가까지 누구나 취향에 맞는 주류 추천 리스트를 만나보세요

    샤또 지스꾸르 2021(750ml) 99,000원 ※ 한정수량 조기 품절될 수 있습니다 ※1인당 최대 2병 구매 가능
    뵈브클리코 옐로우라벨 애로우 서울 (750ml) 99,000원 ※ 한정수량 조기 품절될 수 있습니다
    조니워커 블랙 오징어게임에디션 (750ml) 54,800원
    경주법주 초특선 (700ml) 56,000원
    앙투어솔레 그릴링 치즈 (300g) 19,000원
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    요리하다 윌드뷔페 취향대로 즐기는 21종의 세계 인기 요리맛집!
    한입 새우튀김 (5입/원산지 별도표기) 3,990원
    나시고랭 (팩/원산지 별도표기) 4,990원
    오징어링 튀김 (팩/원산지 별도표기) 3,990원
    갈릭 치킨 스테이크 (팩/원산지 별도표기) 3,990원

    도시곳간 매장에서 당일 직접 만든 프리미엄 반찬부터 간편 도시락까지!
    4팩 골라담기 또는 반찬 4팩 이상 구매시 홍신애×도시곳간 김치맛김 1묶음(3봉입) 증정!
    ※기간 : 11/12(목)~11/15(일), 하루 선착순 100명 ※재고 소진시 행사가 조기 종료될 수 있습니다

    잡채 (팩/원산지 별도표기) 1,000원 할인 4,000원
    그린빈 표고버섯 (팩/원산지 별도표기) 6,500원
    고등어구이 (팩/원산지 별도표기) 6,500원
    소불고기 (팩/원산지 별도표기) 8,500원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    오늘 뭐 먹지? 매일매일의 먹거리 고민 해결사!
    오늘 메뉴로 이 요리 어떠세요? 오늘의 추천메뉴
    요리하다 밀키트 집에서 간편하게 즐기는 쉐프의 레시피!
    L.POINT 3,000원 마이셰프 냉장 밀키트 4종(상품별 상이) 각9,990~12,990원
    ※이화수육개장 칼국수/바지락술찜과파스타/갈릭포크찹스테이크/유산
    L.POINT 2,000원 요리하다 춘천식 닭갈비 (800g) 11,900원
    각7,490원 균일가 CJ 이틀리 3종(상품별 상이) ※현미떡볶이와닭가슴살구이/두부버섯강된장과보리밥/돼지불고기와흑미곤약밥
    각5,990원 균일가 대상 국탕류 4종(상품별 상이) ※얼큰소고기장터국/나주곰탕/파듬뿍양지육개장/고기곰탕
    Global Sauce, Add Your Taste
    음식의 풍미와 맛을 더할 고급 소스류! 글로벌 인기 소스 총 46종, 하나 사면 하나 더!
    1+1 후이펑 스리라차 칠리소스 (252g/481g) 7,980/12,980원
    1+1 타이시아 피넛 월남쌈소스 (175g) 3,980원
    3,010원 할인 올리타리아 엑스트라버진 올리브 오일(250ml) 9,980원
    4,510원 할인 올리타리아 트러플 올리브 오일(250ml) 10,480원

    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    L.POINT 2,000원 대추방울 토마토 (500g/팩/국산) 6,990원
    1+1 마늘치킨 훈제슬라이스 (400g/냉장//원산지 별도표기) 10,900원
    요리하다 양념육 6종 ※교차구매 가능 2팩 이상 구매시 각30% 할인
    ※ 제주흑돼지간장불고기/간장불고기/1등급한우불고기/양념소불고기/양념토시살구이/양념안창살구이
    L.POINT 10,000원 미국산 냉동 꽃갈비구이 (1kg/냉동/미국산 소고기) 49,900원
    L.POINT 300원 롯데/신한/삼성카드 900원 추가할인 남해 시금치(봉/국산) ※페이지 하단 카드할인 세부내용 참고
    애호박 (개/국산) 1,490원
    깐마늘 (500g/봉/국산) 4,990원
    1+1 수산명가 명란/양념 명란 (각 100g/냉장/원산지 별도표기) 각6,990원 ※교차구매 가능
    L.POINT 50% 하림 자연실록 닭볶음탕 (1kg/국내산) 5,490원
    L.POINT 30% 롯데/신한/삼성카드 5% 추가할인 국내산 돼지고기 삼겹살/목심 (각 100g/냉장/국내산 돼지고기) 각1,996원
    ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고
    풀무원 국산나또 전품목 (9종/상품별 용량상이/원산지 별도표기) 20% 할인
    완도 활전복 (왕/마리/냉장/국산) L.POINT 20% 5,280원
    THE 큰치킨 (마리) 9,900원
    해물양장피 (팩/원산지 별도표기) 10,900원
    패밀리초밥(16입/원산지 별도표기) 10,900원
    언양식 불고기한판 (3입/원산지 별도표기) 5,990원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    새단장 기념 오픈 초특가!
    1+1 닥터오트커 까사디마마 포미트피자/불고기피자(357g/345g) 각9,990원
    롯데슈퍼&롯데마트 단독! 2개 이상 50% 풀무원 점보돈까스(300g) 2개 이상 구매시 1개당 각5,295원
    ※1개 구매시 10,590원 ※풀무원 점보 밀떢볶이와 교차구매 가능
    롯데슈퍼&롯데마트 단독! 
    2개 이상 50% 풀무원 점보 밀떡볶이(383.5g) 2개 이상 구매시 1개당 각2,995원
    ※1개 구매시 5,990원
    ※풀무원 점보돈까스와 교차구매 가능
    1+1 삼진 오동통 모듬어묵 (700g) 9,990원
    1+1 폰타나 이탈리아 캄파니아 스파게티면(250g) 1,980원
    1+1 닛신 일본라면 4종(상품별 용량 상이) 각1,680원
    ※ 토마토맛/씨푸드오리지날/씨푸드커리맛/스파이시씨푸드맛 ※교차구매 가능
    1+1 다논 그릭 3종(각 90g×4) 각5,290원
    ※ 플레인/달지않은/블루베리 ※교차구매 가능
    1+1 요플레 5종(각 85g×4) 각3,780원
    ※교차구매 가능 ※ 클래식/플레인/샤인머스캣/제주감귤/라이트바나나
    1+1 목우촌 주부9단 육즙팡팡프랑크(350g) 8,990원
    1+1 롯데 칠성사이다/칠성사이다 제로/펩시콜라/펩시콜라제로/펩시콜라블랙(각 1.25L) 각1,790원
    1+1 오뚜기 양조식초 (1.8L) 2,980원
    1+1 설탕대신 스테비아 (180g) 4,980원
    1+1 설탕대신 알룰로스 (400g) 9,980원
    롯데 칠성사이다/칠성사이다 제로/펩시콜라/펩시콜라제로/펩시콜라블랙(각 1.25L) 각1,790원

    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    오늘 뭐 먹지? 매일매일의 먹거리 고민 해결사!

    1+1 하림 팝콘치킨(650g)/하림 통가슴살 치킨너겟(500g) 각10,990원
    1+1 풀무원 새콤달콤 유부초밥 (330g)※기간 : 12/12(목)~12/15(일) 5,690원
    1+1 풀무원 가케우동 2인(407.6g) ※기간 : 12/12(목)~12/15(일) 6,990원
    1+1 서울치즈 2종(각 180g) ※고단백/짜지않아건강한 ※교차구매 가능 각7,590원
    1+1 남양 불가리스 제로(각 80g×4) ※딸기/플레인/복숭아 ※교차구매 가능 각3,790원
    1+1 코카콜라 스프라이트/스프라이트제로(각 1.5L) ※교차구매 가능 각3,040원
    1+1 네슬레 킷캣 로투스/녹차 (상품별 중량 상이) ※교차구매 가능 각5,980원
    1+1 하인즈 머쉬룸 치즈크림/토마토&바질 파스타소스(각 350g) 6,480/5,980원
    1+1 휴럼 배도라지 스틱 (10g×28포) 21,800원
    2개이상 50% 삼립 하이면 포차우동 2종(각 2인) 2개이상 구매시 1개당 각2,995원
    ※교차구매 가능 ※1개 구매시 5,990원
    ※담백 428g/얼큰 424g

    2개이상 50% 델몬트 오렌지/포도/망고(각 1.8L) 2개이상 구매시 1개당 각2,990원
    ※1개 구매시 각5,980원

    2개이상 50% 크리넥스 울트라클린 시그니처 (30m×30롤) 2개이상 구매시 1개당 각14,450원
    ※1개 구매시 28,900원

    2개이상 50% 크리넥스 수앤수 라이언 물티슈(80매) 2개이상 구매시 1개당 각1,100원
    ※1개 구매시 2,200원

    2개이상 50% 퍼실 디스크 캡슐세제(각 26입) 2개 이상 구매시 1개당 각14,450원
    ※1개 구매시 각28,900원 ※파워/라벤더/실내건조/컬러

    2개이상 50% 명장불난로 핫팩 4종 2개 이상 구매시 1개당 각1,790~3,290원
    ※1개 구매시 각3,590~6,590원

    6,990~7,990원 존쿡 델리미트 후랑크 5종 (각 320g)

    6,480원 동원 비엔나소세지 기획 (300g×2)

    6,980원 공구핫딜 한성 와일드 크래미 (180g×3)

    1,200원 할인 하인즈 리듀스 슈가 케찹(360g)/하인즈 리듀스 쏠트 케찹(397g)/하인즈 할라페뇨 케찹(397g) 각4,280원

    1+1 차오차이 소스 4종(상품별 상이) 각3,480원
    ※ 홍콩식 마파두부/마라샹궈/마라훠궈/즈마훠궈 ※교차구매 가능

    롯데카드/삼성카드 1,800원 할인 하이뮨 프로틴 밸러스 (190ml×16입) 19,800원

    밀크바오밥 퍼퓸 샴푸/바디워시 (각 500ml) 각7,900원

    카밀 핸드크림 클래식&미니 2종 기획 8,900원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

// 211,212 구미점(~12/11)
const L211data = [
  `<h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 12. 5(목) ~ 2024. 12. 11(수) < 비신선식품 적용기간 : 2024. 11. 28(목) ~ 12. 11(수) >
    ·구미점

    롯데마트 구미점 리뉴얼 오픈 1주년
    단독 고객 감사 고객 SALE

    단독 일별특가
    ※12/11(수) 롯데마트 구미점 의무휴업일

    12/5목
    L.POINT 20%
    고등어자반(특대/1손 2마리/냉장) 4,792원
    ※고등어/천일염 : 국산

    12/6금
    L.POINT 30%
    참프레 훈제오리 슬라이스 (500g/냉장/원산지 별도표기) 8,960원

    12/7토
    ALL NEW 연어파티 초밥(20입)(1팩/원산지 별도표기) 16,990원

    12/8일
    L.POINT 1,000원
    빙그레 요플레 10입 기획 4,980원

    12/9월
    L.POINT 2,650원
    동서 맥심 커피믹스 모카골드 마일드(12g×220입) 3,640원 할인 각26,930원

    12/10화
    GAP 완전미 고시히카리(10kg/국산) 34,900원

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다
    이번주 핫프라이스 HOT
    윈터딸기클럽 런칭 기념 '달콤한 특가' 
    딸기 전품목(상품별 상이/국산) 롯데/신한/NH농협/삼성카드로 2개 이상 구매시 각 4,000원 할인
    ※2개 이상 구매시 각 2천원 할인 해당 카드 결제시 각 2천원 추가 할인
    ※1개 구매 시 카드할 인 2천원만 적용 ※교차구매 가 능
    ※점별 운영상품 상이 ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    인기 패션 브랜드 1+1 최대 70%
    아동&유아 의류&용품
    SPAO KIDS
    7만원/10만원 이상 7천원/1만원 즉시 할인!

    agabang
    1 출산 준비물 20만원 이상 20% 할인
    2 모든 구매고객 포인트 3배 적립/손수건 증정
    3 5만원 이상 구매고객 퓨토 보습 샘플키트 증정

    슈즈
    ABC-MART
    ※기간 : 11/28(목) ~ 12/9(월)
    1 이월상품 최대 70%
    2 2족 구매시 20%
    ※1족 구매시 10% ※시즌/겨울부츠 포함
    
    SHOE MARKER
    1 7만원 이상 구매시 장바구니 증정
    2 15만원 이상 구매시 1만원 롯데상품권 증정

    성인 의류
    BRENTWOOD
    1 신상품 44% 이월 정장 70%
    2 모든 구매고객 양말 증정
    40만원 이상 구매 고객 장우산 증정
    ※30개 한정
    3 e-코오롱 회원 고객 대상 신상품 구매시 10% 추가 할인

    LEBSHOMEY STUDIO
    ※기간 : 12/5(목) ~ 12/8(일)
    신상품 50% 할인

    PROJECT M
    1 의류 전품목 1+1
    ※행사상품에 한함
    2 이월 티셔츠/스웨터 각19,900원
    3 이월 아우터 39,900/69,900원

    잡화류
    못된고양이
    1 곱창/집게핀
    3개 골라담기 9,900원

    2 겨울양말
    4개 골라담기 12,000원

    특별 행사장 유명 브랜드 초특가 할인전 
    ※기간 : 11/23(토)~12/15(일)
    르까프, 아디다스, 카파, EXR, FILA, BEVERLY HILLS POLO CULB, KIM YOUNG JOO SPORTS, maitiji
    스포츠&골프의류/여성복/신발 전품목 10,000원 부터!

    롯데마트 구미점
    리뉴얼 OPEN 1주년 기념 이벤트
    97개 입점매장에서 QR코드 스캔하면 5천원 할인쿠폰 증정!
    7만원 이상 구매시 5,000원 할인
    ▶이벤트 기간 : 11/28(목) ~ 12/10(화)
    ▶대상매장 : 롯데마트 구미점 97개 입점매장
    ▶수령 방법 : 구미점 입점매장 QR스캔 후 다운로드
    ▶사용 장소 : 구미점 롯데마트 매장
    ▶사용 기간 : 다운로드 당일 영업시간 한정
    ※롯데마트GO회원 1인당 하루 최대 1회 한정 ※타 할인권 및 쿠폰 중복 적용 불가
    ※담배, 주류, 종량제봉투, 전자게임 등 일부 제외

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    구미점 단독 SALE 상품을 만나보세요!
    1등급 한우 등심 (100g/냉장) 6,490원
    ※국내산 한우고기

    국내산 삼겹살/목심 (각 100g/냉장/국내산 돼지고기) 각1,790원
    ※조기 품절될 수 있습니다

    L.POINT 20%
    대용량 봉지굴 (500g/냉장/국산) 13,520원

    L.POINT 6,000원
    온명란 기획 (100g×2입/냉장/원산지 별도표기) 9,900원

    L.POINT 6,000원
    항공직송 동원 생연어 (320g/냉장/노르웨이산) 15,900원

    L.POINT 4,000원
    양반 들기름향이 그윽한 김 (4.5g×20봉) 6,980원

    햇 밤고구마 (1.5kg/박스/국산) 5,590원

    전골용 모듬채소 (430g/팩/원산지 별도표기) 2,990원

    L.POINT 2,000원
    부사 사과 (4~6입/봉/국산) 7,990원

    7개 구매시
    자이언트 골드원 키위(개/국산) 8,750원
    ※한정수량으로 조기 품절될 수 있습니다
    ※1개 구매시 1,590원

    큰 초밥 (팩/원산지 별도표기) 12,990원

    하나사면 하나 더 1+1
    1+1
    종가 맛김치(900g) 15,900원

    1+1
    마니커 바사삭 치킨 (500g) 10,980원

    1+1
    오뚜기 XO만두(378g×2) 9,980원
    ※기간 : 12/5(목)~12/11(수)

    1+1
    한성 몬스터 크랩 (142g×2) 6,990원

    1+1
    사조대림 로얄크랩 (100g×2) 5,980원

    1+1
    동원 롤 유부초밥 2종 (각 254g) 각5,980원
    ※고소한맛/새콤한맛 ※교차구매 가능

    1+1
    삼립 포차우동 2종(각 2인) 각5,980원
    ※담백한맛/얼큰한맛 ※교차구매 가능

    1+1
    CJ 비비고 사골곰탕(500g) 2,180원

    1+1
    덴마크 인포켓치즈(200g/10입) 14,000원

    1+1
    삼육 검은콩과 칼슘 파우치 (190ml×20입) 19,900원

    1+1
    동서 미떼 핫쵸코 2종 (각 30g×10입) 각7,120원
    ※마일드/오리지날
    ※교차구매 가능

    1+1
    녹차원 꿀 유자차/레몬생강차(각 1kg) 각9,900원
    ※교차구매 가능

    2개 이상 30%
    농심 안성탕면 3종/올리브 짜파게티 (각 5개입) 2개 이상 구매시 1개당 각2,590/3,416원
    ※교차구매 가능
    ※1개 구매시 각 3,700/4,880원

    L.POINT 2,000원
    백설 식용유(1.8L) 6,480원

    L.POINT 500원
    삼립 단팥호빵/야채호빵 (각 95g×4) 4,880/5,380원

    남양 맛있는우유GT 기획 (900ml×2) 1,400원 할인 4,480원

    빙그레 요플레 10입 기획 (85g×10입) 700원 할인 5,980원

    제주 삼다수 그린 2종 (2L×6입/500ml×20입) 30% 4,536/6,720원

    L.POINT 20,000원
    1865 헤리티지 블렌드(750ml) 32,900원

    L.POINT 6,100원
    브레드앤버터 까베르네 소비뇽/피노누아(각 750ml) 각27,800원

    오리온 참붕어빵(29g×8입)/오리온 초코파이(39g×27입) 최대1,000원 할인 3,820/8,720원

    크라운 하임 초코/화이트(각 284g) 1,000원 할인 각3,780원

    종근당건강 아임비타 멀티비타민 이뮨 플러스(23g×7ea) 6,910원 할인 9,990원

    살림안정 물가안정 인기 생필품
    위드샨 60초 네일 매니큐어 (각 9ml) 1,500원 할인 각3,500원
    ※컬러/글리터 35종 ※점별 운영상품 상이
    ※케어류, 탑/베이스코트, 리무버 등 제외

    최대 4,000원 할인 -> 롯데/신한/NH농협/삼성카드 15% 추가할인
    니베아 바디로션 기획 2종 (각 400ml×2) 12,070/13,430원
    ※인텐시브/SOS
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 50%
    퍼실 디스크 4종(각 26개입) 2개 이상 구매시 1개당 각12,950원
    ※파워/라벤더/컬러/실내건조 ※교차구매 가능
    ※1개 구매시 각 28,900원 각 25,900원

    가그린 1개 이상 구매시 가그린(250ml) 샘플 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
    ※구분자 " ㉹ "를 확인하세요
    ※한정수량 증정 ※성인 가그린만 해당
    가그린 전품목(상품별 상이) 각4,200~14,980

    롯데/신한/NH농협/삼성카드 2개 이상 50% 
    크리넥스 수프림소프트 3겹 화장지(27m×30롤) 해당카드로 2개 이상 구매시 1개당 각19,200원
    ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 38,400원

    테팔 스타트 이지 프라이팬 5종 (상품별 상이) 20,400~27,900원

    오늘좋은 말랑보들 이불 (S : 150×200cm/Q : 180×220cm) 40% 29,940/35,940원
    ※크림블루/민트크림 ※입점점에 한함 ※해당상품에 한함

    에너자이저 맥스 10+5입 (AA/AAA) 30% 각8,330원

    L.POINT 30%
    어린이 인기완구
    그래비트렉스 전품목(상품별 상이) 11,140~279,930원

    월간스틱/월간 통살(상품별 상이) 30% 각7,630원
    반려동물 식품

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 11월 7일(목) ~ 11월 27일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    
  </div>
  `,
];

// 231 구미점(~12/18)
const L231data = [
  `<h1>LOTTE Mart</h1>
  <div>
    롯데마트 구미점 리뉴얼 오픈 1주년
    단독 고객 감사 SALE
    
    단독 일별 특가
    12/12 목
    L.POINT 2,000원
    문어 해물탕 (1.2kg/냉장/원산지 별도표기) 17,900원

    12/13 금
    L.POINT 50%
    호주청정우 안심 (100g/냉장/호주산) 3,990원
    
    12/14 토
    2in1 닭강정 콤보(갱엿/순살)(1팩/원산지 별도표기) 12,990원

    12/15 일
    1+1
    CJ 고메 함박스테이크/CJ 고메 치즈함박스테이크 (540g/465g) 각 9,980원
    ※교차구매 가능

    12/16 월
    해태 홈런볼 초코 (41g×4입) 3,980원

    12/17 화
    GAP 친환경 뿌리손질 새송이버섯 (300g×2봉/국산) 2,490원

    인기 패션 브랜드 최대 75%
    탑텐 키즈, BALANCE TOPTEN 겨울 신상품 최대 68% SALE 
    ※기간 : 12/2(월) ~ 12/31(화)

    ABC-MART
    1 크리스마스 세일 신상품 2족 구매시 20%
    ※기간 : 12/13(금) ~ 12/25(수)

    2 신상품 10% 겨울시즌 상품 최대 30%
    ※기간 : '24/12/13(금) ~ '25/1/2(목)
    ※일부품목 제외

    ZIshen
    1 겨울 신상품 전품목 50% 겨울 이월상품 최대 75%
    2 특가상품 구스다운 경량 숏 점퍼 79,000원
    3 20만원 이상 구매시 고급 스카프 증정 구매고객 모두 예쁜 양말 증정
    ※기간 : 12/12(목) ~ 12/25(수) ※특가상품/사은행사 한정수량

    구미점 단독
    L.POINT 50%
    청정와규 윗등심 MB8+ (100g/냉장/호주산) 4,990원

    친환경 어린잎 채소 (90g×2팩/국산) 2,990원

    L.POINT 3,000원
    손질오징어 (3마리/냉장/원양산) 8,990원

    L.POINT 10,000원
    프리미엄 파타고니아 생연어(500g/냉장/칠레산) 19,900원

    L.POINT 3,000원
    냉동 해물모둠 (500g/냉동/원산지 별도표기) 11,900원

    L.POINT 3,000원
    대천 전통 재래 도시락김 (4g×30봉/원산지 별도표기) 8,990원

    L.POINT 2,000원
    부사사과 (4~6입/봉/국산) 7,990원

    대추방울 스윗마토 (500g/팩/국산) 6,990원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    구미점 단독 SALE 상품을 만나보세요!

    하나사면 하나 더 1+1
    1+1
    한성 몬스터 크랩(142g×2) 6,990원
    ※기간 : 12/12(목) ~ 12/18(수)

    1+1
    CJ 고메 바삭 통등심/모짜렐라 돈카츠(각 450g) 각9,980원
    ※기간 : 12/12(목) ~ 12/18(수) ※교차구매 가능

    1+1
    동원 그릴리 델리햄 (450g) 6,990원

    1+1
    청정원 맛선생 국물내기 한알 4종 (상품별 규격 상이) 각9,900원
    ※멸치디포리/야채/사골/황태
    ※교차구매 가능

    1+1
    청정원 햇살담은 두번달여 더진한 진간장(1.7L) 9,890원

    1+1
    백설 육수에는 1분링 2종(각 80g, 멸치디포리/사골) 7,980원
    ※교차구매 가능

    1+1
    매일유업 바리스타 5종 (로슈거라떼 250ml 外4종) 각2,450원
    ※교차구매 가능

    1+1
    프링글스 오리지날/사워크림어니언(각 110g) 3,330원
    ※교차구매 가능

    1+1
    풀무원 얼큰 장칼국수 (2인) 7,480원

    오뚜기 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ⓞ "를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 각 1,980원 할인
    오뚜기 우동/칼제비 5종 (각 2인)※교차구매 가능
    2개 이상 구매시 1개당 각5,000원
    ※1개 구매시 각6,980원

    1+1
    오뚜기 열려라 참깨라면 (4개입) 3,880원

    최대40%할인!

    L.POINT 2,000원
    오뚜기 맛있는밥 (210g×12입) 10,980원

    L.POINT 1,100원
    서울 흰우유(2.3L) 6,480원

    L.POINT 2,000원
    동원 비피더스명장 기획(125ml×12) 3,480원

    40%
    코카콜라(1.8L) 2,350원

    L.POINT 30%
    해태 오예스 24입 기획 (30g×24입) 6,780원
 
    L.POINT 30%
    삼립 호빵 4종(90g×8) 6,150/각6,500원
    ※단팥/단팥&고구마, 단팥&야채, 단팥&피자 

    670원 할인
    농심 새우깡 대용량 (400g) 3,580원

    동서 맥심 기획 모카골드(12g×60포×4개) 29,280원
    ※한정수량으로 조기 품절될 수 있습니다

    L.POINT 6,000원
    종근당건강 락토핏 생유산균 골드 (2g×50포) 9,900원

    L.POINT 5,000원
    종근당건강 아임비타 멀티비타민 이뮨 플러스(23g×7개) 11,900원

    인기 주류 L.POINT 최대 8,200원 할인

    L.POINT 8,200원
    산토리 가쿠빈 (700ml) 29,980원

    북극곰의 눈물 전용 사케잔 2PCS 증정!
    북극곰의 눈물(도자기잔 증정) (720ml) 24,900원

    L.POINT 3,100원
    조닌 모스카토 다스티 (750ml) 13,800원

    L.POINT 최대 6,100원
    간치아 모스카토 다스티/간치아 모스카토 로제(각 750ml) 13,800원

    생활필수품 최대 50% 할인
    나무야 깨끗한 무향 3겹 화장지(27m×30롤) 9,900원
    
    50%
    글라스락 센스 14종 2,950~9,950원

    20%
    하우홈 보온용품 ※보온시트/보온벽지/단열시트 外 6종 1,590~13,520원

    50%
    룸바이홈 순면 차렵이불 24,950/29,950원

    2개 이상 50%
    명장불난로 핫팩 4종 (상품별 상이) 2개 이상 구매시 1개당 각1,795~3,295원
    ※교차구매 가능
    ※1개 구매시 각 3,590~6,590원

    20%
    반려동물 식품
    이나바 대용량 고양이 츄르 11,900~31,200원

    ※정상가란? 2024년 11월 21일(목) ~ 12월 11일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
 
  </div> 
  `,
];
// 330 토이저러스(~12/25)
const L330data = [
  `<h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 12. 5(목) ~ 2024. 12. 25(수) 
    ·토이저러스 입점점에 한함

    Merry Christmas
    ToysRus
    전점 8만장 한정
    L.POINT회원 롯데/신한/NH농협/삼성카드 완구 6만원 이상 구매시 10,000원 롯데상품권 증정
    ▶기간 : 12/12(목) ~ 12/25(수) ※전자게임/유아용품/문구/서적 등 일부품목 제외
    ※상품권 소진 시 행사가 조기종료될 수 있습니다 ※L.POINT회원 해당카드 결제시에 한함
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1회) ※페이지 하단 카드할인 세부내용 참고

    슈팅스타 티니핑 슈팅스타팩트 (3세 이상/26×8×28cm) 93,900원

    ToysRus 단독 상품
    실바니안 힐탑 테라스하우스 기프트 세트(5811) (3세 이상/42×22.5×36cm) 7,000원 할인 62,900원
    
    L.POINT 10%
    람보르기니 레부엘토 전동차 (37개월 이상/105×60×40cm) 268,200원

    1:12 사이버 트럭 (14세 이상/41.5×24.5×23.5cm) 25% 44,850원

    실바니안 힐탑 테라스하우스 기프트 세트(5811) (3세 이상/42×22.5×36cm) 7,000원 할인 62,900원

    실리콘케이스 증정!
    ※점별 수량 상이
    ※소진시 종료
    디지털 펫 내친구 디즈니 큐비찌 (5세 이상/15×12×15cm) 10% 71,900원
    ※입점점에 한함

    ToysRus 단독 상품
    패스트 뉴블랙트럭캐리어 (3세 이상/62.5×12×20.5cm) 50% 19,950원

    레고 콜의 타이탄 드래곤 로봇(71821) (12세 이상/35.6×7.1×37.8cm) 30,000원 할인 119,900원
    ※기간 : 12/1(일) ~ 12/25(수)

    헬로카봇 황금특공대 펜타스톰 (37개월 이상/65×13×41cm) 30% 140,000원

    오타마톤 디럭스 커비 (4세 이상/13.5×14.6×46.8cm) 19,200원 할인 75,000원

    ToysRus몰/롯데마트GO앱
    이벤트 참여하면 PS5 디스크 슬림 본체 경품 증정!
    ▶응모기간 : 12/5(목) ~ 12/25(수)
    ▶당첨자 발표 : 2025/1/8(수)
    ※LMS를 통한 개별 안내 예정
    ※ 이벤트 세부내용은 롯데마트GO앱 해당 이벤트 페이지에서 확인하세요

    1등 PS5 디스크 슬림 본체(1명)

    2등 레고 Mercedes Benz G500 (1명)

    3등 오아 플렌티 에어워셔 4L (2명)

    4등 롯데 모바일 상품권 5만원 (5명)

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 토이저러스 잠실점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※토이저러스몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    인기 레고 8종 최대 60,000원 할인!

    ※레고 행사기간 : 12/1(일) ~ 12/25(수) ※입점점에 한함, 점포별 재고 상이

    레고 근원의 모션 드래곤(71822) (12세 이상/55.5×8.7×37.8cm) 40,000원 할인 159,900원

    레고 엔더 드래곤과 엔드 함선(21264) (8세 이상/46.5×6.1×28.2cm) 17,300원 할인 97,600원

    레고 카이의 닌자 클라이머 로봇(71812) (9세 이상/26.2×38.2×7.05cm) 30,000원 할인 69,900원

    레고 북극 탐사선(60368) (7세 이상/58.2×11.8×37.8cm) 60,000원 할인 139,900원

    레고 고속기차(60337) (7세 이상/54.0×28.2×11.8cm) 40,000원 할인 159,900원

    레고 노란색 이동식 건설용 크레인(60409) (9세 이상/48.0×9.4×37.8cm) 32,000원 할인 127,900원

    레고 광장에서 KK라이브(77052) (7세 이상/45.6×6.1×28.2cm) 23,000원 할인 91,900원

    레고 2022 포드 GT(42154) (18세 이상/48.0×9.1×28.2cm) 48,000원 할인 111,900원

    꼬마버스 타요

    타요 컨트롤 주차타워 디럭스 (3세 이상/60×58×40cm) 21,000원 할인 84,000원
    
    레스큐 타요 포크레인 중장비 타워 디럭스세트 (3세 이상/50×33×40cm) 19,800원 할인 79,200원

    타요 중장비 디럭스 (3세 이상/35×20×35cm) 11,200원 할인 44,800원

    레스큐 타요 꼬마버스 친구들 풀세트3 (3세 이상/48.5×29.2×5.5cm) 16,000원 할인 63,900원

    타요 말하는 중앙차고지 놀이 (3세 이상/40×16.5×26cm) 9,600원 할인 38,300원

    타요 전동공구놀이 (3세 이상/40×9.8×26cm) 7,000원 할인 27,900원

    타요 멜로디 무선청소기 (36개월 이상/24×8.5×42.5cm) 8,000원 할인 31,900원

    타요 멜로디 꼬마버스 타요 (1세 이상/32×13×20cm) 11,000원 할인 43,900원

    슈퍼윙스 일렉트로 히어로즈

    슈퍼윙스 월드우주타워 (3세 이상/59×30×30cm) 15,800원 할인 63,100원

    슈퍼윙스 호기의 전동공구놀이 (3세 이상/30.5×16×32cm) 9,800원 할인 39,100원

    슈퍼윙스 럭키볼 뽑기세트 (5세 이상/32×13×38.5cm) 7,800원 할인 31,100원

    알파벳토이 블록 놀이세트 (8세 이상/52×7.5×31cm) 15,800원 할인 63,100원

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 토이저러스 잠실점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※토이저러스몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div> 
  `,
  `
  <div>
    영유아용 인기 완구상품 추천! ※입점점에 한함, 점포별 재고 상이

    뽀롱뽀롱 뽀로로
    올뉴 뽀로로 폴리스카 (4세 이상/40×25×16cm) 10,600원 할인 42,300원

    뽀로로 아이스크림가게 (3세 이상/35×16×24cm) 8,000원 할인

    뽀로로 말하는 바코드 계산대 (3세 이상/45×17×29cm) 43,900원

    뽀로로 노릇노릇 생선구이 가게 (3세 이상/41×29×16.7cm) 9,600원 할인 38,300원

    뽀로로 똑똑한 코딩기차 (37개월 이상/41×10×31cm) 8,000원 할인 31,800원

    뽀로로 약국놀이 (3세 이상/49×17×31cm) 10,600원 할인 42,300원

    핑크퐁
    미미월드 핑크퐁 전화기(3세 이상/34×11×26cm) 34,900원

    핑크퐁 아기상어 병원놀이 (3세 이상/51×6×36cm) 32,000원
    
    핑크퐁 아기상어 RC카(3세 이상/24.5×19×18cm) 5,800원 할인 23,100원

    핑크퐁 아기상어 매직보드 테이블 (3세 이상/45×7.5×38 cm) 9,400원 할인 37,500원

    베베핀 병원놀이 (3세 이상/50×18×27.5 cm) 9,800원 할인 39,100원

    베베핀 목욕놀이 (3세 이상/50×18×27.5 cm) 9,000원 할인 35,900원

    핑크퐁 119구급차 병원놀이 (37개월 이상/54×54×47cm) 9,900원할인 29,900원

    핑크퐁 아기상어 멜로디 청소기(37개월 이상/33×25×20cm) 10,500원 할인 24,400원

    마우스&펜으로 클릭하는 코딩컴퓨터&펜 완구
    핑크퐁 아기상어 코딩컴퓨터 (3세 이상/37.9×7.5×27cm) 20% 103,200원

    리틀퓨처북 뽀로로펜 코딩S (3세 이상/50×33×33cm) 16,000원 할인 63,900원

    미미월드 뽀로로 코딩컴퓨터 (3세 이상/38×6×27cm) 99,000원

    오타마톤 디럭스 행사상품 구매시 핑거보드 스티커 증정! 
    ※해당점에 한함 ※증정품 소진시 행사 종료
    오타마톤 디럭스 컬러즈 레드 (4세 이상/13.5×14.6×46.8cm) 16,800원 할인 67,000원

    오타마톤 디럭스 화이트 (4세 이상/13.5×14.6×46.8cm) 16,800원 할인 67,000원

    ※카드할인은 스노우포인트 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 제외 ※오프라인 점포에 한함 
    ※전단적용기간 : 2024. 12. 5(목) ~ 12. 25(수)

  </div> 
  `,
  `
  <div>
    인기 캐릭터 장난감 최대 16,000원 할인! ※입점점에 한함, 점포별 재고 상이

    위드림 산리오 NEW 입체스티커 메이커DX(3세 이상/29×11×25.5cm) 11,000원 할인 43,900원

    위드림 산리오 뽑기 스티커 메이커(6세 이상/27×13.5×23cm) 9,600원 할인 38,300원

    산리오 시크릿박스 (3세 이상/28×6×24.8cm) 13,200원 할인 30,700원

    산리오 캐릭터즈 말랑젤 메이커 (7세 이상/29×11×26cm) 7,000원 할인 27,900원

    물속에서 펼쳐지는 재미있는 마술세상을 만나보세요!
    아쿠아매직 산리오 요리놀이 (7세 이상/34×26.5×16cm) 10,600원 할인 42,300원

    8,100원 할인
    산리오 볼빨간 작동봉제 5종 (8세 이상/24.1×7.3×19.9cm) 18,800원

    슈퍼스타 캐치 티니핑!

    슈팅스타 캐치! 티니핑 시즌5 제품출시! 3가지 공간, 3배의 즐거움
    슈팅스타 티니핑 우주별정거장 (3세 이상/45×36.5×44cm) 94,900원

    슈팅스타 캐치! 티니핑 시즌5 제품출시! 프랍을 꽂으면 불빛이 반짝!
    슈팅스타 티니핑 셀레스티얼캐슬 (3세 이상/47×20×41cm) 87,900원

    21개 스타젬에 숨겨진 티니핑을 빔 프로젝터에서 확인해보세요!
    슈팅스타 스타하트빔 보석함세트 (3세 이상/38×11×38cm) 53,900원

    불빛이 반짝반짝! 멜로디가 짠! 우주별 비행기 타고 출발~
    슈팅스타 티니핑 우주별 비행기 (3세 이상/41×23×30cm) 63,900원

    실바니안 패밀리
    실바니안 우편함이 있는 초콜릿 토끼 이층집(5755) (3세 이상/27×21.5×29.5cm) 10,000원 할인 39,900원

    실바니안 행복한 인어공주 캐슬(5701) (3세 이상/47×14×35cm) 15,400원 할인 61,500원

    콩지래빗 구급차 약국놀이 (3세 이상/47.5×14.5×29cm) 14,000원 할인 55,900원

    콩지래빗 아이스크림 카페 (3세 이상/46.5×18.5×33cm) 9,800원 할인 39,100원

    체리 리얼 터치 스마트폰 (3세 이상/28×4×20cm) 15,000원 할인 59,900원

    디즈니
    
    디즈니 입체스티커 메이커 (3세 이상/27×11×19cm) 9,000원 할인 35,900원

    디즈니 겨울왕국 패션돌 스케이팅 (3세 이상/15×33×5cm) 각5,600원 할인 각22,300원

    겨울왕국 반짝반짝 화장대놀이 (3세 이상/38×51×10cm) 5,000원 할인 24,900원

    겨울왕국 멀티 화장대/주방 놀이세트 (3세 이상/25×30×14cm) 각5,000원 할인 각24,900원

  </div> 
  `,
  `
  <div>
    미미

    미미공주님, 무도회에 갈 시간이예요!
    미미월드 공주미미 말하는 궁전 (37개월 이상/58×14×36cm) 6,000원 할인 53,900원

    옷장이 말해주는 코디로 신데렐라 미미로 변신해요!
    미미월드 코디열쇠로 말하는 신데렐라 미미 옷장(37개월 이상/61×14×38cm) 10,000원 할인 39,900원

    동화속 주인공 라푼젤 공주님이 되었어요!
    라푼젤미미 공주파마 (37개월 이상/53×14×33cm) 8,000원 할인 31,900원

    립스틱, 새도우 등 진짜 화장품이 가득!
    미미월드 플라워 미미 화장가방 (6세 이상/46×16×33cm) 12,800원 할인 51,100원

    시크릿 쥬쥬

    크로스백으로, 레디백으로 원하는 스타일링 완성!
    시크릿쥬쥬 미니룸 레디백 (36개월 이상/29×10.6×24cm) 4,000원 할인 35,900원

    폰으로, 액션캠으로 어디서든 레디 액션!
    영실업 시크릿 쥬비쥬폰 (3세 이상/30×45×25.5cm) 16,000원 할인 63,900원

    쫑알쫑알 똘똘이
    
    미미월드 똘똘이 병원놀이카트 (3세 이상/47×13×35cm) 9,000원 할인 35,900원

    똘똘이 시럽쭉쭉 아이스크림가게 (36개월 이상/47×16×33cm) 8,800원 할인 35,100원

    다양한 목욕도구들로 똘랑이와 즐거운 목욕놀이를 해요!
    미미월드 똘랑이 목욕놀이 (3세 이상/42×17×30cm) 38,900원

    똘똘이가 엄마가 되어 똘똘이를 돌바주세요!
    NEW 똘똘이 엄마놀이 (37개월 이상/43×19×30cm) 7,400원 할인 29,500원

    반짝반짝 달님이
    ※입고일 : 12/10(화)
    달님이 아이스크림 가게 (3세 이상/38×12×33cm) 10,000원 할인 39,900원

    달님이 냉장고 놀이 (3세 이상/39×14×33cm) 8,000원 할인 31,900원

    달님이 젤리 가게 (3세 이상/45×17×32cm) 10,800원 할인 43,100원

    달님이 햄버거 가게 (3세 이상/33×12.5×31cm) 9,000원 할인 35,900원

    리얼펫 가방에 쏘옥 깡총 아기토끼 (3세 이상/37×20×24cm) 5,000원 할인 19,900원

    레인보우콘 에그자니아 (3세 이상/23×18×30cm) 10,000원 할인 39,900원

    개비의 매직하우스 레인보우 매직하우스(3세 이상/56×13×42.6cm) 30,000원 할인 69,900원

    개비의 매직하우스 루프탑 파티 플레이세트(3세 이상/42.5×8×27cm) 15,000원 할인 34,900원

    마법 램프를 문지르고 비밀을 풀어내면 지니 요정이 나타나요!
    미미월드 매직믹시스 마법램프 (5세 이상/29×13×34cm) 47,500원 할인 47,400원

    마법으로 요정이 나타나는 마법 항아리!
    미미월드 매직믹시스 마법 항아리 (5세 이상/25×20×34cm) 20% 79,200원

    아기 고양이가 사는 집 얌얌 통조림 타워!
    미미월드 얌얌 통조림 타워 (3세 이상/34×15×28cm) 47,900원

    미미월드 햄찌하우스 (3세 이상/28×19×28cm) 47,900원

    쿠키즈 메이커리 오븐 레인보우 (5세 이상/20.5×16.5×30cm) 20,700원 할인 48,200원

    쿠키즈 메이커리 팬케익 후라이팬 (5세 이상/18×7.5×29.5cm) 12,000원 할인 27,900원

    ※카드할인은 스노우포인트 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 제외 ※오프라인 점포에 한함 
    ※전단적용기간 : 2024. 12. 5(목) ~ 12. 25(수)

  </div> 
  `,
  `
  <div>
    인기 애니메이션 로봇완구 최대 30%
    ※입점점에 한함, 점포별 재고 상이
    ※신상품 및 일부상품 제외

    헬로카봇
    헬로카봇 펜타스톰 X 빅큐브 30% 145,600원
    (37개월 이상/64×13.5×43cm)

    헬로카봇 마이티가드X (37개월 이상/50×13×41cm) 30% 89,600원

    헬로카봇 킹가이즈X (37개월 이상/30×10×30cm) 30% 44,800원

    헬로카봇 뱅set 올스타 (37개월 이상/48.5×12.5×35.5cm) 20% 105,600원

    헬로카봇 하이퍼빌디언 올스타 (37개월 이상/65×13×43cm) 20% 150,400원

    헬로카봇 스타가디언 (37개월 이상/59×13.5×42cm) 20% 140,800원

    헬로카봇 폰X/루크X/나이트X (37개월 이상/30×10×30cm) 20% 각23,680원

    TOBOT
    영실업 또봇 테트란 (36개월 이상/33×14×40cm) 9,000원 할인 80,900원

    또봇 트라이탄 컬러체인지 (36개월 이상/28×14×37cm) 7,300원 할인 65,600원

    영실업 또봇W (36개월 이상/29×12×28cm) 9,400원 할인 37,500원
    
    터닝메카드
    터닝메카드 리마스터 로봇류 35%
    터닝메카드 리마스터 로봇류 (상품별 상이) 35% 32,240~98,800원

    터닝메카드 리마스터 단품 (6세 이상/18.5×5.5×19.5cm) 30% 각11,760원

    메탈카드봇

    메탈카드봇S 블루캅 트리니티 (3세 이상/30×13.5×36.5cm) 67,900원

    메탈카드봇S 기간트렉스 (3세 이상/30×10.5×35.5cm) 59,900원

    메탈카드봇S 머슬하이드 (3세 이상/23.5×13×30cm) 43,900원

    해당상품 구매시 실버베어 증정!
    ※850개 한정
    애니멀포스 DX 애니멀킹(2020) (3세 이상/21×8×29.5cm) 20% 43,200원

    미니 특공대 저스티스 타이탄 (3세 이상/38×16×50cm) 30% 100,800원

    미니특공대 픽셀 하이퍼킹 (3세 이상/42×9×47cm) 127,000원

    BBX BX37 더블 익스트림 스타디움 (6세 이상/44×12.5×45.5cm) 9,300원 할인 83,600원

    듀얼블레이드 슈퍼건 올인원 인피니티 세트 (37개월 이상/52×53×16cm) 5,000원 할인 44,900원

    다이노스터 공룡 수호대
    다이노스터 디럭스 랩터트론 (3세 이상/38.5×14×43cm) 13,800원 할인 55,100원

    신비아파트 고스트볼 ZERO
    신비아파트 시즌5 금룡퇴마검 (5세 이상/53.5×11×32cm) 23,700원 할인 55,200원

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 토이저러스 잠실점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※토이저러스몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div> 
  `,
  `
  <div>
    포켓몬&마블&공룡 피규어 최대 70% 
    ※입점점에 한함
    ※점포별 재고 상이

    언제나 우리들의 친구 포켓몬스터!

    포켓몬 크레인 (3세 이상/30×32cm) 18,000원 할인 71,900원

    포켓몬 LCD게임 몬스터볼 (3세 이상/11×20cm) 14,000원 할인 55,900원

    마블
    마블 어벤져스 7팩 세트 (15세 이상/45.5×5.5×42cm) 20% 47,840원

    마블 14인치 스파이더맨 (6세 이상/25×10×40cm) 30% 23,730원

    쥬라기월드 슈퍼 자이언트 아파토 사우루스 (4세 이상/53.5×19×33cm) 70% 35,970원

    손오공 쥬라기월드 드레드노투스(L) (4세 이상/53×20×33cm) 80% 39,600원

    쥬라기월드 와일드 팝업 피규어 (4세 이상/11.5×5×11.5cm) 70% 4,470원

    쥬라기월드 공룡알 변신 피규어 (4세 이상/11.5×6.5×14cm) 70% 각5,070원

    RC/드론
    BMW i8 프리미엄(1:14) (14세 이상/42×17×22.5cm) 5,000원 할인 44,800원

    부가티 디보 1:14 (14세 이상/43×22.5×17.5cm) 4,900원 할인 44,000원

    현대차 팰리세이드 RC카 (3세 이상/36.5×15×17cm) 6,600원 할인 26,300원

    12/14(토) 출시예정!
    EV 익스트림 RC (8세 이상/27×17×18cm) 10,000원 할인 39,900원

    로보컴뱃 토네이도 (3세 이상/46×26cm) 9,400원 할인 37,500원

    허리케인 배틀탱크 (3세 이상/45×23.5×18cm) 10,000원 할인 69,800원

    1:12 지게차 R/C (8세 이상/45×19×19cm) 20% 47,840원

    테라섹트 R/C (8세 이상/35×16×33cm) 20% 47,840원

    1:14M 1A 1 에이브람스탱크 R/C (8세 이상/50×29×22.5cm) 20% 51,120원

    토미카
    토미카월드 트윈코스 마운틴세트 (3세 이상/45×35×25cm) 20% 54,320원

    토미카 2WAY 액션 고속도로 세트 (3세 이상/45×35×17cm) 58,900원

    엑자일 드론 3Z (14세 이상/17×17cm) 10,000원 할인 39,900원

    에어크래프트 카메라 드론 (14세 이상/42×7×30cm) 59,900원

    핫휠
    핫휠 라이노 하울러 (3세 이상/43×18×25cm) 70% 14,970원

    핫휠 단품 시리즈 할인 60%
    핫휠 카 컬쳐 제이레노 시리즈 외 2종 (3세 이상/13.5×3×16.5cm 외) 60% 3,920~5,560원

    마조렛 재팬개러지 세트 1카 (3세 이상/49×38×10cm) 20,000원 할인 39,900원

    월드카 주차블록 합체 중장비 본부 (3세 이상/61.5×19×41cm) 17,600원 할인 70,300원

    리얼샷 실제같은 액션감으로 쏜다 리얼샷!
    리얼샷 탄피배출 바레트 (14세 이상/87.53×7.7cm) 54,800원

    리얼샷 탄피배출 GROZA (14세 이상/60.5×7.8×27.8cm) 34,800원

    리얼샷 탄피배출 M416 (14세 이상/60.5×7.8×27.8cm) 34,800원

    ※카드할인은 스노우포인트 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 제외 ※오프라인 점포에 한함 
    ※전단적용기간 : 2024. 12. 5(목) ~ 12. 25(수)

  </div> 
  `,
  `
  <div>
    게임/피규어 ※입점점에 한함, 점포별 재고 상이

    스위치 OLED 모여봐요 동물의 숲 세트 + 스위치 모여봐요 동물의 숲 해피홈 파라다이스
    동시구매시 25,000원 할인

    스위치 마리오 카트 8 디럭스 외 28종 (전체 이용가) 최대 15,000원 할인 각27,800~64,800원

    PS5 디스크/디지털 슬림 본체 최대 80,000원 할인 668,000/538,000원
    ※입점점에 한함 ※점별 재고 상이

    슈퍼마리오
    슈퍼마리오 20인치 자이언트 마리오 (3세 이상/25.4×33.02×50.8cm) 20,000원 할인 59,900원

    슈퍼마리오 마리오/루이지 미니RC (3세 이상/33×25×25cm) 10,000원 할인 각69,900원

    마리오 카트레이싱 디럭스 (3세 이상/51×8×30cm) 10,000원 할인 55,900원

    아카데미 과학
    아카데미 프라모델/에어건 최대 20%
    포터 Ⅱ 트럭 (14세 이상/20×10cm) 5,000원 할인 19,900원

    아카데미과학 또봇X 프라모델 (14세 이상/13×20cm) 30,900원

    짱구 대형 피규어 (14세 이상/13×20cm) 30% 55,300원

    크리스마스&연말온가족 함께 보드게임 최대 20% 
    그래비트랙스 스타터 세트 (8세 이상/33.7×11×33.7cm) 20% 69,520원

    그래비트랙스 코어 스타터 : 밸런스 20% 135,120원

    쿼리도 팩맨 (8세 이상/28.1×5.9×28.1cm) 20% 37,520원

    할리갈리 포켓몬 (6세 이상/12.7×5.2×17.1cm) 20% 16,720원

    미미월드 포켓몬 빙고게임 (8세 이상/30×40×23.5cm) 3,000원 할인 11,900원

    클루 (8세 이상) 20% 27,920원

    야외시즌완구
    ※입점점에 한함
    ※점별 재고수량 상이
    마이크로킥보드 스프라이트 LED 실버 (8세 이상/63×38×72cm) 10% 145,800원

    마이크로킥보드 미니투고 플러스 LED 핑크/블루 (49×23×53cm) 21,700원 할인 각194,700원

    마이크로킥보드 미니폴더블 LED 핑크/퍼플/네이비 (49×23×53cm) 16,000원 할인 각143,700원

    마이크로킥보드 맥시 프로 LED 블루/퍼플 (91×26×56cm) 23,700원 할인 각212,900원

    타요 클래식 붕붕카 (47×21×31cm) 20% 38,400원

    L.POINT 10%
    벤츠 GLE63 푸쉬 붕붕카(37개월 이상) 71,100원

    L.POINT 3,500원
    벤츠 악트로스 경찰차 붕붕카 (3세 이상/48.8×24.4×35.3cm) 31,400원

    L.POINT 3,500원
    벤츠 악트로스 소방차 붕붕카 (3세 이상/48.8×24.4×35.3cm) 31,400원

    스쿳앤라이드 해당상품 최대 1만원 할인!
    스쿳앤라이드 LED 안전헬멧 키위/레몬/애쉬/로즈 5,900원 할인 각53,100원

    스쿳앤라이드 하이웨이 1 키위/레몬/애쉬/로즈 10,000원 할인 각145,000원

    전동 승용완구 해당상품 최대 15% 할인!

    L.POINT 10%
    BMW Z4 Roadster 푸쉬카(가죽시트) (3세 이상/99×49.5×28.5cm) 140,400원

    L.POINT 15%
    미니쿠퍼 푸쉬카 168,300원

    L.POINT 15%
    벤츠 EQA 전동 푸쉬카 (3세 이상/116×69×85cm) 236,300원

    ※정상가란? 2024년 11월 14일(목) ~ 12월 4일(수) 토이저러스 잠실점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※토이저러스몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    
  </div> 
  `,
];
const L441data = [
  `<h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 9. 26(목) ~ 2024. 9. 30(월) < 비신선식품 적용기간 : 2024. 9. 19(목) ~ 9. 30(월) > ·원주점

    롯데마트 원주점 새단장 OPEN

    단독 일별특가 9/26목 1+등급 계란(특) (30입/1.8kg/국산) 6,990원

    9/27금 L.POINT 3000원 오뚜기 맛있는밥 (210g×12입) 9,980원

    9/28토 국내산 돼지 앞다리(100g/냉장/국내산) 990원

    9/29금 큰 초밥(20입) 13,990원

    9/30월 스위트마운틴 바나나 1,990원

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다.
    이번주 핫프라이스
    매일 먹는 신선한 사과! 아침마다 활기를 더할 '특가'
    상생 사과 (4~7입/봉/국산) ※1인 2봉 한정

    L.POINT 1000원 + 롯데/신한/NH농협카드 2000원 추가할인 7990원
    ※기간 : 9/26(목) ~ 9/30(월) ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    롯데마트 매장에서 SNOWPLAN 멤버 전용 스노우 특가 상품을 찾아보세요!
    SNOW PLAN 가을 페스타 9/19(목) ~ 9/30(월)

    롯데마트 GO 앱을 다운받고 가입하셨다면, 스노우플랜 멤버입니다!
    스노우특가란?
    스노우플랜 멤버를 위한 특별할인가로,
    결제시 롯데마트 GO App의
    바코드를 꼭 스캔해주세요
    ※핸드폰번호로 적립시, 스노우특가 적용 불가

    9/19(목)~9/30(월)
    12일간, 매일매일!
    10,000원 할인쿠폰 증정

    매일 9/19(목)~9/30(월) ※19,26일 제외 5,000원 할인쿠폰 증정
    ※5만원 이상 구매시 사용 가능

    목요일 9/19(목), 9/26(목) 2일만! 10,000원 할인쿠폰 증정
    ※10만원 이상 구매시 사용 가능

    ※롯데마트GO 앱 회원에 한함
    ※쿠폰 발급 및 사용 가능시간 : 당일 오전 10시~오후 11시 까지
    ※일별 쿠폰은 당일 사용만 가능 ※일 1천명, 총 12,000명 한정
    ※이벤트 세부 내용은 롯데마트GO앱에서 확인하실 수 있습니다

    SNOWPLAN 50% 와규 5초 구이(600g/냉동/호주산) 12,900원

    SNOWPLAN 1500원 할인 제주 GAP 하우스 감귤(1.4kg/팩/국산) 12,490원

    40% + SNOWPLAN 3000원 추가할인 청정원 고추장 2종(각 2kg, 찰고추장/태양초 고추장) 각14,760원

    2개 이상 구매새 1개당 1000원 할인 + SNOWPLAN 1000원 추가할인 CJ 고메 바삭쫄깃한 탕수육/바삭촉촉한 깐풍기(각 450g) 7,980원

    1200원 할인 + 500원 추가할인 동원 소화가 잘되는 우유(2.3L) 6,180원

    SNOWPLAN 5% + 롯데/신한/NH농협카드 10% 추가할인 동서 카누 미니 3종 각27,189원
    (각 0.9g×150입, 마일드/다크/라이트)
    ※실제 할인적용 순서는 결제 수단에 따라 상이할 수 있습니다
    ※행사카드로 미결제시 최종 할인가는 상이할 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 20% + SNOWPLAN 4020원 추가할인 란 멘시온 리제르바/란 멘시온 그란 리제르바(각 750ml) 멤버만을 위한 혜택!

    SNOWPLAN 10% 립케어/바디보습케어 43종(상품별 상이) ※점별 운영상품 상이 ※바세린/니베아/뉴트로지나 外 2,646~17,820원

    SNOWPLAN 6000원 할인 깨끗한나라 순수 오리지널(40m×30롤) 12,900원

    20% + SNOWPLAN 2000원 추가할인 3M 테이프클리너
    롱핸들팩(대형) 12,520원
    (핸들1P+리필 1P) ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이

    2개 이상 구매시 1개당 50% + SNOWPLAN 10% 추가할인 네오플렘 퓨어락 밀폐용기(상품별 상이) 각1,980~5,580원

    고객 여러분의 밥상물가를 지킬 10대 아이템 미션!물가를 잡아라
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.

    ※기간 : 9/19(목)~9/30(월)(신선식품 적용은 9/26(목)~9/30(월), 5일간)

    하림/참프레
    닭 다리살(정육) 990원
    (100g/냉장/원산지 별도표기)
    ※조기 품절될 수 있습니다

    L.POINT 40% ASC 새우살 4종(각 400g/냉동/원산지 별도표기) 11,340원

    산지뚝심 경산 와촌 자두(800g/팩/국산) 7,990원
    
    머루포도(3kg/박스/국산) 19,990원

    1+1 오뚜기 후랑크 핫도그/삼립 그릭슈바인 핫도그 8,980/6,980원
    (상품별 상이) ※동일 상품에 한함

    1+1 씨제이 햇반 곤약밥 4종(각 150g×3입) ※교차구매 가능 각8,980원

    1+1 상온/냉장 커피음료 71종(상품별 상이) ※동일 시리즈간 교차구매 가능 각1,400~3,980원
 
    롯데/신한/NH농협카드 2000원 나무야 깨끗한
    무향 3겹 화장지(27m×30롤) 9,900원
    ※페이지 하단 카드할인 세부내용 참고

    히말라야 핑크솔트 센서티브 칫솔(4입) 6,990원

    롯데/신한/NH농협카드 1+1 퍼실 기능성 용기/리필 30,900/20,900원
    (상품별 상이, 실내건조/하이진젤/그린파워)
    ※용기/리필 각 교차구매 가능
    ※페이지 하단 카드할인 세부내용 참고


    ※정상가란? 2024년 9월 5일(목) ~ 9월 25일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※카드할인은 스노우포인트 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외


  </div>
  `,
  `
  <div>
    원주점 단독 상품을 만나보세요!

    1등급 한우등심(100g/냉장/국내산 한우고기) 6,590원

    L.POINT 50% 미국산 소 척아이롤(100g/냉장/미국산) 1,590원

    L.POINT 40% 미국산 소 갈비살(100g/냉장/미국산) 2,290원

    L.POINT 40% 하늘정성 돼지고기 삼겹살/목심(각 100g/냉장/국내산 돼지고기) 각2,550원

    요리하다 흑돼지 불고기 2종(각 1kg/원산지 별도표기) 각9,900원

    L.POINT 50% 직소싱 새우살 3종(대/중/소)(각 500g/냉동/원산지 별도표기) 각9,900원

    L.POINT 5000원 순살 그릴 고등어(300g/냉동/원산지 별도표기) 9,900원

    L.POINT 20% 서해안 꽃게(100g/냉장/국산) 1,272원

    L.POINT 7000원 항공직송 동원 생연어(320g/냉장/노르웨이산) 14,900원

    손질 오징어 9,920원
    (4마리/해동/원양산) 

    GAP 당도선별 샤인머스캣(1.5kg/박스/국산) 14,990원

    머루포도(3kg/박스/국산) 17,990원

    스윗볼 스테비아 대추방울토마토(500g/팩/국산) 4,990원

    대천 김자반(60g×2봉/원산지 별도표기) 3,490원

    바다원 마른몸통 오징어(240g/원산지 별도표기) 9,900원

    하나사면 하나 더 1+1

    1+1 종가 포기김치(900g) 15,900원
    
    1+1 풀무원 즉석 해물 칼국수(2인) 7,480원

    1+1 CJ 삼호 정통 어묵탕(336g) 4,870원

    1+1 풀무원 생 물만두 9,450원

    1+1 마니커 치킨너겟골드(1kg) 12,980원

    1+1 오뚜기 열려라 참깨라면(4개입) 3,880원

    1+1 풀무원 크로엣지 스위트콘&치즈(372g) 9,980원

    1+1 켈로그 현미 그래놀라 샤르르 큐브(500g) 9,680원

    1+1 남양 프렌치카페믹스/아라비카 골드라벨 누보 (10.9g×50입/10.7g×50입) ※동일 상품에 한함 9,980/11,200원

    3+1 칠성사이다 外 균일가 캔 14종(상품별 상이) ※교차구매 가능 각900원

    2개 이상 구매시 더 싸게

    2개 이상 구매시 50% CJ 고메 바삭 통등심돈카츠(450g) 각4,990원

    2개 이상 구매시 50% 동원 인포켓 치즈(200g) 각7,000원
    
    2개 이상 구매시 50% 빙그레 요플레 10종 각1,890원

    인기 와인 L.POINT 37000원 할인

    L.POINT 14100원 모엣 상동 임페리얼(750ml) 74,900원

    L.POINT 2000원 디아블로 데블스 카나발레드/스위트/카베네소비뇽/소비뇽(각 750ml) 각14,900원

    L.POINT 37000원 글렌피딕 12년/15년(각 700ml) 79,900/109,900원

    L.POINT 5100원 고려은단 비타민C 1000(1,80mg×120정) 9,900원

    L.POINT 6000원 종근당건강 멀티비타민 이뮨플러스(23g×7입) 10,900원

    2개 이상 구매시 1개당 50% 수퍼테크 리필 (각 2.6L, 일반/드럼) 각5,450원
    
    2개 이상 구매시 1개당 50% 순샘 레몬(1.2L) 각1,950원

    L.POINT 50% 테팔 스타트이지 프라이팬(상품별 상이) 20,400~27,900원

    L.POINT 30% 코렐 목련/올리브가든(상품별 상이) 6,230~12,530원

    50% 아이리스 시스템 수납함 11,450원

    1+1 그라스 디퓨저 5종(각 105ml) 각24,900원

    30% 황태핫태 4종(상품별 상이) 각4,200/10,850원

    ※정상가란? 2024년 9월 5일(목) ~ 9월 25일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div> 
  `,
];

/* 999 테스트 전단 */
const L999data = [
  `
  <h1>·전단적용기간 : 2022. 5. 26(목) ~ 소진시까지 ·해당점 : 구미, 대덕, 부평, 사상, 송파, 월드컵, 의왕, 춘천점</h1>
  <div>
    최대 70% 가격인하 라스트 찬스

    조리도구

    주방잡화

    테이블웨어

    인테리어

    라이프스타일
  </div>
  `,
  ` 
  <div>
    일상용품

    HOME

    언더웨어

    양말

    패션잡화
  </div>
  `,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
  `<div></div>`,
];

// 슈퍼 프리미엄 도곡점(~11/27)
const L419data = [
  `<h1>·전단적용기간 : 2024. 11. 21(목) ~ 2024. 11. 27(수)</h1>
  <div>
    · 그랑그로서리 도곡점
    
    GRAND GROCERY
    롯데슈퍼 그랑그로서리 도곡
    2024.11.21(목) Grand OPEN!

    12,900원 1++등급 한우 등심(100g/냉장/국내산)

    14,900원 1++등급 한우 안심(100g/냉장/국내산)
    각4,990원 1++등급 한우 국거리/불고기(각 100g/냉장/국내산)

    25,800원 광어회(300g 내외/냉장/광어:국산)

    9,990원 비파괴 당도선별 샤인머스캣(1.5kg/박스/국산)

    오시는 길
    매주 2/4째 주 일요일 휴무
    3만원 이상 결제시 배달 무료
    3호선 수인분당선 도곡역

    롯데슈퍼 그랑그로서리 도곡점 오픈 이벤트
    ▶기간 : 11/21(목) ~ 11/27(수)
    ※증정품 소진 시, 이벤트 자동 종료
    선착순 1,600명 한정
    당일 7만원 이상 구매시 잘풀리는집 스트로베리 미용티슈(180매×3입) 선착순 증정!
    ※L.POINT 회원에 한함 ※기간 중 1인 1회 증정
    ※일부 덤 행사 구매금액 제외
    ※계산 후 직원에게 문의 바랍니다
    ※당일 구매 영수증에 한함 ※영수증 합산 불가
    ※소진시 별도 고지 없이 행사 종료
    ※ 양곡, 종량제봉투, 주류, 임대매장, 담배,  
    상품권 구매금액 제외

    ※정상가란? 2024년 10월 31일(목) ~ 11월 20일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다   ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외 ※신한BC카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
    LOTTE SUPER GRAND GROCERY 도곡점

    Quality MEAT
    한우 최고등급, 1++(9)등급
    한우 최고등급, 1++(9)등급만을 엄선한 프리미엄 브랜드 Marble9
    마블등급 MB8+ 청정 와규만 선별

    14,900원 마블나인 1++등급 한우 채끝(100g/냉장/국내산) 
    근내지방도 19% 이상만 엄선한 최상급!
  
    5,990원 MBS8+등급 호주산 와규 등심(100g/냉장/호주산) 
    호주 청정지역에서 350일 이상 곡물 먹고 키운

    Fresh SEASONAL 계절과일
    자이언트 & 고당도 보장된 프리미엄 과일 운영
    AI선별/비파괴 당도선별로 철저하게 선별한 고퀄리티 과일을 만나보세요

    19,900원 황금당도 불로초 감귤(1.5kg/박스/국산)

    9,990원 타이벡 감귤(2.5kg/박스/국산)

    3,990원(2.5kg/박스/국산) 초고산지 풍미왕 바나나(1.2kg 내외/필리핀산)

    12,990원 AI선별 샤인머스캣(2입/팩/국산)

    ※정상가란? 2024년 10월 31일(목) ~ 11월 20일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다   ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    오늘 뭐 먹지?  매일매일의 먹거리 고민 해결사!
    Fresh SEAFOOD 생선
    산지직송된 신선하고 위생적인 수산물
    매장에서 바로 맛있게 쪄드립니다!

    L.POINT 25%
    직접 거래로 활력이 살아있는 러시아 대게!
    5,993원 활 대게(100g/생물/러시아산)

    10,000원 할인
    항공직송하여 신선함을 그대로!
    19,900원 피타고니아 생연어 필렛(500g/냉장/칠레산)

    BOTTLE BUNKER Select
    국내 주류 전문 큐레이션샵 보틀벙커에서 엄선한 프리미엄 와인/양주를 한자리에!
    초보자부터 전문가까지 누구나 취향에 맞는 주류 추천 리스트를 만나보세요

    99,000원 샤또 지스꾸르 2021(750ml)
    ※한정수량 조기 품절될 수 있습니다
    ※1인당 최대 2병 구매 가능

    99,000원 뵈브클리코 옐로우라벨 애로우 서울(750ml)
    ※한정수량 조기 품절될 수 있습니다

    54,800원 조니워커 블랙 오징어게임에디션(750ml)

    56,000원 경주법주 초특선(700ml)

    2개 구매시 보냉백 증정! ※200개 한정
    19,000원 앙투어솔레 그릴링 치즈(300g)

    ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외 ※신한BC카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
    LOTTE SUPER GRAND GROCERY 도곡점

    요리하다 월드뷔페
    취향대로 즐기는 21종의 세계 인기 요리맛집!

    3,990원 갈릭 치킨 스테이크(팩/원산지 별도표기)

    3,990원 스리라차 타코야끼(12입/원산지 별도표기)

    3,990원 짜장짬뽕만두(팩/원산지 별도표기)

    4,990원 후라이드 윙(테바나카)(팩/원산지 별도표기)

    도시곳간
    매장에서 당일 직접 만든 프리미엄 반찬부터 간편 도시락까지!
    4팩 골라담기 또는 반찬 4팩 이상 구매시
    도시곳간 인기상품 불닭김부각 1봉 무료 증정!
    ※기간 : 11/21(목)~11/23(토), 하루 선착순 100명  ※재고 소진시 행사가 조기 종료될 수 있습니다

    1,000원 할인
    6,000원 유부야채말이(팩/원산지 별도표기)

    6,000원 유부야채말이(팩/원산지 별도표기)

    8,500원 코다리조림(팩/원산지 별도표기)

    8,900원 케일쌈밥(팩/원산지 별도표기)

    ※정상가란? 2024년 10월 31일(목) ~ 11월 20일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다   ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    오늘 뭐 먹지?  매일매일의 먹거리 고민 해결사!

    오늘 메뉴로 이 요리 어떠세요?
    오늘의 추천메뉴

    요리하다 밀키트
    집에서 간편하게 즐기는 쉐프의 레시피!

    L.POINT 1,000원
    각6,990원 요리하다 송탄식 부대찌개(775g)/의정부식 부대찌개(705g)

    L.POINT 3,000원
    10,990원 요리하다 춘천식 닭갈비(800g)

    2개 이상 50%
    2개 이상 구매시 1개당 각8,500원
    하림 더 미식 밀키트 5종(상품별 상이)
    ※곱창전골/부대전골/소고기된장전골/얼큰만두전골/동태전골

    2개 이상 10%
    5,990~10,990원 메디쏠라 전품목(상품별 상이)
    ※저당반찬삼색버섯소불고기 외, 총 14종

    Global Sauce, Add Your Taste

    음식의 풍미와 맛을 더할 고급 소스류!
    글로벌 인기 소스 총 46종, 하나 사면 하나 더!

    1+1
    각5,980원 헌트 오리지널 바베큐 소스/히코리향 바베큐 소스(각 510g)

    1+1
    4,490원 디벨라 토마토퓨레(680g)

    1+1
    각7,440원 코타니 향신료 7종(상품별 상이) ※교차구매 가능

    1+1
    15,990원 셀렉티아 화이트 트러플 오일(100ml)

    ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외 ※신한BC카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    LOTTE SUPER GRAND GROCERY 도곡점

    8개 구매시 10,000원 큰단감(개/국산) ※1개 구매시 1,590원

    L.POINT 5,000원
    4,990원 스윗볼 스테비아 대추방울토마토(500g/팩/국산)

    롯데/신한/삼성카드 100원 할인
    1,190원 파프리카(개/국산)
    ※기간 : 11/21(목)~11/24(일), 4일간
    ※ 페이지 하단 카드할인 세부내용 참고

    L.POINT 10,000원
    49,900원 미국산 냉동 꽃갈비구이(1kg/냉동/미국산)

    1+1
    각4,990원 풀무원 특등급 국산콩 큰두부(500g/국산)/풀무원 국물두부 조림(380g/원산지 별도표기)
    ※조기 품절 가능

    1+1
    10,490원 CJ 비비고 갈비왕교자(420g×2)

    2,990원 통영 시금치(봉/국산)

    1,290원 애호박(개/국산)

    농활할인 20%
    4,992원 깐마늘(500g/봉/국산)
    ※L.POINT 회원 기간 중 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다

    1+1
    각6,990원 ※교차구매 가능
    수산명가 명란/양념 명란(각 100g/냉장/원산지 별도표기)

    5,990원 풀무원 1+등급 목초란(15입/대란/국산) ※조기 품절 가능

    L.POINT 50%
    5,490원 하림 자연실록 닭볶음탕(1kg/국내산)

    L.POINT 25%
    각2,990원 한돈 무항생제 삼겹살/목심(각 100g/냉장/국내산)

    5,990원 영암 친환경 꿀 고구마(1.5kg/박스/국산)

    L.POINT 25%
    3,750원 완도 활전복(왕/냉장/국산)

    49,900원 항공직송 제주갈치(왕특)(마리/냉장/국산)

    9,900원 THE 큰치킨(마리)

    10,900원 패밀리초밥(16입/원산지 별도표기)

    6,990원 훈제오리구이(반마리/원산지 별도표기)

    7,990원 NEW 갱엿 닭강정(대)(원산지 별도표기)

    2,000원 할인
    5,400원 부드러운 호밀빵(8입/1봉/원산지 별도표기)

    ※정상가란? 2024년 10월 31일(목) ~ 11월 20일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다   ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    새단장 기념 오픈 초특가!

    1+1
    10,490원 CJ 비비고 갈비왕교자(420g×2)

    1+1
    8,790원 풀무원모짜렐라핫도그(320g)

    1+1
    각6,990원 풀무원 정통가쓰오우동 2인(471g)/24시깔끔즉석우동(321.2g) ※교차구매 가능

    1+1
    12,990원 삼진 전통모듬어묵(802g)

    1+1
    1,980원 폰타나 이탈리아 캄파니아 스파게티면(250g)

    1+1
    각880~2,980원 수입라면 16종(상품별 용량 상이)
    ※ 닛신 컵누들 칠리토마토맛/하이디라오마라편/라황상진마이랑탄탄면/인도미미고랭컵라면/마마크리미통낭 外 
    ※브랜드별 동일가격 상품 교차구매 가능

    1+1
    그릭요구르트 전품목(상품별 용량 상이)
    ※ 그릭데이 시그니처/다논그릭플레인/요즘플레인그릭요거트 外
    ※브랜드별 동일가격 상품 교차구매 가능

    1+1
    각3,380원 프링글스(각 110g)
    ※오리지날/양파맛
    ※한정수량
    ※교차구매 가능

    1+1
    8,190원 농심 켈로그 오곡첵스초코(570g) ※한정수량

    1+1
    각3,790원 동원 생크림요거트(각 85g×4)
    ※플레인/바닐라 ※교차구매 가능  

    1+1
    각5,480원 롯데 밀키스/칠성사이다/펩시콜라/펩시제로(각 190ml×6캔)  ※교차구매 가능

    1+1
    각6,480원 폰타나 파스타소스(각 600g)
    ※ 롬바르디아생크림&갈릭로제/나폴리뽀모도로토마토/밀라노크림치즈로제/페페론치노아라비아따/로스티드갈릭토마토  
    ※교차구매 가능

    1+1
    각3,280원 오뚜기 김치라면/스낵면(각 5입)
    ※교차구매 가능
    
    1+1
    2,980원 CJ 비비고 한우 사골곰탕(500g)

    1+1
    3,790원 이금기 팬더굴소스(255g)

    1+1
    7,690원 진주햄 천하장사 오리지날(448g)

    1+1
    각7,980원 하림 더미식 유니/사천 자장면(각 2개입) ※교차구매 가능

    ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외 ※신한BC카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
    LOTTE SUPER GRAND GROCERY 도곡점 오늘 뭐 먹지? 매일매일의 먹거리 고민 해결사!

    1+1
    11,990원 풀무원 국산콩 생나또 8입(396g/국산) ※ 조기 품절될 수 있습니다

    1+1
    7,990원 오뚜기 콤비네이션 피자(415g)

    1+1
    각4,990원 제주(무항생제/저지/A2 유기농)우유(각 750ml) ※교차구매 가능

    1+1
    4,390원 파스퇴르 유산균 우유(900ml)

    1+1
    각3,790원 남양 불가리스 제로(각 80g×4)
    ※딸기/플레인/복숭아
    ※교차구매 가능

    1+1
    각10,900원 풀무원 아임리얼 스트로베리/순수착즙 오렌지(각 700ml) ※교차구매 가능
    
    1+1
    각3,680원 라무네 음료수(각 200ml)
    ※크레용 신짱 라무네/멜론향 라무네 7종
    ※교차구매 가능

    1+1
    각5,980원 네슬레 킷캣 로투스/녹차(상품별 중량 상이) ※교차구매 가능

    1+1
    8,180/13,580원 후이펑 스리라차  칠리소스(255g/481g) ※교차구매 가능

    1+1
    각11,900원 닥터리브 애사비스틱/레몬스틱(각 20g×10입) ※교차구매 가능

    각9,320원 CJ 햇반흑미밥/햇반잡곡밥/현미쌀(각 210g×8)

    9,980원 CJ 해찬들 100% 태양초 우리쌀고추장(1.5kg+300g)

    2,500~4,500원 고래사 간식용 어묵 8종(상품별 상이)

    L.POINT 3,000원
    9,990원 요리하다 햄폭탄 부대찌개(968g)

    L.POINT 2,000원
    7,990원 요리하다 북창동 순두부 찌개(650g)

    3,490원 풀무원 새콤달콤 유부초밥(330g)

    각2,990원 CJ 미정당 국물떡볶이/밀국물떡볶이(2인/각 401g)

    6,990~7,990원 존쿡 델리미트 후랑크 5종(각 320g)

    2개 이상 50%
    2개 이상 구매시 1개당 각9,980~16,980원 맥널티 스테비아 커피 30입 3종 ※교차구매 가능
  
    5,810원 할인
    19,990원 아임비타 이뮨플러스 12+3 기획(23.5g×15입)

    2개 이상 50%
    2개 이상 구매시 1개당 각16,100원 크리넥스 3겹 울트라클린(30m×30롤) ※1개 구매시 32,200원
    
    2개 이상 50%
    2개 이상 구매시 1개당 각14,450원 퍼실 디스크 캡슐세제(각 26입) ※1개 구매시 각28,900원 ※파워/라벤더/실내건조/컬러

    2개 이상 50%
    2개 이상 구매시 1개당 각4,750원 다우니 7일 향수 컬렉션 4종 ※1개 구매시 각9,500원 ※블랙/시트러스/스프링가든/코튼퓨어러브
  
    각7,900원 밀크바오밥 퍼퓸 샴푸/바디워시(각 500ml)

    8,900원 카밀 핸드크림 클래식&미니 2종 기획

    ※정상가란? 2024년 10월 31일(목) ~ 11월 20일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다   ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외 ※신한BC카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함
  
  </div>
  `,
];

// 롯데마트 구미점 모바일 단독 전단
const L511data = [
  `
  <div>
  전단적용기간 : 2024. 11. 28(목) ~ 2024. 12. 11(수) < 신선식품 적용기간 : 2024. 11. 28(목) ~ 12. 4(수) >
  
  롯데마트 구미점 리뉴얼 오픈 1주년
  단독 고객 감사 SALE
  단독 일별 특가

  11/28 목
  애호박(개/국산) 1,290원

  11/29 금
  L.POINT 50%
  프리미엄 파타고니아 생연어(500g/냉장/칠레산) 17,400원

  11/30 토
  L.POINT 50%
  브랜드 돼지고기 삼겹살/목심(각 100g/냉장/국내산) 각2,345원

  12/1 일
  갱엿 순살 닭강정(대)(1팩/원산지 별도표기) 9,990원

  2팩 이상 각 2,000원 할인 
  설향 딸기(500g/팩/국산) 2팩 이상 구매시 1팩당 각10,990원
  ※1팩 구매시 각 12,990원
  ※300팩 한정 ※조기 품절될 수 있습니다
  ※해당 이미지 상품에 한함

  12/2 월
  1+1
  해표 식용유(1,700ml) 8,100원

  12/3 화
  1+1
  인기 탄산음료캔 6입 번들(각 190ml×6입) 각5,480원
  ※교차구매 가능
  ※칠성사이다/펩시콜라/펩시제로라임/밀키스

  2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다.
  이번주 핫프라이스
  L.POINT 20% -> 롯데/신한/NH농협/삼성카드 20% 추가할인
  '2만원대'로 즐기는 제철의 맛! 
  꼭 한번 맛보고 싶은 겨울 별미 제철 대방어회(300g/국산) 24,960원
  ※기간 : 11/28(목) ~ 12/4(수) ※8kg 이상 대방어만 사용합니다
  ※입점점에 한함 ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

  인기 패션 브랜드 1+1 최대 70%
  아동&유아 언더웨어  

  SPAO KIDS 7만원/10만원 이상 7천원/1만원 즉시 할인!

  TOPTEN KIDS 인기아이템 1+1 ※기간 : 11/1(금) ~ 12/1(일)

  agabang
  1. 출산 준비물 20만원 이상 20% 할인
  2. 모든 구매고객 포인트 3배 적립/손수건 증정
  3. 5만원 이상 구매고객 퓨토 보습 샘플키트 증정

  성인 의류
  인기 아이템 1+1 ※기간 : 11/1(금) ~ 12/1(일)

  BRENTWOOD
  1. 신상품 44% / 이월정장 70%
  2. 모든 구매고객 양말 증정 / 40만원 이상 구매 고객 장우산 증정 ※30개 한정
  3. e-코오롱 회원 고객 대상 신상품 구매시 10% 추가 할인

  LEBSHOMEY STUDIO
  신상품 50% 할인
  ※1차 기간 : 11/28(목) ~ 12/1(일)
  ※2차 기간 : 12/5(목) ~ 12/8(일)

  PROJECT M
  1. 의류 전품목 1+1  ※행사상품에 한함
  2. 이월 티셔츠/스웨터 각19,900원
  3. 이월 아우터 39,900원/69,900원

  슈즈

  ABC-MART
  ※기간 : 11/28(목) ~ 12/9(월)
  1. 이월상품 최대 70%
  2. 2족 구매시 20% ※1족 구매시 10% ※시즌/겨울부츠 포함

  SHOE MARKER
  1. 7만원 이상 구매시 장바구니 증정
  2. 15만원 이상 구매시 1만원 롯데상품권 증정

  잡화류
  
  못된고양이
  1. 곱창/집게핀 3개 골라담기 9,900원
  2. 겨울양말 4개 골라담기 12,000원

  특별 행사장 유명 브랜드 초특가 할인전
  ※기간 : 11/23(토) ~ 12/15(일)
  스포츠&골프의류/여성복/신발 전품목 1만원 부터

  롯데마트 구미점 리뉴얼 OPEN 1주년 기념 이벤트

  EVENT 1. 7만원 이상 구매시 안성탕면 5개입 증정!
  ▶기간 : 11/30(토) ~ 12/1(일)
  ※일 200명, 총 400명 한정
  ※영수증 지참 후 '도와드리겠습니다 센터'에서 수령
  ※1인 1일 1개 한정 ※당일 영수증에 한함

  EVENT 2. 97개 입점매장에서 QR코드 스캔하면 5,000원 할인쿠폰 증정!
  ▶이벤트 기간 : 11/28(목) ~ 12/10(화)
  ▶대상매장 : 롯데마트 구미점 97개 입점매장
  ▶수령 방법 : 구미점 입점매장 QR스캔 후 다운로드
  ▶사용 장소 : 구미점 롯데마트 매장
  ▶사용 기간 : 다운로드 당일 영업시간 한정
  ※롯데마트GO회원 1인당 하루 최대 1회 한정 ※타 할인권 및 쿠폰 중복 적용 불가
  ※담배, 주류, 종량제봉투, 전자게임 등 일부 제외

  ※정상가란? 2024년 11월 7일(목) ~ 11월 27일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
    구미점 단독 SALE 상품을 만나보세요!

    L.POINT 50%
    미국산 초이스 척아이롤(100g/냉장/미국산) 1,690원

    L.POINT 30%
    미국산 초이스 꽃갈비살(100g/냉장/미국산) 6,930원

    1등급 한우 국거리/불고기(각 100g/냉장/국내산 한우) 2,990원

    L.POINT 25%
    대용량 봉지굴(500g/냉장/국산) 14,925원

    L.POINT 30%
    껍질벗긴 새우(750g/냉동/원산지 별도표기) 13,930원

    L.POINT 30%
    프리미엄 시그니처 장어 초밥(8입/원산지 별도표기) 8,680원

    L.POINT 4,000원
    양반 들기름 향이 그윽한 김(식탁 4.5g×20봉) 6,980원

    애호박(1개/국산) 1,490원

    상생 양배추(1통/국산) 2,990원

    제주 GAP 감귤(3kg/박스/국산) 11,990원

    L.POINT 5,000원
    비파괴 당도선별 샤인머스캣(1.5kg/박스/국산) 8,990원

    4,000원 할인
    ALL NEW 연어파티 초밥(20입/1팩/원산지 별도표기) 15,990원

    하나사면 하나 더 1+1

    1+1
    종가 맛김치(900g) 15,900원

    1+1
    CJ 김치/새우왕교자(420g×2/315g×2) 각9,480원
    ※교차구매 가능
    ※기간 : 11/28(목)~12/4(수)

    1+1
    고래사 꼬치어묵(446g) 6,940원
    ※기간 : 11/28(목)~12/4(수)

    1+1
    한성 몬스터 크랩(142g×2) 6,990원

    1+1
    사조대림 로얄크랩(100g×2) 5,980원

    1+1
    동원 롤 유부초밥 2종(각 254g) 각5,980원
    ※고소한맛/새콤한맛 ※교차구매 가능

    1+1
    삼립 포차우동 2종(각 2인) 각5,980원
    ※담백한맛/얼큰한맛 ※교차구매 가능

    1+1
    CJ 비비고 사골곰탕(500g) 2,180원

    1+1
    오뚜기 케찹/마요네즈 4종(상품별 규격 상이) 각2,180~5,950원
    ※동일 상품에 한함 ※재고소진 시 행사 조기 종료

    1+1
    덴마크 인포켓치즈(200g/10입) 14,000원

    1+1
    삼육 검은콩과 칼슘 파우치(190ml×20입) 19,900원

    1+1
    동서 미떼 핫쵸코 2종(각 30g×10입) 각7,120원
    ※마일드/오리지날 ※교차구매 가능

    1+1
    녹차원 꿀 유자차/레레몬생강차(각 1kg) 각9,900원
    ※교차구매 가능
    
    2개 이상 30%
    농심 안성탕면 3종/올리브 짜파게티(각 5개입) 2개 이상 구매시 1개당 각2,590/3,416원
    ※1개 구매시 각 3,700/4,880원 ※교차구매 가능

    L.POINT 500원 
    삼립 단팥호빵/야채호빵(각 95g×4) 4,880/5,380원

    1,400원 할인
    남양 맛있는우유GT 기획(900ml×2) 4,480원

    700원 할인
    빙그레 요플레 10입 기획(85g×10입) 5,980원

    L.POINT 20,000원
    1865 헤리티지 블렌드(750ml) 32,900원

    L.POINT 8,200원
    산토리 가쿠빈(700ml) 33,800원

    최대 1,000원 할인
    오리온 참붕어빵(29g×8입)/오리온 초코파이(39g×27입) 3,820/8,720원

    1,000원 할인
    크라운 하임 초코/화이트(각 284g) 각3,780원

    30%
    제주 삼다수 그린 2종(2L×6입/500ml×20입) 4,536/6,720원

    7,000원 할인
    종근당건강 아임비타 멀티비타민 이뮨 플러스(23g×7ea) 9,990원

    살림안정 물가안정 인기 생필품

    1,500원 할인 
    위드샨 60초 네일 매니큐어(각 9ml) 각3,500원
    ※컬러/글리터 35종 ※점별 운영상품 상이
    ※케어류, 탑/베이스코트, 리무버 등 제외

    최대 4,000원 할인 -> 롯데/신한/NH농협/삼성카드 15% 추가할인
    니베아 바디로션 기획 2종(각 400ml×2) 12,070/13,430원
    ※인텐시브/SOS ※페이지 하단 카드할인 세부내용 참고

    2개 이상 50%
    퍼실 디스크 4종(각 26개입) 2개 이상 구매시 1개당 각12,950원
    ※1개 구매시 각 25,900원
    ※파워/라벤더/컬러/실내건조 ※교차구매 가능

    가그린 1개 이상 구매시 가그린(250ml) 샘플 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
    ※구분자 " ㉹ "를 확인하세요
    ※한정수량 증정 ※성인 가그린만 해당

    가그린 전품목(상품별 상이) 각4,200~14,980원

    롯데/신한/NH농협/삼성카드 해당카드 2개 이상 50%
    크리넥스 수프림소프트 3겹 화장지(27m×30롤) 각19,700원
    ※1개 구매시 39,400원
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 50%
    테팔 스타트 이지 프라이팬 5종(상품별 상이) 20,400~27,900원

    40%
    오늘좋은 말랑보들 이불(S : 150×200cm/Q : 180×220cm) 29,940/35,940원
    ※크림블루/민트크림 ※입점점에 한함 ※해당상품에 한함

    30%
    에너자이저 맥스 10+5입(AA/AAA) 각8,330원

    L.POINT 30%
    어린이 인기완구
    그래비트렉스 전품목(상품별 상이) 11,140~279,930원

    30%
    반려동물 용품
    콜리올리 도톰한 3~4회용 배변패드 3종(M 80매/L 30매/XL 20매) 9,730~14,630원

    ※정상가란? 2024년 11월 7일(목) ~ 11월 27일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
];

const L756data = [
  `
  <div>
  ·롯데마트맥스 창원중앙점 ·전단적용기간 : 2024. 11. 28(목) ~ 2024. 12. 11(수)

  롯데마트맥스 창원중앙점 리뉴얼 OPEN

  12월 1주 4일 특가
  기간 : 11/28(목) ~ 12/1(일)

  롯데카드/신한카드 3,000원 할인
  설향딸기(500g/팩/국산) 12,980원

  롯데카드/신한카드 2,700원 할인
  신선한계란(특/30입/국산) 4,980원

  L.POINT 7,000원 할인
  새우살 71-90(908g/팩/냉동/베트남산) 12,980원

  L.POINT 25% 할인
  1등급 한우등심(100g/냉장/국내산 한우) 6,960원

  4,000원 할인
  남양 루카스나인 더블샷 라떼(14.9g×60입) 18,980원

  L.POINT 1,000원 할인
  박카스F(120ml×20입) 11,980원

  4,000원 할인
  메디필 레드락토 콜라겐 클렌징 오일 18,980원

  2,000원 할인
  스테인레스 원형접시 세트 6P(소접시 3P, 대접시 3P) 9,880원

  12월 2주 4일 특가
  기간 : 12/5(목) ~ 12/8(일)

  자이언트 석류(1개/미국산) 5,480원
 
  애호박(1개/국산) 980원

  L.POINT 7,000원 할인
  새우살 21-25(908g/팩/냉동/베트남산) 16,980원

  2개 이상 45%
  하림 닭볶음탕(800g/냉장/국내산) 2개 이상 구매시 1개당 각4,345원
  ※1개 구매시 7,900원

  2,000원 할인
  황성주 국산 검은콩 고칼슘 두유(140ml×24) 7,980원

  2,000원 할인
  비나카페 블랙커피(2g×100입)/인스턴트 믹스커피(16g×50입) 각10,980원

  6,000원 할인
  오아 플렌티 라운드 초음파 가습기(6L) 39,800원

  4,000원 할인
  블루밍가든 공기대접세트 19,980원
  ※조기 품절될 수 있습니다

  창원중앙점 신규오픈
  ※ 더 많은 행사가 준비되어 있습니다!

  500평대 다이소 오픈! HIT상품 다량입고!

  다양한 피자와 델리 메뉴! 한강라면까지 한자리에!

  해당상품 구매시 1.25L 콜라 증정! ※당일 영수증에 한함 
  18인치 콤비네이션(1판/원산지 별도표기) 18,900원

  신선한 빵과 합리적인 가격의 베이커리 브랜드! 명품단팥빵(4입/원산지 별도표기) 6,900원

  헬스보이짐&골프인 창원 최초 입점! 12월 27일 오픈 예정! 헬스 해외 유명 기구 경남 최대 보유!
  사전 모집회원 대상!
  혜택 1. 골프 등록시 헬스 무료!
  혜택 2. 선착순 50명 60% 할인!

  창원중앙점 새단장 OPEN 이벤트!
  EVENT 1. 7만원 이상 maxx 구매시 갑티슈 3입 증정! 
  ※기간 : 11/28(목) ~ 12/4(수)
  ※총 1,000명 한정
  ※영수증 지참 후 ‘도와드리겠습니다’ 센터에서 수령
  ※당일 영수증에 한함 ※1인 1일 1개 한정

  EVENT 2. 7만원 이상 maxx 구매시 장바구니 증정!
  ※기간 : 12/5(목) ~ 12/11(수)
  ※총 1,000명 한정
  ※영수증 지참 후 ‘도와드리겠습니다’ 센터에서 수령
  ※당일 영수증에 한함 ※1인 1일 1개 한정

  창원중앙점 10만원 이상 구매시 5,000원 할인쿠폰 증정!
  ※맥스 창원중앙점 카톡플친 추가완료 고객에 한함
  ※L.POINT 회원에 한함 ※기간 내 1인 1회 한정
  ※롯데마트맥스 창원중앙점에서만 사용 가능
  ※타 할인권 및 적립권 중복적용 불가
  ※담배, 주류 구매금액 합산 불가
  ※결제취소 및 결제수단 변경시 할인권 및
  이에 상응하는 금액으로 환불해 드리지 않으며,
  결제취소 및 재결제시 할인권 사용기간 이후에는
  적용이 불가합니다

  ※정상가란? 2024년 11월 7일(목) ~ 11월 27일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
  스위트마운틴 바나나(1kg 내외/원산지별도표기) 2,280원
  ※기간 : 11/28(목)~12/4(수)

  커팅파인애플(1.5kg/팩/원산지별도표기)/칠레산 블루베리(310g/팩) 11,980/7,980원

  양상추(1통/국산) 2,180원
  ※기간 : 11/28(목)~12/4(수)

  상생 파프리카(700g/봉/국산) 2,980원
  ※기간 : 12/5(목)~12/11(수)

  재첩국(500g×2봉/냉장/원산지 별도표기)/노르웨이 고등어필렛(1.5kg/팩/냉동/노르웨이산) 5,980/16,980원

  L.POINT 최대 5,000원 할인
  성경재래식탁김(5g×30봉)/속살 부드러운 황태채(250g) 7,980/9,980원
  ※원산지 별도표기

  L.POINT 2,000원 할인
  BBQ오징어(300g/원산지 별도표기) 9,980원

  L.POINT 25% 할인
  1등급 이상 돼지 앞다리(100g/냉장/국내산) 960원

  L.POINT 10,000원 할인
  LA식 소 꽃갈비(2kg, 냉동, 호주/미국) 각64,800원

  하림 더 건강한 두마리 치킨(팩/닭고기 : 국내산) 14,980원

  베이커리코너 해당상품 단독 최대 2천원 할인

  1,000원 할인
  톡톡 깨먹는 티라미수(3입/1팩/원산지 별도표기) 각8,900원

  2,000원 할인
  바닐라&블루베리 롤케익(2입/1팩/원산지 별도표기) 13,900원

  크리스마스 케이크 사전예약!
  ※사전예약 기간 : 12/5(목)~12/18(수)
  ※상품수령 기간 : 12/21(토)~12/25(수)

  10% -> 롯데카드/신한카드 3,000원 추가할인
  딸기풍년케익/초코딸기케익(각 2호/1박스/원산지 별도표기) 각24,000원

  2,000원 할인
  남양 초코에몽(190ml×24입) 13,480원

  1+1
  동원 개성 감자만두(1.2kg) 10,480원

  1,500원 할인
  큐원 트루스위트 알룰로스(1.18kg) 11,480원

  7,000원 할인
  CJ 스팸 클래식(200g×10입) 25,480원

  각 700원 할인
  오리온 포카칩(각 264g) 각3,980원
  ※오리지널/어니언맛

  5,000원 할인
  롯데 비엔나 소시지(1,000g) 8,980원

  새롭게 바뀐 주류 공간에서 인기 와인&위스키를 만나보세요!

  L.POINT 5,000원 할인
  탈리스커포트리(700ml) 86,800원

  L.POINT 2,920원 할인
  존바파이니스트(750ml) 9,880원

  L.POINT 10,000원 할인
  르 쁘띠 에뚜알 오가닉 샤르도네(750ml) 14,900원

  코젤잔기획(500ml×4) 9,900원

  필스너우르겔잔기획(500ml×4) 8,980원

  L.POINT 7,000원 할인
  1865 셀렉티드 빈야드 까베르네 소비뇽(청뱀띠에디션)(750ml) 29,800원

  2,000원 할인
  맥스 프리미엄 화장지(40m×30롤) 17,980원

  1,500원 할인
  맥스 3겹 키친타월(150매×12롤) 12,780원

  2,000원 할인
  맥스 스탠다드 중목 양말 6매입(남성/여성) 각6,980원

  2,000원 할인
  비더살롱 크리닉 트리트먼트(1,000ml) 9,980원

  4,000원 할인
  밀크바오밥 세라 바디워시 화이트솝(1.2L) 9,980원

  5,000원 할인
  실바트 미라클 사각팬 17,980원
  
  5,000원 할인
  통3중스텐가마솥(20cm) 17,980원

  1,000원 할인
  썬부탄가스(4P) 4,980원

  롯데마트맥스 창원중앙점 문화센터 크리스마스 EVENT 
  ※접수처 : 맥스창원중앙점 문화센터
  ※접수문의 : 055-240-2590~1
  ※접수 문의처로 연락 주시면 더 다양한 강좌를 만나보실 수 있습니다

  산타 할아버지의 고장난 마법 썰매
  일정: 12/14(토) 15:40~16:20(4세 이상)
  참가비: 2,000원

  딸기 듬뿍 케이크 만들기
  일정: 12/15(일) 11:30~12:20(대상 : 5~9세,보호자 1인 동반)
  참가비: 2,000원
  재료비: 20,000원

  ※정상가란? 2024년 11월 7일(목) ~ 11월 27일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getL601data = () => {
  const dataArray = [];
  L601data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: "생활공감",
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL901data = () => {
  const dataArray = [];
  L901data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: "룸바이홈",
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL903data = () => {
  const dataArray = [];
  L903data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 설 사전예약 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL793data = () => {
  const dataArray = [];
  L793data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 맥스 설 사전예약 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL909data = () => {
  const dataArray = [];
  L909data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `super_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 설 사전예약 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL920data = () => {
  const dataArray = [];
  L920data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `ilsan_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼(백)일산점 추석 사전예약 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL905data = () => {
  const dataArray = [];
  L905data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 전점 본세트 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL809data = () => {
  const dataArray = [];
  L809data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `super_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 본세트 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL791data = () => {
  const dataArray = [];
  L791data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트맥스 본세트 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL439data = () => {
  const dataArray = [];
  L439data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `dogok_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 그랑그로서리 도곡점 단독 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL211data = () => {
  const dataArray = [];
  L211data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `gumi_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 구미점 모바일 단독 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL231data = () => {
  const dataArray = [];
  L231data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `gumi_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 구미점 모바일 단독 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};
const getL330data = () => {
  const dataArray = [];
  L330data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `tru_${fillZero(2, String(i + 1))}.jpg`,
      title: `토이저러스 모바일 단독 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};
const getL441data = () => {
  const dataArray = [];
  L441data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `wonju_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 원주점 모바일 단독 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

// test
const getL999data = () => {
  const dataArray = [];
  L999data.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 테스트 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL419data = () => {
  const dataArray = [];
  L419data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `dogok_${fillZero(2, String(i + 1))}.jpg`,
      title: `슈퍼 프리미엄 도곡점 모바일 단독 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL511data = () => {
  const dataArray = [];
  L511data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `gumi_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트 구미점 모바일 단독 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getL756data = () => {
  const dataArray = [];
  L756data.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `changwon_${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데마트맥스 창원중앙점 ${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L601: {
    title: "스마트전단지-매거진601",
    category: [],
    type: "",
    isFooter: false,
    imgPath: "images/fixed/601",
    contents: getL601data(),
  },
  L901: {
    title: "스마트전단지-룸바이홈901",
    category: [],
    type: "",
    isFooter: false,
    imgPath: "images/fixed/901",
    contents: getL901data(),
  },
  L903: {
    title: "전점 설 사전예약903",
    category: [],
    type: "HYPER",
    isFooter: false,
    imgPath: "images/202412_3/09",
    contents: getL903data(),
  },
  L793: {
    title: "맥스 설 사전예약793",
    category: [],
    type: "MAXX",
    isFooter: true,
    imgPath: "images/202412_3/09",
    contents: getL793data(),
  },
  L909: {
    title: "슈퍼 설 사전예약909",
    category: [],
    type: "SUPER",
    isFooter: true,
    imgPath: "images/202412_3/09",
    contents: getL909data(),
  },
  L920: {
    title: "슈퍼(백)일산점 추석 사전예약920",
    category: [],
    type: "SUPER",
    isFooter: true,
    imgPath: "images/202408_1/09",
    contents: getL920data(),
  },
  L905: {
    title: "전점 본세트 905",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL905data(""),
  },
  L906: {
    title: "전점 본세트 906",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL905data(""),
  },
  L809: {
    title: "롯데슈퍼 본세트 809",
    category: [],
    type: "SUPER",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL809data(""),
  },
  L810: {
    title: "롯데슈퍼 본세트 810",
    category: [],
    type: "SUPER",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL809data(""),
  },
  L791: {
    title: "롯데마트맥스 본세트 791",
    category: [],
    type: "MAXX",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL791data(""),
  },
  L792: {
    title: "롯데마트맥스 본세트 792",
    category: [],
    type: "MAXX",
    isFooter: true,
    imgPath: "images/202409_1/09",
    contents: getL791data(""),
  },
  L439: {
    title: "그랑그로서리도곡점439",
    category: [],
    type: "SUPER",
    isFooter: true,
    imgPath: "images/202412_3/09",
    contents: getL439data(),
  },
  L211: {
    title: "구미점 모바일전단 211",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202412_2/01",
    contents: getL211data(""),
  },
  L212: {
    title: "구미점 모바일전단 212",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202412_2/01",
    contents: getL211data(""),
  },
  L231: {
    title: "구미점231",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202412_3/01",
    contents: getL231data(""),
  },
  L330: {
    title: "TRU330",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202412_3/09",
    contents: getL330data(""),
  },
  L331: {
    title: "TRU331",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202412_3/09",
    contents: getL330data(""),
  },
  L441: {
    title: "원주점 모바일전단 441",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_4/01",
    contents: getL441data(""),
  },
  L442: {
    title: "원주점 모바일전단 442",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202409_4/01",
    contents: getL441data(""),
  },
  L999: {
    title: "스마트전단지-테스트999",
    category: [
      "오늘소식",
      "매일신선",
      "하루식사",
      "담소시간",
      "건강생활",
      "홈&패션",
    ], //전시 카테고리 6개 (주마다 현업에서 확정 필요)
    isFooter: true, //롯데마트몰 바로가기 사용 유무 (공통:HYPER,ZETTA 만 사용. 변경 시 현업에서 확정 필요)
    isCoupon: true, //쿠폰 유무 (주마다 현업에서 확정 필요)
    type: "HYPER", //점포타입 'HYPER' | 'ZETTA' | 'VIC' | 'MAXX' | 'SUPER' | ''(룸바이홈, 생활공감)
    imgPath: "images/fixed/01/",
    contents: getL999data(),
  },
  L419: {
    title: "슈퍼 프리미엄 도곡점 모바일전단 419",
    category: [],
    type: "SUPER",
    isFooter: true,
    imgPath: "images/202411_4/09",
    contents: getL419data(""),
  },
  L511: {
    title: "구미점 모바일전단 511",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202411_5/01",
    contents: getL511data(""),
  },
  L512: {
    title: "구미점 모바일전단 511",
    category: [],
    type: "HYPER",
    isFooter: true,
    imgPath: "images/202411_5/01",
    contents: getL511data(""),
  },
  L756: {
    title: "롯데마트맥스 창원중앙점 756",
    category: [],
    type: "MAXX",
    isFooter: true,
    imgPath: "images/202411_5/07",
    contents: getL756data(""),
  },
  L757: {
    title: "롯데마트맥스 창원중앙점 757",
    category: [],
    type: "MAXX",
    isFooter: true,
    imgPath: "images/202411_5/07",
    contents: getL756data(""),
  },
};

export default data;
