//링크
export const LEAFLET_URL = {
  a1: "https://www.lotteon.com/p/display/shop/seltDpShop/12919?mall_no=4",
  a2: "http://toysrus.lottemart.com",
  chu2020: "https://www.lotteon.com/p/display/shop/seltDpShop/29638",
  main: "http://www.lottemart.com",
};

export const IMAGE_PATH = `${process.env.REACT_APP_BASE_S3_IMAGE}/leaflet`;

/* 지난 전단지 reload */
export const leafletState = {
  // openDate는 오픈날짜로 변경(매주 목요일)
  // 전점,제타플렉스,맥스 위치 고정
  MART: {
    openDate: process.env.REACT_APP_TYPE === "DEV" ? "20241210" : "20241212",
    thisWeek: [
      201, //전점 GO(~12/11) **위치 고정
      203, //제타플렉스 GO(~12/11) **위치 고정
      758, //맥스 GO(~12/11) **위치 고정

      205, //제타플렉스 서울역점 GO(~12/11)
      211, //구미점 GO(~12/11)
      756, //맥스 창원중앙점 GO(~12/11)

      ////////////////////////////////////
      202, //전점 LMS(~12/11)
      204, //제타플렉스 LMS(~12/11)
      759, //맥스 LMS(~12/11)

      206, //제타플렉스 서울역점 LMS(~12/11)
      212, //구미점 LMS(~12/11)
      757, //맥스 창원중앙점 LMS(~12/11)

      //  작업 x
      601, //고정 (생활공감)
      901, //고정 (룸바이홈)

      // 테스트용 고정
      999,
    ],
    willThisWeek: [
      301, //전점 (~12/18) **위치 고정
      303, //제타플렉스 (~12/18) **위치 고정
      738, //맥스 (~12/25) **위치 고정
      305, //제타플렉스 서울역점 (~12/18)

      231, //구미점(~12/18)
      330, //토이저러스 (~12/25)
      331, //토이저러스 LMS (~12/25)
      903, //전점 설 사전예약(~1/18)
      793, //맥스 설 사전예약(~1/18)

      //  작업 x
      601, //고정 (생활공감)
      901, //고정 (룸바이홈)

      // 테스트용 고정
      999,
    ],
  },
  // 롯데슈퍼 대표 전단
  // 성수연 담당 요청 (24.03.28) 슈퍼 LMS 추가
  SUPER: {
    openDate: process.env.REACT_APP_TYPE === "DEV" ? "20241210" : "20241212",
    thisWeek: [
      209, //슈퍼(~12/11)
      210, //슈퍼 LMS (~12/11)
    ],
    willThisWeek: [
      309, //슈퍼(~12/18)
      439, //그랑그로서리도곡점(~12/18)
      909, //슈퍼 설 사전예약(~1/18)
    ],
  },
  // 롯데슈퍼 추가 전단(설,명절 등)
  /*SUPER_THEME: {
    openDate: "20230831",
    thisWeek: [505],
    willThisWeek: [105],
  },*/
};
