import styled from "styled-components";

/**
 * @name 메인 공통 컴포넌트
 */
export const StyledMain = styled.main`
  padding: 0 1rem 3rem 1rem;
`;

/**
 * @name 메인 고정배너 영역 컴포넌트
 */
export const StyledMainFixedBanner = styled.ul`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
`;

/**
 * @name 메인 이벤트배너 영역 컴포넌트
 */
export const StyledMainEventBanner = styled.ul`
  margin-top: 1.5rem;
  li {
    margin-bottom: 0.7rem;
    img {
      width: 100%;
    }
  }
`;
