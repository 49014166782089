import styled from "styled-components";
import { thejamsilFont } from "../styles/font";
import { isMcouponApp } from "../utils/utility";

/**
 * @name 공통 헤더(WEB)
 */
export const StyledHeader = styled.header`
  padding: 1rem; //1rem 1rem 1rem 2rem;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
  .header_area {
    display: grid;
    grid-template-columns: 3.5rem auto 4.5rem;
    align-items: center;
    button {
      background-color: #fff;
    }
    .header_homebtn {
      position: relative;
      button {
        position: absolute;
        right: 0;
        transform: translate(0, -50%);
        top: 50%;

        width: 2.5rem;
        height: 2.5rem;
      }
    }
    .header_appbtn {
      width: 4.5rem;
      height: 2.2rem;
      right: 0;
    }
    h3 {
      ${thejamsilFont("regular")}
      text-align: center;
      font-size: 15px;
    }
  }
`;

/**
 * @name 공통 이미지(WEB)
 */
export const StyledFixedImage = styled.section`
  width: 100%;
  padding: ${!isMcouponApp ? "0" : "3.1rem"} 0 1.5rem;
  img {
    width: 100%;
  }
`;
