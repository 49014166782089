import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";

import { leafletState } from "../LM/constants";
import { gaPageView } from "../../utils/ga";

// 전단 지점별 QR 스캔 시 중계페이지 /launcher?storeType=HYPER
function Launcher() {
  const location = useLocation();
  const params = queryString.parse(location.search);

  //지점타입 구분 index 값 추출 (VIC 미운영)
  const storeType =
    (params.storeType === "VIC" ? "MAXX" : params.storeType) || "HYPER";
  const storeTpyeIndex = ["HYPER", "ZETTA", "MAXX"].findIndex(
    (type) => type === storeType
  );

  //웹 전단으로 redirect
  const getCrntTimeCheck = useCallback(() => {
    axios
      .post(
        `${process.env.REACT_APP_API}/fo/user/common/current-time`,
        {},
        {
          headers: {
            "x-ssp-channel": "1",
            "Content-Type": "application/json; charset=utf-8",
            "x-ssp-gateway-service-id": "USER",
          },
        }
      )
      .then((res) => {
        const crntTime = res.data.data.current_time.substr(0, 8);

        const leafletIndex =
          crntTime - leafletState["MART"].openDate >= 0
            ? leafletState["MART"].willThisWeek
            : leafletState["MART"].thisWeek;
        window.location.href = `/${
          leafletIndex[storeTpyeIndex < 0 ? 0 : storeTpyeIndex]
        }`;
      });
  }, [storeTpyeIndex]);

  useEffect(() => {
    getCrntTimeCheck();
  }, [getCrntTimeCheck]);

  useEffect(() => {
    if (!storeType) return;
    gaPageView(`QR 스캔 진입(${storeType})`, location.pathname);
  }, [location.pathname, storeType]);

  return <></>;
}

export default Launcher;
