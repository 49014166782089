import { getUA, isAndroid, isIOS } from "react-device-detect";

const isAndWebView = !!window.binder;
const isIOSWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(getUA);
export const isMcouponApp = navigator.userAgent.indexOf("_mcoupon") > -1;

/**
 * 값 저장
 * @param key  string
 * @param value  string
 */
export const setKeyValue = (key, value) => {
  try {
    if (isIOSWebView) {
      window.webkit.messageHandlers.setKeyValue.postMessage({
        key: key,
        value: value,
      });
    } else if (isAndWebView) {
      window.binder?.setKeyValue(key, value);
    }
  } catch (e) {
    console.log("bridgeError setKeyValue ", key);
  }
};

/**
 * 값 조회
 * @param key  string
 * @param callback  string
 */
export const getKeyValue = (key, callback) => {
  try {
    if (isIOSWebView) {
      window.webkit.messageHandlers.getKeyValue.postMessage({
        key: key,
        callback: callback,
      });
    } else if (isAndWebView) {
      window.binder?.getKeyValue(key, callback);
    }
  } catch (e) {
    console.log("bridgeError getKeyValue ", key);
  }
};

/**
 * 전단 웹뷰 열기
 * @param url  string
 */
export const openLeaflet = (url) => {
  try {
    if (isIOSWebView) {
      window.webkit.messageHandlers.openLeaflet.postMessage({ url: url });
    } else if (isAndWebView) {
      window.binder?.openLeaflet(url);
    }
  } catch (e) {
    console.log("bridgeError openLeaflet");
  }
};

/**
 * 앱 다운받기 (앱이 있는 경우 앱 열기)
 * @param url  string (https://~)
 */

export const appStoreMove = (url) => {
  if (!isMcouponApp) {
    try {
      //앱 열기
      if (isIOS) {
        window.location.href =
          getUA.indexOf("mcoupon") > -1
            ? url
            : "lottemart-mcoupon-iphone://?url=" + url;
      } else if (isAndroid) {
        var browser = getUA.toLowerCase();

        if (
          parseFloat(browser.substring(browser.indexOf("android") + 8, 3)) >=
          4.4
        ) {
          window.location.href =
            "intent://m.coupon.lottemart?url=" +
            url +
            "#Intent;scheme=lmscp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.lottemart.lmscp;end";
        } else {
          window.location.href = "lmscp://m.coupon.lottemart?url=" + url;
          setTimeout(function () {
            window.location.href = "market://details?id=com.lottemart.lmscp";
          }, 300);
        }
      }
    } catch (e) {
      //오류 시 스토어
      if (isIOS) {
        window.location.href = "https://itunes.apple.com/app/id987435592";
      } else {
        window.location.href = "market://details?id=com.lottemart.lmscp";
      }
    }
  }
};
