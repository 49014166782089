import React from "react";
import { StyledHeader } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { gaEvent } from "../utils/ga";
import { appStoreMove } from "../utils/utility";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const pageName = "전단헤더";

  const handleClickMain = () => {
    gaEvent(pageName, `메인으로가기`);
    navigate("/");
  };

  //앱다운받기 클릭
  const handleClickOpenApp = () => {
    gaEvent(pageName, `APP다운받기`);

    // 앱 전단으로 이동
    const url = `${process.env.REACT_APP_GO_URL}/leaflet?leafletWv_yn=Y&_from=J`;

    appStoreMove(url);
  };

  return (
    <StyledHeader>
      <div className="header_area">
        <div className="header_homebtn">
          {location.pathname !== "/" && (
            <button onClick={handleClickMain}>
              <img
                src={`${process.env.REACT_APP_BASE_S3_IMAGE}/icons/icon_home.png`}
                alt="메인으로가기"
              />
            </button>
          )}
        </div>
        <h3>
          롯데마트GO에서 <br />더 많은 혜택을 만나보세요!
        </h3>
        <div className="header_appbtn">
          <button onClick={handleClickOpenApp}>
            <img
              src={`${process.env.REACT_APP_BASE_S3_IMAGE}/leaflet/common/leaflet_common_appBtn.png`}
              alt="앱 다운받기"
            />
          </button>
        </div>
      </div>
    </StyledHeader>
  );
}

export default Header;
