import React from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { isMobile } from "react-device-detect";
import { isMcouponApp } from "./utils/utility";

import "./styles/reset.css";
import "./styles/swiper.min.css";

import Launcher from "./containers/Launcher";
import LM from "./containers/LM";
import Main from "./containers/Main";
import Header from "./components/Header";

function App() {
  const addGoogleAnalyticsScript = () => {
    return {
      __html: `
        window.gtag('config', ${process.env.REACT_APP_GA_TRACKING_ID});
      `,
    };
  };

  return (
    <>
      <Helmet>
        {/* Global site tag (gtag.js) - Google Analytics
            페이지뷰 - window.gtag('config','GA_TRACKING_ID',{page_title:'',page_location:location.pathname,page_path:'[롯데마트 GO] 홈'});
            이벤트 - window.gtag('event', '액션', {event_category: '카테고리',event_label: '라벨'});
        */}
        <script
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`}
        ></script>
        <script dangerouslySetInnerHTML={addGoogleAnalyticsScript()} />
      </Helmet>

      {/* 모바일 웹에만 노출 - PC, APP 미노출 (isMobileOnly는 safari에서 false 리턴) */}
      {!isMcouponApp && isMobile && <Header />}

      <Routes>
        <Route exact path="/launcher" element={<Launcher />} />
        <Route exact path="/" element={<Main />} />
        <Route exact path="/*" element={<LM />} />
      </Routes>
    </>
  );
}

export default App;
